.bop-classification-building-details {
  .field-row {
    .field-label {
      @include make-sm-column(6);
      @include make-md-column(5);
      padding-right: 0;
      min-height: $field-min-height;
    }

    .field-input {
      @include make-sm-column(5);
      align-items: center;
    }

    + .button-row {
      display: flex;
      justify-content: flex-end;
      @include make-sm-column-offset(6);
      @include make-md-column-offset(5);
    }
  }

  .classification-section {
    min-height: 410px;

    > .field-row {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .building-details-section {
    .input-size {
      width: 55%;
    }
  }

  .valuation {
    margin-left: 5px;
  }

  .icon-launch-white {
    @include square(19px);
    @include icon();
  }
}
