@import "@clearquote/ui/layout/common/common";

// 'button' is the generic version of the group of button for checking
$button-select-group-list: "state" "button";

@each $selection-entry in $button-select-group-list {
  .#{$selection-entry}-selection-container {
    @include button-selection-container();

    .#{$selection-entry}-option-container {
      @include button-option-container();
    }

    .btn-default {
      @include button-selection-btn-default();
    }
  }
}

.page-account-setup {
  .cqui-table {
    .table-col-address {
      width: 30%;
    }

    .table-col-city-state {
      width: 30%;
    }
  }

  .multi-tag-selection-group {
    max-height: 135px;
    overflow: auto;
  }

  .icon-check-blue {
    margin-left: 15px;
  }
}

.customer-type {
  margin-right: 5px;
  margin-top: 4px;
}
