.modal-open .modal {
  display: block;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  .modal-header > .section {
    width: 100%;
  }
  .panel-info {
    width: 100%;
  }
}

.modal.fade {
  opacity: 1;
}

.modal.fade .modal-dialog,
.modal.in .modal-dialog {
  transform: none;
}

.modal-open .modal .full-screen-modal {
  display: block;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 100%;
}

.modal-dialog {
  margin: 0;
}

.modal.modal-common {
  /* The additional padding from the mockup site causes issues when reducing the height of the viewport */
  padding-top: 0px;

  .modal-body {
    padding: 0 15px;

    /* Override additional padding that leaked from the mockup site */
    .section .field-row:nth-child(1):nth-last-child(1) {
      margin: 0px 0px 5px 0px;
    }
  }

  .modal-footer {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    .vbr-continue-button {
      float: left;
    }
  }
  .modal-dialog {
    margin: 0;
  }
}

/* KY Tax Exemption Questions Modal */
.modal.ky-tax-exemption-modal-type {
  .ky-tax-notification {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 10px;

    .icon-exclamation-warning {
      height: 16px;
      width: 16px;
      background-size: 100% 100%;
      margin-right: 5px;
    }
  }
}

/*
	It appears that the version of bootstrap.ui we're using only supports a single modal backdrop.
	This should get us most of the way to having the appearing of multiple modal backdrops.
*/
.modal + .modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.please-wait-modal {
  .modal-dialog {
    z-index: 1000;
    width: auto;
    display: flex;
  }

  .modal-content {
    min-width: 460px;
    max-width: 900px;
    background-color: #e5f4ff;
    display: flex;
    align-items: flex-start;
    margin: auto;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 3px;
    border: 0 solid;
    box-shadow: -3px 4px 3px 0 rgba(50, 50, 50, 0.9);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;

    .modal-header {
      padding: 0px 15px 0px;
      border-bottom: 0px none;
      margin: auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      font-weight: normal;

      // Some styles where introduced in modals.scss which are more specific, and causing issues
      // TODO: we need to consolidate our modal scss, and get a little bit of consistency in our stylings / layouts
      padding-top: 0px !important;
      padding-left: 20px;
      padding-right: 20px;

      .content {
        display: inline-block;
      }
      .section {
        width: 100%;
      }
    }

    .text {
      padding-left: 11px;
      margin: auto;
      margin-top: 0;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0%;
      flex: 1 1 0%;
      width: 300px;

      font-weight: normal;
      margin: 0;
      -ms-flex-item-align: center;
      -webkit-align-self: center;
      -ms-grid-row-align: center;
      align-self: center;

      padding-left: 11px;
      margin-top: 0;
      width: 500px;
      margin-left: 4px;

      p {
        font-size: 14pt;
      }
    }

    p {
      float: left;
      line-height: 1.15em;
      margin-bottom: 0;
    }
  }

  .dancing-dots-loader-popup {
    position: relative;
    width: 80px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    background-size: 100% 100%;
  }
}

/* Progress Modal */
.modal.modal-common.modal-type-progress {
  .modal-body {
    display: flex;
    align-items: center;

    margin-top: 15px;
    padding: 0 15px;

    .progress-indicator {
      height: 40px;
      width: 80px;
      background-size: 100% 100%;
      margin-left: 20px;
    }

    .progress-message {
      font-size: 14pt;
      margin-left: 15px;
    }
  }
}

/* State Selector */
.modal.modal-type-state-selection {
  padding-top: 0px;

  .state-selection-container {
    .btn-default {
      display: initial;
    }
  }

  .button-option-container {
    margin-right: 5px;
  }

  .panel-body {
    margin-bottom: 0px;
    padding: 0px;
  }
}

/* Add Driver (CA Information page) */
.modal.modal-type-add-driver {
  padding-top: 0px;

  .modal-dialog {
    width: 650px;
  }

  .modal-body .panel .panel-body {
    margin-bottom: 0px;
    padding: 0px;
  }

  .modal-content .form-control {
    float: none;
  }
}

/* Multiple VIN Lookup */
.modal-type-add-multiple-VIN .modal-dialog {
  width: 475px !important;
  height: 450px !important;
}

.modal {
  .contact-container.modal-body
    .panel-default
    > .panel-body
    > .contact-info-field {
    padding: 5px 10px;
  }

  .contact-container .contact-body .select-contact-name,
  .contact-container .contact-body .select-contact-address {
    padding: 0;
  }

  .contact-container.modal-body {
    padding: 0 15px 15px;
  }
}

/* Add Named Insured */

.modal.modal-common.modal-type-add-named-insured {
  .modal-body {
    padding: 0 15px;
  }

  .panel-body {
    &.contact-body {
      border: 1px solid $medium-gray;
    }

    &.white-bg-border {
      border: 1px solid #ddd;
      border-radius: 5px;
      margin-bottom: 5px;
      background: #fff;
    }
    padding: 0px;

    .section {
      padding-top: 15px;

      .field-row {
        .field-input {
          .first-name {
            display: inline-block;
            width: 34%;
          }
          .middle-name {
            width: 8%;
            margin-left: 2px;
            display: inline-block;
          }
          .last-name {
            width: 34%;
            margin-left: 2px;
            display: inline-block;
          }
          .suffix {
            width: 20%;
            margin-left: 1px;
            display: inline-block;
          }
        }
      }
    }

    max-height: 500px;
  }

  .contact-popup-edit {
    position: absolute;
    top: 0px;
    right: 10px;
  }
  .read-only-address {
    color: $cerulean;
  }
  .add-contact-form {
    .contact-popup-edit {
      position: absolute;
      top: 10px;
      right: 15px;
    }
  }

  .individual {
    .field-input {
      display: inline-flex;
      align-content: center;
      justify-content: space-between;

      .first-name {
        width: 34%;
      }

      .middle-name {
        width: 8%;
        margin-left: 2px;
      }

      .last-name {
        width: 34%;
        margin-left: 2px;
      }

      .suffix {
        width: 24%;
        margin-left: 2px;
      }
    }
  }

  .bottom-divided-row:first-child {
    .field-row .field-input {
      .entity-text-field {
        border-top: 1px solid $cerulean;
        padding-top: 5px;
      }

      .entity-buttons {
        top: 5px;
      }
    }
  }

  .ae-named-insured-dynamic-entity {
    .field-description {
      line-height: 1em;

      .note {
        font-size: 10pt;
      }
    }

    .entity-buttons {
      position: absolute;
      top: 0;
      right: -55px;
      width: 65px;
    }
  }
}

.modal.modal-type-alert {
  .modal-body {
    display: flex;
    align-items: center;
  }

  .alert-icon {
    @include image-url("icon-exclamation-red.svg");
    background-size: 100% 100%;

    width: 54px;
    height: 54px;
    flex-shrink: 0;
  }

  .alert-message {
    font-size: 12pt;
    color: $black;

    .unlicensed {
      margin-bottom: 20px;
    }
  }
}

/* State Reciprocity */
.modal.modal-type-state-reciprocity {
  .modal-body {
    display: flex;
    align-items: flex-start;
  }

  .alert-icon {
    @include image-url("icon-exclamation-red.svg");
    background-size: 100% 100%;

    width: 80px;
    height: 80px;
    margin-right: 20px;

    &.alert-icon-resize {
      width: 100px;
    }
  }

  .alert-message {
    font-size: 12pt;
    margin-right: 20px;

    .unlicensed {
      margin-bottom: 20px;
    }
  }
}

/* Scheduled Coverage Table */
.modal.modal-type-scheduled-coverage {
  .modal-header {
    display: flex;
    align-items: center;

    padding-bottom: 5px;

    .modal-header-icon {
      height: 50px;
      width: 50px;
      background-size: 100% 100%;
      margin-right: 5px;
    }

    h2 {
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }

    .modal-header-button {
      margin-top: auto;
      margin-left: auto;
    }
  }

  .modal-body {
    .ae-scheduled-coverage-table {
      height: 250px;
      max-height: 250px;
      overflow-y: auto;
      background-color: white;
      border: 1px solid #ddd;

      /* The only way to easily achieve rows filling their container without expansion is to specify fixed heights */
      .cqui-table {
        border: none;

        thead {
          cqui-required-indicator {
            margin-top: 5px;
            margin-left: 0;
            display: flex;
            max-height: 15px;
            align-items: flex-start;

            span {
              margin-top: -5px;
              max-height: 15px;
            }
          }

          th {
            vertical-align: top;
            white-space: nowrap;
          }
        }

        tbody {
          tr {
            height: 46px;
          }
          .form-control {
            min-width: 150px;
          }
        }
      }
      .statutory-employer td {
        width: 25%;
        .cqui-icon-button.icon-small {
          margin-bottom: 0px;
        }

        table {
          width: calc(100% - 20px);
        }

        .shift-down {
          margin-top: 5px;
        }

        .entity-row {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          position: relative;

          .entity-add {
            position: absolute;
            right: -30px;

            tooltip {
              position: absolute;
              margin-left: 5px;
            }

            &.hide-tooltip {
              .tooltip-container {
                &.active {
                  display: none;
                }
              }
            }
          }

          .entity-input {
            min-width: 322px;
          }

          .entity-delete {
            min-height: 20px;
            min-width: 20px;
            border-radius: 50%;
            background-color: $cerulean;
            position: absolute;
            right: 6px;
            background-repeat: no-repeat;
            background-size: 100% auto;
            display: inline-block;
            vertical-align: middle;
            background-image: ae-image-url("icon-clear-circle.svg");
          }
        }
        &.multiple-entity {
          vertical-align: top;
          td {
            vertical-align: top;
          }
        }
        &.schedule-number {
          width: 10%;
        }
        &.contactor-col {
          width: 65%;
        }
        &.row-delete {
          float: right;
          margin-left: 15px;
          .multiple-entity-delete-btn {
            padding-top: 4px;
          }
          tooltip {
            margin-left: 6px;
          }
        }

        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          .shift-btn-up {
            margin-top: -13px;
          }

          .entity-delete {
            margin-top: -10px;
          }

          &.row-delete {
            float: none;
          }
        }
      }
    }
  }

  .modal-footer {
    padding-top: 15px;

    display: flex;
    align-items: center;
    justify-content: left;

    .save-button {
      margin-left: auto;
    }
  }

  .button-edit-left {
    float: left;
    text-align: left;
  }
}

.modal.modal-copy-building {
  &.modal-copy-building-prop {
    .modal-content {
      width: 600px;

      .copy-risk-form {
        width: 100%;

        .field-row {
          display: flex;

          .field-label {
            flex: 2;
          }

          .field-input {
            flex: 3;
          }
        }
      }
    }
  }

  .modal-content {
    width: 450px;
  }

  .panel-body {
    &.white-bg-border {
      border: 1px solid #ddd;
      border-radius: 5px;
      background: #fff;
      margin: 5px 0px;
    }
    padding: 0px;

    .section {
      padding-top: 3px;
      min-height: 101px;
      display: flex;
      align-items: center;
      .copy-building-form {
        padding: 5px;
      }

      .field-row {
        margin-top: 5px;
        .field-label {
          text-align: right;
          width: 70%;
        }
        .field-input {
          .no-of-copies {
            display: inline-block;
            width: 25%;
          }
        }
      }
    }
    .sub-header {
      display: block;
    }
    max-height: 500px;
  }
}

.modal.different-premium-modal {
  .alert-message {
    font-size: 14pt;
  }
}

// VBR modal
.modal-type-vbr {
  .shift-vbr-up {
    margin-bottom: 10px;
  }

  // Override the 60px image dimensions coming from the mockup site
  &.modal.modal-common .section-header-icon {
    height: 40px;
    width: 40px;
    margin-bottom: 5px;
  }
}

.alert-modal {
  .icon-exclamation-red {
    width: 30px;
    height: 30px;
    display: inline-block;
  }
  .icon-next {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

.input-group-addon {
  height: fit-content !important;
}

.modal {
  account-setup-did-you-mean {
    width: 50%;
    .ae-did-you-mean-address {
      width: 100%;
    }
  }
}

.modal.modal-common.farm-location-map {
  .panel-body {
    .section.section-highlighted-lightest {
      background-color: transparent;
    }
    .section > .section-subgroup .subsection.subsection-map .map-container {
      padding: 0;
    }
    .field-input-multiple {
      &.field-input-section-township-range {
        cq-input {
          margin-right: 5px;
          margin-bottom: 5px;
          height: auto;
          flex: 0 1 calc((100% - 10px) / 3);

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
