@import "prerequisites";

.cqui-table {
  @include alert-row("info", "global");
  @include alert-row("alert", "global");

  overflow: hidden;
  border-collapse: separate;
  @include border("all", gray);
  @include border-radius("all", table);
  text-align: center;
  margin-bottom: 0px;

  .table-col-cell {
    display: flex;
    flex: 0 1 auto;

    .button-row {
      + .table-col-text {
        margin-left: 5px;
      }
    }
  }

  & > thead {
    color: $white;

    & > tr {
      & > th {
        background-color: $cerulean;
        font-weight: normal;
        text-align: center;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        border-bottom: none;
        vertical-align: middle;
        height: 100%;

        @include text-left-selector(parent);

        .required {
          background-color: $cerulean;
          color: $white;
        }
      }

      .table-col-sort {
        &:hover {
          cursor: pointer;
        }

        span {
          display: flex;
          align-items: center;

          .sort-indicator {
            margin-left: 7px;
          }
        }
      }

      .table-col-required {
        > span {
          display: flex;
        }
      }
    }
  }

  @include table-header-asterisk-in-header();

  & > tbody > tr > td {
    padding: 10px;
    vertical-align: middle;
    border-top: none;
    @include border-radius("all", 0);

    ng-form {
      width: 100%;

      .field-row {
        width: 100%;

        .field-input {
          width: 100%;
          padding-right: 0;
        }
      }
    }
  }

  & > tbody {
    tr {
      td {
        .icon {
          @include square(25px);
          min-width: 25px;
          margin-right: 5px;
        }

        #{$lib-prefix}-icon {
          .icon {
            @include square(100%);
            margin: 0;
            min-width: auto;
          }
        }

        &.table-cell-group {
          display: flex;
          justify-content: flex-end;

          .button {
            margin-right: 5px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    > tr {
      @include table-notification-message();
    }
  }

  @include table-striped();
}
