@include classification();

.icon-map-with-pin {
  @include image-url("icon-address-available.svg");
}

.icon-copy-white {
  @include image-url("icon-copy-white.svg");
}

.icon-general-liability-black-blue {
  @include image-url("icon-general-liability-blue-black.svg");
}

.icon-add-schedule {
  background-image: ae-image-url("icon-add-schedule.svg");
}

.icon-account-add-contact {
  background-image: ae-image-url("icon-contact-add.svg");
}

.icon-question-mark {
  background-image: ae-image-url("icon-question.svg");
}

.icon-clear {
  background-image: ae-image-url("icon-clear-circle.svg");
}

.icon-copy-building {
  background-image: ae-image-url("icon-copy.svg");
}

.icon-forbidden {
  @include image-url("icon-forbidden.svg");
}

.icon-exclamation-red {
  @include image-url("icon-exclamation-red.svg");
}
