.field-row {
  .field-input {
    .form-control.multi-tag-selection-group {
      .multi-tag {
        background-color: #005e7a;
      }
    }
    @include field-input-notification();
  }
  @include field-notification-messages();
}

.dto-input-field,
.gpa-ctrl-group {
  width: 100%;
}

.input-group-addon {
  border-radius: 0;
  border-color: $input-border;
  font-size: 10.75pt;
  height: 100%;
  width: auto;
  padding: 2px 5px;
  line-height: 1.4;
  text-align: center;
}
