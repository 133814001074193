@import "@clearquote/ui/layout/common/common";

.bop-businessowners-coverages {
  .sort-indicator {
    @include icon();
    @include square(12px);
    display: inline-flex;
  }

  .policywide-additional-insured.contact-container {
    background-color: $white;
    padding-left: 25px;
    padding-right: 15px;

    .panel-body {
      min-height: 100px;
      overflow: hidden;
      text-align: center;
      color: $gray;
      font-style: italic;
      line-height: 90px;
    }

    .contact-info-field {
      @include border-radius("bottom-left-right", 0);
    }

    .tables-container {
      .table-container {
        .label-header {
          .section-header-row {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
