cqui-field-row-expansible-group {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;

  cqui-field-row-expansible-header {
    margin-top: 0px !important;
    margin-bottom: 0px !important;

    .expand-visible,
    .expand-collapse {
      .field-row {
        padding: 5px 0px !important;
      }
    }

    .field-row {
      border-bottom: none !important;
      padding: 0px 0px !important;
    }
  }
}
