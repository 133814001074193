@import "prerequisites";

.#{$lib-prefix}-menu-container {
  $menu-entry-first-child-top-padding: 7px;

  $menu-entry-content-side-margin: 9px;
  $menu-entry-content-inactive: $white;
  $menu-entry-content-side-padding: 15px;
  $menu-entry-side-padding: 20px;

  $menu-entry-text-right-buffer-padding: 10px;

  $menu-details-indicator-hover: $light-blue;
  $menu-details-indicator-inactive: $white;
  $menu-detail-indicator-width: 40px;
  $menu-detail-indicator-border-width: 1px;

  $menu-container-left-trim-width: 5px;
  $menu-container-min-width: 200px;
  $menu-container-max-width: 250px;
  $menu-container-nested-width: 250px;

  $menu-entry-height: 47px;

  // Avoid using the property transform as that will interfere with the
  // position: fixed value
  // https://stackoverflow.com/questions/15194313/transform3d-not-working-with-position-fixed-children
  height: auto;
  position: fixed;
  padding-top: 5px;
  font-size: font-size("menu-container");
  color: $menu-container-text;
  min-width: $menu-container-min-width;
  max-width: $menu-container-max-width;

  &.potential-menu-indicator {
    transform: none;
  }

  > .menu-group {
    padding: 0;
    display: flex;
    width: 100%;
    box-shadow: 0px 2px 2px 0px rgba(50, 50, 50, 0.75);

    > .menu-group-listing {
      border-width: 1px 0 1px 0;
      border-color: $nav-bar-border-outline;
      border-style: solid;
      @include border("left", blue, $menu-container-left-trim-width);
      display: flex;
      width: 100%;

      > ng-transclude {
        width: 100%;
      }

      &:last-child {
        @include border("right", blue);
      }
    }

    .menu-entry {
      cursor: pointer;
      @include border("left", blue, 5px);
      margin-left: -5px;
      background-color: $menu-entry;
      display: flex;
      position: relative;
      min-height: $menu-entry-height;

      &:after {
        @include border("bottom", blue);
        position: absolute;
        top: 0;
        left: #{2 * $menu-container-left-trim-width};
        content: "";
        width: calc(100% - #{4 * $menu-container-left-trim-width});
        height: 1px;
        z-index: 5;
      }

      &.menu-entry-content-buttons {
        padding: 0 10px;

        &:hover {
          cursor: default;

          .menu-entry-content {
            background-color: $menu-entry-content-buttons-hover;
          }

          .menu-entry-content {
            &:after {
              display: none;
            }
          }
        }

        .menu-entry-content {
          padding-left: 0;
          padding-right: 0;
          cursor: default;

          .button-group {
            .btn {
              &:last-child,
              &:only-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      &.menu-entry-listing-show {
        .menu-entry-content {
          padding-bottom: 5px;
        }
      }

      &:hover {
        background-color: $menu-entry-hover;

        .menu-entry-content {
          background-color: $menu-entry-content-hover;
        }

        .menu-details-indicator {
          background-color: $menu-details-indicator-inactive;

          .menu-details-indicator-arrow {
            z-index: 2;
            pointer-events: none;
          }

          &:after {
            content: " ";
            position: absolute;
            top: 0;
            right: 0;
            @include square(100%);
          }
        }
      }

      &:last-child {
        flex: 1 0 auto;

        .menu-entry-content {
          border-bottom: none;
        }
      }

      &:first-child {
        &:after,
        &:before {
          display: none;
        }
      }

      &:not(:first-child) {
        &:hover {
          .menu-entry-content {
            &:before {
              content: "";
              position: absolute;
              top: 0;
              width: calc(
                100% - #{$menu-entry-side-padding -
                  $menu-detail-indicator-border-width}
              );
              z-index: 3;
              left: #{$menu-entry-side-padding * 0.5};
            }
          }

          .menu-details-indicator {
            &:before {
              top: 0;
              left: #{$menu-entry-side-padding * 0.5};
              position: absolute;
              content: "";
              width: calc(100% - #{$menu-entry-side-padding});
              z-index: 50;
            }
          }
        }

        &.menu-entry-left-menu-indicator {
          position: relative;

          .menu-entry-content {
            &:hover {
              &:after {
                display: none;
              }

              &:before {
                width: calc(100% - #{$menu-detail-indicator-width});
                left: $menu-detail-indicator-width;
              }

              .menu-details-indicator {
                z-index: 5;

                &:before {
                  width: 100%;
                  z-index: 0;
                }

                &:after {
                  width: calc(100% + #{$menu-container-left-trim-width});
                  @include border(
                    "left",
                    blue,
                    $menu-container-left-trim-width
                  );
                  left: -#{$menu-container-left-trim-width};
                }
              }
            }
          }
        }
      }

      .menu-entry-content {
        display: flex;
        align-items: center;
        padding: 10px $menu-entry-content-side-padding;
        position: relative;
        width: 100%;
        min-height: $menu-entry-height;

        &.menu-entry-content-no-details {
          .menu-entry-text-group {
            width: 100%;
          }
        }

        .menu-entry-content-custom,
        .menu-entry-text-group {
          display: flex;
          flex-direction: column;
          z-index: 3;
          pointer-events: none;
          width: calc(
            100% - #{$menu-detail-indicator-width -
              $menu-entry-content-side-padding -
              $menu-container-left-trim-width}
          );
          min-width: calc(
            100% - #{$menu-detail-indicator-width -
              $menu-entry-content-side-padding -
              $menu-container-left-trim-width}
          );
          padding-right: $menu-entry-content-side-padding;
        }

        .menu-entry-content-custom {
          width: 100%;
        }

        .menu-entry-title {
          margin: 0 0 3px 0;
          font-size: font-size("menu-entry-title");
          font-weight: bold;
        }

        .#{$lib-prefix}-icon {
          flex: 1 0 auto;
          z-index: 3;
          @include icon-margin("right", 10px);
          display: flex;
          pointer-events: none;
        }

        &:hover {
          &:after {
            position: absolute;
            content: "";
            width: calc(100% - #{$menu-detail-indicator-width});
            height: 100%;
            left: 0;
            top: 0;
            background-color: $menu-entry-content-hover;
          }

          .menu-details-container {
            background-color: $menu-details-indicator-inactive;
            width: 100%;
            position: absolute;
            top: 0;
            right: 0;

            .menu-details-indicator {
              background-color: $menu-details-indicator-hover;
              z-index: 2;
            }

            &:not(.#{$lib-prefix}-mouse-enter) {
              .menu-details-indicator {
                background-color: $menu-details-indicator-inactive;

                &:before {
                  width: calc(100% - #{$menu-detail-indicator-width});
                  left: $menu-detail-indicator-width;
                  @include border("left", blue);
                }
              }
            }

            &.#{$lib-prefix}-mouse-enter {
              z-index: 2;

              .menu-details-indicator {
                &:before {
                  border-left: none;
                  top: 0;
                  left: -#{$menu-container-left-trim-width};
                  @include size(#{$menu-container-left-trim-width}, 100%);
                  position: absolute;
                }
              }
            }

            #{$lib-prefix}-menu-entry-container {
              .menu-entry-content {
                background-color: $menu-details-indicator-inactive;
              }
            }
          }
        }

        &.#{$lib-prefix}-mouse-enter {
          > {
            .menu-text-background {
              top: 0;
              left: 0;
              width: calc(100% - #{$menu-detail-indicator-width});
              height: 100%;
              content: "";
              position: absolute;
              z-index: 2;
            }
          }
        }

        .menu-details-indicator-arrow-wrapper {
          height: 100%;
          display: flex;
          align-items: center;
        }

        .menu-details-container {
          position: absolute;
          right: 0;
          top: 0;
          @include size(#{$menu-detail-indicator-width}, 100%);
        }

        .menu-details {
          height: 100%;

          > [ng-transclude="menu-entry-menu-details"] {
            display: flex;
            height: 100%;

            > #{$lib-prefix}-menu-details {
              position: absolute;
              right: 0;
              display: flex;
              @include size(#{$menu-detail-indicator-width}, 100%);

              &:hover {
                .menu-entry {
                  .menu-entry-content {
                    background-color: $menu-entry-content-inactive;

                    &:hover {
                      background-color: $menu-entry-listing-item-hover;
                    }
                  }
                }
              }

              > #{$lib-prefix}-menu-entry-container {
                position: fixed;
                top: 0;
                left: 0;
                width: $menu-container-nested-width;
                padding-top: 0;
                background-color: $white;

                .menu-entry {
                  .menu-entry-text-group {
                    width: 100%;
                    white-space: nowrap;
                    overflow: auto;
                  }

                  .menu-entry-content {
                    &:before {
                      border-top: none;
                    }

                    .menu-entry-text {
                      text-transform: uppercase;
                    }
                  }
                }

                &:hover {
                  .menu-entry {
                    .menu-entry-content {
                      background-color: $menu-entry-content-inactive;
                    }
                  }
                }
              }
            }
          }
        }

        .menu-details-indicator {
          position: absolute;
          top: 0;
          right: 0;
          width: $menu-detail-indicator-width;
          @include border("left", blue);
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            width: $menu-detail-indicator-width;
            right: 0;
            border-left: none;
            background-color: $menu-details-indicator-hover;

            .menu-details-indicator-arrow-wrapper {
              position: absolute;
              top: 0;
              right: 0;
              margin-right: #{$menu-detail-indicator-width -
                ($menu-entry-side-padding + $menu-container-left-trim-width) +
                $menu-detail-indicator-border-width};
              z-index: 1;
              pointer-events: none;
            }

            &:before {
              @include border("left", blue);
              content: " ";
              position: absolute;
              top: 0;
              right: 0;
            }

            &:after {
              content: " ";
              position: absolute;
              width: $menu-detail-indicator-width;
              right: 0;
              @include border("left", blue);
            }

            + .menu-details {
              #{$lib-prefix}-menu-entry-container {
                .menu-entry-content {
                  background-color: $menu-entry-content-inactive;
                }
              }
            }
          }

          .menu-details-indicator-arrow {
            @include collapse-arrow-collapsed();
            top: 15px;
            pointer-events: none;
          }
        }

        #{$lib-prefix}-menu-entry-content {
          width: calc(
            100% -
              (
                #{$menu-detail-indicator-width -
                  $menu-entry-content-side-padding}
              )
          );
          min-width: calc(
            100% -
              (
                #{$menu-detail-indicator-width -
                  $menu-entry-content-side-padding}
              )
          );
          display: flex;
          z-index: 5;
        }

        .menu-entry-text {
          padding-right: 10px;
          flex: 0 1 auto;
          width: 100%;
          line-height: 1.3;
        }

        .menu-entry-listing {
          width: 100%;
        }

        .menu-entry-listing-header {
          display: flex;
          align-items: center;
          margin-left: 25px;
          word-break: break-all;
        }

        .menu-entry-listing-header-icon {
          margin-right: 5px;

          &.collapsed {
            @include collapse-arrow-collapsed();
          }

          &.expanded {
            @include collapse-arrow-expanded();
          }
        }

        .menu-entry-listing-item {
          padding: 5px 0 5px 12.5px;
          margin-left: 12.5px;
          display: flex;
          align-items: center;
          @include border("bottom", blue);

          .menu-entry-listing-item-text {
            word-break: break-all;
          }

          &:last-child {
            border-bottom: none;
          }
        }

        .button-group {
          display: flex;
          flex-direction: column;
          width: 100%;

          > ng-transclude {
            .btn {
              width: 100%;
            }
          }
        }
      }

      &.menu-entry-left-menu-indicator {
        &:after {
          margin-left: 0;
        }

        &:hover {
          &:after,
          &:before {
            content: "";
            position: absolute;
            left: #{2 * $menu-container-left-trim-width};
          }
        }

        > .menu-entry-content {
          > .icon {
            margin-left: $menu-detail-indicator-width;
          }

          > .icon,
          > .menu-entry-text-group,
          > .menu-entry-content-custom {
            z-index: 5;
          }

          > .menu-details-container-on-left {
            left: 0;

            > .menu-details {
              #{$lib-prefix}-menu-details {
                position: relative;
                @include square(100%);
                left: 0;
              }
            }

            .menu-details-indicator {
              left: 0;
              border-left: none;
              z-index: 3;

              .menu-details-indicator-arrow-wrapper,
              .menu-details-indicator-arrow {
                pointer-events: none;
              }

              .menu-details-indicator-arrow {
                @include collapse-arrow-collapsed("right");
              }
            }

            ~ .menu-details-indicator-divider {
              z-index: 6;
              top: 0;
              left: $menu-detail-indicator-width;
              height: 100%;
              @include border("right", blue);
              position: absolute;
            }

            &:hover {
              .menu-details-indicator {
                &:before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: -#{$menu-container-left-trim-width};
                  background-color: $menu-details-indicator-hover;
                  @include border(
                    "left",
                    blue,
                    $menu-container-left-trim-width
                  );
                }

                &:not(.#{$lib-prefix}-mouse-enter) {
                  &:before {
                    background-color: $menu-details-indicator-inactive;
                  }
                }
              }
            }

            &.#{$lib-prefix}-mouse-enter {
              .menu-details-indicator {
                &:after {
                  background-color: $menu-details-indicator-hover;
                }
              }

              + .menu-text-background {
                background-color: $menu-details-indicator-inactive;
                content: "";
                position: absolute;
                width: calc(100% - #{$menu-detail-indicator-width});
                height: 100%;
                z-index: 3;
                left: $menu-detail-indicator-width;
              }
            }
          }

          &:hover {
            &:before {
              position: absolute;
              content: "";
              @include square(100%);
              left: 0;
              top: 0;
              background-color: $menu-entry-content-hover;
            }

            &:after {
              width: 100%;
            }

            .menu-details-indicator {
              background-color: $menu-details-indicator-inactive;

              &:before {
                background-color: $menu-details-indicator-inactive;
              }
            }
          }

          &.#{$lib-prefix}-mouse-enter {
            position: relative;

            &:after {
              content: "";
              position: absolute;
              background-color: $menu-details-indicator-hover;
              @include size(calc(100% - #{$menu-detail-indicator-width}), 100%);
              left: #{$menu-detail-indicator-width};
            }
          }
        }
      }

      &.no-menu-indicator-present {
        .menu-entry-content {
          > .icon {
            margin-left: 0;
          }
        }

        .menu-entry-text {
          margin-right: 0;
          flex: 0 1
            calc(
              100% - #{$menu-entry-side-padding -
                $menu-detail-indicator-border-width}
            );
          width: calc(
            100% - #{$menu-entry-side-padding -
              $menu-detail-indicator-border-width}
          );
        }
      }
    }
  }

  .icon {
    @include square(25px);
    max-height: 25px;
    max-width: 25px;

    // Reduce the size of the building because the square shaped icons
    // look bigger than they actually are
    &.icon-building {
      height: 20px;
    }
  }
}

cqui-menu {
  &.menu {
    position: absolute;
    right: 0;
    top: 45px;

    .menu-wrapper {
      background-color: $white;
      min-width: 200px;
      min-height: 200px;
      @include border(all, map-get($border-color, menu), 1px);
    }
  }
}
