#{$lib-prefix}-field-row-expansible-content {
  display: flex;

  background-color: $expansible-container-embed;
  @include border-radius("bottom-left-right", expansible-container);

  > ng-transclude {
    @include expansible-container-field-row-nested();

    .section-subsection {
      .field-row {
        &.field-row-first-list-child:last-child {
          border-bottom: none;
        }
      }
    }
  }

  margin-bottom: 5px;
}
