.ae-account-information {
  .account-classification {
    ae-question-typeahead {
      .field-input {
        display: flex;

        .cqui-icon-button {
          margin-left: 5px;
        }
      }
    }
  }
  .ae-zipcode .form-description {
    padding-right: 4px;
  }

  .kyexemption-acknowledge-button {
    margin-top: 10px;
  }

  .wc-primary-location {
    .header-field {
      .section-header,
      .section-header-note {
        display: inline-block;
      }
    }
  }

  .section-for-account-questions {
    > .section-subsection + .section-subsection {
      margin-top: 0px;
      @include border("top");
    }

    .field-input input {
      width: 167px;
    }
  }

  .account-loss-information {
    .field-row-nested-group + .field-row-nested-group {
      border-top: 1px solid #ccc;
    }
  }

  .section .section-fields-underlined .field-row-nested-group {
    height: auto;
  }
}

.classification-sections {
  background-color: white;
  margin-bottom: 15px;
  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  overflow: visible;

  h2 {
    margin-top: 0px;
    margin-bottom: 0px !important;
  }

  .field-row .field-input.field-input-info .btn {
    margin: 0px;
    align-items: center;
    height: 33px;
    padding-bottom: 2px;
    padding-top: 2px;
    border-radius: 0px;
  }
  li {
    display: list-item;
  }

  .btn-group {
    flex-grow: 4;

    .dropdown-button-applicant-classification {
      background-color: #f2f2f2;

      border-color: #999;
      color: black;
      padding: 5px 45px 5px 5px;
      width: 100%;
      text-align: left;
    }
  }

  .btn-group.dropdown.open {
    .dropdown-button-applicant-classification {
      background-color: #f2f2f2;
      border-color: #999;
      color: black;
      padding: 5px 45px 5px 5px;
      width: 100%;
      text-align: left;
    }
  }
}

.cqui-typeahead {
  &.cqui-typeahead-hint {
    max-height: 338px;
    overflow-y: auto;
  }
}

ae-account-class-code-typeahead {
  > {
    @include search-field-row();
  }
}

ae-account-product-selection {
  display: flex;
  width: 100%;
  .section {
    width: 100%;
  }
}

ae-account-classification {
  + ae-account-product-selection {
    @include border("top");
    margin-top: 10px;
  }
}

.typeahead {
  &[template-url="aeTypeaheadHint"] {
    padding: 0;
    background-color: $ship-gray;

    &:before {
      content: "(GL Code) General Business Description";
      background-color: $ship-gray;
      color: $white;
      font-style: italic;
      padding: 5px 20px;
      display: flex;
    }

    &:after {
      content: "";
      top: 0;
      position: absolute;
      height: 26px;
    }

    > li {
      background-color: $white;

      &:last-child {
        padding-bottom: 5px;
      }
    }
  }
}
