.nav-container {
  > .navbar-header {
    background-color: $main-nav-bar-header;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  > .navbar-container {
    position: relative;
  }
}
