@use "units";

.cq-tab-group {
  .mat-mdc-tab-header {
    border-bottom: 1px solid #005e7a;
    padding-left: var(--cq-tab-header-padding-left);

    --mdc-secondary-navigation-tab-container-height: #{units.px(40)};
  }

  .mat-mdc-tab-body {
    --cq-tab-header-padding-left: 0;
  }

  .sticky .mat-mdc-tab-header {
    background-color: white;
    top: 0;
    z-index: 1000;
    position: sticky;
  }
}
