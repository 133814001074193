@font-face {
  font-family: proxima-nova-light;
  src: url("~/assets/fonts/ProximaNova-Light_gdi.woff") format("woff");
}

@font-face {
  font-family: proxima-nova;
  src: url("~/assets/fonts/ProximaNova-Regular_gdi.woff") format("woff");
}

@font-face {
  font-family: proxima-nova-bold;
  src: url("~/assets/fonts/ProximaNova-Bold_gdi.woff") format("woff");
}
