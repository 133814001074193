#{$lib-prefix}-field-input {
  height: 100%;

  > ng-transclude {
    display: flex;
    height: 100%;
  }

  .expand-text-container {
    @include text-overflow-ellipsis();
    padding-top: $field-label-top-padding;
    line-height: $expansible-container-hint-text-line-height;
  }

  &.field-input-info {
    > ng-transclude {
      @include field-input-info;
    }
  }
}
