@import "prerequisites";

.cqui-collapsible-container-group {
  $cqui-collapsible-container-btn-disabled: $disabled-text-gray;
  $cqui-collapsible-container-btn-disabled-border: $disabled-text-gray;

  $container-title: $black;

  $container-notification: $alert-background-pink;
  $container-notification-text: $black;

  $accordion-heading: $powder-blue;
  $accordion-heading-opened: $white;
  $accordion-heading-disabled: $disabled-gray;
  $accordion-heading-container-heading-disabled: $disabled-text-gray;

  $cqui-tabs-set: $black;

  $tab-container: $powder-blue;
  $tab-container-inactive: $light-medium-blue;

  $tab-state-disabled-muted: $muted-blue;

  $nested-tabs-set-tab-height: 50px;

  overflow-y: hidden;

  uib-accordion {
    > .panel-group {
      margin-bottom: 0;

      > ng-transclude {
        height: 100%;
        display: flex;
        flex-direction: column;

        &.all-opened {
          display: block;
          overflow-y: auto;

          .heading-content {
            cursor: default;
          }
        }

        &.only-one {
          .heading-content {
            cursor: default;
          }

          .cqui-collapsible-container {
            &:not([icon-class]):not([heading]) {
              [uib-accordion-group] {
                > .panel-heading {
                  pointer-events: none;
                  border: none;
                }
              }
            }
          }
        }

        > .cqui-notification {
          width: 100%;

          .notification-content {
            justify-content: center;
          }
        }
      }
    }
  }

  uib-accordion,
  uib-accordion > .panel-group,
  uib-accordion [uib-accordion-group] > ng-transclude,
  uib-accordion [uib-accordion-group] > .panel-collapse,
  uib-accordion [uib-accordion-group] > .panel-collapse > .panel-body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  uib-accordion {
    [uib-accordion-group] {
      > .panel-collapse {
        &.collapse {
          display: none;

          &.in {
            display: flex;
          }
        }

        > .panel-body {
          padding: 0px;
          border: none;
          border-radius: 0px;
          height: auto;
          overflow-x: hidden;
        }
      }
    }
  }

  .cqui-collapsible-container {
    + .cqui-collapsible-container {
      margin-top: 5px;
    }

    .container-icon + [ng-transclude="tab"],
    .container-text {
      width: calc(100% - 55px);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex: 1 0 auto;

      .heading-text {
        display: flex;
        align-items: center;
      }
    }

    .container-heading {
      display: flex;
      align-items: center;
      padding: 7px 7px 7px 15px;
      flex: 1 0;

      .container-header-section-tabs {
        display: flex;
      }

      .expansion-indicator {
        @include collapse-arrow-collapsed();
        margin-right: 10px;
        margin-left: -5px;
      }

      .container-title,
      .container-subtitle,
      .container-expand {
        color: $container-title;
        letter-spacing: 0.01em;
      }

      .container-title {
        font-size: font-size("collapsible-container-title");
        @include font-base();
        line-height: 1;
        display: flex;
        align-items: center;
      }

      .container-title-text {
        margin-right: 5px;
      }

      .container-subtitle-icon {
        @include icon();
        @include square(20px);
        min-width: 20px;
        margin-right: 5px;
      }

      .container-subtitle {
        display: block;
      }

      .container-expand {
        font-size: font-size("collapsible-container-expand-heading");
        @include font-weight-base();
      }

      cqui-info-indicator {
        margin-right: 5px;
        display: flex;
      }

      .container-icon-wrapper {
        display: flex;
        align-items: center;
      }

      .container-icon {
        @include icon();
        display: inline-block;
        margin-right: 7px;
        @include square(22px);
        align-self: flex-start;
      }
    }

    .container-button {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 15px;

      /*
        Firefox seems to have issues setting flex on buttons directly.
        In order to have this display correctly cross browser, then we must target a child span wrapper.
       */
      .btn {
        > span {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
        }
      }
    }

    [ng-transclude="header-section"] {
      display: flex;
      align-items: center;
      padding-right: 15px;

      &:empty {
        padding: 0;
      }
    }

    cqui-collapse-header-section {
      .button-group {
        display: flex;
        align-items: center;

        .btn {
          margin-left: 5px;
          margin-bottom: 0;
        }
      }
    }

    .container-notification {
      display: flex;
      align-items: center;
      background: $container-notification;
      font-size: font-size("collapsible-container-notification");
      padding: 0 3px;
      margin-left: auto;
      color: $container-notification-text;
      @include border-radius("top-right" "bottom-right");
      @include error-icon-in-toggleable-container();
    }

    [uib-accordion-group] {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      border: none;
      border-radius: 0px;

      > .panel-heading {
        background-color: $accordion-heading;
        padding: 0;
        @include border-radius("all", collapsible-container);
        cursor: pointer;

        .panel-title {
          height: 100%;
          width: 100%;
          display: flex;
          font-size: $base-font-size;
        }

        cqui-collapse-tabs-nav,
        cqui-collapse-header-section {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: 100%;
          flex: 1 0 auto;
          position: relative;
        }

        .cqui-tabs-nav-container {
          > .cqui-tabs-nav-tabs {
            display: flex;
            flex-wrap: wrap;
          }
        }

        .accordion-toggle {
          @include square(100%);
          display: flex;

          span[uib-accordion-header] {
            height: 100%;
            width: 100%;
            display: flex;

            // IE11 appears to require height to be specified in order to have flex fill appropriately.
            min-height: $collapsible-container-min-height;

            &:empty {
              min-height: 0;
            }
          }

          &:hover,
          &:visited,
          &:link {
            text-decoration: none;
          }

          .heading-content {
            display: flex;
            width: 100%;

            &.transclude-tabs {
              .container-heading {
                width: 100%;
              }
            }
          }
        }

        .cqui-tabs-set {
          margin: 0;
          @include square(100%);

          .cqui-tab-text-group {
            .cqui-tab-icon {
              width: 20px;
              height: 20px;
            }

            &.invalid {
              border-color: #{map-get($border-color, error)};
            }
          }
        }
      }

      .panel-body {
        .cqui-tabs-set {
          &.cqui-tabs-fixed-header {
            > .cqui-tabs-nav-container {
              z-index: 6;

              &:after {
                height: 15px;
                top: -10px;
              }
            }

            > .cqui-tabs-content-container {
              > cqui-tabs-contents {
                padding-top: 40px;
              }
            }
          }

          @include tabs-wrap();
        }

        .cqui-tabs-nav-container {
          max-height: $nested-tabs-set-tab-height;

          cqui-tabs-nav {
            max-height: $nested-tabs-set-tab-height;
            background-color: $white;
          }

          .cqui-tab-text-group {
            min-height: $nested-tabs-set-tab-height;
          }
        }

        cqui-tabs-nav {
          .cqui-tab-text-group {
            &.invalid {
              @include border("bottom", 2px, $error-outline);

              &:hover {
                background-color: $alert-background-pink-hover;

                &:after {
                  height: auto;
                }
              }
            }

            &.disabled {
              @include border("bottom", 4px, $gray);
              color: $gray;

              &:hover {
                background-color: $ash-gray;

                &:after {
                  height: auto;
                }
              }
            }

            &.active {
              color: $black;
              font-weight: bold;
              background-color: $white;
              @include border("bottom", 4px, $surf);

              .cqui-tab-text {
                padding-bottom: 0px;
              }

              &:after {
                height: auto;
              }

              &.invalid {
                @include border("bottom", 4px, $error-outline);
                &:hover {
                  background-color: $alert-background-pink-hover;

                  &:after {
                    height: auto;
                  }
                }
              }

              &:hover {
                background-color: $light-medium-blue;

                &:after {
                  height: auto;
                }
              }
            }

            &:hover {
              background-color: $light-medium-blue;

              &:after {
                height: auto;
              }
            }

            .cqui-tab-icon {
              height: 15px;
              max-width: 30px;
              min-width: 30px;
            }

            position: relative;
            border: 0;
            @include border("bottom", 1px, $surf);
            background-color: $white;
            font-size: font-size("nested-tabs-line-tab-text");
            margin-right: 15px;
            padding-left: 5px;
            padding-right: 5px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            color: $black;
            opacity: 1;

            .cqui-tab-text {
              padding-bottom: 3px;
              display: inline;
            }
          }
        }
      }
    }

    &.opened {
      display: flex;
      flex-direction: column;
      height: auto;

      // To avoid compressing a sub-container to zero height when there
      // are too many of them on the page, keep at least the height of the
      // sub-container pane label
      min-height: $collapsible-container-min-height;
      overflow-y: hidden;

      .collapse {
        &.in {
          height: auto;
          overflow-y: auto;

          .accordion-body {
            height: auto;
          }
        }
      }

      .heading-content {
        cursor: default;

        &.transclude-tabs {
          .container-heading {
            padding-bottom: 0;
          }
        }
      }

      [uib-accordion-group] {
        > .panel-heading {
          @include border-radius("all", 0);
          background-color: $accordion-heading-opened;
          @include border("bottom", blue);
          display: flex;
          flex: 1 0 auto;

          .expansion-indicator {
            @include collapse-arrow-expanded();
          }

          .cqui-tabs-set {
            margin: 0;
            padding: 0;
            color: $cqui-tabs-set;
            display: flex;

            .cqui-tab-text-group {
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        .panel-body {
          cqui-tabs-nav {
            .cqui-tab-text-group {
              &.active {
                &:hover {
                  &:after {
                    height: auto;
                  }
                }
              }
            }
          }
        }

        .cqui-tabs-nav-container {
          > cqui-tabs-nav {
            .cqui-tab-text-group {
              &:hover {
                position: relative;

                /* For IE, underline is showing upon hovering of the active tab,
								need to use pseudo after element to cover the underline that comes
								from the group container.
								*/
                &:after {
                  @include tab-border-after();
                }
              }
            }
          }
        }

        .accordion-body {
          border: none;
          min-height: $collapsible-container-min-height;
        }
      }
    }

    &:not(.opened) {
      .cqui-tabs-nav-container > {
        cqui-tabs-nav {
          .cqui-tab-text-group {
            &.active {
              background-color: $tab-container;

              &:after {
                background-color: $accordion-heading;
              }

              &:hover {
                background-color: $tab-container-inactive;

                &:after {
                  background-color: $tab-container-inactive;
                }
              }
            }
          }
        }
      }

      [uib-accordion-group] {
        > .panel-heading {
          .cqui-tabs-set {
            .cqui-tabs-nav-container {
              > .cqui-tabs-nav-tabs {
                > cqui-tabs-nav {
                  border-bottom: none;

                  .cqui-tab-text-group {
                    margin-right: 0;
                    border-radius: 0;
                    border-left: 0;
                    border-top: 0;
                    opacity: 1;

                    &:last-child {
                      border-right: 0;
                    }

                    &.disabled {
                      background-color: $tab-state-disabled-muted;
                    }

                    &.invalid {
                      background-color: $tab-container;
                      @include border("color right", $cerulean);
                    }

                    &.active {
                      background-color: $tab-container;

                      &:after {
                        background-color: $tab-container;
                      }

                      &:hover {
                        &:after {
                          background-color: $tab-container-inactive;
                        }
                      }
                    }

                    &.invalid,
                    &.active {
                      &:hover {
                        background-color: $tab-container-inactive;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .heading-content {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &.disabled {
      cursor: not-allowed;

      [uib-accordion-group] {
        > .panel-heading {
          background-color: $accordion-heading-disabled;
          pointer-events: none;

          .container-heading {
            .container-title,
            .container-subtitle,
            .container-expand {
              color: $accordion-heading-container-heading-disabled;
            }
          }

          .heading-content {
            cursor: not-allowed;
          }

          .expansion-indicator {
            @include border("color left", "disabled-gray");
          }

          .btn {
            background-color: $cqui-collapsible-container-btn-disabled;
            border-color: $cqui-collapsible-container-btn-disabled-border;
          }
        }
      }
    }

    .#{$lib-prefix}-collapsible-container-panel-body-content {
      &:not(.transclude-tabs-container) {
        .cqui-tabs-set {
          &.cqui-tabs-fixed-header {
            padding-top: 10px;
            margin-top: 0;

            > .cqui-tabs-nav-container {
              &:after {
                top: -15px;
              }
            }
          }
        }
      }

      > .section {
        > .cqui-tabs-fixed-header {
          &.cqui-tabs-split-type {
            left: 10px;
            width: calc(100% - 45px);
            overflow-x: hidden;
            margin-top: -15px;
            min-height: $tabs-container-fixed-wrap-nav-height;

            &:not(.cqui-tabs-wrap) {
              max-height: 100%;
              overflow: auto;
              padding-top: 0;
              width: calc(100% - 20px);
              min-height: $tabs-container-fixed-nav-height + 15px;

              > .cqui-tabs-nav-container {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }

    &.#{$lib-prefix}-collapsible-container-transclude-tabs-header {
      .#{$lib-prefix}-collapsible-container-panel-heading {
        + .panel-collapse {
          .#{$lib-prefix}-collapsible-container-panel-body {
            .#{$lib-prefix}-collapsible-container-panel-body-content {
              > .cqui-tabs-set {
                &.cqui-tabs-split-type {
                  .cqui-tabs-content-container {
                    > cqui-tabs-contents {
                      > .tab-contents-wrapper {
                        > *:not(.cqui-tabs-inline-type):not(ng-form) {
                          position: relative;
                        }
                      }
                    }

                    .cqui-tabs-set {
                      &.cqui-tabs-split-type {
                        &.cqui-tabs-fixed-header {
                          background-color: $white;
                          overflow-y: auto;
                          margin-top: 5px;
                          min-height: $tabs-container-fixed-nav-height;
                          width: calc(100% - 20px);
                          left: 0;

                          .cqui-tabs-nav-container {
                            max-width: calc(100% - 20px);
                            min-height: $tabs-container-fixed-nav-height;

                            &:after {
                              display: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
