.ae-bop-building-classification {
  .classification-section {
    min-height: 410px;
  }
  .building-details-section {
    ng-transclude {
      display: flex;
      align-items: center;
    }
    .blue-border.field-row {
      height: 0;
      margin-bottom: -10px;
      .field-input {
        border-top: 1px solid #0086ae;
        padding-left: 0;
        margin-left: 15px;
        margin-top: 5px;
      }
    }

    .retrieve-valuation-button-row.field-row {
      justify-content: flex-end;
      padding-right: 0;
      margin-left: 15px;
    }
  }

  .gpa-ctrl-group {
    .col-xs-12.field-row {
      float: none;
      width: auto;
    }
  }

  .icon-information-circle {
    min-width: 15px;
    min-height: 15px;
    display: block;
    background-size: 100% 100%;
  }

  .cqui-collapsible-container {
    .heading-content {
      outline: none;
    }
  }
}
