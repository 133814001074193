@use "sass:math";

@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "variables";

@mixin float() {
  float: left;
  width: 100%;
}

@mixin full-width() {
  .full-width {
    width: 100%;
    min-width: 100%;
  }
}

@mixin split-even-children($quantity) {
  > * {
    width: #{math.div(100%, $quantity)};
  }
}

@mixin text-center {
  text-align: center;
  justify-content: center;
}

@mixin text-center-selector($selection: none) {
  @if $selection == parent {
    &.text-center {
      @include text-center;
    }
  } @else if $selection == current {
    @include text-center();
  } @else {
    .text-center {
      @include text-center;
    }
  }
}

@mixin text-left {
  text-align: left;
  justify-content: flex-start;
}

@mixin text-left-selector($selection: none) {
  @if $selection == parent {
    &.text-left {
      @include text-left;
    }
  } @else if $selection == current {
    @include text-left();
  } @else {
    .text-left {
      @include text-left;
    }
  }
}

@mixin text-bold {
  font-weight: bold;
}

@mixin text-overflow-ellipsis() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin font-url($font, $font-format: "woff") {
  src: url("#{$cqui-font-path}/#{$font}") format($font-format);
}

@mixin icon {
  background-size: 100% 100%;
  display: block;
  background-repeat: no-repeat;
}

@mixin icon-ratio($iconName, $width) {
  width: #{$width}px;
  min-width: #{$width}px;
  height: #{$width * map-get($icon-size, $iconName)}px;
}

@mixin icon-margin($sides: right, $amount: 5px) {
  @if $sides == "right" {
    margin-right: $amount;
  } @else {
    @each $side in $sides {
      margin-#{$side}: $amount;
    }
  }
}

@mixin icon-group-set() {
  #{$lib-prefix}-icon-group {
    .icon-group {
      .icon-group-entry {
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:last-child {
          margin-right: 0;

          #{$lib-prefix}-icon {
            margin-right: 0;
          }
        }
      }

      #{$lib-prefix}-icon {
        @include square(25px);
        max-width: 25px;
        margin-right: 0;
      }
    }
  }
}

@mixin font-weight-base() {
  font-weight: 500;
}

@mixin font-light() {
  font-family:
    "proxima-nova-light",
    #{fonts-fallback()};
}

@mixin font-base() {
  @include font-weight-base();
  font-family:
    "proxima-nova",
    #{fonts-fallback()};
}

// For IE to style the large button set, due to font download restrictions
@mixin font-secondary-fallback() {
  font-family: "proxima-nova", Calibri;
}

@mixin ng-transclude-full-width() {
  > ng-transclude {
    width: 100%;
  }
}

@mixin zebra-striping-even() {
  &:nth-child(even) {
    background-color: $zebra-stripe-even;
  }

  &:last-child {
    @include border-radius("bottom-left" "bottom-right", listing-entry);
  }
}

@mixin nationwide-logo {
  @include image-url("#{$nationwide-logo}.svg");
}

@mixin nationwide-white-logo {
  @include image-url("#{$nationwide-white-logo}.svg");
}

@mixin nationwide-logo-small {
  @include image-url("#{$nationwide-logo-small}.svg");
}

@mixin ng-form($class: "") {
  ng-form#{$class},
  &[ng-form] {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $white;

    @content;
  }
}

@mixin modal-header() {
  font-size: font-size("modal-header");
  font-weight: bold;
}

@mixin field-row-vertical {
  &.field-row-vertical {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-bottom: none;
    background-color: $white;
    @include border-radius("all", section);

    $field-row-vertical-label-margin-bottom: 5px;

    .field-label,
    .field-input {
      width: 100%;
    }

    .field-label {
      margin-left: 0;
      padding-left: 0;
      min-height: #{$field-min-height - $field-row-vertical-label-margin-bottom};
      margin-bottom: 5px;
      display: flex;
      align-items: flex-start;
    }

    .field-description {
      align-self: self-start;
    }

    .field-input {
      padding-right: 0;
    }
  }
}

@mixin field-input-group {
  .field-input-group {
    .field-input {
      width: 100%;
      padding-right: 0;

      .question-type-input {
        width: 100%;
      }
    }
  }
}

@mixin zip-code-field {
  .zip-field-row {
    .field-input {
      @include make-sm-column(percent-to-bootstrap-grid-unit($input-zip));
      @include make-md-column(percent-to-bootstrap-grid-unit($input-zip));
      @include make-lg-column(percent-to-bootstrap-grid-unit($input-zip));
      min-width: 75px;
      padding-left: 0;

      + .zip-code-location {
        display: flex;
        align-items: center;
        margin-left: -10px;

        p {
          color: $zip-address-text;
          margin: 0;
        }
      }
    }
  }
}

@mixin field-label-propset {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  text-align: right;
  padding-top: $field-label-top-padding;
}

@mixin field-label-props {
  @include field-label-propset;
}

@mixin field-label-class {
  .field-label {
    @include field-label-props();
  }
}

@mixin field-label($selection: "parent") {
  @if $selection == "parent" {
    & {
      @include field-label-propset;
    }
  } @else if $selection == "current" {
    @include field-label-props();
  } @else {
    @include field-label-class();
  }
}

@mixin field-description-props() {
  line-height: 1.3em;
  text-align: right;
  left: 0;
  padding-right: $label-description-padding-right;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: flex-start;
}

@mixin field-input-right-align {
  .field-input-right-align {
    .field-label {
      @include make-sm-column(8);
      min-height: $field-min-height;
    }

    .field-input {
      @include make-sm-column(4);
    }
  }
}

@mixin field-full-width($base-max) {
  @if $base-max == 100% {
    @include make-sm-column(12);
  } @else {
    @include make-sm-column(7);
  }
}

@mixin field-input-group-auto-width() {
  &.field-input-group-auto-width {
    display: block;

    @media (max-width: $screen-sm-max) {
      & {
        display: flex;
        flex-direction: column;
        width: 45%;
      }

      .btn {
        width: 100%;

        + .btn {
          margin-top: 5px;
        }
      }
    }
  }
}

@mixin input-error-outline() {
  background-color: $error-background;
  @include border("all", $error-outline, 1px);
}

@mixin input-caution-outline() {
  background-color: $yellow;
  @include border("all", $corn, 1px);
}

@mixin subsection-group-form() {
  .subsection-group {
    @include ng-form(".single-form");
  }
}

@mixin subsection-confirmation() {
  &.subsection-confirmation {
    @include border("bottom", blue);
    margin-bottom: 10px;
    padding-bottom: 5px;

    .field-row {
      &:last-child {
        border-bottom: none;

        &.field-row-level-top {
          border-bottom: none;
        }
      }
    }
  }
}

@mixin section-fields-right-label() {
  .field-label {
    @include make-sm-column(8);
    min-height: $field-min-height;
  }
}

@mixin section-fields-right-input-column {
  @include make-sm-column(4);
  padding-left: 0;
  display: flex;
  align-items: flex-start;
}

@mixin section-fields-right-input() {
  .field-input {
    @include section-fields-right-input-column;
  }
}

@mixin section-header-note {
  .section-header-note {
    margin-top: 5px;
    display: inline-block;
    font-weight: normal;
    line-height: 1.25;

    @content;
  }
}

@mixin subsection-classification() {
  .section-subsection {
    &.classification {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      align-content: stretch;
      min-height: 0;

      .section {
        &.section-fields-underlined {
          #{$lib-prefix}-field-row {
            border-bottom: none;
          }
        }
      }

      .field-static {
        @include make-sm-column(7);
      }

      .available-classifications,
      .added-classifications {
        max-height: 50%;
        min-height: 0;
      }

      .added-classifications {
        .section-header-group {
          .section-header {
            margin-top: 0;
            margin-bottom: 7px;
          }
        }
      }

      .available-classifications,
      .selected-classifications {
        overflow-y: auto;
      }

      .available-classifications {
        + .added-classifications {
          margin-top: 15px;
        }
      }

      .selected-classifications {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
      }

      .classification-basis {
        display: flex;
        align-items: flex-start;
        justify-content: center;
      }

      // Input field with placeholder text of
      // 'Number of full time employees' needs more space
      .classification-basis-column {
        width: map-get($table-col-width, lg) + 40px;
      }

      .class-column {
        width: map-get($table-col-width, sm);
      }

      .classification-basis-column,
      .description-column {
        text-align: left;
      }

      .button-column {
        width: map-get($table-col-width, md);
      }

      .empty-table {
        height: 150px;
      }

      .classification-is-required {
        display: flex;
        align-items: center;
        justify-content: center;

        color: $dove-gray;

        .icon-errors {
          @include square(20px);
          @include icon();
          margin-right: 3px;
        }
      }
    }
  }
}

@mixin field-labels-medium {
  .field-labels-medium {
    .field-row {
      @include section-fields-right-label();
      @include section-fields-right-input();

      .field-label {
        @include make-sm-column(6);
      }

      .field-input {
        @include make-sm-column(6);
        padding-left: 0;
      }
    }

    .field-row-nested {
      .section-subsection {
        padding: 0;
      }
    }
  }

  @content;
}

@mixin field-labels-wide {
  .field-labels-wide {
    .field-row {
      @include section-fields-right-label();
      @include section-fields-right-input();
    }

    .field-row-nested {
      .section-subsection {
        padding: 0;
      }
    }
  }

  @content;
}

@mixin field-input-notification-content {
  width: 100%;
  font-weight: normal;
  padding: 5px 7px;
  line-height: 1.1;
  align-items: center;
  margin-bottom: 0;

  .notification-text {
    display: flex;
    align-items: center;
    @include square(100%);
    margin: 0 auto;

    .notification-text-content {
      display: flex;
      @include square(100%);
      align-items: center;
      flex: 0 1 100%;
    }
  }

  .notification-icon {
    margin-top: 3px;
  }
}

@mixin compact-notification() {
  .cqui-notification {
    margin: 0;
    width: 100%;

    .notification-icon {
      @include square(20px);
      min-width: 20px;
      margin-top: 3px;
      @include icon-margin("right", 7px);
    }

    .notification-text {
      line-height: 1.25;
    }
  }
}

// When using .field-notification directly on .field-row
@mixin field-row-notification() {
  &.field-notification {
    > {
      @include compact-notification();
    }
  }
}

// When using .field-notification on .field-input
@mixin field-input-notification() {
  &.field-notification {
    align-items: flex-start;
    flex-wrap: wrap;

    &:after {
      content: "";
      width: 100%;
    }

    .cqui-notification {
      flex: 1 0 100%;
    }

    .cqui-notification:last-child {
      @include field-input-notification-content;
    }
  }
}

@mixin input-group() {
  &.input-group {
    display: flex;
    flex: 1 0 auto;
    height: 100%;

    .field-input {
      width: 100%;
    }
  }
}

@mixin field-input-info {
  display: flex;
  align-items: center;

  .btn {
    height: 100%;
  }
}

@mixin required() {
  .required {
    position: absolute;
    right: -9px;
  }
}

@mixin alert-row($alert-type: "info", $child: "", $selector: "") {
  @if $child == "global" {
    .#{$alert-type}-row {
      background-color: map-get($alert-colors, $alert-type);
    }
  } @else if $child == "parent child" {
    &.#{$alert-type}-row,
    &.#{$alert-type}-row #{$selector} {
      background-color: map-get($alert-colors, $alert-type);
    }
  } @else if $child == "child" {
    &.#{$alert-type}-row #{$selector} {
      background-color: map-get($alert-colors, $alert-type);
    }
  }
}

@mixin border-color($side, $color, $width: 1px) {
  $mapped-color: border-color-check($border-color, $color);
  @if $side == "all" {
    border: #{$width} solid #{$mapped-color};
  } @else {
    border-#{$side}: #{$width} solid #{$mapped-color};
  }
}

@mixin border($side: all, $color: gray, $width: 1px) {
  @if $side == "color" {
    border-color: border-color-check($border-color, $color);
  } @else if $side == "color left" {
    border-left-color: border-color-check($border-color, $color);
  } @else if $side == "color right" {
    border-right-color: border-color-check($border-color, $color);
  } @else if $side == "color top" {
    border-top-color: border-color-check($border-color, $color);
  } @else if $side == "color bottom" {
    border-bottom-color: border-color-check($border-color, $color);
  } @else {
    @include border-color($side, $color, $width);
  }
}

@mixin border-radius($sides: all, $size: $container-border-radius) {
  // Use a preset when it is available from $radius variable
  $preset-size: $size;
  $preset-found: map-get($border-radius, $size);
  @if $preset-found {
    $preset-size: $preset-found;
  }

  @if $sides == "all" {
    border-radius: $preset-size;
  } @else if $sides == "bottom-left-right" {
    @include border-bottom-radius($preset-size);
  } @else if $sides == "top-left-right" {
    @include border-top-radius($preset-size);
  } @else {
    @each $item in $sides {
      border-#{$item}-radius: $preset-size;
    }
  }
}

@mixin nav-bar-direction-arrow-skew-x-bottom(
  $direction: "positive",
  $nav: "main-nav"
) {
  @if $direction == "positive" {
    @if $nav == "main-nav" {
      transform: skewX(#{map-get($nav-tab-main-nav-skew, "main-nav")});
    }
  } @else if $direction == "negative" {
    @if $nav == "main-nav" {
      transform: skewX(-#{map-get($nav-tab-main-nav-skew, "main-nav")});
    }
  }
}

@mixin nav-bar-direction-arrow-skew-x-bottom-disabled($pseudo-position) {
  @if $pseudo-position == "before" {
    transform: #{map-get($nav-tab-main-nav-skew-disabled, "before")};
  } @else if $pseudo-position == "after" {
    transform: #{map-get($nav-tab-main-nav-skew-disabled, "after")};
  }
}

@mixin nav-bar-direction-arrow-point-internal {
  // Arrow point
  &:before {
    z-index: 2;
    transform: skewX(27deg);
  }

  // Outline for the arrow point
  &:after {
    bottom: 0;
    z-index: 1;
    @include nav-bar-direction-arrow-skew-x-bottom();
  }

  &:before,
  &:after {
    content: "";
    right: -8px;
    position: absolute;
    height: 50%;
    width: 100%;
    @include border("right", $nav-bar-directional-arrow-outline, 1.6px);
    pointer-events: none;
  }
}

@mixin nav-bar-direction-arrow-point-update {
  @include nav-bar-direction-arrow-point-internal;
  @content;
}

@mixin nav-bar-direction-arrow-point($type: "active") {
  @include nav-bar-direction-arrow-point-internal;

  @if $type == "active" {
    &:before {
      border-left: none;
      background-color: $nav-bar-active;
      top: 0;
    }

    &:after {
      background-color: $nav-bar-active;
      width: 100%;
    }
  }
}

@mixin nav-bar-menu-activated-background() {
  background-color: $nav-bar-active-hover;
  color: $nav-bar-active-hover-text;
  font-weight: bold;
}

@mixin nav-bar-tab-square-corners-cover {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: 20px;
    height: 100%;
    background-color: $nav-bar-active;
    @content;
  }
}

// Moves the :before element to close the horizontal
// gap between the accompanying :after element
@mixin nav-bar-middle-line-gap-offset-cover {
  &:before {
    height: calc(50% + 0.8px);
  }
}

@mixin nav-bar-tab-horizontal-seam-cover($horizontal-cover-color) {
  &:before {
    content: " ";
    position: absolute;
    @include size(100%, 4px);
    top: calc(50% - 2px);
    background-color: $horizontal-cover-color;
    margin-left: 13px;
  }
}

@mixin panel-body() {
  .panel-body {
    @include border("all", gray);
    @include border("top", clear, 0);
    @include border-radius("bottom-left-right", 4px);
  }
}

@mixin cqui-icon-button-padding {
  padding-left: $btn-icon-padding-side;
  padding-right: $btn-icon-padding-side;
}

@mixin search-field-input-internal {
  .field-input {
    position: relative;
    padding-right: 0;
    margin-right: 5px;

    &.field-input-info {
      @include field-input-info;

      input {
        min-height: 35px;
        font-size: font-size("search-field-input");
        padding-right: 45px;
      }

      textarea {
        min-height: 35px;
        font-size: font-size("search-field-input");
        word-break: break-word;
        padding: 5px 45px 5px 5px;
      }
      ~ .btn {
        height: 200%;
        padding: 6.5px;
      }
    }

    .cqui-icon-button {
      position: absolute;
      top: 0;
      right: 0;
      @include border-radius("all", 0);
      border: none;
      @include cqui-icon-button-padding();
    }

    &:first-child:nth-last-child(1) {
      margin-right: 0;
    }
  }

  &:first-child {
    margin-top: 10px;
  }
}

@mixin search-field-input($compact) {
  @if $compact == true {
    @include search-field-input-internal;

    .field-input {
      padding-left: 0;

      &.field-input-info {
        input {
          &.form-control {
            min-height: $field-min-height;
            font-size: $base-font-size;
          }
        }
      }
    }
  } @else {
    @include search-field-input-internal;
  }
}

@mixin search-field-row($nested: true) {
  @if $nested == true {
    .field-row {
      @include search-field-input(false);
    }
  } @else {
    &.field-row-search {
      @include search-field-input(true);
    }
  }
}

@mixin classification-lookup() {
  .classification-lookup {
    margin-top: 10px;
    margin-bottom: 10px;
    @include search-field-row();
    &.modal-inline {
      margin-top: -10px;
      margin-bottom: 0px;
    }
  }
}

@mixin expansible-container-listing() {
  .expansible-container-listing {
    &:first-child {
      margin-top: 0px;
    }

    + .section-subsection {
      &.classification {
        margin-top: 0;

        .added-classifications {
          .section-header-group {
            .section-header {
              margin-top: 15px;
            }
          }

          .label-header {
            + .section {
              .cqui-notification {
                &:only-child {
                  margin-top: 30px;
                }
              }
            }
          }
        }
      }
    }

    .expansible-container {
      &.no-header-border {
        .expansible-header {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}

@mixin container-label-error() {
  .show-label-text-color-on-error {
    color: $error-outline;
  }
}

@mixin search-listings() {
  &.search-listings {
    > {
      @include search-field-row();
    }

    padding-top: 10px;
    padding-bottom: 10px;

    .header-field {
      .section-header {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: font-size("search-listing-header-field-section-header");
        flex: 1 0 auto;
      }

      .section-header-note {
        flex: 0 1 100%;
        margin-right: 10px;
        margin-bottom: 10px;
        align-self: flex-start;
        display: flex;

        cqui-info-indicator {
          margin: 3px 5px 0;
          flex: 1 0 auto;
        }
      }
    }

    .field-input-info {
      flex: 1 0 auto;
    }

    + .section {
      @include border("top");
      margin-top: 10px;
    }

    &.search-listings-compact {
      margin: 0;
      padding: 0;
      padding-top: 10px;

      .classification-lookup {
        margin: 0;

        .field-row {
          margin-top: 0;

          .expansible-container-listing-spaced-right {
            @include make-sm-column-offset(4);
          }
        }
      }
    }
  }
}

@mixin informational-icon($margin-left: 0) {
  @if $margin-left == "margin-left" {
    $margin-left: 5px;
  }

  cqui-info-indicator,
  .icon-information-circle {
    @include square(15px);
    @include icon();
    max-width: 15px;
    margin-left: $margin-left;
  }
}

@mixin btn-input-icon-selected($input-type-color) {
  .button-icon {
    &.button-icon-circle-selected {
      @if $input-type-color == "black" {
        @include image-url("icon-checkbox-selected-black.svg");
      }
    }

    .icon-checkbox-checked {
      @include image-url("icon-checkbox-selected.svg");
    }
  }
}

@mixin btn-modes {
  $button-error-text: $black;
  $button-caution-text: $black;

  &[tooltip-error-type="true"] {
    @include input-error-outline();
    color: $button-error-text;

    &.btn-selected {
      @include btn-input-icon-selected($button-error-text);

      &[role="radio"] {
        .button-icon-circle-selected {
          @include image-url("icon-radio-selected-black.svg");
        }
      }
    }

    &:hover,
    &:focus,
    &:active {
      @include input-error-outline();
      color: $button-error-text;
    }
  }

  &[tooltip-caution-type="true"] {
    @include input-caution-outline();
    color: $button-caution-text;

    &.btn-selected {
      @include btn-input-icon-selected($button-caution-text);

      &[role="radio"] {
        .button-icon-circle-selected {
          @include image-url("icon-radio-selected-black.svg");
        }
      }
    }

    &:hover,
    &:focus,
    &:active {
      @include input-caution-outline();
      color: $button-caution-text;
    }
  }
}

@mixin btn-state(
  $background-color,
  $color,
  $border-color,
  $icon-color: "white"
) {
  &,
  &:active,
  &:focus,
  &:hover {
    @include border("all", $border-color);
    background-color: $background-color;
    color: $color;
  }

  &:active {
    &[disabled],
    &.disabled {
      background-color: $background-color;
    }
  }

  &:focus,
  &:hover {
    &.disabled,
    &[disabled] {
      @include border("all", $border-color);
      background-color: $background-color;
      color: $color;
    }
  }

  &:focus {
    &:active {
      background-color: $background-color;
    }
  }

  &.btn-selected {
    @include btn-input-icon-selected($icon-color);

    &:focus {
      @include btn-input-icon-selected($icon-color);

      &:active {
        &:hover {
          @include btn-input-icon-selected($icon-color);
        }
      }
    }
  }

  @include btn-modes();
}

@mixin btn-error {
  @include btn-state($light-pink, $black, $error-outline, "black");

  .button-icon {
    &.button-icon-circle-selected {
      @include button-icon-sm;
      @include image-url("icon-radio-selected-black.svg");
    }
  }
  @include btn-input-icon-selected("black");
}

@mixin severe-button-error {
  background-color: $cosmos;
  color: $black;
  @include border("all", $bright-red);
}

@mixin btn-default {
  @include btn-state($btn-default, $btn-default-text, gray);

  &.btn-selected {
    @include btn-state($btn-default-selected, $btn-selected-text, gray);
  }

  &.btn-launch-url {
    min-width: 0;
    @include cqui-icon-button-padding();
    @include border("all", $btn-default-border);

    &.disabled,
    &[disabled] {
      @include border("all", $btn-default-border);
    }
  }

  &.btn-error {
    @include btn-error();
  }

  &.btn-primary {
    color: $btn-default-text;

    &:not(.btn-launch-url) {
      border-color: $medium-gray;
    }
  }
}

@mixin collapse-arrow-expanded($size: 7, $color: $black) {
  border-bottom: none;
  border-top: #{$size}px solid #{$color};
  border-left: #{$size}px solid transparent;
  border-right: #{$size}px solid transparent;
}

@mixin collapse-arrow-collapsed($direction: "left", $size: 7, $color: $black) {
  border-bottom: #{$size}px solid transparent;
  border-top: #{$size}px solid transparent;

  @if ($direction == "left") {
    border-left: #{$size}px solid #{$color};
    border-right: none;
  } @else if($direction == "right") {
    border-right: #{$size}px solid #{$color};
    border-left: none;
  }
}

@mixin error-icon-in-toggleable-container() {
  .#{$lib-prefix}-icon {
    @include icon();
    @include square(15px);
  }
}

@mixin expansible-container-header-end() {
  .header-end {
    min-height: $expansible-list-min-height;
    align-items: center;
    display: flex;
    background-color: $alert-background-pink;
    @include border-radius("top-right" "bottom-right");
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 5;

    cqui-expansible-header-end {
      align-items: center;
      display: flex;
      height: 100%;
    }

    @include error-icon-in-toggleable-container();
  }
}

@mixin expansible-content-groupings {
  .expansible-content-grouping {
    border-top: none;
    padding-top: 0;
  }
}

@mixin expansible-content-grouping {
  display: flex;
  flex-direction: column;
  padding-bottom: 7px;
  font-size: font-size("expansible-content-grouping");
  margin-left: 25px;
  @include border("top", $cerulean);
  padding-top: 15px;
}

@mixin expansible-content-grouping-neighbor {
  padding-top: 0;
  border-top: none;
}

@mixin expansible-content-grouping-header {
  font-weight: bold;
  display: flex;
}

@mixin expansible-content-grouping-header-icon {
  width: $toggle-container-icon-height;
  @include icon();
  display: inline-block;
  margin-right: 5px;
}

@mixin expansible-content-grouping-list {
  padding-left: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

@mixin expansible-content-grouping-list-entry {
  width: 100%;
  display: flex;
  line-height: 1.3;

  &.alert-item {
    margin-left: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@mixin expansible-container-header-divider(
  $border-color: expansible-container,
  $height: 1px,
  $width: "calc(100% - 30px)",
  $margin-left: 15px
) {
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: #{$width};
    margin-left: $margin-left;
    @include border("bottom", $border-color, $height);
  }

  @if ($border-color == transparent) {
    border-bottom-color: $border-color;
  }
}

@mixin expansible-container-field-row-nested() {
  > .field-row-nested {
    padding: 0 #{$expansible-container-field-row-question-side-padding};

    .field-label {
      margin-left: 10px;
    }

    .input-group {
      .field-label {
        margin-left: 5px;
      }
    }

    .field-row {
      @include field-row-vertical();
    }
  }
}

@mixin field-static() {
  .field-static {
    width: auto;
    padding: 5px;
    padding-left: 0;
    padding-top: 4px;
    min-width: 80px;
    font-size: $base-font-size;

    .field-description {
      > ng-transclude {
        max-width: 70px;
      }
    }

    + .field-input {
      padding-right: 5px;
      margin-right: 5px;
      @include section-fields-right-input-column;

      &.input-group {
        .form-control {
          min-width: 0;
        }
      }
    }
  }
}

@mixin field-row-expansible-group() {
  .field-row-expansible-group {
    &.field-row-expansible-group-embed {
      &.field-row-expansible-group-header-right-large {
        .field-row {
          .field-input {
            @include field-static();
          }

          @media (min-width: $screen-sm-min) {
            .field-label {
              @include label-width($field-row-expansible-group-label-width);
            }
            .field-input {
              width: calc(#{100% - $field-row-expansible-group-label-width});
            }
          }

          @media (max-width: $screen-sm-max) {
            .field-label {
              @include label-width(
                $field-row-expansible-group-label-width-small-screen-width
              );
            }

            .field-input {
              width: $field-row-expansible-group-label-width-small-screen-width;
            }
          }
        }
      }
    }
  }
}

@mixin tiles-row {
  @include make-row();
  display: flex;
  flex-wrap: wrap;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}

@mixin tile {
  background-color: $white;
  margin-bottom: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  @include border-radius();
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  float: left;
}

@mixin tile-text {
  margin-top: 7px;
  margin-bottom: 7px;
  flex: 1 0 auto;
  max-width: 250px;
}

@mixin tile-button-group {
  margin: 10px 0 5px 10px;
  display: flex;
  flex: 0 1 auto;

  .btn {
    padding: 0 3px;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin animation-bob($prefix, $bob-distance) {
  @keyframes #{$prefix}bob {
    0% {
      transform: translateY(0);
    }

    6.25% {
      transform: translateY(-$bob-distance * 0.25);
    }

    13% {
      transform: translateY(-$bob-distance * 0.5);
    }

    25% {
      transform: translateY(-$bob-distance);
    }

    37.5% {
      transform: translateY(-$bob-distance * 0.5);
    }

    43.5% {
      transform: translateY(-$bob-distance * 0.25);
    }

    50% {
      transform: translateY(0);
    }

    62.5% {
      transform: translateY($bob-distance * 0.25);
    }

    69% {
      transform: translateY($bob-distance * 0.5);
    }

    75% {
      transform: translateY($bob-distance);
    }

    87% {
      transform: translateY($bob-distance * 0.5);
    }

    93.5% {
      transform: translateY($bob-distance * 0.25);
    }

    100% {
      transform: translateY(0);
    }
  }
}

@mixin dancing-dots {
  $bob-distance: 50px;
  $dancing-dot: $cerulean;

  .dancing-dots {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include animation-bob("", $bob-distance);
  @include animation-bob("short", 12.5px);

  .dot {
    animation: bob 1s cubic-bezier(0.93, 0.19, 0, 0.93) infinite;
  }

  .dancing-dot,
  .dot {
    @include border-radius("all", 100%);
    float: left;
    transform: translateY(0);
    opacity: 1;

    &:not(:last-child) {
      margin-right: 5px;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation-delay: $i * 0.185s;
        fill: $dancing-dot;
        background-color: $dancing-dot;
      }
    }
  }

  .dancing-dot {
    animation: shortbob 1s cubic-bezier(0.93, 0.19, 0, 0.93) infinite;
    @include square(13px);
  }
}

@mixin table-wrapper {
  .table-wrapper {
    overflow-x: auto;
    @include border-radius("all", 4px);

    .section-header-title {
      display: flex;
      padding-right: 15px;

      .section-header {
        margin-top: 3px;
        flex: 0 1 auto;
      }

      .form-control {
        margin-bottom: 5px;
      }

      .field-input {
        margin-bottom: 10px;
      }
    }

    &.table-wrapper-background {
      padding: 15px;
      @include border-radius();
      background-color: $screen-background;

      .section-header-row {
        .section-header {
          width: auto;

          &:first-child {
            margin-bottom: 10px;
          }

          ~ .btn {
            margin-bottom: 10px;
          }
        }
      }

      .table {
        margin-bottom: 0;
      }
    }
  }
}

@mixin table-error-row {
  &.error-row {
    background-color: $alert-background-pink;
  }
}

@mixin table-header-col-before-hide-gap {
  &:before {
    top: 0;
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    @include size(3px, 100%);
    left: -2px;
    background-color: $cerulean;
  }

  &:first-child {
    @include border-radius("top-left", table);

    &:before {
      display: none;
    }
  }
}

@mixin table-fields {
  .field-input {
    &.field-input-type-currency,
    &.field-input-type-number {
      flex: 1;

      .input-group-addon {
        padding: 0.45px 5px;
      }
    }
  }
}

@mixin table-large {
  &.table-large {
    display: block;
    overflow-x: auto;
  }
}

@mixin table-body-td {
  > tbody {
    > tr {
      > td {
        @content;
      }
    }
  }
}

@mixin table-striped {
  &.table-striped {
    background-color: $white;

    > tbody {
      > tr {
        &:nth-of-type(odd) {
          background-color: $white;
        }

        &:nth-of-type(even) {
          background-color: $light-blue;
        }

        &.table-even-row {
          background-color: $light-blue;

          + .table-dynamic-row {
            @include table-dynamic-row();
            background-color: $light-blue;

            .#{$lib-prefix}-dropdown-menu {
              ng-transclude {
                background-color: $light-blue;
              }
            }
          }
        }

        &.table-odd-row {
          background-color: $white;

          + .table-dynamic-row {
            @include table-dynamic-row();
            background-color: $white;

            .#{$lib-prefix}-dropdown-menu {
              ng-transclude {
                background-color: $white;
              }
            }
          }
        }

        @include table-notification-message();

        > td {
          &:first-child,
          &:last-child {
            @include border-radius("all", 0);
          }

          &.button-group {
            text-align: right;
          }
        }
      }
    }
  }
}

@mixin table-header-asterisk-in-header {
  thead {
    th {
      #{$lib-prefix}-required-indicator {
        position: relative;
        right: 0;
        display: inline-block;
        margin-left: 5px;
        @include square(10px);

        > span {
          line-height: 0.75;
          height: 10px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

@mixin tab-border-after {
  @include size(100%, 2.5px);
  position: absolute;
  display: block;
  bottom: -2px;
  left: 0;
}

@mixin table-dynamic-row {
  &.table-dynamic-row {
    > td {
      padding: 0;

      #{$lib-prefix}-dropdown-entry-container-section {
        .table {
          margin: 0 15px 15px;
        }
      }
    }
  }
}

@mixin pill-container {
  @include border("all", blue, 2px);
  @include border-radius("all", 5px);
  height: 55px;
  display: flex;
  position: relative;
  background-color: $cerulean;
  flex: 1 0 auto;
}

@mixin pill-label {
  cursor: default;
  float: left;
  background: $cerulean;
  color: $white;
  text-align: right;
  padding-right: 10px;
  @include size(40%, 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  flex: 1 0 40%;
}

@mixin pill-value {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: font-size("pill-value");
  @include border-radius("bottom-right" "top-right");
  position: relative;
  background-color: $white;
}

@mixin table-notification-message() {
  &.error-row {
    background-color: map-get($alert-colors, alert);

    .button-container {
      position: relative;
      padding-bottom: 30px;

      .btn {
        margin-bottom: 0;
        margin-top: 10px;
      }
    }

    .error-row-message {
      position: absolute;
      bottom: 3px;
      margin-right: 3px;
      white-space: nowrap;
      right: 0;
      padding-right: 10px;
      display: block;

      .error-row-icon {
        @include icon();
        @include image-url("icon-errors.svg");
        @include square(15px);
        display: inline-block;
        margin-bottom: -2px;
        min-width: 15px;
      }
    }
  }
}

@mixin tabs-wrap {
  &.cqui-tabs-wrap {
    .cqui-tabs-nav-container {
      display: flex;
      flex-wrap: wrap;
      max-height: 100%;
      max-width: calc(100% - 15px);

      > .cqui-tabs-nav-tabs {
        position: relative;
        display: flex;
        overflow-x: hidden;
        width: 100%;

        > cqui-tabs-nav {
          border-bottom: none;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          @include border("bottom", blue);
          z-index: 5;
        }
      }

      cqui-tabs-nav {
        flex-wrap: wrap;
        max-height: 100%;

        .cqui-tab-text-group {
          margin-top: 10px;
          position: relative;

          &:after {
            content: "";
            width: 200%;
            position: absolute;
            bottom: -1px;
            left: 100%;
            @include border("bottom", blue);
          }

          &.active {
            &:after {
              bottom: -4px;
            }
          }
        }
      }
    }

    &.cqui-tabs-fixed-header {
      &.cqui-tabs-wrap {
        .cqui-tabs-nav-container {
          > .cqui-tabs-nav-tabs {
            min-height: $tabs-container-fixed-wrap-nav-height;
          }
        }
      }
    }
  }
}

@mixin image-url($image) {
  background-image: url("#{$cqui-image-path}/#{$image}");
}
