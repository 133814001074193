.contact-container {
  .contact-select-icon {
    background-image: ae-image-url("icon-contact-lookup.svg");
  }

  .contact-body {
    background: white;
  }

  .icon-clear {
    min-height: 20px;
    min-width: 20px;
    border-radius: 50%;
    background-color: $cerulean;
    position: absolute;
    right: 6px;
    margin-top: -23px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    display: inline-block;
    vertical-align: middle;
    background-image: ae-image-url("icon-clear-circle.svg");
  }

  .contact-no-data {
    width: 100%;
    text-align: center;
    line-height: 60px;
    color: #555555;
  }

  .select-contact-search input {
    padding-right: 28px;
  }

  .select-contact-search input::-ms-clear {
    width: 0;
    height: 0;
  }

  .select-contact-name .contact-name {
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  td.button-group {
    float: none;
  }

  .button-edit-left {
    float: left;
  }
}
