@import "_mixins";

/* To allow for fonts to display properly in IE:
  Go to the settings dialog, "Internet Options",
  and in the 'Security' tab, click on the 'Custom Level' button.

  Scroll down to the area where you will see the "Downloads" section.
  There will be a 'Font Download' sub-section which the 'Enable' radio
  button can be selected to activate the downloading of fonts to enable
  external font resources to be render properly.
*/

@font-face {
  font-family: "proxima-nova-light";
  @include font-url("ProximaNova-Light_gdi.woff");
}

@font-face {
  font-family: "proxima-nova";
  @include font-url("ProximaNova-Regular_gdi.woff");
}

@font-face {
  font-family: "proxima-nova-bold";
  @include font-url("ProximaNova-Bold_gdi.woff");
}
