cqui-field-row-expansible-header {
  ae-question-yes-no {
    width: 100%;
  }
}

ae-question-yes-no {
  .label-bold label {
    font-weight: bold;
  }
}
