#{$lib-prefix}-field-row-expansible-group {
  display: flex;
  position: relative;
  min-height: $expansible-list-min-height;
  flex-direction: column;

  > ng-transclude {
    display: flex;
  }

  &.expanded {
    &.overlay {
      > ng-transclude {
        position: absolute;
        width: 100%;
        z-index: 100;
      }
    }
  }

  &.collapsed {
    > ng-transclude {
      #{$lib-prefix}-field-row-expansible-header {
        + .field-expansible-type-embed {
          .field-row {
            min-height: 0;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  #{$lib-prefix}-field-row-expansible-header {
    &.expand-collapse {
      + .field-expansible-type-embed {
        .field-row {
          min-height: 0;
        }
      }
    }
  }

  .cqui-expansible-container {
    &.collapsed {
      min-height: 0;

      .expansible-container {
        min-height: 0;
      }
    }
  }
}
