.page-account-information {
}

#reset-recomendation-button {
  flex: auto;
  justify-content: right;
  margin-left: auto;
  flex-grow: 0;
  margin-top: 7px;
  margin-right: 15px;
}

#business-description-disabled-display {
  background-color: #e5f4ff;
  margin-bottom: 0px;
  padding: 0px;
  .field-row .field-input.field-input-info {
    padding: 0px;
    margin-left: auto;
  }
}

.dropdown-options-classification-account-screen {
  margin-top: 0px;

  @media screen and (max-width: 991px) {
    width: 602px;
  }
  @media screen and (min-width: 992px) {
    width: 822px;
  }
  @media screen and (min-width: 1200px) {
    width: 1022px;
  }

  #show-all-dropdown-options {
    border-radius: 0px;
    display: flex;
    font-style: italic;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    height: 33px;
    width: 100%;
  }

  li a {
    height: 30px;
    display: flex;
    flex: auto;
    justify-content: left;
    align-items: center;
    padding-left: 10px;
    overflow: hidden;
  }

  li a:hover {
    background-color: rgb(37, 174, 219);
    color: white;
  }

  li:nth-child(even) {
    background: #e5f4ff;
  }
}

.application-classification-product-selection {
  overflow: visible;
  h2 {
    padding-top: 10px;
    font-size: 12.0000000025pt;
    margin: 0px;
    font-weight: 600;
  }
  h3 {
    margin: auto;
    font-size: 10.75pt;
    padding-bottom: 5px;
    font-weight: 500;
  }

  .field-row .field-input.field-input-info .btn {
    margin-left: auto;
    height: 33px;
    padding-bottom: 2px;
    padding-top: 2px;
    border-radius: 0px;
  }

  .dropdown-chevron-button-icon {
    button {
      min-height: 33px;
      min-width: 28px;
      display: flex;
      justify-content: center;
    }

    .cqui-icon-button .btn-content {
      width: 16px;
      height: 10px;
    }
  }

  .classification-sections {
    background-color: white;
    margin-bottom: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    overflow: visible;
    .business-desc-account-info-page-button {
      margin-bottom: 10px;
      margin-top: 1px;
      display: flex;
      flex: auto;
      button {
        margin-left: auto;
        display: flex;
        flex: 0.1;
        justify-content: right;
        align-items: center;
      }
      h2 {
        display: flex;
        align-items: center;
        justify-content: left;
        padding-top: 0px;
        margin: 0px;
      }
    }
    .field-row .field-input.field-input-info .btn {
      margin: 0px;
      align-items: center;
    }
    li {
      display: list-item;
    }

    .btn-group,
    .btn-group-vertical {
      flex-grow: 4;

      .dropdown-button-applicant-classification {
        background-color: #f2f2f2;

        border-color: #999;
        color: black;
        padding: 5px 45px 5px 5px;
        width: 100%;
        text-align: left;
      }
    }

    textarea {
      min-height: 33px;
      padding: 5px 45px 5px 5px;
    }
  }
  .premium-main-label {
    font-size: small;
  }
}
.cqui-collapsible-container-group .section {
  border-bottom: none;
  margin-bottom: 0px;
}
