.address-modal {
  .modal-dialog {
    width: auto !important;

    &.single-column {
      width: 330px !important;
    }

    &.double-column {
      width: 630px !important;
    }

    &.triple-column {
      width: 930px !important;
    }
  }

  .addresses-container {
    display: flex;
    .address-column {
      float: left;
      width: 280px;
      background: #ffffff;
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 15px;
      .icon-address-warning {
        width: 30px;
        height: 30px;
        display: inline-block;
        background-repeat: no-repeat;
        float: left;
        margin: 15px;
      }
      .warning-address-text {
        display: inline-block;
        float: left;
        width: (calc(100% - 80px));
      }
      .header-row {
        border-bottom: 1px solid #ccc;
        margin: 15px;
        padding-bottom: 5px;
        font-size: 14pt;
        font-weight: bold;
      }
      .sub-header-row {
        margin: 15px;
        padding-bottom: 5px;
        font-size: 14pt;
        font-weight: bold;
        color: #0086ae;
      }
      .address-text-container {
        margin-left: 15px;
      }

      .warning-block {
        margin: 10px 10px 10px;
        float: left;
      }

      .btn-checkbox {
        width: 32px;
        height: 32px;
        margin-bottom: 10px;
        text-align: center;
        margin-right: 5px;
        float: left;
        min-width: 32px;
        &.btn-selected-checkbox {
          background-color: #0086ae;
          color: #fff;
          border: none;
          .button-icon.radio {
            @include image-url("icon-radio-selected.svg");
          }
        }
        &.btn-unselected-checkbox {
          color: #000;
          background-color: #b2dae7;
          border: none;
          .button-icon.radio {
            @include image-url("icon-radio-unselected.svg");
          }
        }
      }
    }
  }
  .address-modal-header {
    float: left;
    max-width: 250px;
    margin: 15px;

    .text-content {
      float: left;
      width: calc(100% - 70px);
      margin-left: 10px;

      h2 {
        margin-top: 0px;
        margin-left: 0px;
        color: #000;
        font-size: 15pt;
        top: 1px;
        font-weight: bold;
      }
    }
    .map-icon {
      width: 60px;
      height: 60px;
      float: left;
    }
  }

  .message-button-row {
    margin: 15px 0px;
    float: left;
    margin-top: 0px;
    width: 100%;
    height: 25px;
    .message-buttons {
      position: absolute;
      right: 20px;
    }
  }
}
