.ae-location {
  .subsection-group {
    float: left;
    width: 50%;
    padding-left: 15px;
    .subsection {
      width: 100%;
      .header {
        margin-top: 5px;
        font-weight: bold;
        margin-left: 0px;
      }
      .description {
        font-weight: bold;
      }
      &:last-child {
        border: none;
      }
      &.subsection-labelless-form {
        > .field-row {
          border-bottom: none;
          padding-bottom: 0px;
        }
        .zip-city-state-field {
          margin-bottom: 5px;
        }
        .field-label {
          display: none;
        }
        /* It appears a change to the mockup site has removed all padding, from all elements, on labelless forms */
        .btn {
          padding: 0 9px;
        }
      }
    }
    .subsection-listing {
      .listing-entry {
        .listing-entry-button-group {
          flex: 1 0 auto;
          flex-direction: column;
          display: flex;
        }
        .listing-entry-button {
          align-self: flex-end;
          margin-bottom: 5px;
        }
        &.active {
          background-color: $light-gray;
        }
      }
    }
    .listing-entry-icon {
      min-width: 37px;
    }
    .non-specific-button {
      align-self: flex-end;
      padding-bottom: 5px;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .field-row {
      .alert-message {
        img {
          width: 20px;
          height: 20px;
          top: 50%;
          position: absolute;
          margin-top: -10px;
        }
        .alert-message-text {
          display: inline-block;
          width: calc(100% - 40px);
          margin-left: 30px;
        }
      }
    }
  }
  .enter-new-address-btn {
    margin-top: 10px;
    float: left;
  }
  .ae-previously-entered-address {
    .listing-group {
      // Select / Edit Buttons
      .cqui-icon-button {
        width: 85px;
        .btn-content {
          justify-content: left;
        }
      }
    }
  }
  .ae-did-you-mean-address {
    padding-left: 15px;
    .listing-group {
      height: 190px !important;
      .cqui-icon-button {
        width: 85px;
        .btn-content {
          justify-content: left;
        }
      }
    }
  }

  .additional-information-address-questions-container {
    .building-address-suggestion {
      border-top: 1px solid grey;
      padding-top: 5px;

      .header {
        margin-left: 0px;
        font-weight: bold;
      }
    }
    .col-additional-question {
      padding-bottom: 10px;
    }
    .no-of-employees {
      padding-left: 0px;
      label {
        float: left;
        margin-left: 0px;
        text-align: left;
        width: 70%;
        max-width: 250px;
      }
      input {
        width: 60px;
        float: left;
        margin-left: 30px;
      }
    }
    .label-description {
      label {
        text-align: right;
        //min-width: 130px;
      }
      .required {
        position: absolute;
        margin-left: 3px;
      }
    }
  }

  .modify-Address-btn {
    margin-top: 60px;
  }

  /* Address Not Verified Message */
  .address-not-verified {
    display: flex;
    align-items: center;
    font-size: 9pt;
    margin-bottom: 5px;

    img {
      width: 15px;
    }

    p {
      margin: 0;
      margin-left: 3px;
    }
    &.wc-state-eligible-error {
      position: absolute;
      top: 10px;
      left: 165px;
    }
    &.auto-state-eligible-error {
      position: absolute;
      top: 5px;
      margin-left: 125px;
    }
  }

  .building-address-suggestion.one-entry {
    .header {
      display: flex;
      align-items: center;

      .address-not-verified {
        margin-left: 5px;
      }
    }
  }

  .ae-previously-entered-address {
    .section-header-row {
      float: none;
    }

    .address-not-verified {
      margin-bottom: 10px;
    }
  }

  &.section-split-subsections-half .subsection.subsection-listing {
    // Override enforced height dimensions from mockup site
    .listing-group {
      max-height: 265px;
      height: auto;
    }

    // Override excessive padding from mockup site
    .listing-entry {
      padding: 10px 10px;
    }
  }

  .additional-information-address {
    margin-top: 0px;
  }

  .address-container {
    margin-bottom: 10px;
  }
}
