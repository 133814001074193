.bop-businessowners-coverages {
  .policywide-coverage-container {
    .button-text {
      margin-left: 5px;
    }
  }

  .darker-background {
    &.negative,
    &.affirmative {
      background-color: $cerulean;
      color: $white;
      .radio {
        @include image-url("icon-radio-selected.svg");
      }
    }
  }

  .btn[disabled]:not(.darker-background) {
    background-color: $cerulean;
    color: $white;
  }

  .btn[disabled]:not(.darker-background) .button-icon.radio {
    @include image-url("icon-radio-unselected-white.svg");
  }

  .button-icon.checkbox-checked {
    @include image-url("icon-checkbox-selected.svg");
  }

  .cqui-collapsible-container {
    a:hover,
    a:focus {
      text-decoration: none;
    }
    .heading-content {
      outline: none;
    }
  }

  .icon-single-account {
    min-height: 30px;
    min-width: 30px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    margin-left: 4px;
    @include image-url("icon-contact.svg");
  }

  .header-row .content-row {
    @include make-row();
    .label-name {
      @include make-sm-column(3);
    }
    .label-address {
      @include make-sm-column(4);
    }
    .button-group {
      @include make-sm-column(5);
    }
  }

  .field-row .form-control:disabled {
    opacity: 0.5;
    background-color: $medium-gray;
    border: 1px solid $gray;
    font-family: inherit;
    color: $black;
  }

  .error-text-field {
    border: 1px solid $bright-red;
    background: $light-pink !important;
  }

  .ng-datepicker {
    display: flex;
  }

  .section .field-row {
    .field-input {
      display: flex;
    }
  }

  .section .field-row {
    .field-plus-endorse-msg {
      display: flex;
      width: 33.33%;
      padding-left: 15px;
    }
  }

  .section .field-row .field-input {
    padding-left: 0;

    select {
      width: 100%;
    }
  }

  .bop-coverages-section,
  .policywide-coverage-container,
  .policywide-coverage-more-options-container {
    .field-input {
      padding-left: 0;
      align-items: center;
    }

    .coverage.section {
      .field-input {
        padding-left: 0;
      }
      padding: 0;

      & > .field-row:first-child:nth-last-child(1) {
        margin-top: 0;
      }
    }

    .contact-container {
      background-color: transparent;
    }
    .expansible-type-embed {
      .section-subsection {
        margin-top: 5px;
      }
    }
  }
}
