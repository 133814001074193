.wc-coverages {
  .modifiers-and-deductibles {
    .section-modifiers,
    .section-deductibles {
      .section-header {
        margin-top: 10px;

        .tooltip-font {
          font-weight: normal;
        }
      }
    }
  }

  .section-modifiers,
  .section-deductibles {
    .field-row {
      .form-control.ng-invalid.ng-touched {
        border: 1px solid #cc0000;
        background: #ffcccc !important;
      }
      .field-input.radio-pair-error {
        button {
          border: 1px solid #cc0000;
          background: #ffcccc !important;
        }
      }
    }
  }
  .section-fields-underlined {
    .term-group {
      border-bottom: 1px solid #ddd;
      .field-row {
        border: none;
      }
    }
  }
}

.blanket-waiver-list {
  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
