.review-quote {
  .btn.disabled {
    pointer-events: none;
  }
  .short-description {
    display: flex;
  }
  .referral-btn {
    display: flex;
    justify-content: flex-end;
    .btn {
      align-self: flex-end;
    }
  }
  .icon-alert {
    min-width: 16px;
    min-height: 20px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    display: flex;
    @include image-url("icon-alert-information.svg");
    margin-top: 5px;
  }

  .expansible-content {
    max-height: 430px;
    overflow-y: auto;
  }

  .cqui-collapsible-container-group.section .section:not(.section-highlighted),
  .cqui-collapsible-container-group.section
    .section:not(.section-highlighted-light) {
    padding: 0px;
  }
  .alert-list {
    border-bottom: 1px solid $cerulean;
    &:first-child {
      margin-top: 10px;
      border-top: 1px solid $cerulean;
    }
    &:last-child {
      margin-bottom: 10px;
    }
    .icon-alert {
      margin-left: 15px;
    }
  }
  .alert-content {
    background-color: $light-pink;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .coverage-classification-container {
    min-height: 0px;
    padding-bottom: 15px;

    .building-address {
      width: 25%;

      .button-row {
        padding-left: 27px;
      }
    }

    .header-row-location .coverage-classification {
      @include make-sm-column(7);
    }

    .content-row-location .coverage-classification {
      @include make-sm-column(7);
      border-bottom: none;

      .coverage {
        display: flex;

        .coverage-name {
          width: 50%;
          min-width: 190px;
        }

        .coverage-value {
          // per comment in defect 424318, add more space
          margin-left: 15px;
        }
      }

      .classification {
        border-top: 1px solid $cerulean;
        padding-top: 5px;
        margin-top: 5px;
      }
    }
  }

  .icon-wc-large {
    float: left;
  }
  .table-container .table .table-col .table-col-cell {
    float: left;
    width: 100%;
  }
  .submission-details {
    .tooltip-container {
      min-width: 100px;
    }
  }
  .accordion-section .accordion-content .quote-accordion {
    min-height: 100px;
  }

  .warning-message-section {
    .inline-description {
      align-items: center;
    }
    .inline-description-icon {
      min-width: 20px;
      min-height: 20px;
    }
  }

  .icon-forbidden {
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-left: 3px;
  }

  .icon-margin {
    margin-left: 4px;
  }
}
