.cqui-sub-nav-bar {
  $sub-nav-bar: $silver;
  $sub-nav-bar-border: $cerulean;

  $sub-nav-bar-text: $black;
  $sub-nav-bar-tab-inactive: $white;
  $sub-nav-bar-tab-text-active: $white;
  $sub-nav-bar-tab-active: $cerulean;
  $sub-nav-bar-tab-text-active: $white;
  $sub-nav-bar-tab-active-hover: $medium-dark-blue;
  $sub-nav-bar-tab-hover: $nationwide-blue;
  $sub-nav-bar-tab-text-hover: $white;

  $sub-nav-bar-tab-text-disabled: $disabled-text-gray;
  $sub-nav-bar-tab-disabled: $disabled-gray;

  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: calc(100% - 1px);
  z-index: 2;
  background-color: $sub-nav-bar;
  padding: 0;
  border-radius: 0;
  @include border("left", $sub-nav-bar-border);

  .content-container {
    display: block;
  }

  .sub-navbar-nav {
    margin: 0px 5px 0 7px;
    height: calc(100% - 10px);
    display: flex;

    &:before,
    &:after {
      display: none;
    }

    > li {
      @include border("top", "blue-general");
      @include border("bottom", "blue-general");
      height: auto;
      overflow: hidden;
      display: flex;

      a {
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        color: $sub-nav-bar-text;
        background: $sub-nav-bar-tab-inactive;

        .nav-bar-icon {
          + .nav-bar-icon-on-hover {
            display: none;
          }
        }

        &:hover {
          background-color: $sub-nav-bar-tab-hover;
          color: $sub-nav-bar-tab-text-hover;

          .nav-bar-icon {
            display: none;

            + .nav-bar-icon-on-hover {
              display: block;
              margin-right: 0;
              margin-left: 5px;

              &.nav-bar-icon-left {
                margin-right: 5px;
                margin-left: 0;
              }
            }
          }
        }
      }

      .nav-bar-icon {
        width: 25px;
        height: 25px;
        margin-right: 5px;
      }

      .nav-bar-text {
        font-size: font-size("main-nav-bar-sub-page-text");
        font-weight: 700;
        line-height: 1em;
        margin-left: 0;

        + .nav-bar-icon {
          margin-right: 0;
          margin-left: 5px;
        }
      }

      &[disabled] {
        cursor: not-allowed;

        a {
          pointer-events: none;
          color: $sub-nav-bar-tab-text-disabled;
        }
      }

      &.active {
        cursor: pointer;

        a {
          color: $sub-nav-bar-tab-text-active;
          background-color: $sub-nav-bar-tab-active;

          &:hover {
            background-color: $sub-nav-bar-tab-active-hover;
          }
        }
      }

      &:first-child {
        @include border("left", "blue-general");
        @include border-radius("top-left" "bottom-left", subnav);
      }

      &:last-child {
        @include border("right", "blue-general");
        @include border-radius("top-right" "bottom-right", subnav);

        a {
          @include border("left", $white);
        }
      }

      &:not([icon-right="true"]) {
        // Navbar icon on the left
        a {
          &:hover {
            background-color: $sub-nav-bar-tab-hover;
            color: $sub-nav-bar-tab-text-hover;

            .nav-bar-icon {
              display: block;
            }
          }
        }
      }
    }
  }

  .navbar-right {
    margin-right: 10px;
    @include border-radius();
  }

  .sub-navbar-nav {
    margin-bottom: 0;
  }

  &.cycle-pages {
    &.cqui-sub-nav-bar {
      .sub-navbar-nav {
        .cqui-sub-nav-tab {
          pointer-events: all;

          &:hover {
            cursor: pointer;
          }

          a {
            padding: 4px 7px;
            display: flex;
            flex-direction: row;
            border-radius: 0;
          }

          + .cqui-sub-nav-tab {
            @include border("left", blue);
          }

          &:first-child {
            a {
              padding-left: 10px;

              .nav-bar-text {
                text-align: right;
              }
            }
          }

          &:last-child {
            a {
              padding-right: 10px;

              .nav-bar-text {
                text-align: left;
                justify-content: flex-start;
              }
            }
          }

          &[disabled] {
            &:hover {
              cursor: not-allowed;
            }

            a {
              background-color: $sub-nav-bar-tab-disabled;
              color: $sub-nav-bar-tab-text-disabled;
            }
          }
        }
      }
    }
  }

  @media (max-width: $screen-md-max) {
    &.cycle-pages {
      &.cqui-sub-nav-bar {
        .nav-bar-text {
          display: none;
        }
      }
    }
  }
}
