.page-additional-information {
  .main-form-input-section > {
    .section:first-child {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .field-row {
    .field-label {
      @include make-sm-column(5);
      padding-right: 0;
      min-height: $field-min-height;
    }

    .field-input {
      @include make-sm-column(6);

      &.field-input-sm {
        @include field-input-sm(3);
      }

      &.field-input-md {
        @include make-sm-column(4);
      }
    }

    .field-static {
      @include make-sm-column(6);
    }

    &.field-row-search {
      .field-input {
        padding: 0;
        width: 100%;
      }
    }
  }

  .zip-field-row {
    .field-input {
      .form-control {
        width: 100%;
      }

      .cqui-cover {
        width: calc(100% - 30px);
        margin-left: 15px;
      }
    }
  }

  .fein-field {
    .field-input {
      @include make-sm-column(
        percent-to-bootstrap-grid-unit($input-zip) * $input-fein-over-base-ratio
      );
    }
  }

  @include field-input-right-align;
}
