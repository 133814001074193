ae-auto-coverage {
  .field-row .field-container .expansible-type-embed {
    &:before {
      content: none;
    }

    .expansible-container:after {
      content: none;
    }

    .field-input select.form-control {
      max-width: 100%;
    }
  }

  &:last-child .field-row {
    border-bottom: none;
  }

  .coverage-selector {
    display: flex;
  }
}
