.sub-page-view-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  .main-form-content {
    .main-form-input-section {
      > .section {
        margin-top: 0px;

        &:first-child {
          margin-top: 0;
          height: auto;
        }

        &:only-child {
          height: 100%;
          overflow-y: auto;
        }

        &:last-child {
          height: 100%;
        }
      }
    }
  }

  .header-field {
    .section-subsection {
      margin: 0;

      .section-header {
        padding-left: 0;
      }
    }
  }

  .section-split-subsections-half {
    .subsection {
      &.subsection-map {
        margin-right: 10px;
        width: calc(50% - 10px);
        padding-left: 5px;
      }
    }

    .subsection-group {
      .subsection {
        border-left: none;

        &.subsection-listing {
          .header-field {
            .section-header {
              padding-bottom: 0;
            }

            + :not(.listing-group) {
              margin-top: 10px;
            }
          }
        }

        &.subsection-map {
          .listing-group {
            height: 300px;
          }
        }
      }
    }
  }
}
