$lib-prefix: "cqui";

$cqui-image-path: "/legacy/ui/lib/assets/images" !default;
$cqui-font-path: "/legacy/ui/lib/assets/fonts" !default;

$screen-xs: 1px !default;
$screen-sm: 2px !default;

$nationwide-logo: "nationwide-logo";
$nationwide-white-logo: "nationwide-white-logo";
$nationwide-logo-small: "nationwide-logo-small";

$brand-height: 60px;

// Red/Pink
$bright-red: #c00c00;
$hot-pink: #d51122;
$cosmos: #ffd4d4;
$light-pink: #ffcccc;
$misty-rose: #ffe6e6;

// Yellow
$yellow: #fff7d1;
$golden-sand: #ffea8d;
$corn: #cfa900;

// Orange
$orange: #ff9600;

// Blues
$powder-blue: #b2dae7;
$muted-powder-blue: #c5d6db;
$light-bright-blue: #c6ecf7;
$light-blue: #e5f4ff;
$lighter_blue: $light-blue;
$light-medium-blue: #dbeef4;
$light-medium: #2597d4;

$muted-blue: #c2d9e1;
$medium-light-blue: #1690b7;
$medium-blue: #419ad1;
$medium-dark-blue: #0086ae;
$cerulean: #005e7a;
$astronaut-blue: #004c62;
$dark-teal: #003a4b;
$dark-blue: #1c5180;
$blue-lagoon: #006c8d;
$eastern-sky-blue: #1790b7;
$nationwide-blue: #1c57a5;
$alice-blue: #f1f9ff;
$shakespeare: #59b3ce;
$curious: #25aedb;
$surf: #0e6c8b;

// Grays
$lightest-gray: #f2f2f2;
$ash-gray: #ececec;
$light-gray: #dedede;
$medium-gray: #ccc;
$medium-darker-gray: #cdcdcd;
$gray: #999;
$dark-gray: #7f7f7f;
$ship-gray: #505050;
$industrial-gray: #575757;
$general-background-gray: $light-gray;
$gray-nurse: #ebebe4;
$silver: #cbcbcb;
$gallery: #ededed;
$alto: #d7d7d7;
$boulder: #777777;
$dove-gray: #666666;

$disabled-gray: #dbdbdb;
$disabled-text-gray: #a7a7a7;
$disabled-content-gray: $disabled-text-gray;

$white: rgb(255, 255, 255);
$black: #000;

$shadow: rgba(50, 50, 50, 0.9);

$alert-background-pink: $light-pink;
$caution-background-yellow: $yellow;
$informational-background-blue: $powder-blue;
$exclamation-background: $white;
$general-border-blue: $cerulean;

$alert-background-pink-hover: $misty-rose;

$error-outline: $bright-red;
$error-background: $light-pink;
$error-background-hover: $cosmos;

$alert-colors: (
  info: $informational-background-blue,
  alert: $alert-background-pink,
);

$zebra-stripe-even: $light-blue;

$horizontal-rule: $medium-gray;

$icon-size: (
  icon-building: 0.8333333333,
  icon-hide-optional-fields: 0.75,
);

$nav-bar-border-outline: $cerulean;

$indicator-arrow: $black;

$container-border-radius: 3px;

$field-label-top-padding: 4px;

$input: $lightest-gray;
$input-placeholder-text: $gray;
$input-border: $gray;
$input-date-divider: $black;
$input-disable-border: $medium-gray;
$input-disabled: $light-gray;
$input-disabled-placeholder-less-bold: $dark-gray;
$input-disabled-placeholder-bold: $black;

$input-small-base-width: 25%;

$input-zip-city-state: 100%;
$input-zip: $input-small-base-width;
$input-city-state: $input-zip-city-state - $input-zip;
$input-phone: $input-zip;
$input-id: 20%;

$input-fein-over-base-ratio: 1.1;

$input-width: (
  city-state: $input-city-state,
  zip: $input-zip,
  phone: $input-phone,
  id: $input-id,
);

$btn: $powder-blue;
$btn-selected: $cerulean;
$btn-selected-text: $white;
$btn-disabled: $powder-blue;

$btn-primary: $cerulean;
$btn-primary-text: $white;

$btn-default: $btn;
$btn-default-width: 70;
$btn-default-selected: $btn-selected;
$btn-default-selected-active: $white;
$btn-default-hover: $btn-default;
$btn-default-text: $black;
$btn-default-hover-text: $white;
$btn-default-disabled: $btn-disabled;
$btn-default-disabled-hover: $btn-default-disabled;
$btn-default-border: $cerulean;

$btn-icon-width: 20px;
$btn-icon-padding: 3px;
$btn-icon-padding-side: 9px;

$radio-btn-set-text: $black;
$radio-btn-set-border: $white;
$radio-btn-set-outline-border: $black;
$radio-btn-set-outline: $white;

$font-fallbacks: "Arial", "Trebuchet", "Verdana", "MS Sans Serif";
$base-font: $black;
$base-font-size: 10.75pt;

// Font size ratio list is over the base font size ($font-ratio / $base-font-size)
$font-size-ratios: 0.8837209302, 0.8930232558, 0.911627907, 0.8372093023, 0.9,
  0.9302325581, 0.976744186, 1, 1.023255814, 1.069767442, 1.093023256,
  1.11627907, 1.11999999, 1.209302326, 1.302325581, 1.395348837,
  1.302325581395349, 1.488372093, 1.534883721, 1.581395349, 1.674418605, 1.9;
$font-size-ratio-over-base: ();
$font-vars-to-size-ratios: (
  button-option-container: 0.8372093023 * $base-font-size,
  collapsible-container-notification: 0.8372093023 * $base-font-size,
  main-nav-bar-info: 0.8372093023 * $base-font-size,
  field-expand-text: 0.8837209302 * $base-font-size,
  button-caret: 0.8837209302 * $base-font-size,
  modal-field-text-status-hint: 0.8837209302 * $base-font-size,
  tile-text-line-icon: 0.8837209302 * $base-font-size,
  menu-container: 0.8930232558 * $base-font-size,
  main-nav-bar-text: 0.8930232558 * $base-font-size,
  main-nav-bar-sub-page-text: 0.9 * $base-font-size,
  field-legend-value: 0.911627907 * $base-font-size,
  main-nav-bar-page-bar-subheader: 0.911627907 * $base-font-size,
  contact-modal-details: 0.9302325581 * $base-font-size,
  modal-button-selection-container: 0.9302325581 * $base-font-size,
  field-legend-key: 0.9302325581 * $base-font-size,
  main-footer: 1rem,
  section-header-note: 0.9302325581 * $base-font-size,
  radio-set-button: 0.9302325581 * $base-font-size,
  subsection-entry-listing-entry-header: 0.9302325581 * $base-font-size,
  notification-text-content-hint: 0.9302325581 * $base-font-size,
  tile-text-line: 0.9302325581 * $base-font-size,
  expansible-container-listing-count: 0.976744186 * $base-font-size,
  menu-entry-title: 0.976744186 * $base-font-size,
  tooltip-text: 0.976744186 * $base-font-size,
  expansible-container-medium-header-subtitle: $base-font-size,
  section-notification-text: $base-font-size,
  field-misc-section-header: 1.023255814 * $base-font-size,
  alert-description: 1.023255814 * $base-font-size,
  subsection-listing-entry-text: 1.023255814 * $base-font-size,
  collapsible-container-expand-heading: 1.023255814 * $base-font-size,
  expansible-container-header: 1.023255814 * $base-font-size,
  main-nav-bar-page-bar-menu-entry: 1.023255814 * $base-font-size,
  expansible-container-notification-text: 1.023255814 * $base-font-size,
  expansible-container-header-subtitle: 1.023255814 * $base-font-size,
  main-nav-bar-main-form-address-text: 1.023255814 * $base-font-size,
  expansible-container-expand-text: 1.023255814 * $base-font-size,
  carousel-listing-description-text: 1.023255814 * $base-font-size,
  alert-description-header-subtitle: 1.069767442 * $base-font-size,
  subsection-listing-entry-header-h3: 1.093023256 * $base-font-size,
  base-h2: 1.11627907 * $base-font-size,
  btn-text: 1.11627907 * $base-font-size,
  field-text: 1.11627907 * $base-font-size,
  field-description: 1.11627907 * $base-font-size,
  expansible-header-title-text-common: 1.11627907 * $base-font-size,
  expansible-content-grouping: 1.11627907 * $base-font-size,
  footer-app-brand-logo: 1rem,
  section-bar-listing-entry-text: 1.11627907 * $base-font-size,
  search-field-input: 1.11627907 * $base-font-size,
  main-nav-bar-text-active: 1.11627907 * $base-font-size,
  collapsible-container-title: 1.11627907 * $base-font-size,
  tabs-container: 1.11627907 * $base-font-size,
  expansible-header-title-text: 1.11627907 * $base-font-size,
  main-nav-bar-page-bar-menu-entry-text: 1.11627907 * $base-font-size,
  nested-tabs-line-tab-text: 1.11627907 * $base-font-size,
  inline-description-text-callout: 1.209302326 * $base-font-size,
  expansible-container-medium-header-title: 1.209302326 * $base-font-size,
  modal-text-row: 1.209302326 * $base-font-size,
  modal-description: 1.209302326 * $base-font-size,
  search-listing-header-field-section-header: 1.209302326 * $base-font-size,
  carousel-description-icon-with-text: 1.209302326 * $base-font-size,
  section-row-header-large: 1.488372093 * $base-font-size,
  carousel-modal-body-header: 1.488372093 * $base-font-size,
  main-nav-bar-pagebar-header: 1.488372093 * $base-font-size,
  modal-header: 1.581395349 * $base-font-size,
  carousel-description-text-bullet: 1.581395349 * $base-font-size,
  section-header-required: 1.674418605 * $base-font-size,
  pill-value: 1.674418605 * $base-font-size,
  expansible-container-large-header-title: 1.9 * $base-font-size,
);

// use @debug $font-size-ratio-over-base to check on converted values to pt

@each $font-ratio in $font-size-ratios {
  $font-size-ratio-over-base: map-merge(
    $font-size-ratio-over-base,
    (#{$font-ratio}: $font-ratio * $base-font-size)
  );
}

$nav-tab-main-nav-skew: (
  main-nav: 153deg,
);

$nav-tab-main-nav-skew-disabled: (
  before: skewX(206deg) rotate(-45deg),
  after: skewX(-206deg) rotate(45deg),
);

$main-nav-bar-container: $ship-gray;
$main-nav-bar: transparent;
$main-nav-bar-header: $dark-teal;
$main-nav-bar-active: $cerulean;
$main-nav-bar-active-text: $white;
$main-nav-bar-available-text: $white;
$main-nav-bar-visited: $blue-lagoon;
$main-nav-bar-text: $medium-gray;
$main-nav-bar-menu-item-disabled: $medium-gray;
$main-nav-bar-pseudo-width-offset: 17px;
$main-nav-bar-radio-button-set-text: $white;

$nav-bar: $white;
$nav-bar-height: 58px;
$nav-bar-vertical-gap: 3px;
$nav-bar-total-height: $nav-bar-height + (2 * $nav-bar-vertical-gap);

$nav-bar-active: $cerulean;
$nav-bar-active-text: $white;
$nav-bar-available-text: $black;
$nav-bar-border: $powder-blue;
$nav-bar-text: $medium-gray;
$nav-bar-menu-item-disabled: $medium-gray;
$nav-bar-hover: $light-medium;
$nav-bar-hover-text: $white;

$nav-bar-active-hover: $powder-blue;
$nav-bar-active-hover-corner: $ship-gray;
$nav-bar-active-hover-text: $black;
$nav-bar-indicator-arrow: $indicator-arrow;
$nav-bar-indicator-arrow-active: $white;
$nav-bar-directional-arrow-outline: $medium-dark-blue;

$nav-bar-disabled-opacity: 0.65;

$nav-bar-page-bar: $ash-gray;
$nav-bar-page-bar-height: 52px;
$nav-bar-page-bar-spacing: 10px;
$nav-bar-page-bar-top-spacing: 2px;
$nav-bar-page-bar-tab-min-height: $nav-bar-page-bar-height -
  (2 * $nav-bar-page-bar-top-spacing);
$nav-bar-margin-left-offset: 30px;

$nav-bar-page-bar-text: $industrial-gray;
$nav-bar-page-bar-text-active: $black;
$nav-bar-page-bar-header-group: $black;
$nav-bar-page-bar-marker-width: 10px;
$nav-bar-page-bar-marker-active-outline: $black;
$nav-bar-page-bar-marker-before-active: $blue-lagoon;
$nav-bar-page-bar-marker-active: $cerulean;
$nav-bar-page-bar-marker-active-backdrop: $blue-lagoon;
$nav-bar-page-bar-marker-inactive-outline: $ship-gray;
$nav-bar-page-bar-marker-inactive: $gray;
$nav-bar-page-bar-description-text: $white;
$nav-bar-page-bar-active: $silver;
$nav-bar-page-bar-inactive: $ash-gray;
$nav-bar-page-bar-hover: $powder-blue;
$nav-bar-page-bar-hover-text: $black;
$nav-bar-container-height: $brand-height + $nav-bar-total-height +
  $nav-bar-page-bar-height;

$sub-page-nav-bar-page-bar: $ash-gray;

$sub-page-nav-bar-page-bar-no-page-bar: $ash-gray;
$sub-page-nav-bar-page-bar-active-not-first-active: $ash-gray;

$sub-page-nav-bar-page-bar-text: $black;
$sub-page-nav-bar-page-bar-header-group: $black;
$sub-page-nav-bar-page-bar-header-note: $black;

$sub-page-nav-bar-page-bar-inactive: $silver;
$sub-page-nav-bar-page-bar-inactive-index-less-than-of-active: $ash-gray;
$sub-page-nav-bar-page-bar-inactive-index-greater-than-active: $ash-gray;

$sub-page-nav-bar-page-bar-active: $silver;
$sub-page-nav-bar-page-bar-hover: $powder-blue;
$sub-page-nav-bar-page-bar-unvisited: $ash-gray;

$sub-page-nav-bar-page-arrow-outline: $cerulean;
$sub-page-nav-bar-link-left-offset: 25px;

$menu-container: $white;
$menu-container-text: $black;
$menu-entry: $white;
$menu-entry-border: $general-border-blue;
$menu-entry-hover: $white;
$menu-entry-content-hover: $light-blue;
$menu-entry-content-buttons-hover: transparent;
$menu-entry-listing-show: $white;
$menu-entry-listing-item-hover: $light-blue;

$border-divider-blue: $cerulean;
$border-divider-medium-gray: $medium-gray;

$inline-description: $white;
$inline-description-border: $border-divider-blue;
$inline-description-text: $black;

$border-color: (
  disabled-gray: $disabled-content-gray,
  gray: $border-divider-medium-gray,
  gray-general: $light-gray,
  ship-gray: $ship-gray,
  muted-powder-blue: $muted-powder-blue,
  $light-blue: $light-blue,
  light-bright-blue: $light-bright-blue,
  blue: $border-divider-blue,
  blue-general: $general-border-blue,
  blue-medium: $medium-blue,
  $light-medium: $light-medium,
  $cerulean: $cerulean,
  nav-bar-page-bar-top: $white,
  error: $bright-red,
  clear: transparent,
  tranparent: transparent,
  black: $black,
  white: $white,
  input: $input-border,
  nav-bar-border: $nav-bar-border,
  nav-bar-indicator-arrow: $nav-bar-indicator-arrow,
  nav-bar-indicator-arrow-active: $nav-bar-indicator-arrow-active,
  nav-bar-menu-item-disabled: $nav-bar-menu-item-disabled,
  expansible-container: $medium-blue,
  menu: $cerulean,
);

$radius: (
  small: 2px,
  small-medium: 3px,
  medium: 4px,
  medium-large: 5px,
  large: 6px,
  extra-large: 7px,
);

// Border
$border-radius: (
  section: map-get($radius, small),
  section-bar: map-get($radius, extra-large),
  tooltip: map-get($radius, small),
  button: map-get($radius, small),
  button-group: map-get($radius, medium-large),
  expansible-container: map-get($radius, small-medium),
  collapsible-container: map-get($radius, small),
  listing-entry: map-get($radius, medium),
  navbar: map-get($radius, medium),
  subnav: map-get($radius, medium),
  table: map-get($radius, medium),
  table-inside: map-get($radius, small-medium),
  modal: map-get($radius, large),
);

$table-col-min-width: (
  sm: 55px,
  md: 80px,
  mdlg: 225px,
  lg: 250px,
);

$table-col-width: (
  sm: map-get($table-col-min-width, sm),
  md: map-get($table-col-min-width, md),
  mdlg: map-get($table-col-min-width, mdlg),
  lg: map-get($table-col-min-width, lg),
);

$tables-container-wrapper: $light-gray;
$table-row-even: $zebra-stripe-even;

$table-col-button-group-min-width: 90px;

$field-min-height: 26px;

// modal's default z-index is 1050 and relative layering of
// elements will be based on this value
$modal-z-index: 1050;

$max-negative-z-index: -2147483647;
$max-z-index: 2147483647;

$z-index-group: "searchMenu";

$z-index: ();

@each $z-index-entry in $z-index-group {
  $i: index($z-index-group, $z-index-entry);
  $z-index-entry-val: $modal-z-index - $i;
  $z-index: map-merge($z-index, (#{$z-index-entry}: $z-index-entry-val));
}

$tooltip-hover-z-index-factor: 2;

$label-width: 33.3333333333%;
$input-static-width: 100% - 33.3333333333%;

$label-description-padding-right: 4px;

$expansible-label-width: 66.6666666666%;

$label-spacing-right: 20px;

$toggle-container-height: 36px;
$toggle-container-icon-height: 22px;
$tab-text-group-height: $toggle-container-height;
$tab-min-height: $tab-text-group-height;
$tabs-container-fixed-nav-height: $tab-min-height + 14px;
$tabs-container-fixed-wrap-nav-height: $tabs-container-fixed-nav-height + 10px;
$inline-icon-height: 28px;

$input-group-addon-min-width: 18px;

// IE Bug: Working around the use of min-height with flex to align-items: center.
// Using line-height value to match the min-height to match the
// behavior of align-items: center.
// https://philipwalton.com/articles/normalizing-cross-browser-flexbox-bugs/
$expansible-header-top-padding: 5px;
$expansible-list-min-height: $toggle-container-height;
$expansible-list-middle-content-min-height: $expansible-list-min-height -
  (2 * $expansible-header-top-padding);
$expansible-list-alert-min-height: $expansible-list-min-height;
$expansible-list-alert-middle-content-min-height: $expansible-list-middle-content-min-height;

$expansible-container-hint-text-line-height: 1.2;

$expansible-container-listing: $white;
$expansible-container-header: $cerulean;
$expansible-container-text: $white;

$expansible-container-medium-header-icon-height: 26px;
$expansible-container-large-header-icon-height: 36px;

$collapsible-container-min-height: $toggle-container-height;

$expansible-container-embed: $alice-blue;
$expansible-container-embed-header-border-underline: $medium-blue;

$expansible-container-field-row-question-side-padding: 10px;

$field-row-expansible-group-label-width: 60%;
$field-row-expansible-group-label-width-small-screen-width: 50%;

// Bootstrap units out of a total of 12 units based on the grid
$button-row-group-margin-push: 4;
