@import "@clearquote/ui/lib/assets/scss/prerequisites";

// Clear fixed font-size from bootstrap scaffolding
html {
  font-size: unset;
}

body,
html {
  background-image: bg-linear-diagonal($gallery, $alto);
  background-size: 5px 5px;
  color: $base-font;
  height: 100%;
  width: 100%;

  /*
	* Correct for rendering of font bolding size in Chrome on Macs
	*/
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  @include font-base();
}

* {
  box-sizing: border-box;
}

:after,
:before {
  box-sizing: border-box;
}

input {
  background-color: $input;
  @include border("all", input);
  @include border-radius("all", 0);
  @include size(100%, $field-min-height);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  &::placeholder {
    color: $input-placeholder-text;
  }
}

// Remove the IE clear button
::-ms-clear {
  display: none;
}

select {
  width: 100%;
  @include border("all", input);
}

select,
option {
  min-height: $field-min-height;

  &:hover {
    cursor: pointer;
  }
}

ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

li {
  display: list-item;
}

p {
  color: $paragraph;
}

img {
  display: block;
}

h2 {
  font-size: font-size("base-h2");
  margin-left: 15px;
}

label {
  @include font-weight-base();
  font-size: $base-font-size;
  position: relative;
  left: 6px;
  margin-bottom: 0;
}

hr {
  border-color: $horizontal-rule;
}

a:not([href]) {
  cursor: pointer;
}

pre {
  margin-right: 10px;
}

code {
  margin-bottom: 10px;
}
