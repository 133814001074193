.page-additional-information {
  .cqui-collapsible-container-group.section
    .section:not(.section-highlighted)
    .field-row {
    width: 100%;
  }

  .cqui-collapsible-container {
    .heading-content {
      outline: none;
    }
  }
  .ae-zipcode .zip-city-state-field {
    .zip-code-field {
      margin-right: 4px;
    }

    .zipcode-verify-message {
      margin-left: 15px;
      width: calc(100% - 30px);
    }
  }

  .field-description-value {
    width: 89px;
    display: inline-block;
  }

  .field-length {
    margin: -0.5px;
    width: 270px;
  }

  .field-length-input {
    margin-left: 10px;
    width: 270px;
  }

  .tile-height {
    height: 115px;
  }

  cq-gl-additional-information {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 36px;
    overflow-y: hidden;
  }

  .field-row .field-input.field-input-multiple .gpa-ctrl-group {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }

    .form-control {
      width: 100%;
    }
  }
}
