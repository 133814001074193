@import "prerequisites";

.cqui-expansible-list {
  @include border("top", "blue-general");
  @include border("bottom", "blue-general");
  margin-bottom: 5px;

  .cqui-expansible-container {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .cqui-notification {
    &[notify-bold] {
      .notification-text {
        font-size: font-size("expansible-container-notification-text");
      }

      .notification-text-content {
        margin-right: 5px;
      }
    }

    .notification-icon {
      margin-right: 10px;

      > .icon {
        max-width: 100%;
      }

      &.icon-hide-optional-fields-black-blue {
        @include icon-ratio("icon-hide-optional-fields", 35);
      }
    }
  }
}

.cqui-expansible-container {
  @mixin expansible-content-grouping-list-entry-icon {
    @include icon();
    @include square(15px);
    min-width: 15px;
    vertical-align: middle;
    float: left;
  }

  $expand-text-margin-left: 5px;

  position: relative;
  min-height: $expansible-list-min-height;

  &.expansible-container-highlight-lightest {
    background-color: $expansible-container-listing;
    @include border("all", $dark-gray);
    @include border-radius("all", expansible-container);

    &.expanded {
      .expansible-container {
        .expansible-header {
          position: relative;
          @include expansible-container-header-divider();
        }

        .expansible-content {
          &.zebra-striping {
            padding: 0;
            margin-top: 0;

            .expansible-container-content-heading {
              display: flex;
              background-color: $expansible-container-header;
              color: $expansible-container-text;
              min-height: 30px;
              align-items: center;
              padding: 5px 15px;
              position: relative;

              &:before {
                content: "";
                position: absolute;
                top: -2px;
                left: 0;
                @include size(100%, 2px);
                background-color: $white;
              }

              // Dummy width element for allowing the alignment of the right button column
              &:after {
                content: "";
                min-width: 107px;
                height: 100%;
              }

              .expansible-container-content-heading-text {
                flex: 1;
                min-width: 40px;

                &:first-child {
                  flex: 0 1 auto;
                  margin-right: 10px;
                }
              }
            }

            ng-transclude {
              .expansible-container-listing-content {
                @include zebra-striping-even();
                padding: 10px 15px;
                display: flex;

                > ng-transclude {
                  display: flex;
                  width: 100%;
                }

                .expansible-container-listing-content-text-group,
                .button-group {
                  display: flex;
                }

                .expansible-container-listing-content-text-group {
                  flex: 1 0 1%;

                  & > * {
                    min-width: 25px;
                    flex: 1;
                    margin-right: 15px;

                    @include icon-group-set();

                    > .text-group-item {
                      display: block;
                    }

                    &:first-child {
                      margin-right: 25px;
                      flex: 0;
                    }
                  }

                  &.expansible-container-listing-header-medium {
                    > * {
                      min-width: 80px;
                      display: flex;
                    }

                    #{$lib-prefix}-icon {
                      &:not(.tooltip-icon) {
                        @include square(20px);
                        @include icon-margin("right");
                      }
                    }
                  }
                }

                .button-group {
                  flex: 0 1 auto;
                }
              }
            }

            .table {
              border: none;
              border-radius: 0;
              @include border-radius("bottom-left-right", 2px);
            }
          }
        }
      }
    }
  }

  &.expansible-container-listing {
    &.expansible-container-listing-spaced-right {
      width: calc(100% - #{$btn-icon-width + 2 * $btn-icon-padding-side});
    }

    &.expansible-container-compact-class-code {
      &.expanded {
        .expansible-container {
          .expansible-content {
            &.zebra-striping {
              .expansible-container-content-heading {
                .expansible-container-content-heading-text {
                  &:nth-child(1) {
                    min-width: 60px;
                  }

                  &:nth-child(2) {
                    margin-right: 25px;
                  }

                  &:nth-child(2),
                  &:nth-child(3) {
                    flex: 0 1 calc((100% - 140px) / 2 - 5px);
                    max-width: calc((100% - 140px) / 2 - 5px);
                    width: calc((100% - 140px) / 2 - 5px);
                  }
                }
              }

              .expansible-container-listing-content {
                .expansible-container-listing-content-text-group {
                  > :first-child {
                    min-width: 60px;
                    margin-right: 10px;
                  }

                  > :second-child {
                    min-width: calc((100% - 140px) / 2);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.persistent {
    .expansible-header {
      cursor: default;
    }
  }

  .expansible-container {
    @include border-radius();
  }

  &.expanded {
    &.overlay {
      .expansible-container {
        position: absolute;
        width: 100%;
        z-index: 100;
        box-shadow: -4px 3px 5px -2px;
      }
    }

    .expansible-header .expansion-indicator {
      margin-left: -5px;
      margin-right: 5px;
      @include border("top", black, 7px);
      @include border("left", clear, 7px);
      @include border("right", clear, 7px);
      @include border("bottom", clear, 0);
    }

    .expansible-content {
      display: block;
    }
  }

  .expansible-content {
    display: none;
    margin-top: 5px;
    padding-left: 15px;
    padding-right: 15px;

    > ng-transclude {
      display: flex;
      flex-direction: column;

      .field-notification {
        @include field-input-notification();
        width: calc(100% - 20px);
        margin: 5px 10px 0;
      }
    }

    .section-header {
      margin-top: 10px;
      margin-bottom: 5px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .expansible-content-groupings {
    @include expansible-content-groupings;
  }

  .expansible-content-grouping {
    @include expansible-content-grouping;

    + .expansible-content-grouping {
      @include expansible-content-grouping-neighbor;
    }
  }

  .expansible-content-grouping-header {
    @include expansible-content-grouping-header;
  }

  .expansible-content-grouping-header-icon {
    @include expansible-content-grouping-header-icon;
  }

  .expansible-content-grouping-list {
    @include expansible-content-grouping-list;
  }

  .expansible-content-grouping-list-entry {
    @include expansible-content-grouping-list-entry;
    @include border("bottom", blue);
    margin-bottom: 5px;
    padding-bottom: 5px;

    + .expansible-content-grouping-list-entry {
      margin-top: 0;
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .expansible-content-grouping-list-entry-icon {
    @include expansible-content-grouping-list-entry-icon;
  }

  .expansible-header {
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: $black;
    font-weight: bold;
    font-size: font-size("expansible-container-header");
    letter-spacing: 0.01em;
    padding: 5px 15px;
    min-height: $expansible-list-min-height;
    flex: 1 0 100%;
    @include size(100%, auto);
    max-width: 100%;

    .expansible-container-middle-content-wrapper {
      flex: 0 1 100%;
      display: flex;
      align-items: center;
      min-height: $expansible-list-middle-content-min-height;
      max-width: 100%;
      width: 100%;
    }

    cqui-expansible-header-middle-right {
      max-width: 100%;
    }

    .expansible-container-middle-content {
      display: flex;
      align-items: center;
      flex: 1 0 100%;
      max-width: 100%;
      margin: 0 auto;
      @include size(100%, auto);
    }

    .expansible-container-header-icon {
      @include square($toggle-container-icon-height);
      flex: 0 0 $toggle-container-icon-height;
      @include icon-margin("right");
      @include icon();
    }

    .expansion-indicator {
      @include square(0);
      margin-right: 10px;
      @include border("bottom", clear, 7px);
      @include border("top", clear, 7px);
      @include border("left", black, 7px);
    }

    &.expansible-header-border {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  .header-text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    line-height: 1.25;

    @include container-label-error();

    &.header-text-center {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .header-title-text {
        display: flex;
        align-items: center;

        .required {
          &.icon {
            @include icon();
            @include square(6pt);
            min-width: 10px;
            position: relative;
            margin-top: 2px;
          }
        }
      }
    }
  }

  .header-title-text {
    color: $black;
    @include font-base();
    font-size: font-size("expansible-header-title-text");
    display: inline-block;
    margin-left: 0;
    margin-right: 5px;
    line-height: 1.2;
    display: flex;

    + .expand-text {
      margin-left: 0;
    }
  }

  .header-subtitle-text {
    font-size: font-size("expansible-container-header-subtitle");
    font-weight: normal;
    display: flex;
  }

  .entry-listing-count {
    font-size: font-size("expansible-container-listing-count");
    font-weight: bold;
  }

  .expand-text {
    font-weight: normal;
    font-size: font-size("expansible-container-expand-text");
    margin-left: $expand-text-margin-left;
  }

  .expansible-container-right {
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-end;
    align-items: center;
  }

  &[header-text-position="center"] {
    .header-text {
      @include make-sm-column(
        percent-to-bootstrap-grid-unit($expansible-label-width)
      );
      min-width: $expansible-label-width;
    }

    $expand-header-content-right-margin: 25px;
    $expand-text-header-width: calc(
      100% - #{$label-width} - #{$expand-header-content-right-margin}
    );

    .expand-text-container {
      max-width: $expand-text-header-width;
      margin-right: $expand-header-content-right-margin -
        $expand-text-margin-left;
      padding-right: 5px;

      @include text-overflow-ellipsis();

      > .expand-header-content {
        display: flex;

        .cqui-notification {
          display: none;
        }

        > * {
          display: flex;
        }
      }
    }

    .expand-text-container,
    .expand-header-content,
    .expand-text {
      line-height: $expansible-container-hint-text-line-height;
    }
  }

  &:not([header-text-position="center"]) {
    .expand-text {
      flex: none;
      display: flex;
      max-width: 100%;
      align-items: center;
    }
  }
}
