// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

// Plus imports for other components in your app.
@use "@nationwide-bolt/adapter-material/dist/bolt-material-theme";

/* Core application styling */
@use "core" as core;

/* Shared material component global styling overrides */
@use "material" as material;

/* Legacy styling */
@use "@clearquote/ui/lib/assets/scss/variables" as variables;
@use "@clearquote/assets/scss/vendor" as vendor;
@use "@clearquote/assets/scss/main" as main;
@use "@clearquote/ui/layout/common/module" as common;
@use "@clearquote/ui/layout/account/module" as account;
@use "@clearquote/ui/layout/bop/module" as bop;
@use "@clearquote/ui/layout/auto/module" as auto;
@use "@clearquote/ui/layout/wc/module" as wc;
@use "@clearquote/ui/layout/gl/module" as gl;
@use "@clearquote/ui/layout/quote/module" as quote;
@use "@clearquote/ui/layout/im/module" as im;

/* Upgrade alignment styling */
@use "upgrade" as upgrade;
