@import "prerequisites";

.cqui-icon-button {
  &:not([button-text]),
  &[button-text=""] {
    padding-left: $btn-icon-padding;
    padding-right: $btn-icon-padding;
  }

  &.btn-xs {
    padding: 2px;
  }

  .btn-content {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-icon {
      @include square($btn-icon-width);
      max-width: $btn-icon-width;
      @include icon();
    }

    .btn-text {
      margin-left: 5px;
    }

    &.btn-content-no-icon {
      .btn-text {
        margin-left: 0;
        white-space: normal;
        text-align: left;
      }
    }
  }

  #{$lib-prefix}-icon {
    pointer-events: none;
  }
}

// Override of ESRI provided button styling
button[disabled] {
  pointer-events: initial;
}

#{$lib-prefix}-radio-buttons-set {
  .radio-buttons-set-description {
    text-align: center;
    font-size: font-size("radio-set-button");
    color: $radio-btn-set-text;
  }

  .#{$lib-prefix}-radio-buttons-set-container {
    &.no-background-outline {
      .radio-buttons-set-buttons {
        padding: 0;
        border: none;

        .btn {
          min-width: #{$btn-default-width}px;
        }
      }
    }

    &.radio-buttons-large {
      .radio-buttons-set-buttons {
        padding: 3px;
        border-width: 2px;
      }

      .btn {
        @include size(70px, 26px);
      }
    }

    &.text-to-left {
      display: flex;
      align-items: center;

      .radio-buttons-set-description {
        margin-right: 10px;
      }
    }

    &.text-to-right {
      display: flex;
      flex-direction: row-reverse;

      .radio-buttons-set-buttons {
        margin-right: 10px;
      }
    }
  }

  .radio-buttons-set-buttons {
    @include border-radius("all", "button-group");
    @include border("all", $radio-btn-set-outline-border);
    background-color: $radio-btn-set-outline;
    padding: 4px;
    display: flex;
    justify-content: space-between;

    button {
      min-width: 0;
      flex: 1 0 auto;

      &.non-gray-disabled {
        pointer-events: none;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
