.cqui-collapsible-container-group {
  .cqui-collapsible-container {
    .container-heading {
      .container-icon {
        &.icon-building {
          height: 24.75px;
        }
      }
    }
  }

  .section {
    @include border("bottom");
    padding-bottom: 15px;
    margin-bottom: 5px;

    &:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &.section {
    padding-bottom: 10px;

    display: flex;
    flex-direction: column;
    height: 100%;

    .section {
      @include section-highlighted;

      .tooltip-container {
        .section:not(.section-highlighted-light) {
          &.bullet-listing-grouping {
            padding: 0;
            background-color: transparent;
          }
        }
      }

      &.section-highlighted,
      &.section-highlighted-light {
        margin-top: 10px;

        &:first-child {
          border-bottom: none;
        }
      }

      &:not(.section-highlighted),
      &:not(.section-highlighted-light) {
        padding: 15px 15px 20px;

        .header-field {
          .section-header {
            margin-top: 5px;
            padding-left: 0;
          }

          .section-header-icon {
            + .section-header {
              margin-top: 0;
            }
          }
        }

        .field-row {
          padding-left: 0px;
          padding-right: 0px;
        }

        &.cqui-tabs-set {
          margin-top: 0;

          cqui-tabs-contents {
            padding: 0;

            .section-header-row {
              margin-bottom: 10px;
            }
          }
        }

        > .cqui-tabs-set {
          margin: 0;

          > .cqui-tabs-content-container {
            > cqui-tabs-contents {
              padding: 0;
            }
          }
        }
      }

      @include section-notification-compact();

      &.section-structural {
        padding: 0px;
        padding-top: 10px;
      }

      &.section-highlighted-light,
      &.section-highlighted-lightest {
        @include search-listings();
      }

      &.section-buttons {
        padding: 7px 15px;
        border-bottom: none;
        margin-bottom: 0;

        button {
          margin-right: 10px;
        }
      }

      &.section-modal {
        padding: 0;
      }

      &:last-child {
        border-bottom: none;
      }

      &.cqui-expansible-list {
        padding: 0;
        margin: 0;
        border-bottom: none;
        @include border("top");
      }

      .coverage-period-field {
        .field-input {
          width: 45%;
          @include field-input-notification();
        }
      }

      .subsection-map {
        margin-top: 0;
        padding-left: 0;
      }

      .field-row {
        + .section {
          &.search-listings {
            padding-top: 0;

            .classification-lookup {
              padding: 0;

              .field-row {
                padding: 10px 0;
              }
            }
          }
        }
      }
    }

    @include section-group;

    .section-subsection {
      .section {
        padding: 10px 0;
      }

      .label-header {
        + .section {
          padding-top: 5px;
        }
      }
    }

    accordion {
      overflow-y: auto;
    }
  }

  @include section-group;
  .section-group {
    .cqui-expansible-list {
      border: none;
    }
  }
}
