@use "sass:math";

@import "@clearquote/ui/lib/assets/scss/prerequisites";

@mixin child-contents-full-width() {
  > * {
    width: 100%;
  }
}

@mixin flex() {
  display: flex;
}

@mixin flex-col() {
  flex-direction: column;
}

@mixin datepicker-calendar {
  .ui-datepicker-trigger {
    cursor: pointer;
    @include icon();
    @include size(18px, 22px);
    @include image-url("icon-calendar.svg");
    min-width: 18px;
    position: relative;
    font-size: 0;
    margin-left: 3px;
  }
}

@mixin expansible-tag-icon {
  @include icon();
  @include square(30px);
}

@mixin inline-description {
  .inline-description {
    @include border-radius();
    @include border("all", blue);
    background-color: $inline-description;
    line-height: 1.4em;
    display: flex;
    padding: 5px;
    line-height: 1;
    align-items: center;
    flex: 1 0 auto;
  }

  .inline-description-icon {
    @include icon();
    @include square(26px);
    min-width: 26px;
    margin-right: 7px;
    align-self: baseline;
  }

  .inline-description-text {
    display: flex;
    flex: 1;
    align-items: center;
    text-align: left;
    width: 100%;
    flex-wrap: wrap;

    &.inline-description-text-vertical {
      flex-direction: column;
      align-items: flex-start;

      .inline-description-text-note {
        margin-top: 5px;
      }
    }
  }

  .inline-description-text-callout {
    font-size: font-size("inline-description-text-callout");
    font-weight: bold;
    width: 100%;
  }

  .inline-description-text-note {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .inline-description-text-note-normal {
    font-weight: normal;
  }

  .inline-field {
    margin-bottom: 5px;
    @include inline-description-medium-icon;
  }
}

@mixin inline-description-medium-icon {
  .inline-description-icon {
    @include square(20px);
    min-width: 20px;
  }
}

@mixin section-header-icon {
  @include icon();
  float: left;
}

@mixin section-notification-compact {
  &.section-notification-compact {
    padding-bottom: 5px;
  }
}

@mixin section-only-notification {
  display: flex;
  align-items: center;
  justify-content: center;

  .notification-icon {
    margin-right: 10px;

    &.icon-hide-optional-fields-black-blue {
      @include icon-ratio("icon-hide-optional-fields", 35);
    }
  }
}

@mixin header-field-icon {
  @include section-header-icon;
  @include icon-ratio("icon-building", 30);
  margin-left: 15px;
  margin-right: 10px;
}

@mixin panel() {
  .panel-info,
  .panel,
  .panel-body {
    border: none;
    margin-bottom: 0;
    box-shadow: none;
  }

  .panel {
    background-color: transparent;
  }

  .panel-heading {
    border-bottom: none;
  }
}

@mixin modal-medium() {
  .modal-dialog {
    width: 650px;
    min-width: 650px;
  }
}

@mixin modal-description() {
  font-size: font-size("modal-description");
  line-height: 1.1;
}

@mixin modal-section-header-icon {
  @include section-header-icon;
  @include square(#{$modal-icon-width}px);
  max-width: #{$modal-icon-width}px;
  cursor: default;
}

@mixin modal-coverage-period-field {
  .coverage-period-field {
    .field-input {
      @include make-sm-column(percent-to-bootstrap-grid-unit(60%));
      padding-left: 0;
    }
  }
}

@mixin modal-subsection-card-container() {
  display: flex;
  height: auto;
  margin-top: 5px;
  margin-bottom: 0;
}

@mixin modal-subsection-card() {
  @include field-miscellaneous(100%);

  .field-row {
    clear: both;
    padding: 0;

    .field-input {
      padding: 0;
    }

    + * {
      .field-input {
        padding: 0;
      }
    }

    .field-input {
      &.field-input-multiple {
        width: 100%;
        padding: 0;

        @include field-input-multiple-zip-city-state(
          map-get($input-width, city-state)
        );
      }
    }
  }

  .subsection {
    border-left: none;

    @include subsection-listing {
      width: 100%;

      .listing-group {
        height: auto;
        @include border("bottom", blue);
        margin-bottom: 10px;
        width: calc(100% - 10px);

        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
        }

        .listing-entry-wrapper {
          display: flex;
          $listing-entry-precursor-to-text-width: 35px;

          .listing-entry-button {
            &:first-child {
              .btn {
                &:not([button-text]) {
                  min-width: $listing-entry-precursor-to-text-width;
                  @include square($listing-entry-precursor-to-text-width);
                  display: flex;
                  align-items: center;
                  margin-right: 0;

                  .button-icon {
                    @include square(15px);
                    background-size: 15px auto;
                  }
                }
              }
            }
          }

          .listing-entry-icon {
            @include square($listing-entry-precursor-to-text-width);
            min-width: $listing-entry-precursor-to-text-width;
          }
        }

        .listing-entry {
          flex-wrap: wrap;
          padding: 0 10px;
          cursor: default;
          margin-bottom: 5px;
        }

        .listing-entry-header {
          width: 100%;
        }

        .listing-entry-text {
          color: $black;
        }
      }
    }
  }

  .subsection-entity-type-icon-group {
    .icon {
      @include size(30px, 45px);
      @include icon-margin("right", 10px);
    }
  }

  .section-header {
    font-size: font-size("field-misc-section-header");
    margin-top: 0;
    margin-bottom: 5px;
    word-break: break-all;
    word-wrap: break-word;

    &.section-header-break-word {
      word-break: break-word;
    }
  }

  .subsection-grouping {
    @include border("bottom", blue);
    padding: 0 0 10px 0;
    margin-bottom: 0;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    + .subsection-grouping {
      .section-header {
        margin-top: 10px;
      }
    }
  }

  &:first-child {
    margin-top: 0;
  }

  .section {
    &.section-fields-underlined {
      .field-row {
        padding: 5px 0;

        @include field-divider-grouping();
      }
    }
  }
}

@mixin section-highlighted {
  &.section-highlighted {
    background-color: $screen-background;
    border-bottom: none;
    border-top: none;
    border-radius: rounding(section);

    .expansible-type-embed {
      .expansible-container {
        background-color: $medium-darker-gray;

        .expansible-header {
          &:after {
            background-color: $dark-gray;
          }
        }
      }
    }
  }
}

@mixin section-highlighted-light {
  background-color: $section-highlighted-light;
  @include border-radius("all", section);
}

@mixin section-highlighted-lightest {
  background-color: $section-highlighted-lightest;
  @include border-radius("all", section);
  border: none;
  border-color: $section-highlighted-lightest-border;
}

@mixin section-highlighted-blue {
  background-color: $section-highlighted-blue;
  @include border-radius("all", section);
}

@mixin section {
  > .section {
    margin: 0 0px 5px;
    padding-bottom: 15px;

    &:last-child {
      border-bottom: 1px solid $border-divider-medium-gray;
      margin-bottom: 7px;
    }

    &:first-child {
      border-top: none;
    }

    @include section-highlighted;
  }
}

@mixin section-group {
  $section-split-subsection-half-map-width: 60%;
  $section-split-subsection-half-map-width-small: 50%;

  $section-split-subsection-tile-icon-width: 30px;

  .section-group {
    margin-bottom: 10px;
    @include border("top");
    @include clearfix;

    &:first-child {
      border-top: none;
    }

    + .section {
      @include border("top");
    }

    .section {
      padding-bottom: 10px;

      &.section-group-parent {
        border-bottom: none;
      }

      + .section {
        padding-top: 0;
      }

      .section-header-group {
        + .button-group {
          align-self: center;
        }
      }

      .section-header-row {
        margin-bottom: 10px;

        &.section-header-row-vcenter {
          margin-top: 5px;
          margin-bottom: 20px;

          .section-header-group {
            flex: 0 1 auto;
            width: 100%;
          }

          .section-header {
            margin: 0;
          }

          .section-description {
            margin-top: 5px;
          }
        }
      }

      &.section-highlighted {
        padding: 10px;
      }

      &.section-split-subsections-half {
        padding: 10px;
        overflow-y: auto;

        .subsection-map {
          width: $section-split-subsection-half-map-width;

          .map-container {
            background-color: transparent;
            @include square(100%);
            overflow: hidden;
            padding: 0;

            .map-background {
              width: 100%;
              min-width: 775px;
            }
          }

          + .subsection {
            margin-top: 0;
            width: calc(100% - #{$section-split-subsection-half-map-width});
            overflow-y: auto;

            &.subsection-group {
              padding-left: 10px;

              .tiles-group {
                .tiles-row {
                  ~ .tiles-row {
                    .section-header-group {
                      margin-top: 7px;
                    }
                  }
                }

                .section-header-row {
                  margin-bottom: 10px;

                  .section-header {
                    margin: 0;
                  }
                }

                .tile {
                  width: 100%;
                }

                .tile-icon {
                  @include icon();
                  @include icon-margin("right", 10px);
                  @include size(
                    $section-split-subsection-tile-icon-width,
                    45px
                  );
                  min-width: $section-split-subsection-tile-icon-width;
                  align-self: flex-start;
                  margin-top: 10px;
                  margin-bottom: 10px;
                }

                .tile-text {
                  max-width: calc(
                    100% - #{$section-split-subsection-tile-icon-width +
                      $table-col-button-group-min-width - 10px}
                  );
                  flex: 1 0 100%;
                }

                .tile-button-group {
                  align-self: flex-start;
                  margin-top: 10px;
                  justify-content: flex-end;
                  flex: 1 0 auto;
                }
              }
            }
          }
        }
      }
    }

    &.section-group-highlighted-light {
      > .section-subgroup {
        @include section-highlighted-light;

        padding: 0 10px;

        &:first-child {
          margin-top: 10px;
        }

        &:last-child {
          padding-bottom: 10px;
        }
      }

      .section {
        margin-bottom: 0;
        border: none;
        @include section-highlighted-light;

        &:first-child {
          margin-top: 10px;
        }

        &:last-child {
          padding-bottom: 10px;
        }

        .section-split-subsections-half {
          .subsection {
            padding-left: 0;

            .section-header {
              margin-top: 0;
            }

            &:first-child {
              padding-right: 15px;
            }

            &:last-child {
              padding-left: 15px;
            }

            .header-field {
              margin: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    .section-group {
      .section {
        &.section-split-subsections-half {
          .subsection-map {
            width: $section-split-subsection-half-map-width-small;

            + .subsection {
              width: calc(
                100% - #{$section-split-subsection-half-map-width-small}
              );
            }
          }
        }
      }
    }
  }
}

@mixin section-header-inline() {
  &.section-header-inline {
    display: flex;
    align-items: center;
    width: 100%;

    .section-header {
      margin-bottom: 0;
      width: auto;
      display: flex;
      flex: 1 0 auto;
    }

    .header-inline-description {
      display: flex;
      flex: 1 1 100%;
    }
  }
}

@mixin section-header() {
  margin-left: 0;
  margin-top: 5px;
}

@mixin section-divider() {
  .section-divider {
    @include border("bottom", gray);
    padding-bottom: 15px;
  }
}

@mixin section-divider-grouping {
  &.section-divider-grouping {
    @include border("bottom", $nationwide-blue);
    padding-bottom: 5px;

    &.subsection-card {
      padding-bottom: 0;
      margin-bottom: 5px;
    }
  }
}

@mixin section-divider-options() {
  .section-divider-bold {
    border-width: 4px;
  }
}

@mixin section-divider-settings() {
  @include section-divider();
  @include section-divider-options();
}

@mixin subsection-listing-entry-header {
  .listing-entry-header {
    > * {
      margin: 10px 0;
    }

    h3 {
      font-size: font-size("subsection-listing-entry-header-h3");
      font-weight: bold;
      color: $listing-header;
    }
  }
}

@mixin subsection-listing {
  &.subsection-listing {
    border: none;
    padding-left: 5px;
    background-color: $subsection-listing;
    display: flex;
    flex-direction: column;

    .list-group-header {
      @include float();
    }

    .section-header {
      margin-left: 10px;
      margin-bottom: 5px;
    }

    .listing-group {
      overflow: auto;
      @include float();
    }

    .listing-entry {
      display: flex;
      padding: 15px 10px;
      margin-bottom: 5px;
      color: $listing-text;
      @include border("top");
      border-color: $listing-border;

      .listing-entry-button-group {
        flex: 1 0 auto;
        flex-direction: column;
        display: flex;
        justify-content: center;
      }

      &:hover {
        cursor: pointer;
      }

      &.listing-selected {
        background-color: $listing-selected;
      }

      &:first-child {
        border-top: none;
        margin-top: 0;
      }

      @include subsection-listing-entry-header();

      .listing-entry-text {
        line-height: 1.3;
        margin: 0 10px;
        font-size: font-size("subsection-listing-entry-text");
      }

      .listing-entry-button {
        align-self: flex-end;
        margin-bottom: 5px;

        button {
          float: right;
        }
      }
    }

    @content;
  }
}

@mixin button-selection-container() {
  @include border-radius();
  @include border("all", clear);
  cursor: pointer;
  position: relative;
  margin-right: 0;
}

@mixin button-option-container() {
  font-size: font-size("button-option-container");
  margin-bottom: 5px;
  margin-right: 0;
  margin-left: 0;
  padding: 3.5px 0 3.5px 10px;
  min-width: 113px;
  text-align: left;
}

@mixin button-selection-btn-default() {
  .checkout-like {
    margin: 0 5px 0 0;
  }

  &[disabled] {
    background-color: $btn-default-disabled;
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

@mixin button-group {
  .button-group {
    display: flex;
    justify-content: flex-end;

    .cqui-icon-button {
      margin-right: 5px;
      margin-bottom: 5px;

      &:last-child {
        margin-right: 0;
      }

      &:not([button-text]) {
        &:first-child {
          margin-left: 0;
        }

        .btn-content {
          height: 100%;
        }
      }
    }
  }
}

@mixin button-row-blue-overline {
  &.button-row-overline {
    @include make-row();
    margin-left: -#{2 * $row-margin-left};
    margin-top: 10px;

    .button-row-group {
      @include border("top", blue);
      padding-top: 10px;
      padding-left: 0;
      padding-right: 0;
      display: flex;
      width: calc(100% - 15px);
    }
  }

  @content;
}

@mixin button-icon-align-top {
  .button-icon {
    flex: 0 1 auto;
  }
}

@mixin field-input-sm($sm: 2, $md: 2, $lg: 2) {
  @include make-sm-column($sm);
  @include make-md-column($md);
  @include make-lg-column($lg);
}

@mixin field-input-text-match-radio {
  $section-underline-input: map-get($section-underline, input);

  .field-input-type-text {
    input,
    select {
      width: #{$section-underline-input}px;
    }

    &.input-group {
      input,
      select {
        width: calc(
          #{$section-underline-input}px - #{$input-group-addon-min-width}
        );
      }
    }
  }
}

@mixin field-input-multiple-zip-city-state(
  $city-state-width: (
    100% - $input-zip,
  )
) {
  $field-city-state: $city-state-width;
  $field-state: 20%;
  $field-city: calc(#{$city-state-width - $field-state});
  $field-zip: calc(100% - #{$field-city-state});

  .field-input-county {
    width: 100%;
    min-width: 100%;
  }

  .field-input-city-state {
    width: $field-city-state;
    min-width: $field-city-state;
  }

  .field-input-zip {
    width: $field-zip;
    min-width: $field-zip;
  }

  .field-input-city {
    width: $field-city;
    min-width: $field-city;
  }

  .field-input-state {
    width: $field-state;
    min-width: $field-state;
  }

  .field-input-zip,
  .field-input-city {
    padding-right: 5px;
  }
}

@mixin field-miscellaneous($base-max: "none") {
  $bootstrap-grid-max: 12;

  $year-field-bootstrap: 4;
  $year-field-percentage-width: percentage-from-decimal(
    math.div($year-field-bootstrap, $bootstrap-grid-max)
  );

  $business-field-bootstrap: 3;
  $business-field-percentage-width: percentage-from-decimal(
    math.div($business-field-bootstrap, $bootstrap-grid-max)
  );

  $phone-field-bootstrap: 4;
  $phone-field-percentage-width: percentage-from-decimal(
    math.div($phone-field-bootstrap, $bootstrap-grid-max)
  );

  $mailing-billing-field-bootstrap: 8;
  $mailing-billing-field-percentage-width: percentage-from-decimal(
    math.div($mailing-billing-field-bootstrap, $bootstrap-grid-max)
  );

  @if $base-max == 100% {
    $year-field-bootstrap: percent-to-bootstrap-grid-unit(
      $year-field-percentage-width
    );
    $business-field-bootstrap: percent-to-bootstrap-grid-unit(
      $business-field-percentage-width
    );
    $phone-field-bootstrap: percent-to-bootstrap-grid-unit(
      $phone-field-percentage-width
    );
    $mailing-billing-field-bootstrap: percent-to-bootstrap-grid-unit(
      $mailing-billing-field-percentage-width
    );
  }

  .year-field {
    .form-control {
      @include make-sm-column($year-field-bootstrap);
      padding: 2px 3px 3px 5px;
    }
  }

  .phone-field,
  .zip-field-row,
  .fein-field,
  .ssn-field {
    .field-label {
      @include make-sm-column(percent-to-bootstrap-grid-unit($label-width));
    }
  }

  .fein-email-group {
    display: flex;
  }

  .city-state-field {
    .field-input {
      justify-content: space-between;
    }
  }

  .email-field,
  .phone-field,
  .fein-field,
  .ssn-field {
    .field-input {
      @include make-sm-column($phone-field-bootstrap);
      margin-right: 5px;
    }
  }

  .mailing-billing-field {
    .field-input {
      @include make-sm-column($mailing-billing-field-bootstrap);
    }

    .btn {
      min-width: 185px;
    }

    .button-icon {
      margin: 0 5px 0 0;
    }
  }

  .employees-number-row,
  .premise-id-row {
    .field-input {
      @include make-sm-column(
        percent-to-bootstrap-grid-unit(map-get($input-width, id))
      );
      padding-left: 0;
    }
  }

  .address-field,
  .mailing-billing-field,
  .copy-address-field {
    // NOTE: Do not add zip-field-row

    padding-right: 15px;

    .field-input {
      @include field-full-width($base-max);
      padding-left: 0;
    }
  }

  .business-field,
  .zip-field-row {
    padding-right: 0;

    .field-input {
      @include make-sm-column($business-field-bootstrap);
      padding-left: 0;
    }
  }
}

@mixin field-question-type {
  .question-type-input {
    &.toggle-radio-yes-no {
      > ng-form {
        display: flex;
      }
    }

    &.input-dollar-sign {
      &,
      > ng-form {
        display: flex;
      }

      input {
        width: calc(#{map-get($section-underline, input)}px - 20px);
      }
    }

    &.button-checkbox {
      .input-template {
        width: 100%;
        .btn {
          width: 100%;
        }
      }
    }
  }
}

@mixin fields-input-name {
  &.field-input-name {
    .form-control-first-name {
      @include make-sm-column(4.2, 0);
      margin-right: 5px;
    }
    .form-control-middle-name {
      @include make-sm-column(1, 0);
      margin-right: 5px;
    }
    .form-control-last-name {
      @include make-sm-column(3.8, 0);
      margin-right: 5px;
    }
    .form-control-suffix-name {
      @include make-sm-column(2.7, 0);
    }

    .form-control {
      padding-left: 5px;
      padding-right: 5px;
    }

    @media (max-width: $screen-sm-max) {
      .form-control-last-name {
        @include make-sm-column(3, 0);
        margin-right: 5px;
      }

      .form-control-suffix-name {
        @include make-sm-column(3.5, 0);
      }

      .form-control {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}

@mixin label-width($width-percentage) {
  min-width: $width-percentage;
  @include make-sm-column(percent-to-bootstrap-grid-unit($width-percentage));
}

@mixin field-row-right-large {
  &.field-row-right-large {
    .field-row-level {
      > .field-input {
        width: auto;
        margin-right: 20px;
        padding-right: 5px;

        > .question-type-input {
          width: 100%;
        }

        .input-group {
          .form-control {
            min-width: 0;
          }
        }

        .field-static {
          width: auto;
          padding-right: 5px;
          padding-left: 5px;
          min-width: 80px;

          + .field-input {
            padding-right: 5px;

            + .button-group {
              display: flex;
            }
          }
        }
      }
    }

    .field-label {
      @include label-width(50%);
    }

    @media (min-width: $screen-md-max) {
      .field-label {
        @include label-width(60%);
      }
    }
  }
}

@mixin field-full-input {
  &.field-full-input {
    width: 100%;
    padding: 0 15px;

    .required {
      position: relative;
      right: -3px;
    }

    .field-label,
    .field-description,
    .field-input {
      @include make-sm-column(12);
      padding: 0;
    }

    .field-label {
      display: flex;
      justify-content: flex-start;
    }

    .field-description {
      width: auto;
      align-self: flex-start;
    }

    .field-input {
      textarea {
        min-height: 100px;
      }
    }
  }

  + .field-full-input {
    .field-label {
      @include border("top", gray);
      padding: 5px 0;
      margin-top: 5px;
    }

    .field-input {
      textarea {
        min-height: 100px;
      }
    }
  }
}

@mixin field-text-area {
  &.text-area-field {
    &[#{$lib-prefix}-component] {
      > ng-transclude {
        display: flex;
        flex-direction: column;

        #{$lib-prefix}-field-label {
          flex: 1 0 auto;
        }

        #{$lib-prefix}-field-input {
          flex: 0 1 auto;
        }
      }
    }
  }
}

@mixin field-divider-grouping {
  &.field-divider-grouping {
    @include border("top", $nationwide-blue);
    padding-top: 10px;
    margin-top: 5px;
  }
}

@mixin input-border($side: "bottom") {
  padding-bottom: 10px;
  margin-bottom: 5px;
  @include border($side, gray);
}

@mixin building-coverage-grid {
  .building-address {
    @include make-sm-column(5);
    display: flex;
  }

  .coverage-classification {
    text-align: center;
    @include make-sm-column(7);
  }
}

@mixin button-icon-sm {
  @include icon();
  @include square(10px);
  display: inline-block;
  margin: auto;
  position: relative;
  background-size: 10px auto;
}

@mixin button-icon-med {
  @include button-icon-sm;
  @include square(19px);
  @include icon();
}

@mixin tables-container-wrapper {
  .tables-container-wrapper {
    $header-row-location: $cerulean;
    $header-row-location-text: $white;

    $content-row-location: $white;
    $content-row-location-text: $black;

    @include make-row();
    background-color: $tables-container-wrapper;
    padding-bottom: 20px;
    @include border-radius("all", section);

    .label-header {
      margin-bottom: 10px;

      .section-header {
        margin-top: 15px;
        margin-left: 15px;
        margin-bottom: 0;
        margin-right: 10px;
        width: auto;
      }

      .section-header-row {
        margin-top: 15px;
        align-items: center;

        .section-header {
          margin-top: 0;
          margin-left: 15px;
        }

        .form-control {
          margin-bottom: 0;
        }
      }
    }

    .header-row-location {
      @include make-row();
      margin-left: 15px;
      margin-right: 15px;
      padding-top: 5px;
      padding-bottom: 5px;
      @include border-radius("top-left-right");
      background-color: $header-row-location;
      color: $header-row-location-text;
      @include building-coverage-grid;
    }

    .table-container {
      > .content-row-location {
        padding-left: 0;
        padding-right: 0;

        .content-row-location-wrapper {
          margin: 0 auto;
          display: flex;
          max-width: 100%;
        }

        &.no-row-entries {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
          overflow-y: auto;
          @include border();
          @include border-radius("top-left-right");

          .row-message {
            width: 100%;
            padding: 0 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 90px;
            padding: 15px;

            .alert-icon {
              @include icon();
              @include square(15px);
              min-width: 15px;
              @include icon-margin("right");
              @include image-url("icon-alert-information.svg");
            }

            .row-message-text {
              height: 100%;
              display: flex;
              align-items: center;
            }
          }
        }

        .row-content {
          display: flex;
        }

        &.notification-row {
          &.alert {
            background-color: map-get($alert-colors, alert);
            margin-bottom: 0;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            @include border-radius("bottom-left-right");
          }

          &.info {
            background-color: map-get($alert-colors, info);
          }

          .notification-row-message {
            display: flex;
            justify-content: center;
            align-self: flex-end;
            padding: 0 15px;

            .notification-type {
              align-self: center;
              padding: 0;
              @include icon-margin("right");
              margin-bottom: 0;
              border: none;
              @include icon();
              @include square(15px);
              min-width: 15px;

              &.alert {
                @include image-url("icon-alert-information.svg");
              }

              &.info {
                @include image-url("icon-information-circle.svg");
              }
            }
          }
        }
      }
    }

    .content-row-location {
      @include make-row();
      margin-left: 15px;
      margin-right: 15px;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: $content-row-location;
      color: $content-row-location-text;
      @include border("left");
      @include border("right");
      @include building-coverage-grid;
      display: flex;
      flex-direction: column;

      .coverage-classification {
        flex: 1;
        padding-left: 10px;
        padding-right: 5px;

        .table {
          margin-bottom: 0;
        }

        .table-col {
          padding-top: 0;
          padding-bottom: 0;
        }

        .table-col-icon {
          min-width: auto;
          @include square(40px);
        }

        .table-col-entry-details-row {
          padding: 5px;
        }

        .classification {
          @include border("top", blue);
          margin: auto;
          padding-top: 3px;
          width: calc(100% - 20px);
          margin-top: 3px;
        }
      }

      .building-limits,
      .coverage-classification {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .address-details {
      display: inline-block;
      line-height: normal;

      .button-row {
        margin-top: 5px;
        display: block;
      }
    }

    .icon-building {
      @include icon();
      @include square(25px);
      @include image-url("icon-building.svg");
      margin-right: 5px;
      vertical-align: top;
      margin-top: 5px;
    }

    .table-container {
      margin-bottom: 0px;

      .table-wrapper {
        &:last-child {
          &.table-wrapper-background {
            padding-bottom: 0;
          }
        }
      }

      > .content-row-location {
        @include zebra-striping-even();

        &:nth-child(even) {
          .table {
            .table-body {
              background-color: $table-row-even;
            }
          }
        }
      }

      > .content-row-location:last-child {
        @include border("bottom");
        @include border-radius("bottom-left-right");
      }

      .text-group {
        max-width: 40%;
      }
    }

    @include tables-container-master-col {
      &.table-col-master {
        width: #{math.div(5, 12) * 100%};

        @include table-container-master-col();
      }
    }
  }

  .tables-container {
    &.tables-container-add-delete-building {
      .header-row-location {
        > * {
          float: left;
        }
      }

      .building-address {
        width: 35%;

        &.exposure-details {
          width: 45%;
        }
      }

      .building-limits {
        width: 15%;
      }

      .coverage-classification {
        width: 35%;
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    .tables-container-wrapper {
      @include tables-container-master-col {
        &.table-col-master {
          width: #{math.div(3, 12) * 100%};
        }
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    .tables-container-wrapper {
      .table-container {
        .building-address {
          @include make-sm-column(3);
          flex-direction: column;
        }

        .coverage-classification {
          @include make-sm-column(5.4);
        }
      }

      @include tables-container-master-col {
        &.table-col-master {
          width: #{math.div(2.4, 12) * 100%};

          .table-col-cell {
            flex-direction: column;
          }
        }
      }
    }
  }
}

@mixin table-row-icon {
  margin-top: 0px;
  margin-bottom: 0px;
  @include icon();
}

@mixin table-container-master-col {
  .table-col-cell {
    display: flex;
    flex-wrap: wrap;
  }

  .table-col-icon {
    @include square(25px);
    min-width: 25px;
    margin-top: 5px;
  }

  .text-group {
    flex: 1 0 auto;
    width: auto;
  }

  .button-row {
    margin-top: 5px;

    .btn {
      margin-bottom: 5px;
    }
  }
}

@mixin tables-container-master-col {
  .tables-container {
    &.table-master-details-type {
      .table {
        .table-col {
          @content;
        }
      }
    }
  }
}

@mixin table-col-to-left {
  .table-col {
    &.table-col-to-left {
      justify-content: flex-start;

      .table-col-cell {
        .text-group {
          max-width: 100%;
          width: 100%;
        }

        .button-row {
          display: flex;
          flex-wrap: wrap;
          margin-top: 5px;

          .btn {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

@mixin table-auto-add-driver {
  &.auto-add-driver {
    thead {
      tr {
        th {
          line-height: 1.2;
        }
      }
    }

    .first-name {
      width: 120px;
    }

    .middle-name {
      width: 24px;
    }

    .last-name {
      width: 120px;
    }

    .suffix-name {
      width: 70px;
    }

    .field-label {
      width: 26%;
    }

    .field-input {
      width: 74%;
    }

    .driver-icon-column {
      width: 26px;
      text-align: left;
    }

    .driver-name-column {
      width: 25%;
      text-align: left;
      vertical-align: middle;

      .icon-driver-pip {
        @include size(22px, 10px);
        @include table-row-icon;
        min-width: 22px;
      }

      .icon-driver-doc {
        @include size(29px, 10px);
        @include table-row-icon;
        min-width: 29px;
      }

      .icon-driver-exclude {
        @include square(15px);
        @include table-row-icon;
        min-width: 15px;
        margin-right: 5px;
      }
    }

    .driver-name-column-text {
      display: flex;
      align-items: center;

      cqui-info-indicator {
        margin: 0 5px;
      }

      .driver-name-separator {
        margin: 0 5px;
      }
    }

    .driver-select,
    .driver-edit-delete {
      width: 8%;
    }

    &.auto-account-add-driver {
      .driver-name-column {
        width: 45%;
      }

      .driver-license-status-column {
        width: 15%;
      }

      .driver-license-number-column {
        width: 20%;
      }

      .driver-select-column {
        width: 15%;
      }
    }

    tbody {
      .driver-name-column {
        vertical-align: middle;

        ~ td {
          text-align: center;
        }
      }
    }
  }
}

// tables-container-dual-columns pertains to the adjustments made
// to the table which of the 'master details type'
@mixin table-container-dual-columns {
  + .section:last-child {
    padding: 15px 0;
  }

  .table {
    border: 0;

    .table-col {
      .table-col-icon {
        @include icon();
        min-width: 40px;
        @include size(40px, 38px);
      }

      .text-group {
        flex-wrap: wrap;
      }
    }
  }

  .table-wrapper {
    .table-container {
      &:first-child {
        padding-bottom: 15px;
      }

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    > .table-container {
      .table-head {
        .table-col-entry-details-row {
          padding-top: 0;
          align-items: center;
        }
      }

      .table-body {
        tr {
          &:last-child {
            .table-col {
              &:last-child {
                > .table-col-cell {
                  .table-col-entry-details {
                    &:first-child {
                      .table-col-entry-details-row {
                        padding-top: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .label-header {
      .section-header-row {
        .section-header {
          margin-left: 0;
          margin-top: 0;
        }
      }
    }
  }

  .table-header-row {
    .table-col {
      &:last-child {
        padding: 3px 15px;
      }
    }
  }

  &.tables-container-dual-columns-40-60-split {
    .table-col {
      &:first-child {
        width: 40%;
      }

      &:last-child {
        width: 60%;
      }

      &.table-col-master {
        .text-group {
          max-width: 100%;
        }
      }
    }

    .table-header-row {
      &:last-child {
        text-align: center;

        .table-col-entry-details {
          padding: 0;
          line-height: 1.2;
        }

        .button-row {
          min-width: $table-col-button-group-min-width;
        }
      }
    }

    .text-group {
      + .button-row {
        min-width: $table-col-button-group-min-width;
        display: flex;
        justify-content: flex-end;

        .btn {
          margin-right: 5px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  @content;
}

@mixin expansible-container-embed-divider($position: before, $settings: "") {
  &:#{$position} {
    content: "";
    top: -5px;
    @include border("bottom", gray);
    width: 100%;
    position: absolute;
    background-color: $expansible-container-border-underline;
  }

  @if $settings != "" {
    @each $key, $val in $settings {
      &:#{$position} {
        #{$key}: $val;
      }
    }
  }
}

@mixin expansible-container-embed-fields() {
  > .field-row-level-top {
    &:first-child {
      &.hide,
      &.ng-hide {
        + .field-expansible-type-embed {
          .field-row-level {
            .expansible-type-embed {
              &:before {
                @include square(0);
              }
            }
          }
        }
      }
    }

    &:not(:first-child) {
      + .field-expansible-type-embed {
        .field-row-level {
          .expansible-type-embed {
            @include expansible-container-embed-divider();
          }
        }
      }
    }
  }
}

@mixin add-edit-addresses {
  .subsection-addresses {
    .section-header-note {
      font-weight: bold;
    }

    .subsection-listing {
      display: flex;
      flex-direction: column;

      .listing-entry {
        color: $black;
      }

      .listing-group {
        height: auto;

        + {
          @include button-group();
        }

        + .button-group {
          padding: 0 10px;
        }
      }
    }
  }
}

@mixin premium-content {
  .premium-main-empty {
    display: flex;
    @include size(12px, 100%);
    align-items: center;
    justify-content: center;
  }

  .premium-main-empty-dash {
    display: flex;
    flex-direction: column;
    line-height: 1;
    @include size(100%, 2px);
    background-color: $black;
  }
}

@mixin contacts-modal-col {
  .select-contact-name,
  .select-contact-address,
  .select-contact-search,
  .select-contact-type-loan,
  .select-contact-add-additional,
  .select-contact-button-group {
    @content;
  }

  .select-contact-add-additional {
    @include text-center-selector(current);
  }
}

@mixin class-is-required($margin-left: 0) {
  .class-is-required {
    display: flex;
    align-items: center;
    line-height: 1.15;

    .icon,
    [cqui-info-tool-tip] {
      @include icon();
      @include square(15px);
      min-width: 15px;
      margin-right: 5px;
      margin-left: $margin-left;
    }
  }
}

@mixin main-form-buttons(
  $second-button-of-second-button-group-min-width: 215px
) {
  .main-form-buttons {
    .main-form-buttons-group {
      &:nth-child(2):nth-last-child(1) {
        .btn {
          &:nth-child(2):nth-last-child(1),
          &:only-child {
            min-width: $second-button-of-second-button-group-min-width;
            @content;

            &[button-text="Next"] {
              min-width: $second-button-of-second-button-group-min-width;
              @content;
            }
          }
        }
      }
    }
  }
}
