.modal-open {
  .modal {
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;

    .modal-dialog {
      // used to be margin: 0;
      margin: auto;
    }
  }
}

.modal {
  display: block;
  min-width: 750px;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black with opacity */

  $modal-static-text: $blue-lagoon;
  $modal-section-highlight-static-text: $black;

  $modal-icon-animated-loader-width: 80px;

  $modal-section-header-group-margin-right: 10px;

  $field-align-to-header-content-margin-left: $modal-icon-width +
    $modal-icon-margin-right - $row-margin-left;

  .field-static {
    @include make-sm-column(7);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;
    color: $modal-static-text;

    .btn {
      margin-bottom: 0;
    }
  }

  .field-input {
    padding-left: 0;

    &.field-input-sm {
      padding-left: 0;
    }

    &.field-input-multiple {
      display: flex;

      @include make-sm-column(8);

      margin-left: 0;
      padding-left: 0;
    }

    @include fields-input-name();
  }

  .field-text-status {
    @include icon();
    @include icon-margin("right", 10px);
    @include square(20px);
    min-width: 20px;
  }

  .field-text {
    font-size: font-size("field-text");
  }

  @include field-labels-medium();

  @include field-labels-wide() {
    .field-label + .field-row-level {
      @include section-fields-right-input-column;

      .field-input {
        width: 100%;
      }
    }

    .field-row-level-top {
      .field-description {
        font-weight: bold;
      }
    }

    .field-row {
      .field-container {
        .expansible-common {
          .expansible-container {
            &:after {
              display: none;
            }

            .expansible-header {
              .header-text {
                .header-title-text {
                  font-weight: bold;
                }
              }
            }

            .section-fields-underlined {
              .field-row {
                &.field-row-first-list-child {
                  .field-description {
                    font-weight: 500;
                  }
                }

                &:first-child {
                  padding-top: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  @include panel();

  .modal-body {
    > .panel {
      border: none;
      background-color: transparent;

      .panel-body {
        border: none;
        padding: 0;

        #{$lib-prefix}-field-row {
          &:before {
            border-top: none;
          }
        }

        .field-row:not([#{$lib-prefix}-component]) {
          + #{$lib-prefix}-field-row {
            #{$lib-prefix}-field-input {
              padding-top: 0;
            }
          }
        }

        .associated-grouping {
          position: relative;
          padding: 10px 0 0;
          width: calc(100% - 20px);
          margin: auto;

          .field-input-group {
            .btn {
              @include button-icon-align-top();
            }
          }

          > .field-input-group {
            .field-row {
              &:last-child {
                margin-bottom: 0;

                .field-input {
                  margin-bottom: 0;
                }
              }
            }
          }
        }

        .section {
          &.bullet-listing-grouping {
            padding: 15px 10px 0;

            .section-header {
              font-weight: bold;
            }
          }

          &.section-fields-split-even {
            .field-row {
              .field-input {
                flex: 1 0 auto;
                margin-right: 0;
              }

              @include field-text-area();
            }
          }

          &.cqui-collapsible-container-group {
            padding: 5px;
          }
        }
      }

      @media (max-height: 940px) {
        .panel-body {
          max-height: 700px;
        }
      }

      .field-row-group {
        padding: 0;
        margin-left: $field-align-to-header-content-margin-left;
        .field-row {
          margin-bottom: 0;
          display: flex;
          align-items: center;

          &:first-child {
            padding: 0;
            margin: 0;
          }

          .field-label {
            width: auto;
          }
        }

        .field-row-center {
          display: flex;
          // justify-content: center;
          align-items: center;
          .field-label {
            flex: 2;
            width: auto;
            text-align: end;
          }

          .field-text {
            flex: 3;
            font-weight: bold;
            padding-left: 10px;
          }
        }

        &.field-row-process {
          padding: 0;
          margin-left: 0;

          .field-row {
            background-color: $white;
            padding: 5px;
            @include border-radius("all", section);
            margin-bottom: 5px;

            &:first-child {
              margin-bottom: 5px;
              padding: 5px;
            }

            .field-text-status {
              margin-left: 35px;
              margin-right: 30px;
            }

            .field-text {
              font-weight: bold;
              display: flex;
              align-items: center;

              .icon {
                @include square(20px);
                @include icon-margin("right", 10px);
              }
            }

            .field-text-content {
              margin-right: 4px;
            }

            .field-text-status-hint {
              color: $astronaut-blue;
              font-size: font-size("modal-field-text-status-hint");
              font-weight: normal;
            }
          }
        }
      }

      @include modal-coverage-period-field();

      .cqui-collapsible-container-group {
        @include modal-coverage-period-field();
      }
    }
  }

  &.in {
    .modal-dialog {
      transform: none;
    }
  }

  .subsection-labelless-form {
    border: none;
  }

  .modal-section-padded {
    padding: 10px 10px 0;
    background-color: $modal-section;
    @include border-radius("all", section);
  }

  &.modal-scrollable {
    .modal-body > .panel .panel-body {
      overflow-y: auto;
    }
  }

  &.modal-common {
    display: flex !important; // Angular UI Bootstrap puts a block style on the modal element directly
    height: auto;
    overflow: auto;
    justify-content: center;
    align-items: center;
    align-content: center;

    .panel-body {
      .section {
        padding-top: 15px;
        padding-bottom: 15px;

        .section-header {
          font-weight: bold;
        }

        &.section-highlighted {
          .header-field {
            .section-header {
              margin-top: 5px;
            }
          }

          .field-static {
            color: $modal-section-highlight-static-text;
          }
        }

        &.section-highlighted-lightest {
          @include section-highlighted-lightest;

          .map-pins-row {
            padding-top: 0;
          }
        }

        .field-row {
          + .button-row {
            display: flex;
            justify-content: flex-end;
            @include make-sm-column-offset($button-row-group-margin-push);

            .button-row-group {
              position: relative;
              left: -15px;
            }
          }
        }

        .field-row:only-child {
          &.single-date-field {
            .field-label {
              @include make-sm-column(6);
              min-height: $field-min-height;
            }
          }
        }

        .notification-field {
          margin-bottom: 0;
          margin-left: 10px;
          margin-right: 10px;
        }

        .button-row {
          &.button-row-overline {
            margin-left: -#{2 * $row-margin-left};

            .button-row-group {
              left: 0;
              width: calc(
                100% -
                  #{bootstrap-grid-unit-to-percent(
                    $button-row-group-margin-push
                  )} -
                  #{$row-margin-left}
              );
            }
          }
        }
      }

      .section-wrapper {
        .section {
          &:last-child {
            margin-bottom: 10px;
          }
        }
      }
    }

    .modal-dialog {
      align-self: center;
      min-width: 600px;
      max-width: calc(100% - 40px);
    }

    .modal-content {
      background-color: $modal;
      display: flex;
      flex-direction: column;
      align-items: center;

      /*
					Set a min-height through flex-box to keep small modal size consistent
					when there is only one modal section present which is usually
					the header text section
				*/
      .modal-content-row:nth-child(1):nth-last-child(1) {
        flex: 1 0 100px;
        margin-bottom: 0;
        padding-top: 0;
      }

      border-radius: rounding(modal);
    }

    .modal-content-columns {
      display: flex;
      flex-direction: row;
      width: 100%;

      .modal-content-column {
        flex: 1;
        height: 100%;

        .feedback {
          padding-top: 15px;
          padding-bottom: 15px;
          padding-right: 15px;

          .feedback-content {
            display: flex;
            flex-direction: column;
            padding: 20px;
            height: 100%;

            .feedback-heading {
              display: flex;
              flex-direction: row;
              .feedback-heading-icon {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .cqui-chat-bubble {
                  height: 80%;
                }
              }
              .feedback-heading-text {
                flex: 4;
                font-weight: 900;
                font-size: 20px;
              }
            }
            .feedback-body {
              padding-top: 10px;
              padding-bottom: 10px;

              .feedback-question {
                .feedback-question-label {
                  font-weight: bolder;
                  font-size: 16px;
                }

                .feedback-question-input {
                  padding-top: 5px;

                  &.rating {
                    display: flex;
                    justify-content: center;
                  }
                }

                textarea {
                  resize: none;
                  height: 80px;
                }
              }
            }
            .feedback-button-right {
              align-self: flex-end;
            }
          }
        }
      }
    }

    .modal-body {
      padding: 0;
      margin-bottom: 15px;
      width: 100%;

      + .modal-footer {
        margin-top: -15px;
      }

      @include add-edit-addresses();

      &.modal-input-form {
        .panel-body {
          .section {
            padding-top: 0;

            .field-row {
              display: block;
              margin: 0;
              padding: 0;

              &.field-full-input {
                .required {
                  position: relative;
                  right: 0;
                }

                .field-label,
                .field-description,
                .field-input {
                  @include make-sm-column(12);
                  padding: 0;
                }

                .field-label {
                  margin-bottom: 5px;
                }

                .field-description {
                  font-size: font-size("field-description");
                  width: 100%;
                  float: left;
                  text-align: left;
                  justify-content: flex-start;
                }

                .form-control {
                  height: 100px;
                  min-height: 100px;
                  resize: vertical;
                }
              }
            }

            &:last-child {
              padding-bottom: 0;
            }
          }
        }

        &.modal-input-form-medium-height {
          .form-control {
            min-height: 180px;
          }
        }

        .field-label,
        .field-input {
          @include make-sm-column(12);
          padding: 0;
        }

        .field-label {
          margin-bottom: 10px;
          min-height: $field-min-height;
        }

        .field-description {
          font-size: font-size("field-description");
          width: 100%;
          float: left;
          text-align: left;
          justify-content: flex-start;
        }

        .form-control {
          min-height: 80px;
          resize: vertical;
        }
      }

      &.modal-search-form {
        .search-field {
          .field-input {
            width: 100%;
            padding-right: 0;
          }
        }
      }

      &.modal-text {
        .text-row {
          font-size: font-size("modal-text-row");
          padding-left: 50px;

          p {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .cqui-table {
        margin-bottom: 0;

        // Tables cell text tends to be black and overrides
        // the rule where the static text in modal tends to
        // be blue when accompanying a text label
        tbody {
          tr {
            td {
              .field-static {
                color: $black;
              }
            }
          }
        }

        .button-group {
          &.button-group-vertical {
            display: flex;
            flex-direction: column;

            .btn {
              width: 100%;
              margin-left: 0;

              .btn-icon {
                min-width: 20px;
              }
            }

            .btn-content {
              justify-content: flex-start;
              padding-left: 5px;
            }
          }
        }

        .table-header-row {
          .table-col {
            padding: 5px 10px;
            vertical-align: middle;
          }
        }

        .table-tbody {
          .table-col {
            text-align: center;

            &:first-child {
              text-align: left;
            }
          }
        }
      }
    }

    .modal-header {
      border-bottom: none;
      margin-bottom: 15px;
      padding-bottom: 0;
      width: 100%;
      flex: 1 0 auto;
      display: flex;
      align-items: center;

      + .modal-body {
        margin-top: -15px;
        height: 100%;
        padding: 15px;

        > .panel-info {
          .panel-body {
            border-bottom: none;
          }
        }
      }
    }

    .modal-header-row {
      display: flex;
      align-items: flex-start;
      width: 100%;

      .section {
        width: 100%;
      }

      > .section-header-group {
        margin-right: $modal-section-header-group-margin-right;
        width: auto;

        &:last-child {
          flex: 1 0 auto;
          margin-right: 0;
        }

        > .section-header {
          margin-top: 10px;
        }
      }

      .section-header-group {
        .section-header-description {
          * {
            &:first-child {
              margin-top: 5px;
            }
          }
        }

        .section-header-description,
        .section-description {
          @include modal-description();
        }
      }

      .section-header {
        @include modal-header();
        margin-top: 0;
        cursor: default;
      }
    }

    .section-split-subsections-half {
      padding: 0 5px;

      .subsection,
      .subsection-group {
        padding: 0 5px;
        margin-top: 0;

        @include section-divider-grouping();
      }

      .subsection-group {
        .subsection-labelless-form {
          .field-row {
            .field-input {
              &.field-input-multiple {
                [cqui-cover],
                .cqui-cover {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .subsection-map {
        display: flex;
        flex-direction: column;

        .map-container {
          overflow: hidden;
          @include border-radius("all", section);

          .map-background {
            min-width: 615px;
          }
        }
      }

      @include add-edit-addresses();

      .subsection-addresses {
        padding: 0;
        margin: 10px;
        background-color: $white;
        @include border-radius("all", "section");

        &.subsection-group {
          .subsection-group {
            width: 100%;
          }
        }

        .subsection-card {
          flex-direction: column;
        }

        &.subsection-addresses-fields {
          .subsection-listing {
            padding-right: 0;

            .listing-entry {
              padding-right: 5px;
            }
          }

          .subsection {
            &.subsection-form-with-label {
              .field-input {
                width: 50%;
              }
            }
          }
        }
      }
    }

    .section-subgroup {
      width: 100%;
      margin-top: 0;

      .subsection {
        &.subsection-card {
          @include modal-subsection-card-container();
          @include modal-subsection-card();
        }
      }
    }

    .section-header-group {
      & + .button-group {
        align-self: center;
      }
    }

    .section-header-icon {
      @include modal-section-header-icon;

      &.icon-building {
        width: 50px;
        height: 40px;
        margin-right: 5px;
      }

      &.animated-loader {
        @include size($modal-icon-animated-loader-width, 40px);
        max-width: $modal-icon-animated-loader-width;
      }
    }

    .section-header-row {
      align-items: flex-start;

      .section-header-group:first-child {
        margin-right: $modal-icon-margin-right;
        width: auto;
        flex: 0 0 auto;
      }

      .section-header-group:last-child {
        flex: 1 1 auto;
        align-self: center;

        &.section-header-inline {
          flex: 1 1 100%;
        }
      }
    }

    .modal-body {
      padding: 0;
    }

    .panel-body {
      .section {
        padding-top: 15px;
        padding-bottom: 15px;

        &.section-highlighted-lightest {
          @include section-highlighted-lightest;

          .map-pins-row {
            padding-top: 0;
          }
        }

        &.section-compact {
          padding: 0;
        }

        .cqui-tabs-set {
          margin: 0;
        }

        cqui-tabs-contents {
          background-color: $white;
          height: 500px;
          overflow-y: auto;
          overflow-x: hidden;

          cqui-tabs-contents {
            height: auto;
          }
        }

        > .cqui-tabs-set {
          &:not(.cqui-tabs-fixed-header) {
            cqui-tabs-contents {
              > .tab-contents-wrapper {
                display: block;
                height: 100%;

                > .section-subgroup {
                  height: 100%;
                }
              }
            }
          }

          &.cqui-tabs-inline-type {
            > .cqui-tabs-content-container {
              &.contents-present {
                cqui-tabs-contents {
                  padding: 15px;

                  .cqui-tabs-set {
                    padding: 0;
                    margin-top: 10px;
                  }
                }
              }
            }
          }
        }

        .field-row:nth-child(1):nth-last-child(1) {
          &.single-date-field {
            .field-label {
              @include make-sm-column(6);
              min-height: $field-min-height;
            }
          }
        }

        .cqui-tabs-content-container {
          cqui-tabs-contents {
            .cqui-tabs-set {
              &.cqui-tabs-fixed-header {
                > .cqui-tabs-content-container {
                  cqui-tabs-contents {
                    padding: 0;
                    padding-top: $tab-min-height + 10px;
                  }
                }
              }
            }
          }
        }
      }

      .btn-icon {
        &.icon-checkbox-checked {
          width: 10px;
          height: 10px;
        }
      }
    }

    .modal-footer {
      border-top: none;
      padding-top: 0;
      width: 100%;
      border-radius: rounding(modal);

      .button-group {
        .button-group-wrapper {
          display: flex;

          .button-group-wrapper-container {
            display: flex;

            + .button-group-wrapper-container {
              margin-left: 5px;
            }
          }
        }

        &:first-child {
          float: left;
        }

        &:last-child {
          float: right;

          .cqui-icon-button {
            margin-bottom: 0;
          }
        }

        .cqui-icon-button {
          margin-bottom: 0;
        }
      }

      &.modal-footer-split {
        display: flex;
        justify-content: space-between;

        &.button-group {
          flex: 1;
        }
      }

      .left-footer {
        float: left;
      }
    }

    @include subsection-group-form();
  }

  &.modal-responsive {
    @media (min-width: $screen-sm-min) {
      .modal-dialog {
        width: 765px;
        max-width: 1000px;
      }
    }

    @media (min-width: $screen-md-min) {
      .modal-dialog {
        width: 870px;
      }
    }

    @media (min-width: $screen-lg-min) {
      .modal-dialog {
        width: 1000px;
      }
    }
  }

  &.modal-carousel {
    .modal-dialog {
      width: 900px;
    }
    .modal-header-row {
      .section-header {
        margin-bottom: 0;
      }
    }

    .panel-body {
      .section {
        margin-bottom: 10px;
      }
    }
  }

  &.modal-narrow {
    .modal-dialog {
      width: 450px;
      min-width: 450px;
    }
  }

  &.modal-extra-small {
    .modal-dialog {
      width: 350px;
      min-width: 350px;
    }
  }

  &.modal-medium {
    @include modal-medium();
  }

  &.modal-list {
    .panel-body {
      .section {
        padding: 0;
      }
    }

    .modal-dialog {
      min-width: 750px;
    }
  }

  &.modal-vbr {
    .cqui-expansible-list {
      max-height: 550px;
      overflow-y: auto;
      margin-bottom: 0;
    }
  }

  &.modal-table .modal-dialog {
    min-width: 690px;

    .table-header-row {
      // Last child of 5
      .table-col:nth-child(5):nth-last-child(1) {
        &.table-col-buttons {
          width: 25%;
        }
      }

      // Last child of 4
      .table-col:nth-child(4):nth-last-child(1) {
        &.table-col-buttons {
          width: 20%;
        }
      }
    }
  }

  &.modal-type-state-selection {
    .panel {
      .section {
        padding: 10px 15px 5px;
      }
    }

    .modal-dialog {
      width: 650px;
    }

    .button-selection-container {
      padding: 0;
      margin-left: -5px;
      margin-bottom: 0;

      strong {
        font-size: font-size("modal-button-selection-container");
      }

      .button-container {
        flex-wrap: wrap;
      }

      &:hover {
        cursor: default;
      }

      .button-icon {
        margin: 0;
        margin-right: 3px;
        margin-bottom: -1.5px;
      }
    }

    .state-selection-container {
      .state-container {
        columns: 5;
        orphans: 1;
        widows: 1;
      }

      &.state-selection-container-buttons-auto-width {
        .state-container {
          columns: 1;
          display: flex;
          flex-wrap: wrap;

          .btn {
            display: flex;
            align-items: center;
            height: auto;
            max-width: 100%;
            margin-right: 5px;
            padding-right: 3.5px;
            white-space: pre-line;

            .button-text {
              width: calc(100% - 10px);
            }
          }
        }
      }
    }

    &.modal-type-button-full-width {
      .state-selection-container {
        width: 100%;
        margin-left: 0;

        .state-container {
          columns: 1;
        }

        .btn {
          width: 100%;
        }
      }
    }
  }

  &.modal-input-textarea-no-whitespace {
    .panel-body {
      .section {
        &:not(.section-highlighted-lightest) {
          padding-bottom: 0;

          .field-row {
            &.field-full-input {
              margin: 0;
              padding: 0;

              .field-label {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &.modal-address {
    .panel-body {
      .section {
        $section-split-subsections-half-min-height: 415px;

        .section-subgroup {
          display: flex;

          @include add-edit-addresses();
        }

        .section-split-subsections-half {
          min-height: $section-split-subsections-half-min-height;

          .subsection-map {
            min-height: $section-split-subsections-half-min-height;
          }
        }
      }
    }
  }

  &.modal-subsection-card {
    $subsection-group-padding: 10px 10px 10px 10px;

    &.modal-medium {
      @include modal-medium();
    }

    .modal-dialog {
      min-width: 900px;
    }

    .panel-body {
      .section {
        padding: 0;
        @include border-radius("all", section);

        > .section-subgroup {
          .subsection {
            &:only-child {
              padding: $subsection-group-padding;
              @include ng-form {
                background-color: transparent;
              }
            }
          }
        }
      }
    }

    .section {
      > .section-subgroup {
        padding: 0;
        height: auto;

        .subsection {
          padding: 0;

          &.subsection-card {
            @include border-radius("all", section);
            background-color: $white;
            padding: 10px;

            > .section-header-row {
              margin-bottom: 10px;

              .section-header-group {
                .btn,
                .section-header {
                  margin-bottom: 0;
                }
              }
            }

            @include modal-subsection-card-container();
            @include modal-subsection-card();
          }

          &.subsection-map {
            .map-container {
              padding: 10px 0px 10px 10px;
              background-color: $screen-background;
            }
          }

          // > &:last-child {
          //   padding-right: 0;
          //   padding-left: 5px;
          // }

          &.subsection-map {
            + :last-child {
              &:not(.subsection-addresses) {
                padding: $subsection-group-padding;
              }
            }
          }

          @include section-divider-grouping();
        }
      }
    }
  }

  &.modal-wait {
    .panel-body {
      .field-row-group {
        margin-left: $modal-icon-animated-loader-width +
          $modal-section-header-group-margin-right;
        padding: 0;
      }
    }

    .modal-dialog {
      min-width: 450px;
    }
  }
}

.contacts-modal {
  // TODO: Remove this important rule once a general
  // means of applying styles to the modal is in line with
  // the rest of the primary modal styles
  display: flex !important;

  .contact-details {
    font-size: font-size("contact-modal-details");
    color: $cerulean;
  }

  .select-contact {
    .interest-modal {
      .panel-body {
        width: 100%;
        min-height: 220px;
      }
    }
  }

  .panel-default {
    .panel-body {
      @include border("all", gray);
      border-top: none;

      .field-row {
        .field-input {
          .cqui-cover {
            width: calc(100% - 25px);
            margin-left: 10px;
          }
        }
      }
    }
  }

  .modal-dialog {
    width: 650px;
    height: auto;
    display: flex;
    align-items: center;
  }

  .modal-content {
    @media (min-width: $screen-sm-min) {
      width: 100%;
    }
  }

  .modal-footer {
    border-top: 0;
    padding-top: 0;
    padding-bottom: 10px;
    background-color: $lighter_blue;
    position: relative;
    top: -2px;
    @include border-radius("bottom-left-right", 4px);
  }
}
.contact-container {
  .panel {
    margin-bottom: 10px;
  }

  .panel-default {
    .panel-body {
      width: 100%;
      min-height: 260px;
      max-height: 375px;
      overflow-y: auto;

      & > div {
        @include zebra-striping-even();
      }
    }

    > .panel-heading {
      width: 100%;
      color: $white;
      background-color: $cerulean;
      margin-left: 0;
      margin-right: 0;
      padding: 5px 15px;

      &.contact-info-field {
        @include contacts-modal-col {
          display: flex;
          align-items: center;

          .sort-indicator {
            @include icon-margin("left");
          }
        }
      }
    }

    > .panel-body {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .contact-body {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    background-color: $white;
    border: none;

    .select-contact-name,
    .select-contact-address {
      padding: 5px 0;
    }
  }

  .contact-select-header {
    display: flex;
    align-items: center;
    color: $black;
    letter-spacing: 0.01em;
  }

  .contact-select-icon {
    @include icon();
    @include square(50px);
    min-width: 50px;
    @include image-url("icon-contact-lookup.svg");
  }

  .contact-select-text {
    margin-left: 10px;

    .main-text {
      position: relative;
      top: -5px;
      @include font-base();
      @include modal-header();
      margin-left: 0;
    }

    .sub-text {
      position: relative;
      top: -12px;
      @include modal-description();
    }
  }

  .label-header {
    margin-bottom: 5px;
  }

  .section-subsection {
    padding: 0;
  }

  .button-group {
    float: right;

    &.add-new-contact {
      margin-right: 0;
      flex: 1 0 auto;

      .button-text {
        margin-left: 3px;
      }
    }
  }

  .contact-info-field {
    width: 100%;
    @include make-row();
    margin-left: 0;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    @include border-radius("top-left-right");
    position: relative;

    .select-contact-name {
      @include make-sm-column(4);
    }

    .select-contact-address {
      @include make-sm-column(4);
      @include make-md-column(3);
    }

    .select-contact-search {
      @include make-sm-column(4);
      @include make-md-column(5);
    }

    .select-contact-type-loan {
      @include make-sm-column(3);
    }

    .select-contact-add-additional {
      @include make-sm-column(1);
      text-align: center;
    }

    .select-contact-button-group {
      @include make-sm-column(2);
    }

    @include contacts-modal-col {
      padding-left: 0;
      padding-right: 5px;
    }

    .select-contact-button-group {
      padding-right: 0;
    }
  }

  .name-text {
    display: flex;
    align-items: center;
  }

  .single-account {
    @include square(30px);
    min-width: 30px;
    @include icon();
    @include image-url("icon-contact.svg");
  }

  .table-wrapper {
    .table {
      margin-bottom: 10px;
    }
  }

  .icon-search {
    min-height: 20px;
    min-width: 20px;
    @include border-radius("all", 50%);
    @include icon();
    background-color: $cerulean;
    position: absolute;
    right: 4px;
    top: 3.5px;
  }

  .modal-body {
    padding-top: 0;
    background-color: $lighter_blue;
    @include border-radius("top-left-right", 4px);

    .panel {
      margin-bottom: 0;
    }

    .panel-default > {
      .panel-heading {
        padding-left: 10px;
        padding-right: 10px;

        .select-contact-name,
        .select-contact-address {
          display: flex;
          align-items: center;
        }
      }

      .panel-body {
        padding: 0;

        > .contact-info-field {
          padding: 0 10px;
        }
      }
    }

    @include contacts-modal-col {
      padding-right: 0;
    }

    .field-input.field-input-multiple {
      display: flex;

      @include make-sm-column(7);

      margin-left: 0;
      padding-left: 0;
    }
  }
}

.interest-modal {
  background-color: $lighter_blue;
  height: 100%;
  @include border-radius("top-left-right", 4px);

  .modal-body {
    padding-top: 0;
  }

  .field-row {
    .field-label {
      @include make-sm-column(4);
      padding-right: 5px;
      min-height: $field-min-height;
    }

    .field-input {
      @include make-sm-column(7);
      padding-left: 10px;
    }

    .first-name.field-input {
      @include make-sm-column(3);
      padding-left: 10px;
      padding-right: 0;
    }

    .mid-name.field-input {
      @include make-sm-column(1);
      padding-left: 5px;
      padding-right: 5px;
    }

    .last-name.field-input {
      @include make-sm-column(3);
      padding-left: 0;
    }

    .zip.field-input {
      @include make-sm-column(2);
      padding-left: 10px;
    }

    .contact-details.field-input {
      @include make-sm-column(4);
      padding-left: 10px;
      display: flex;
      flex-direction: column;
    }

    .contact-details-edit.field-input {
      @include make-sm-column(2);
      padding-left: 10px;
      margin: auto;
    }
  }

  .panel {
    margin-bottom: 0;
    background-color: $white;
    padding: 10px 0;
  }

  .modal-body {
    > .panel {
      background-color: $white;
    }
  }

  .contact-select-text {
    margin-left: 10px;
  }

  .contact-select-text .main-text {
    position: relative;
    top: -5px;
    @include font-base();
    @include modal-header();
    margin-left: 0;
  }

  .contact-select-text .sub-text {
    position: relative;
    top: -12px;
    @include modal-description();
  }

  .interest-modal-header {
    display: flex;
    align-items: center;
    color: $black;
    letter-spacing: 0.01em;
    margin-bottom: 10px;
  }

  .contact-select-icon {
    @include icon();
    @include square(50px);
    min-width: 50px;
    @include image-url("icon-contact-add.svg");
  }

  .panel-body {
    width: 100%;
  }

  .panel-body .contact-type button {
    background-color: $powder-blue;
  }
}
