ae-loss-information {
  .section-subsection {
    padding: 0 15px !important;
  }

  .field-row-nested-group {
    height: auto !important;

    .field-row {
      border-bottom: none !important;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
  }

  cqui-field-row-expansible-group {
    cqui-field-row-expansible-header {
      .field-row {
        border-bottom: none !important;
      }
    }
  }
}
