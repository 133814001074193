@import "prerequisites";

@mixin icon {
  background-size: 100% 100%;
  display: block;
  background-repeat: no-repeat;
}

@mixin map-pin {
  @include icon();
  @include size(17px, 20px);
}

@mixin listing-icon {
  @include icon();
  @include size(27px, 37px);
  min-width: 27px;
}

#{$lib-prefix}-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .icon {
    @include square(100%);

    svg {
      @include square(100%);
      max-width: 100%;
      max-height: 100%;
    }
  }

  .overlay-icon-container {
    position: absolute;
    bottom: -15%;
    right: -15%;
  }

  &.#{$lib-prefix}-overlay-icon {
    margin-right: 10px;
  }
}

#{$lib-prefix}-icon-group {
  #{$lib-prefix}-icon {
    @include icon-margin("right");
  }

  .icon-group {
    display: flex;
    flex-wrap: wrap;
  }

  .icon-group-entry-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.animated-loader {
  @include image-url("cqui-loader.gif");
}

.nationwide-logo-small {
  @include image-url("#{$nationwide-logo-small}.svg");
}

.nationwide-logo-no-text {
  @include image-url("nationwide-logo-no-text.svg");
}

.nationwide-logo {
  @include image-url("#{$nationwide-logo}.svg");
}

.nationwide-white-logo {
  @include image-url("#{$nationwide-white-logo}.svg");
}

.icon {
  @include icon();
}

.icon-loader-white {
  @include image-url("icon-loader-white.gif");
}

.map-pin {
  @include map-pin;
}

.listing-entry-icon {
  @include listing-icon;
}

@for $i from 1 through 50 {
  @if $i < 10 {
    .icon-location-location-#{$i} {
      @include image-url("pins/icon-pin-0#{$i}.svg");
    }
  } @else {
    .icon-location-location-#{$i} {
      @include image-url("pins/icon-pin-#{$i}.svg");
    }
  }
}

.icon-chevron-down {
  @include image-url("icon-chevron-down.svg");
}

.icon-chevron-up {
  @include image-url("icon-chevron-up.svg");
}

.icon-person-blue {
  @include image-url("icon-person-blue.svg");
}

.icon-person-black-blue {
  @include image-url("icon-person-black-blue.svg");
}

.icon-person-white {
  @include image-url("icon-person-white.svg");
}

.icon-person {
  @include image-url("icon-person-black-blue.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-person-gray.svg");
  }

  &.active {
    @include image-url("icon-person-white.svg");
  }
}

.icon-person-black {
  @include image-url("icon-person-black.svg");
}

.icon-account {
  @include image-url("icon-person-blue.svg");
}

.icon-account-add-product {
  @include image-url("icon-account-add-product.svg");
}

.icon-account-edit {
  @include image-url("icon-account-edit.svg");
}

.icon-account-lookup {
  @include image-url("icon-account-lookup.svg");
}

.icon-forbidden {
  @include image-url("icon-forbidden.svg");
}

.icon-account-error {
  @include image-url("icon-person-error.svg");
}

.icon-address {
  @include image-url("icon-address-available.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-address-disabled.svg");
  }

  &.active {
    @include image-url("icon-address-active.svg");
  }
}

.icon-class-building-details {
  @include image-url("icon-class-building-details-available.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-class-building-details-disabled.svg");
  }

  &.active {
    @include image-url("icon-class-building-details-active.svg");
  }
}

.icon-qualification-questions {
  @include image-url("icon-qualification-questions-available.svg");

  &[disabled],
  &.disabled {
    @include image-url("icon-qualification-questions-disabled.svg");
  }

  &.active {
    @include image-url("icon-qualification-questions-active.svg");
  }
}

/* BOP ICONS */

.icon-building {
  @include image-url("icon-building.svg");
}

.icon-building-details {
  @include image-url("icon-building-details.svg");
}

.icon-building-white {
  @include image-url("icon-building-white.svg");
}

.icon-bop-black {
  @include image-url("icon-bop-black.svg");
}

.icon-bop-white {
  @include image-url("icon-bop-white.svg");
}

.icon-bop-black-blue {
  @include image-url("icon-bop-black-blue.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-bop-gray.svg");
  }

  &.active {
    @include image-url("icon-bop-white.svg");
  }
}

.icon-bop-edit {
  @include image-url("icon-bop-edit.svg");
}

.icon-bop-blue {
  @include image-url("icon-bop-blue.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-bop-gray.svg");
  }
}

/* AUTO ICONS */

.icon-auto-blue {
  @include image-url("icon-auto-blue.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-auto-gray.svg");
  }

  &.active {
    @include image-url("icon-auto-white.svg");
  }
}

.icon-auto-edit {
  @include image-url("icon-auto-edit.svg");
}

.icon-auto-black {
  @include image-url("icon-auto-black.svg");
}

.icon-auto-white {
  @include image-url("icon-auto-white.svg");
}

.icon-auto-black-blue {
  @include image-url("icon-auto-black-blue.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-auto-gray.svg");
  }

  &.active {
    @include image-url("icon-auto-white.svg");
  }
}

.icon-auto-search {
  @include image-url("icon-auto-search.svg");
}

.icon-auto-search-2 {
  @include image-url("icon-auto-search-2.svg");
}

/* WORKERS COMP ICONS */

.icon-wc-blue {
  @include image-url("icon-wc-blue.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-wc-gray.svg");
  }
}

.icon-wc-black {
  @include image-url("icon-wc-black.svg");
}

.icon-wc-white {
  @include image-url("icon-wc-white.svg");
}

.icon-wc-black-blue {
  @include image-url("icon-wc-black-blue.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-wc-gray.svg");
  }

  &.active {
    @include image-url("icon-wc-white.svg");
  }
}

.icon-wc-check {
  @include image-url("icon-wc-check.svg");
}

/* GENERAL LIABILITY ICONS */

.icon-gl-blue {
  @include image-url("icon-general-liability-blue.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-general-liability-gray.svg");
  }

  &.active {
    @include image-url("icon-general-liability-white.svg");
  }
}

.icon-general-liability-white {
  @include image-url("icon-general-liability-white.svg");
}

.icon-general-liability-black {
  @include image-url("icon-general-liability-black.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-general-liability-gray.svg");
  }

  &.active {
    @include image-url("icon-general-liability-white.svg");
  }
}

.icon-gl-white {
  @include image-url("icon-general-liability-white.svg");
}

.icon-gl-black {
  @include image-url("icon-general-liability-black.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-general-liability-gray.svg");
  }

  &.active {
    @include image-url("icon-general-liability-white.svg");
  }
}

.icon-general-liability-black-blue {
  @include image-url("icon-general-liability-black-blue.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-general-liability-gray.svg");
  }

  &.active {
    @include image-url("icon-general-liability-white.svg");
  }
}

.icon-gl-check {
  @include image-url("icon-general-liability-check.svg");
}

/* FARM ICONS */

.icon-farm-blue {
  @include image-url("icon-farm-blue.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-farm-gray.svg");
  }

  &.active {
    @include image-url("icon-farm-white.svg");
  }
}

.icon-farm-white {
  @include image-url("icon-farm-white.svg");
}

.icon-farm-black {
  @include image-url("icon-farm-black.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-farm-gray.svg");
  }

  &.active {
    @include image-url("icon-farm-white.svg");
  }
}

.icon-farm-black-blue {
  @include image-url("icon-farm-black-blue.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-farm-gray.svg");
  }

  &.active {
    @include image-url("icon-farm-white.svg");
  }
}

/* PROPERTY ICONS */

.icon-prop-blue {
  @include image-url("icon-property-blue.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-property-gray.svg");
  }

  &.active {
    @include image-url("icon-property-white.svg");
  }
}

.icon-prop-white {
  @include image-url("icon-property-white.svg");
}

.icon-property-black-blue {
  @include image-url("icon-property-black-blue.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-property-gray.svg");
  }

  &.active {
    @include image-url("icon-property-white.svg");
  }
}

.icon-prop-black {
  @include image-url("icon-property-black.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-property-gray.svg");
  }

  &.active {
    @include image-url("icon-property-white.svg");
  }
}

/* UMBRELLA ICONS */

.icon-umbrella-blue-black {
  @include image-url("icon-umbrella-blue-black.svg");
}

.icon-umbrella-white {
  @include image-url("icon-umbrella-white.svg");
}

.icon-umbrella-black {
  @include image-url("icon-umbrella-black.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-umbrella-gray.svg");
  }

  &.active {
    @include image-url("icon-umbrella-white.svg");
  }
}

/* INLAND MARINE ICONS */

.icon-inland-marine-white {
  @include image-url("icon-inland-marine-white.svg");
}

.icon-inland-marine-black {
  @include image-url("icon-inland-marine-black.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-inland-marine-gray.svg");
  }

  &.active {
    @include image-url("icon-inland-marine-white.svg");
  }
}

/* CRIME ICONS */

.icon-crime-white {
  @include image-url("icon-crime-white.svg");
}

.icon-crime-black {
  @include image-url("icon-crime-black.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-crime-gray.svg");
  }

  &.active {
    @include image-url("icon-crime-white.svg");
  }
}

/* HUMAN SERVICE ICONS */

.icon-human-service-white {
  @include image-url("icon-human-service-white.svg");
}

.icon-human-service-black {
  @include image-url("icon-human-service-black.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-human-service-gray.svg");
  }

  &.active {
    @include image-url("icon-human-service-white.svg");
  }
}

/* SENIOR LVING ICONS */

.icon-senior-living-white {
  @include image-url("icon-senior-living-white.svg");
}

.icon-senior-living-black {
  @include image-url("icon-senior-living-black.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-senior-living-gray.svg");
  }

  &.active {
    @include image-url("icon-senior-living-white.svg");
  }
}

/* COP ICONS */

.icon-cop-black {
  @include image-url("icon-cop-black.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-cop-gray.svg");
  }

  &.active {
    @include image-url("icon-cop-white.svg");
  }
}

.icon-cop-white {
  @include image-url("icon-cop-white.svg");
}

/* PL ICONS */

.icon-pl-white {
  @include image-url("icon-pl-white.svg");
}

.icon-pl-black {
  @include image-url("icon-pl-black.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-pl-gray.svg");
  }

  &.active {
    @include image-url("icon-pl-white.svg");
  }
}

/* NC FACILITY ICONS */

.icon-nc-facility-auto-white {
  @include image-url("icon-nc-facility-auto-white.svg");
}

.icon-nc-facility-auto-black {
  @include image-url("icon-nc-facility-auto-black.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-nc-facility-auto-gray.svg");
  }

  &.active {
    @include image-url("icon-nc-facility-auto-white.svg");
  }
}

/* INLAND MARINE ICONS */

.icon-im-black {
  @include image-url("icon-inland-marine-black.svg");
}

.icon-im-white {
  @include image-url("icon-inland-marine-white.svg");
}

.icon-im-black-blue {
  @include image-url("icon-inland-marine-blue-black.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-inland-marine-gray.svg");
  }

  &.active {
    @include image-url("icon-inland-marine-white.svg");
  }
}

.icon-im-check {
  @include image-url("icon-wc-check.svg");
}

.icon-im-edit {
  @include image-url("icon-inland-marine-white.svg");
}

.icon-im {
  @include image-url("icon-inland-marine-blue.svg");
}

.icon-im-blue {
  @include image-url("icon-inland-marine-blue.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-inland-marine-gray.svg");
  }
}

/* UMBRELLA ICONS */

.icon-umb-black {
  @include image-url("icon-umbrella-black.svg");
}

.icon-umb-white {
  @include image-url("icon-umbrella-white.svg");
}

.icon-umb-black-blue {
  @include image-url("icon-umbrella-blue-black.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-umbrella-gray.svg");
  }

  &.active {
    @include image-url("icon-umbrella-white.svg");
  }
}

.icon-umb-check {
  @include image-url("icon-wc-check.svg");
}

.icon-umb-edit {
  @include image-url("icon-umbrella-white.svg");
}

.icon-umb {
  @include image-url("icon-umbrella-blue.svg");
}

.icon-umb-blue {
  @include image-url("icon-umbrella-blue.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-umbrella-gray.svg");
  }
}

/* REVIEW & QUOTE ICONS */

.icon-review-white {
  @include image-url("icon-review-quote-white.svg");
}

.icon-review {
  @include image-url("icon-review-quote.svg");

  &.disabled,
  &[disabled] {
    @include image-url("icon-review-quote-gray.svg");
  }

  &.active {
    @include image-url("icon-review-quote-white.svg");
  }
}

.icon-review-quote-edit {
  @include image-url("icon-review-quote-edit.svg");
}

/* OTHER ICONS */

.icon-alert-information {
  @include image-url("icon-alert-information.svg");
}

.icon-reclassify {
  @include image-url("icon-reclassify.svg");
}

.icon-first-named-insured {
  @include image-url("icon-first-named-insured.svg");
}

.icon-driver {
  @include image-url("icon-person-black-blue.svg");
}

.icon-driver-doc {
  @include image-url("icon-doc.svg");
}

.icon-exclude,
.icon-driver-exclude {
  @include image-url("icon-exclude.svg");
}

.icon-driver-pip {
  @include image-url("icon-pip.svg");
}

.icon-delete-white-invert {
  @include image-url("icon-delete-white-invert.svg");
}

.icon-alert-add {
  @include image-url("icon-expand-errors.svg");
}

.icon-information-blue {
  @include image-url("icon-information-blue.svg");
}

.icon-information-circle {
  @include image-url("icon-information-circle.svg");
}

.icon-header {
  @include image-url("icon-new-account-blue.svg");
}

.icon-exclamation-blue {
  @include image-url("icon-exclamation-blue.svg");
}

.icon-exclamation-red,
.icon-exclamation-warning {
  @include image-url("icon-exclamation-red.svg");
}

.icon-error,
.icon-errors {
  @include image-url("icon-errors.svg");
}

.icon-caution-add {
  @include image-url("icon-expand-cautions.svg");
}

.icon-cautions {
  @include image-url("icon-cautions.svg");
}

.icon-underwriting-requirements {
  @include image-url("icon-underwriting-requirements.svg");
}

.icon-add {
  @include image-url("icon-add.svg");
}

.icon-add-blue {
  @include image-url("icon-add-blue.svg");
}

.icon-add-black {
  @include image-url("icon-add-black.svg");
}

.icon-edit {
  @include image-url("icon-edit.svg");
}

.icon-delete {
  @include image-url("icon-delete.svg");
}

.icon-delete-white {
  @include image-url("icon-delete-white.svg");
}

.icon-delete-blue {
  @include image-url("icon-delete-blue.svg");
}

.icon-delete-gray {
  @include image-url("icon-delete-gray.svg");
}

.icon-copy-white {
  @include image-url("icon-copy-white.svg");
}

.icon-copy {
  @include image-url("icon-copy.svg");
}

.icon-erase {
  @include image-url("icon-erase.svg");
}

.icon-check-white {
  @include image-url("icon-check-white.svg");
}

.icon-check-blue-border {
  @include image-url("icon-check-blue-border.svg");
}

.icon-check-blue {
  @include image-url("icon-check-blue.svg");
}

.icon-radio-selected-black {
  @include image-url("icon-radio-selected-black.svg");
}

.icon-radio-unselected {
  @include image-url("icon-radio-unselected.svg");
}

.icon-radio-unselected-white {
  @include image-url("icon-radio-unselected-white.svg");
}

.icon-radio-selected {
  @include image-url("icon-radio-selected.svg");
}

.icon-checkbox-selected-black {
  @include image-url("icon-checkbox-selected-black.svg");
}

.icon-checkbox-unselected-white {
  @include image-url("icon-checkbox-unselected-white.svg");
}

.icon-checkbox-unselected {
  @include image-url("icon-checkbox-unselected.svg");
}

.icon-checkbox-selected {
  @include image-url("icon-checkbox-selected.svg");
}

.icon-approved-blue {
  @include image-url("icon-approved-blue.svg");
}

.icon-approved {
  @include image-url("icon-approved.svg");
}

.icon-declined {
  @include image-url("icon-decline.svg");
}

.icon-cautions {
  @include image-url("icon-cautions.svg");
}

.icon-cautions-border {
  @include image-url("icon-cautions-border.svg");
}

.icon-errors-border {
  @include image-url("icon-errors-border.svg");
}

.icon-calendar {
  @include image-url("icon-calendar.svg");
}

.icon-next {
  @include image-url("icon-next.svg");
}

.icon-previous {
  @include image-url("icon-previous.svg");
}

.icon-next-blue-black {
  @include image-url("icon-next-blue-black.svg");

  &.disabled {
    @include image-url("icon-next-gray.svg");
  }
}

.icon-previous-blue-black {
  @include image-url("icon-previous-blue-black.svg");

  &.disabled {
    @include image-url("icon-previous-gray.svg");
  }
}

.icon-next-gray {
  @include image-url("icon-next-gray.svg");
}

.icon-previous-gray {
  @include image-url("icon-previous-gray.svg");
}

.icon-cancel {
  @include image-url("icon-cancel.svg");
}

.icon-chat-bubble {
  @include image-url("icon-chat-bubble.svg");
}

.icon-star-selected {
  @include image-url("icon-star-selected.svg");
}

.icon-star-unselected {
  @include image-url("icon-star-unselected.svg");
}

.icon-person-contact {
  @include image-url("icon-contact.svg");
}

.icon-contact-lookup {
  @include image-url("icon-contact-lookup.svg");
}

.icon-contact-add {
  @include image-url("icon-contact-add.svg");
}

.icon-sort {
  @include image-url("icon-sort-white.svg");
}

.icon-magnifying-glass-white {
  @include image-url("icon-magnifying-glass-white.svg");
}

.icon-magnifying-glass-blue {
  @include image-url("icon-magnifying-glass-blue.svg");
}

.icon-keyboard {
  @include image-url("icon-manual-entry.svg");
}

.icon-search {
  @include image-url("icon-search.svg");
}

.icon-select-mark {
  @include image-url("icon-tick-mark.svg");
}

.icon-checkbox-checked {
  @include image-url("icon-checkbox-checked.svg");
}

.icon-checkbox-unchecked {
  @include image-url("icon-checkbox-unchecked.svg");
}

.icon-checkbox-unchecked-white {
  @include image-url("icon-checkbox-unchecked-white.svg");
}

.icon-address-map-add {
  @include image-url("icon-address-map-add.svg");
}

.icon-address-map-edit {
  @include image-url("icon-address-map-edit.svg");
}

.icon-address-map {
  @include image-url("icon-address-map.svg");

  &[disabled] {
    @include image-url("icon-address-map-disabled.svg");
  }
}

.icon-billing {
  @include image-url("icon-billing.svg");
}

.icon-mailing {
  @include image-url("icon-mailing.svg");
}

.icon-pin-primary {
  @include image-url("pins/icon-pin-primary.svg");
}

.icon-pin-billing {
  @include image-url("pins/icon-pin-billing.svg");
}

.icon-pin-mailing {
  @include image-url("pins/icon-pin-mailing.svg");
}

.icon-pin-available,
.icon-location-available {
  @include image-url("pins/icon-pin-available.svg");
}

.icon-location-not-available,
.icon-pin-unavailable {
  @include image-url("pins/icon-pin-unavailable.svg");
}

.icon-briefcase-magnify {
  @include image-url("icon-business-search.svg");
}

.icon-check-double-white {
  @include image-url("icon-check-double-white.svg");
}

.icon-check-double {
  @include image-url("icon-check-double.svg");
}

.icon-split-period {
  @include image-url("icon-split-period.svg");
}

.icon-chevron-left {
  @include image-url("icon-chevron-left.svg");
}

.icon-chevron-right {
  @include image-url("icon-chevron-right.svg");
}

.icon-launch-white {
  @include image-url("icon-launch-white.svg");
}

.icon-launch-blue {
  @include image-url("icon-launch-blue.svg");
}

.icon-irpm {
  @include image-url("icon-irpm.svg");
}

.icon-irpm-blue-black {
  @include image-url("icon-irpm-blue-black.svg");
}

.icon-calculator {
  @include image-url("icon-calculator.svg");
}

.icon-pricing-applied {
  @include image-url("icon-applied-pricing.svg");
}

.icon-apply-pricing {
  @include image-url("icon-apply-pricing.svg");
}

.icon-display-proposal {
  @include image-url("icon-display-proposal.svg");
}

.icon-thumbs-up {
  @include image-url("icon-thumbs-up.svg");
}

.icon-schedule {
  @include image-url("icon-schedule.svg");
}

.icon-schedule-add {
  @include image-url("icon-add-schedule.svg");
}

.icon-auto-car,
.icon-car {
  @include image-url("icon-car.svg");
}

.icon-truck,
.icon-auto-truck {
  @include image-url("icon-truck.svg");
}

.icon-auto-trailer {
  @include image-url("icon-trailer.svg");
}

.icon-upload {
  @include image-url("icon-upload.svg");
}

.icon-special-vehicle {
  @include image-url("icon-special-vehicle.svg");
}

.icon-farm-rv-blue-black {
  @include image-url("icon-farm-rv-blue-black.svg");
}

.icon-zone-rated-truck {
  @include image-url("icon-zone-rated-truck.svg");
}

.icon-spouse {
  @include image-url("icon-spouse.svg");
}

.icon-public-transport {
  @include image-url("icon-public-transport.svg");
}

.icon-upload-drivers {
  @include image-url("icon-upload-drivers.svg");
}

.icon-files {
  @include image-url("icon-files.svg");
}

.icon-asterisk-3 {
  @include image-url("icon-asterisk-3.svg");
}

.icon-question {
  @include image-url("icon-question.svg");
}

.icon-retry {
  @include image-url("icon-retry.svg");
}

.icon-bindable {
  @include image-url("icon-bindable.svg");
}

.icon-bindable-black-blue {
  @include image-url("icon-bindable-black-blue.svg");
}

.icon-hide-optional-fields {
  @include image-url("icon-hide-optional-fields.svg");
}

.icon-hide-optional-fields-black-blue {
  @include image-url("icon-hide-optional-fields-black-blue.svg");
}

.icon-rocket {
  @include image-url("icon-rocket.svg");
}

.icon-store-front {
  @include image-url("icon-store-front.svg");
}

.icon-prize {
  @include image-url("icon-prize.svg");
}

.icon-envelope {
  @include image-url("icon-envelope.svg");
}

.icon-diversify {
  @include image-url("icon-diversify.svg");
}
