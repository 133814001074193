/* stylelint-disable selector-class-pattern */

@use "units";

.cq-dialog-panel {
  .mat-mdc-dialog-container {
    --mdc-dialog-container-color: #e5f4ff;
    --mdc-dialog-subhead-color: #000;
    --mdc-dialog-subhead-font: var(--cq-font);
    --mdc-dialog-subhead-tracking: 0;
    --mdc-dialog-supporting-text-color: #000;
    --mdc-dialog-supporting-text-font: var(--cq-font);
    --mdc-dialog-supporting-text-line-height: normal;
    --mdc-dialog-supporting-text-size: var(--cq-font-size);
    --mdc-dialog-supporting-text-tracking: 0;
  }
}

.cq-dialog-tabs {
  .mat-mdc-tab-labels {
    border-bottom: units.px(2) solid #005e7a;
  }

  .mat-mdc-tab-header {
    --mdc-tab-indicator-active-indicator-height: units.px(2);
  }

  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    font-weight: bold;
  }
}
