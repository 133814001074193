@use "units";

html,
body {
  background-size: units.px(5) units.px(5);
  background-image: linear-gradient(
    -45deg,
    #ededed 25%,
    #d7d7d7 0,
    #d7d7d7 50%,
    #ededed 0,
    #ededed 75%,
    #d7d7d7 0,
    #d7d7d7
  );
  height: 100%;
}

body {
  font-family: var(--cq-font);
  margin: 0;
}
