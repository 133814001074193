.auto-driver {
  .accounts-row {
    margin-bottom: 5px;
    @include make-sm-column(12);
  }

  .named-insured-field {
    background-color: $white;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    @include border-radius();
    position: relative;
    padding-left: 7px;
    padding-right: 7px;
    float: left;
  }

  .edit-delete-button-group {
    margin: 5px 0 5px 5px;
  }

  .icon-person-contact {
    margin-top: 3px;
    margin-bottom: 3px;
    @include square(30px);
    @include icon();
  }

  .add-named-insured-wc {
    display: flex;
    justify-content: flex-end;
  }

  .account-name {
    margin-top: 7px;
    margin-bottom: 7px;
  }
}
