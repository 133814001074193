@import "prerequisites";

.cqui-progress-loader {
  $loader: $light-bright-blue;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  .progress {
    @include border("all", blue);
    margin-bottom: 0;
    flex: 1 0 auto;
  }

  .progress-bar {
    background-color: $loader;
    height: 100%;
  }

  @include dancing-dots;

  .loader-animation {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .animation-img {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
}
