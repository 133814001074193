@mixin coverages-expansible-containers-borderfix {
  .field-row .field-container .expansible-type-embed {
    &:before {
      content: none;
    }

    .expansible-container:after {
      content: none;
    }

    .field-input select.form-control {
      max-width: 100%;
    }
  }
}

@mixin field-notification-messages {
  .field-notification {
    width: 100%;
    margin-top: 0;
    .cqui-notification {
      margin-top: 0;
    }

    .cqui-notification[field-above="true"] {
      margin-top: 5px;
    }

    .field-notification-wrapper {
      width: 100%;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      &:after {
        content: "";
        width: 100%;
      }

      .field-notification-wrapper-item {
        margin-bottom: 5px;

        &:first-child {
          margin-top: 5px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@mixin classification() {
  .classification-lookup {
    .section-header {
      font-weight: 500;
      display: flex;
      align-items: center;

      cqui-info-indicator {
        margin: 3px 5px 0;
      }
    }

    .field-row {
      .field-input {
        position: relative;
        padding-right: 0;
        margin-right: 5px;

        &:only-child {
          width: 100%;
          margin-right: 0;
        }

        // Remove the IE clear button
        input::-ms-clear {
          display: none;
        }

        .cqui-icon-button {
          position: absolute;
          top: 0;
          right: 0;
          @include border-radius("all", 0);
          border: none;
          @include cqui-icon-button-padding();
        }
      }
    }
  }
}
