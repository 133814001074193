.section {
  .section-flex-col {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .section-header {
    margin-right: 10px;
    @include font-base();

    &.section-sub-header {
      margin-left: 35px;
      margin-right: 35px;
      padding-bottom: 5px;
      @include border("bottom");
      width: calc(100% - 70px);
    }

    @include section-header-note {
      font-size: font-size("section-header-note");
    }
  }

  @include section-header-note();

  &.cqui-expansible-list {
    > * {
      &:first-child {
        > .tiles-container {
          margin-top: 10px;
        }
      }
    }
  }

  > .section-header-row-vcenter {
    margin-bottom: 5px;

    .button-group {
      flex: 1 0 auto;
      justify-content: flex-end;
      align-self: flex-end;
      display: flex;
    }
  }

  .section-subsection {
    padding: 15px;

    > .button-container {
      margin-top: 10px;
      justify-content: space-between;

      .button-group {
        align-self: flex-end;
        flex: 1 0 auto;
        display: flex;
        justify-content: flex-end;

        &:first-child {
          justify-content: flex-start;
        }

        &:last-child {
          justify-content: flex-end;
        }
      }
    }

    .buttons-row {
      float: right;
    }

    .section-header-row {
      .button-group {
        float: right;
      }

      + .section-header-row {
        margin-top: 10px;
      }
    }

    &.section-highlighted {
      @include section-highlighted;

      .section-sub-header {
        @include border("bottom");
      }
    }

    &.section-button-row {
      .button-row {
        margin-left: -#{2 * $row-margin-left};

        .button-row-group {
          position: relative;
          @include make-xs-column-push($button-row-group-margin-push);
        }

        @include button-row-blue-overline();
      }
    }

    @include subsection-confirmation();

    > .field-row-level {
      .expansible-type-embed {
        @include expansible-container-embed-divider();
      }
    }

    @include expansible-container-embed-fields();
  }

  @include classification-lookup();
  @include subsection-classification();
  @include expansible-container-listing();

  > .section-subsection {
    + .section-subsection {
      margin-top: 15px;
    }

    &:last-child {
      border-bottom: none;
    }

    @include subsection-confirmation();
  }

  &.section-highlighted {
    background-color: $screen-background;
  }

  &.section-highlighted-light {
    @include section-highlighted-light;

    .section-subgroup {
      &.section-split-subsections-half {
        margin-top: 20px;

        .map-container {
          padding-right: 0;
        }
      }
    }
  }

  &.section-highlighted-lightest {
    @include section-highlighted-lightest;
  }

  &.split-section {
    padding: 10px 0;
    display: flex;

    .section-header {
      margin-top: 5px;
      margin-bottom: 0;
      flex: 1 0 auto;
    }
  }

  &.section-fields-split-even {
    .field-row {
      .field-label {
        @include make-sm-column(6);
        @include make-md-column(5);
      }

      .field-input {
        @include make-sm-column(6);
        @include make-lg-column(5);
        padding-left: 0;
        @include field-input-group-auto-width();
      }

      @include field-full-input();
    }
  }

  &.section-fields-right {
    .field-row {
      @include section-fields-right-label();
      @include section-fields-right-input();

      .field-input {
        @include field-input-notification();
      }

      #{$lib-prefix}-field-input {
        &.field-input {
          padding-right: 25px;
        }
      }

      .input-right-template-container {
        .cqui-informational-indicator {
          margin-top: 5px;
        }
      }

      @include field-row-vertical();
      @include field-row-right-large();
      @include field-input-group();
    }

    @include field-input-group();
    @include field-row-expansible-group();
  }

  &.section-fields-underlined {
    .header-field {
      + .field-row {
        &.field-row-level-top {
          border-top: none;
        }
      }

      + .field-row-level-children {
        .expansible-type-embed {
          &:before {
            @include square(0);
          }

          @include field-question-type();
        }
      }
    }

    .field-row {
      @include border("bottom");
      padding: 5px 0;
      margin-bottom: 0;

      &.full-width {
        &.description {
          border-bottom: none;

          .field-label {
            width: 100%;

            .field-description {
              text-align: left;
              width: 100%;
            }
          }

          .field-input {
            width: 0%;
            padding: 0;
          }

          + .field-row {
            border-top: none;
          }
        }

        > [template] {
          width: 100%;
        }
      }

      &.field-row-level-top {
        @include border("top");
        border-bottom: none;

        &:first-child {
          border-top: none;
        }

        + .field-row-level-has-children {
          &.no-expansible-list {
            border-top: none;
          }

          + .field-expansible-type-embed {
            &:not(.field-row-show-children) {
              border-top: none;
              border-bottom: none;
            }
          }

          &.field-row-show-children {
            .field-row-level {
              border-bottom: none;
            }

            &.no-expansible-list {
              border-bottom: none;
            }
          }
        }

        + .field-row-level-show-children {
          @include border("bottom");

          &.no-expansible-list {
            .field-row {
              border-top: none;

              &:before {
                background-color: transparent;
                height: 0;
              }
            }

            .field-row-level {
              padding-top: 0;
              padding-bottom: 0;
            }
          }
        }
      }

      @include field-divider-grouping();
      @include field-input-text-match-radio;
    }

    > .field-row {
      &:last-child {
        border-bottom: none;
      }
    }

    .field-row-level {
      .field-input {
        .question-type-input {
          &.select-dropdown {
            width: 75%;
          }
        }
      }
    }

    .field-row-nested-group {
      height: 100%;

      .field-row-first-list-child {
        border-bottom: none;
        position: relative;
      }

      .field-nested-group-children {
        height: 100%;

        .field-nested-child {
          .field-row {
            > [template] {
              width: 100%;
              padding: 0 5px 10px;
            }
          }
        }
      }
    }

    .field-row-nested-group,
    .field-nested-group-children {
      .question-type-input {
        &.select-dropdown {
          width: 100%;

          + .input-right-template-container {
            margin-left: 5px;
          }
        }
      }

      .field-input {
        select.form-control {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .field-row-level-children {
      border-bottom: none;

      &.field-expansible-type-embed {
        .expansible-list-table {
          background-color: $expansible-container-embed;
        }

        .expansible-container {
          &:after {
            display: none;
          }

          @include field-question-type();
        }
      }

      &.no-expansible-list {
        .field-row-level {
          padding-bottom: 0;
        }

        .field-row-nested-group {
          .field-row-first-list-child {
            padding-top: 0;

            &:before {
              display: none;
            }
          }
        }
      }

      .field-row-first-list-only-child {
        border-bottom: none;
      }

      + .field-row-level-children {
        border-bottom: none;
      }

      .field-row-level {
        border-bottom: none;
        flex-direction: column;

        .field-row {
          width: 100%;

          &.field-row-first-list-child {
            border-bottom: none;
          }
        }
      }

      + .field-row-level-top {
        border-bottom: none;

        &:last-child {
          @include border("bottom");
        }
      }

      &.input-group {
        flex-direction: column;
        background-color: $white;
        margin-bottom: 10px;
        @include border-radius("all", section);

        .field-label,
        .field-description,
        .field-input,
        .form-control,
        .btn {
          width: 100%;
          text-align: left;
          justify-content: flex-start;
        }

        .field-input {
          padding-left: 10px;
          padding-right: 10px;
          width: 100%;
        }

        .btn {
          justify-content: flex-start;
          height: auto;

          @include button-icon-align-top();

          .button-text {
            flex: 1 0 calc(100% - 10px);
            flex-wrap: wrap;
          }
        }
      }
    }

    #{$lib-prefix}-field-row-expansible-group {
      + #{$lib-prefix}-field-row-expansible-group {
        @include border("top");
      }
    }
  }

  &.section-fields-no-underline {
    #{$lib-prefix}-field-row {
      + #{$lib-prefix}-field-row {
        &:before {
          border-top: none;
        }
      }
    }
  }

  &.section-fields-input-flex {
    .field-input {
      display: flex;

      .form-control {
        + * {
          margin-left: 5px;
          margin-right: 0;
        }
      }

      &.field-input-multiple {
        .form-control {
          margin-left: 0;
        }
      }
    }
  }

  &.section-fields {
    .field-row {
      padding: 5px 0;
      margin-bottom: 0;

      .field-label {
        @include make-sm-column(8);
        min-height: $field-min-height;
      }

      .field-input {
        @include make-sm-column(4);
        padding-left: 0;
        display: flex;
      }
    }
  }

  &.section-listings {
    width: 100%;
    padding: 15px 20px;

    &.section-listings {
      &.flex-row {
        > .section-subsection {
          margin-top: 0;

          .pill-container {
            min-width: 250px;
            flex: 1 0 auto;
          }

          .pill-value {
            flex: 0 1 60%;
            padding: 0 15px;
          }

          &:first-child {
            width: auto;
            flex: 1 0;
          }

          &::last-child {
            flex: 0 0 250px;
          }
        }
      }
    }

    > .section-subsection {
      padding: 0;
      flex: 0 1 auto;
    }

    .section-subsection {
      > .pill-container {
        .pill-label {
          width: auto;
          padding: 0 15px;
        }

        .pill-value {
          width: auto;
          padding: 0 20px;
        }
      }
    }

    .field-row {
      margin-bottom: 0;
      padding: 7px 0;
      @include border("bottom", blue);
      font-weight: bold;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &.section-highlighted-blue {
    @include section-highlighted-blue();
  }

  &.section-notification {
    display: flex;
    flex: 1 0 auto;
    margin-bottom: 0;
    padding-bottom: 10px;

    @include section-notification-compact();

    > .cqui-notification {
      margin-bottom: 0;

      .notification-content {
        justify-content: flex-end;
      }

      .notification-icon {
        @include square(20px);
        margin-top: 3px;
      }

      .notification-text {
        width: auto;
        line-height: 1.2;
        font-size: font-size("section-notification-text");
      }
    }
  }

  &.section-only-notification {
    uib-accordion {
      .panel-group {
        > ng-transclude {
          @include section-only-notification();

          &.all-opened {
            @include section-only-notification();
          }
        }
      }
    }
  }

  $section-tile-height: 95px;
  $section-tiles-large-overflow-height: 1270px;
  $section-tiles-large-overflow-increase-factor: 2.5;

  // To prevent the look of excess margin bottom space
  $section-tiles-offset-vertical-margin: 5px;

  &.section-tiles {
    .tiles-group {
      max-height: $section-tile-height + 5px;
      overflow-y: auto;

      .tiles-row {
        margin-top: -#{$section-tiles-offset-vertical-margin};
      }

      .tile {
        min-height: $section-tile-height;
        align-items: flex-start;
        margin-bottom: 0;
        margin-top: $section-tiles-offset-vertical-margin;
      }

      .tile-text {
        margin-right: 20px;
      }
    }
  }

  > .field-row:first-child:nth-last-child(1) {
    margin-top: 15px;
  }

  ng-form,
  [ng-form] {
    @include expansible-container-embed-fields();
  }

  @include inline-description;

  > .notification-field {
    margin-bottom: 15px;

    &:first-child {
      margin-top: 5px;
    }
  }

  @include panel-body();

  @media (min-height: $section-tiles-large-overflow-height) {
    &.section-tiles {
      .tiles-group {
        max-height: $section-tile-height *
          $section-tiles-large-overflow-increase-factor;
      }
    }
  }
}

.section-split-subsections-half {
  display: flex;

  .subsection {
    @include make-sm-column(6);

    margin-top: 10px;

    .field-row {
      margin-right: 0;
      padding-left: 0;
    }

    .field-input {
      width: 100%;
      padding-right: 0;
    }

    &:first-child {
      padding-right: 0;
    }

    &:last-child {
      @include border("left");

      padding-right: 0;

      .field-row:last-child {
        margin-bottom: 0;
      }
    }

    .section-header {
      margin-top: 10px;
    }

    &.subsection-card {
      background-color: $white;
    }
  }

  .subsection:first-child:nth-last-child(1),
  .subsection:first-child:nth-last-child(2),
  .subsection:first-child:nth-last-child(2) ~ .subsection {
    .section-header {
      margin-left: 0;
    }
  }

  .subsection {
    &.subsection-map {
      + .subsection {
        border-left: none;
      }

      .map-container {
        @include square(100%);
        padding: 5px;
        margin: 0;
        background-color: $map-container;
      }

      .map-background {
        height: 100%;
        background-color: $map-background;
        margin: auto;
      }

      .map-pins-row {
        padding: 5px;

        .map-pin-group {
          float: left;
          display: flex;

          + .map-pin-group {
            margin-left: 10px;
          }

          .map-pin-text {
            color: $map-pin-text;
          }

          .map-pin {
            margin-right: 3px;
          }
        }
      }
    }

    @include subsection-listing();
  }

  .subsection-group {
    float: left;
    width: 50%;

    .section-header-row {
      .section-header-group {
        &:nth-child(1):nth-last-child(2),
        &:nth-child(2):nth-last-child(1) {
          display: flex;
          justify-content: flex-end;

          .button-group {
            .btn {
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    .subsection {
      width: 100%;
      padding-left: 0;
      margin-bottom: 5px;

      &.first-child {
        padding-bottom: 10px;
      }

      &.subsection-labelless-form {
        padding-right: 10px;
        border: none;

        > * {
          padding: 0;
        }

        > .button-group {
          @include float();
          display: flex;
          justify-content: flex-end;

          button {
            margin-right: 5px;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        > .field-row {
          border-bottom: none;
          padding-bottom: 0px;
        }

        .zip-field-row {
          .field-input-multiple {
            width: 100%;
            padding-right: 0;

            @include field-input-multiple-zip-city-state();
          }
        }

        .zip-city-state-field {
          margin-bottom: 5px;
        }

        .field-label {
          display: none;
        }
      }

      &.subsection-form-with-label {
        border-left: none;

        .field-label {
          display: flex;
        }

        .field-input {
          padding-right: 0;

          .question-type-input {
            width: 100%;
          }
        }
      }

      &.subsection-group-dividers {
      }
    }

    .subsection-listing {
      .listing-entry {
        .listing-entry-button-group {
          &.listing-entry-button-group-width {
            .listing-entry-button {
              min-width: 80px;
            }

            button {
              width: 100%;

              .btn-content {
                justify-content: flex-start;
              }
            }
          }
        }
      }
    }
  }
}

.section-split-subsections-vertical {
  display: flex;

  .section-header {
    margin-top: 0;
  }

  .section-header-group {
    .section-header {
      margin-left: 5px;
    }
  }

  .section-row {
    .section-row-group {
      &.button-group {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .subsection {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 15px;
    @include border("right", 3px, white);

    // Two columns
    &:nth-child(1):nth-last-child(2) {
      @include make-xs-column(5.4);
    }

    &:nth-child(2):nth-last-child(1) {
      @include make-xs-column(7.6);
    }

    // Three columns
    &:nth-child(1):nth-last-child(3),
    &:nth-child(2):nth-last-child(2),
    &:nth-child(3):nth-last-child(1) {
      @include make-xs-column(4);
    }

    &:nth-child(2):nth-last-child(2),
    &:nth-child(3):nth-last-child(1) {
      .listing-group {
        flex: 1 0 80%;
      }
    }

    &:last-child {
      border-right: none;
    }
  }

  .subsection-listing {
    margin: 0 15px;
    line-height: 1.2;
    display: flex;

    .listing-entry-icon {
      float: left;
      margin-right: 10px;
    }

    &.text-center {
      .listing-entry-wrapper {
        display: flex;
      }

      @include subsection-listing-entry-header();

      .listing-entry-text-group {
        display: flex;
        flex-direction: column;
        width: 100%;

        .listing-entry-header {
          font-size: font-size("subsection-entry-listing-entry-header");
          font-weight: bold;
          margin-top: 0;
          margin-bottom: 3px;
          text-align: left;
        }
      }

      .listing-group {
        display: flex;
        justify-content: center;
        flex: 0 1 55%;
        flex-direction: column;
      }

      .listing-entry {
        padding-bottom: 10px;
        @include border("bottom", blue-medium);

        + .listing-entry {
          margin-top: 10px;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .listing-entry-text {
        text-align: left;
        display: flex;
      }
    }

    + .section-row {
      padding-top: 7px;
      margin: 0 15px;
      @include border("top", blue-medium);
    }
  }

  @media (max-width: $screen-sm-max) {
    .subsection-listing {
      + .section-row {
        margin: 0 7px;
      }
    }
  }
}

.section-header {
  padding-top: 0;
  font-weight: bold;

  .button-row-group {
    margin-left: 10px;
  }
}

.section-header-row {
  display: flex;
  align-items: center;

  .section-header-group {
    flex: 0 1 auto;
    width: 100%;
  }

  .section-header {
    margin-bottom: 0;
    padding-bottom: 0;
    width: 100%;
    @include section-header();
    cursor: default;
  }

  &.section-header-row-large {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    margin-top: 10px;
    padding: 10px;

    .section-header-group {
      margin-top: 15px;
      width: auto;

      &:first-child {
        flex: 0 0 auto;
      }

      &:last-child {
        margin-left: 10px;
      }
    }

    .section-header {
      font-size: font-size("section-row-header-large");
      margin-top: 0;
      margin-bottom: 5px;
    }

    .section-header-icon {
      @include section-header-icon;
      width: 60px;
      height: 50px;
      margin-top: -15px;
    }
  }

  .button-group {
    &:nth-child(1):nth-last-child(1) {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  &.section-header-row-group-split {
    .section-header-group {
      flex: 0 1 auto;
    }

    .section-header-group {
      &:last-child {
        flex: 0 1 40%;
        display: flex;
        justify-content: flex-end;
        padding-right: 15px;
      }
    }
  }

  @include compact-notification();
}

.section-header-group {
  .section-header {
    margin-bottom: 10px;
    @include section-header();
  }

  & + .button-group {
    align-self: flex-start;

    &.button-group-column {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  @include section-header-inline();

  .section-header-row-group {
    @include make-row();
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    .section-header-icon {
      padding: 0 3px;
    }

    .section-header {
      margin-top: 0;
    }

    &.section-header-row-group-underlined {
      .section-header-icon {
        @include header-field-icon;
      }

      .section-header-row {
        @include border("bottom");
        padding-top: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .section-header-group {
          flex: 0 1 auto;
          width: auto;
        }

        .section-header {
          margin-top: 10px;

          &:first-child {
            margin-bottom: 0;
          }

          &:last-child {
            margin-bottom: 10px;
          }

          & + .section-header {
            margin-top: 0px;
          }
        }

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    &.section-header-row-group-center {
      .section-header-row {
        @include make-xs-column-offset(3);
        @include make-xs-column(9);

        @include make-md-column-offset(3.6);
        @include make-md-column(7.2);

        @include make-lg-column-offset(4);
        @include make-lg-column(7.2);
      }
    }
  }
}

.section-group {
  .section:not(:only-child) {
    &:first-child {
      .field-row {
        border-bottom: none;
      }
    }
  }

  .search-field {
    .field-input {
      width: 40%;
    }
  }
}

.section-bar-listing {
  background-color: $section-bar-listing;
  @include border-radius("all", section-bar);
  padding: 5px;

  .section-bar-listing-groupings {
    display: flex;

    .section-bar-listing-group {
      @include border("right", white);
      display: flex;
      flex: 1 0 auto;
      justify-content: space-around;
      align-items: center;
      margin-right: 10px;

      .section-bar-listing-entry {
        padding: 0 10px;
        display: flex;
        align-items: center;

        &:first-child {
          margin-left: 10px;
        }

        &:last-child {
          margin-right: 10px;
        }

        .section-bar-listing-entry-icon {
          @include square(25px);
          min-width: 25px;
          @include icon-margin("right");
        }

        .section-bar-listing-entry-text {
          color: $white;
          font-weight: bold;
          font-size: font-size("section-bar-listing-entry-text");
          line-height: 1.1;
        }
      }

      &:last-child {
        border-right: none;
        margin-right: 0;
        justify-content: center;
        margin: auto;
        flex: 0 1 auto;
      }
    }
  }

  + .cqui-notification {
    justify-content: flex-end;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@include section-divider-settings();

@media (max-width: $screen-sm-max) {
  .section-bar-listing {
    .section-bar-listing-groupings {
      .section-bar-listing-group {
        flex: 0 1 auto;
        .section-bar-listing-entry {
          .section-bar-listing-entry-icon {
            @include square(20px);
            min-width: 20px;
          }

          .section-bar-listing-entry-text {
            font-size: font-size("subsection-listing-entry-text");
          }
        }
      }
    }
  }
}
