.cq-upgrade-reset,
.cdk-overlay-container {
  line-height: 1.15; // Overrides bootstrap default body line height to the modern-normalize value

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert; // Overrides bootstrap default font-size
    font-weight: revert; // Overrides bootstrap default font-weight
    line-height: revert; // Overrides bootstrap default line-height
    margin-bottom: revert; // Overrides bootstrap default margin-bottom
    margin-left: revert; // Overrides global heading margin-left
    margin-top: revert; // Overrides bootstrap default margin-top
  }

  input {
    box-shadow: revert; // Overrides global input box-shadow
  }

  label {
    left: revert; // Overrides global label left
  }

  legend {
    border-bottom: revert; // Overrides bootstrap default border-bottom
    font-size: revert; // Overrides bootstrap default font-size
    color: revert; // Overrides bootstrap default color
    margin-bottom: revert; // Overrides bootstrap default margin-bottom
  }

  p {
    color: revert; // Overrides global p color
    margin: revert; // Overrides bootstrap default margin
  }

  // Revert global button-container styling
  .button-container {
    display: revert;
    justify-content: revert;
    margin-bottom: revert;
  }

  // Revert global checkbox styling
  .checkbox {
    position: revert;
    display: revert;
    margin-top: revert;
    margin-bottom: revert;
  }

  // Revert global label styling
  .label {
    border-radius: revert;
    color: revert;
    font-weight: revert;
    font-size: revert;
    line-height: revert;
    padding: revert;
    text-align: revert;
    white-space: revert;
    vertical-align: revert;
  }

  // Revert global pending styling
  .pending {
    opacity: revert;
  }

  // Revert global required styling
  .required {
    color: revert;
    top: revert;
    font-size: revert;
    font-weight: revert;
    font-family: revert;
  }

  // Revert bootstrap row styling
  .row {
    margin-left: revert;
    margin-right: revert;
  }

  // Revert global tooltip styling
  .tooltip {
    display: revert;
    font-family: revert;
    font-style: revert;
    letter-spacing: revert;
    line-break: revert;
    line-height: revert;
    font-weight: revert;
    text-align: revert;
    text-decoration: revert;
    text-shadow: revert;
    text-transform: revert;
    opacity: revert;
    position: revert;
    white-space: revert;
    word-break: revert;
    word-spacing: revert;
    word-wrap: revert;
    z-index: revert;
  }
}
