@import "prerequisites";

.form-control {
  height: $field-min-height;
  color: $black;
  @include border-radius("all", 0);
  background-color: $lightest-gray;
  border-color: $gray;
  padding: 2px 3px 3px 5px;
}

.date-divider {
  height: 100%;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  .date-divider-marker {
    height: 2px;
    background-color: $black;
    width: calc(100% - 15px);
    display: inline-block;
  }
}
