.page-bop-building {
  .cqui-collapsible-container {
    .container-text {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .error-text {
    background-color: $error-background;
    @include border("all", error);
  }
}
