.modal-type-dry-cleaner-additional-location-information {
  @media (max-height: 940px) {
    .modal .modal-body > .panel .panel-body {
      max-height: 400px;
    }
  }

  .field-row {
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .field-row .field-label {
    width: 60%;
  }
  .field-row .field-input {
    width: 40%;
  }
  .additional-details-location-with-pin {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: auto;
    height: 60px;
    width: 95%;
    border-bottom: 1px solid #005e7a;
    border-width: 99%;

    h2 {
      height: 20px;
      margin-top: 0px;
    }
  }
}
