@use "units";

.cq-base-input {
  .mat-mdc-form-field {
    --mat-mdc-form-field-floating-label-scale: 1;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: calc((var(--cq-input-height) - var(--cq-font-size)) * 1.5);
  }

  /* stylelint-disable selector-class-pattern */
  .mat-mdc-form-field-type-mat-select.mat-form-field-appearance-outline
    .mdc-notched-outline__notch {
    max-width: 100%;
  }
  /* stylelint-enable selector-class-pattern */

  /* stylelint-disable selector-class-pattern */
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    --mat-mdc-form-field-label-transform: translateY(
      calc(var(--cq-input-height) * -1)
    );

    transform: var(--mat-mdc-form-field-label-transform);
    font-size: calc(var(--cq-font-size) * 0.9);
  }
  /* stylelint-enable selector-class-pattern */

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
    min-height: units.px(36);
    padding: units.px(5) 0;
    width: auto;
  }

  .mdc-text-field {
    background-color: var(--cq-form-field-background-color);
    border-radius: units.px(4);
  }

  .mat-mdc-form-field-icon-suffix {
    color: var(--mat-form-field-enabled-select-arrow-color);
  }

  /* stylelint-disable selector-class-pattern */
  .mdc-text-field--focused:not(.mdc-text-field--disabled) {
    .mat-mdc-form-field-icon-suffix {
      color: var(--mdc-outlined-text-field-focus-outline-color);
    }
  }
  /* stylelint-enable selector-class-pattern */

  /* stylelint-disable selector-class-pattern */
  .mdc-text-field--invalid:not(.mdc-text-field--disabled) {
    background-color: var(--cq-form-field-error-background-color);

    .mat-mdc-form-field-icon-suffix {
      color: var(--mdc-outlined-text-field-error-focus-outline-color);
    }
  }
  /* stylelint-enable selector-class-pattern */

  .mat-mdc-form-field
    .mat-mdc-select.mat-mdc-select-invalid
    .mat-mdc-select-arrow {
    color: var(--mdc-outlined-text-field-error-focus-outline-color);
  }
}

.cq-base-input.readonly:not(.pending) {
  .mdc-text-field {
    background-color: var(--cq-form-field-readonly-background-color);
  }
}
