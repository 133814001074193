@import "prerequisites";

.cqui-alert-list {
  .cqui-expansible-container {
    min-height: $expansible-list-alert-min-height;

    .expansible-header {
      .expansible-container-header-icon {
        @include square($toggle-container-icon-height);
      }
    }

    .header-text {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .header-title-text,
    .header-subtitle-text,
    .expand-text {
      display: flex;
      align-items: center;
      line-height: 1.3;
    }

    .header-title-text {
      flex: 0 1 auto;
      max-width: 100%;
    }

    .header-subtitle-text {
      margin: 0 auto;
      flex: 1 0 auto;
      max-width: 100%;
    }
  }

  .alert-item {
    display: flex;
    @include border("bottom", blue);
    margin: 5px 0 5px 45px;
    padding-bottom: 5px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 10px;
      border-bottom: none;
    }

    .alert-icon {
      min-width: 15px;
      @include square(15px);
      @include icon();
      margin-right: 0px;
    }
  }

  .alert-description {
    margin-left: 5px;
    margin-right: 10px;
    font-size: font-size("alert-description");
    width: 100%;
    line-height: 1.25;

    + .cqui-icon-button {
      height: 22px;

      .btn-icon {
        @include square(15px);
      }
    }
  }

  .alert-icon {
    @include square($toggle-container-icon-height);
    min-width: $toggle-container-icon-height;
    margin-right: 7px;
  }

  &.cqui-error-list,
  &.cqui-ineligible-list {
    .cqui-expansible-container {
      .expansible-container {
        background-color: $alert-background-pink;
      }
    }
  }

  &.cqui-caution-list {
    .cqui-expansible-container {
      .expansible-container {
        background-color: $caution-background-yellow;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  .cqui-alert-list {
    // Reduce the padding on the 'expansible-container' to account for situations
    // where the label text takes up considerable space (ie : 'Ineligibles') on smaller screen widths,
    // to prevent unwanted wrapping of label description text
    .expansible-header {
      padding: 5px 10px;
    }

    .expansible-content {
      padding: 0px 15px 0 10px;
    }

    .expansible-header {
      .expansion-indicator {
        margin-right: 5px;
      }
    }

    .expansible-container-header-icon {
      margin-right: 3px;
    }

    .header-subtitle-text {
      font-size: font-size("alert-description-header-subtitle");
      line-height: 1.3;
    }
  }
}
