.auto-lookup {
  .lookup-lookup {
    margin-top: 5px;
    margin-bottom: 5px;

    .field-input {
      position: relative;

      .cqui-icon-button {
        position: absolute;
        top: 0;
        right: 16px;

        background-color: transparent;
        border: none;
      }
    }
  }

  .lookup-basis {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      width: 120px;
      margin-right: 5px;
    }
  }

  .makemodel-column {
    width: 400px;
    text-align: left;
    align-content: left;
  }

  .vin-column {
    text-align: left;
  }

  .section {
    .section-header {
      margin-top: 10px;
    }
  }

  .empty-table {
    height: 150px;
  }
}
