.cqui-main-nav-bar {
  .navbar-nav {
    > li {
      &.available {
        a {
          + cqui-menu-entry-container {
            display: block;
            position: absolute;
            top: 100%;
          }
        }
      }
    }
  }

  ae-secondary-nav-bar {
    display: flex;
    width: 100%;
  }
}
