.cqui-expansible-container {
  .expansible-container {
    min-height: $expansible-list-min-height;
  }

  &.container-alert {
    background-color: $alert-background-pink;
  }

  &.expansible-common {
    .expansible-container {
      background-color: $expansible-tag-group;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .expansible-header {
        padding-top: 5px;
        padding-bottom: 5px;
        height: 100%;
        min-height: $expansible-list-min-height;
      }

      .expansible-content {
        padding-bottom: 0;
        margin-top: 0;

        > ng-transclude {
          margin: 0;

          .section-subsection {
            margin: 0;
            padding: 0;

            &.input-right-template {
              .field-row-nested-group {
                .field-input {
                  padding-right: 0;
                }
              }
            }
          }

          &:not(:empty) {
            margin-bottom: 5px;
          }
        }

        .expansible-content-container {
          margin-bottom: 5px;

          &.expansible-content-child-full-width {
            @include child-contents-full-width();
          }
        }
      }
    }

    .tiles-container {
      margin-bottom: 0;

      .section-header-group {
        .section-header {
          margin-top: 0;
        }
      }
    }
  }

  .expansible-list-tags-container {
    @include border-radius();

    .expansible-container {
      background-color: $expansible-tag-group;
      @include border-radius();
      margin-bottom: 5px;

      .expansible-content {
        > ng-transclude {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
      }
    }

    .expansible-container-header-icon {
      margin-right: 5px;

      &.icon {
        width: 30px;
      }
    }

    .expansible-tag {
      display: flex;
      float: left;
      align-items: center;
      padding: 5px;
      background-color: $expansible-tag;
      margin-right: 5px;
      margin-bottom: 5px;
      padding-bottom: 5px;
      @include border-radius();
      /* Use a value of 0 for height and let flexbox
				stretch the height accordingly
			*/
      height: 0%;
      min-height: 50px;
      flex: 0 1 250px;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
    }

    .expansible-tag-group-row {
      display: flex;
      width: 100%;
    }

    .expansible-tag-icon-group {
      margin-right: 5px;

      .expansible-tag-icon {
        @include expansible-tag-icon();

        section-header-row:nth-last-child(2),
        &:nth-child(2):nth-last-child(1) {
          height: 20px;
        }
      }
    }

    .expansible-text-group {
      display: flex;
      flex-direction: column;
      line-height: 1.2em;
      margin-right: 10px;
      flex: 0 1 auto;

      &.expansible-text-group-full-width {
        padding: 3px;
        width: 100%;
        margin: 0;
        background-color: $expansible-tag-text-notice;
        color: $expansible-tag-text-notice-color;

        @include border-radius();
        margin-top: 5px;

        &:first-child {
          margin-top: 0;
          margin-bottom: 5px;
          flex: 0 1 auto;
          align-self: flex-start;
        }
      }
    }

    .expansible-button-group {
      display: flex;
      align-items: center;

      .btn {
        padding: 0 3px;
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
