.bop-coverages {
  .section-fields-underlined {
    .field-row-nested-group {
      .question-type-input {
        &.select-dropdown {
          width: 75%;
        }
      }

      .field-input {
        select.form-control {
          width: 100%;
        }
      }
    }
  }

  .field-row {
    .field-label {
      @include make-sm-column(6);
      padding-right: 0;
      min-height: $field-min-height;
    }

    .field-input {
      @include make-sm-column(6);
      align-items: center;
      @include field-input-notification();
    }
  }

  .header-row {
    @include make-row();
    .header-description {
      @include make-sm-column(6);
      font-weight: bold;
      text-align: right;
      margin-left: 10px;
    }
  }

  .more-options-coverages {
    .field-row {
      align-items: center;
      padding-bottom: 5px;
      @include border("bottom", gray);
    }
  }

  .addition-insureds-section.section {
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .recommended-coverages-section {
    min-height: 300px;
  }

  .limits-details-container {
    .field-row {
      &.field-divider-related {
        &:after {
          bottom: -7px;
          margin-left: 25%;
          width: 75%;
        }
      }
    }

    .field-row:last-child {
      padding-bottom: 10px;
      @include border("bottom", gray);
    }
  }

  .additional-limit-button {
    background-color: $powder-blue;
  }

  .contact-container .panel-body {
    min-height: 50px;
    overflow-y: hidden;
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;
  }

  .contact-container {
    background-color: transparent;
  }

  .contact-info-field {
    min-height: auto;

    .select-contact-name {
      @include make-sm-column(3);
      padding-left: 0;
      padding-right: 0;
    }

    .select-contact-address {
      @include make-sm-column(3);
      padding-left: 0;
      padding-right: 0;
    }

    .select-contact-search {
      @include make-sm-column(6);
      display: flex;
      padding-left: 0;
      padding-right: 0;
    }

    @include button-group();

    .button-group {
      flex: 1 0 auto;
    }
  }

  .form-control {
    margin-right: 5px;
  }
}
