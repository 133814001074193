@import "@clearquote/ui/layout/common/common";

.tables-container-garage-vehicle {
  @include table-container-dual-columns {
    .table-col {
      &.table-col-garage-vehicle-address,
      &.table-col-garage-vehicle-year-make-vin {
        width: 50%;
      }

      &.table-col-garage-vehicle-address {
        vertical-align: middle;
        width: 25%;
        @include border-radius("top-left");

        .text-group {
          width: 100%;
          max-width: 100%;
        }

        + .table-col-garage-vehicle-year-make-vin {
          .text-line {
            @include class-is-required(5px);
          }
        }
      }

      &.table-col-garage-vehicle-year-make-vin {
        @include border-radius("top-right");

        .table-col-entry-details {
          padding: 0 5px 5px;

          &.info-row,
          &.alert-row {
            .table-col-entry-details-row {
              &:first-child {
                border-bottom: none;
                padding-bottom: 0;

                + .table-col-entry-notification-row {
                  padding-top: 0;
                  justify-content: flex-end;

                  .notification-icon {
                    max-width: 15px;
                    @include square(15px);
                    min-width: 15px;
                  }

                  .notification-text {
                    padding-right: 0;
                  }

                  .notification-field {
                    margin-top: 5px;
                    padding: 0;
                    border: none;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                  }
                }
              }
            }
          }

          @include alert-row("info", "parent child", ".notification-field");
          @include alert-row("alert", "parent child", ".notification-field");
        }

        .text-group {
          + .button-row {
            min-width: $table-col-button-group-min-width;
            display: flex;
            justify-content: flex-end;

            .btn {
              margin-right: 5px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    .table-header-row {
      .table-col-garage-vehicle-year-make-vin {
        text-align: center;

        .table-col-entry-details {
          padding: 0;
          line-height: 1.2;
        }

        .button-row {
          min-width: $table-col-button-group-min-width;
        }
      }
    }
  }
}
