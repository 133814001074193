.page-wc-info {
  .ineligible-location-message-inline-row {
    background-color: $alert-background-pink !important;

    border-right: transparent;
    .no-border {
      border-top: 0;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    .message {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .icon {
        width: 15px;
        height: 15px;
        margin: 5px 5px 5px 0;
        display: inline-block;
        @include image-url("icon-errors.svg");
      }
    }
  }

  .table-container .table .table-body .ineligible-location-message-inline-row {
    .table-col {
      border-right: transparent;

      &:last-child {
        border-bottom-right-radius: 0px;
      }
      &:first-child {
        border-bottom-left-radius: 0px;
      }
    }
  }

  .locations-section {
    .table-wrapper.table-wrapper-background {
      // Override mockup radius
      border-radius: 0px;

      // Add top radius back for first table
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      // Remove top radius for all other tables
      & ~ .table-wrapper.table-wrapper-background {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }

      // Add bottom radius back for last table
      &:last-child {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }

  .icon-forbidden {
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 3px;
  }
}
