@use "@nationwide-bolt/css-core/dist/scss/tokens" as bolt;
@use "units";

:root {
  // General
  --cq-font: proxima-nova, arial, trebuchet, verdana, "MS Sans Serif",
    sans-serif;
  --cq-font-light: proxima-nova-light, arial, trebuchet, verdana,
    "MS Sans Serif", sans-serif;
  --cq-font-size: #{units.px(14.333)};
  --cq-expansion-panel-body-padding: #{units.px(15)};
  --cq-divider-color: rgb(0 0 0 / 12%);

  // Icons
  --cq-icon-size: #{units.px(24)};
  --cq-icon-weight: normal;
  --cq-icon-symbol-fill: 0;
  --cq-icon-symbol-grade: 0;
  --cq-icon-symbol-weight: 400;
  --cq-icon-symbol-optical-sizing: 24;

  // Buttons
  --cq-button-font-size: #{units.px(14)};
  --cq-button-background-color: #005e7a;
  --cq-button-text-color: #fff;
  --cq-checkbox-button-background-color: #b2dae7;
  --cq-checkbox-button-disabled-background-color: rgba(0 0 0 / 12%);
  --cq-checkbox-button-disabled-text-color: rgba(0 0 0 / 38%);
  --cq-checkbox-button-padding: #{units.px(6)} 0;
  --cq-checkbox-button-text-color: black;
  --cq-checkbox-button-selected-background-color: #005e7a;
  --cq-checkbox-button-selected-text-color: white;
  --cq-link-button-font-size: #{units.px(12)};
  --cq-link-button-text-color: #005e7a;

  // Cards
  --cq-card-background-color: #f4f4f4;

  // Errors & Cautions
  --cq-caution-background-color: #fff7d1;
  --cq-caution-border-color: #cfa900;
  --cq-caution-icon-color: #ffcb0e;
  --cq-error-background-color: #fcc;
  --cq-error-border-color: #d51122;
  --cq-error-icon-color: #d84f36;

  // Form Fields
  --cq-form-field-background-color: transparent;
  --cq-form-field-error-background-color: #fdf7f7;
  --cq-form-field-group-gap: 0.3rem;
  --cq-form-field-readonly-background-color: #f4f4f5;
  --cq-form-field-row-gutter: 1rem;
  --cq-form-field-row-label-padding: 2rem;
  --cq-form-field-row-label-width: 50%;
  --cq-form-field-row-label-medium-width: var(--cq-form-field-row-label-width);
  --cq-form-field-row-label-small-width: var(--cq-form-field-row-label-width);
  --cq-input-height: #{units.px(24)};
  --cq-input-max-width: 100%;
  --cq-input-pending-background-color: rgba(229 244 255 / 50%);
  --cq-input-width: #{units.px(225)};

  // We should look to supply our own theme for warning / error treatments
  --mdc-theme-error: #c00c00;
  --mdc-outlined-text-field-error-focus-outline-color: var(--mdc-theme-error);
  --mdc-outlined-text-field-error-focus-label-text-color: var(
    --mdc-theme-error
  );
  --mdc-outlined-text-field-error-hover-outline-color: var(--mdc-theme-error);
  --mdc-outlined-text-field-error-hover-label-text-color: var(
    --mdc-theme-error
  );
  --mdc-outlined-text-field-error-outline-color: var(--mdc-theme-error);
  --mdc-outlined-text-field-error-label-text-color: var(--mdc-theme-error);

  // Dialog
  --cq-dialog-content-background-color: #fff;
  --cq-dialog-content-padding: #{units.px(10)};
  --cq-dialog-margin-left: #{units.px(15)};
  --cq-dialog-margin-right: #{units.px(15)};
  --cq-dialog-margin-bottom: #{units.px(12)};

  // Tabs
  --cq-tab-header-padding-left: 0;

  // Table
  --cq-table-row-background-color: #fff;

  // We should look to supply our own typography for font standardization
  --mat-table-header-headline-font: var(--cq-font);
  --mat-table-header-headline-size: var(--cq-font-size);
  --mat-table-row-item-label-text-font: var(--cq-font);
  --mat-table-row-item-label-text-size: var(--cq-font-size);

  // Colors
  --cq-dark-blue: #005e7a;
  --cq-light-blue: #e5f4ff;
}
