#{$lib-prefix}-field-row {
  width: 100%;

  > ng-transclude {
    min-height: $field-min-height;
  }

  & + #{$lib-prefix}-field-row {
    position: relative;

    &:before {
      @include border("top", gray);
      position: absolute;
      width: calc(
        100% - #{$expansible-container-field-row-question-side-padding}
      );
      top: 0;
      left: #{$expansible-container-field-row-question-side-padding};
    }
  }
}
