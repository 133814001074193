.ae-account-add-products {
  .section-for-account-questions {
    > .section-subsection + .section-subsection {
      margin-top: 0px;
      @include border("top");
    }

    .field-input input {
      width: 167px;
    }
  }
}
