.modal-open .modal .full-screen-modal {
  display: block;
  flex: none;
  width: 100%;
}
.modal-open .modal {
  display: block;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.modal-header .content {
  float: left;
  display: inline-block;
}

.dancing-dots-loader-popup {
  position: relative;
  width: 90px;
  height: 80px;
  display: flex;
  align-items: center;
}

/*Modal on top of another modal*/

.full-screen-modal .modal-dialog.second-modal {
  z-index: 1000001;
}

.full-screen-modal .modal-dialog.second-modal .modal-header {
  padding-top: 10px;
}

/*Wait state modal*/

.full-screen-modal .please-wait.message {
  padding: 22px 0;
}

.full-screen-modal .please-wait .text {
  font-weight: normal;
  margin: 0;
  align-self: center;
}
.full-screen-modal .modal-dialog .please-wait.sm-width {
  width: 400px;
}
.full-screen-modal .modal-content .cqui-expansible-list {
  max-width: 900px;
  min-width: 460px;
}
.please-wait-message {
  margin-left: 10px;
}

/*Delete Building modal*/
.modal-content {
  margin: auto;
}
.modal-content .form-control {
  float: left;
  &.suffix-field {
    display: inline-block;
    width: 17%;
  }
}
.modal-content > span.glyphicon {
  font-size: 500%;
  padding-top: 5px;
}
.modal-main-icon {
  width: 55px;
  height: 55px;
}
.full-screen-modal.common-modal .modal-header > img {
  width: 54px;
  height: 54px;
}
.full-screen-modal.common-modal .modal-content .modal-header {
  padding-top: 10px;
}
.full-screen-modal.common-modal .modal-content .text p {
  font-size: 14pt;
}
.full-screen-modal.common-modal .message-button-row {
  float: right;
  margin-right: 20px;
}
.full-screen-modal.font-size-sm .modal-content .text p {
  font-size: 10.5pt;
}
.full-screen-modal .modal-body-content {
  width: 100%;
  padding: 0px 15px;
  margin-top: 10px;
}

.full-screen-modal .btn .button-text {
  top: 0;
}
.full-screen-modal .btn .link-text {
  top: -1px;
  padding-left: 5px;
  display: inline-block;
}
.full-screen-modal .message-button-row {
  margin: auto;
  margin-top: 15px;
  float: left;
  text-decoration: underline;
}
.full-screen-modal .message-button-row .buttons-inner-container {
  margin-left: -5px;
}
.full-screen-modal .message-button-row .buttons-inner-container img {
  float: left;
  width: 19px;
  height: 19px;
}
.full-screen-modal .message-button-row .buttons-inner-container .btn {
  float: left;
}
.full-screen-modal .message-button-row .buttons-inner-container a {
  line-height: 1.2em;
}
.full-screen-modal .message-button-row .btn {
  height: 26px;
  margin-left: 7px;
  padding: 0 10px;
}
.full-screen-modal .gray-background-overlay {
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  background-color: #000;
  z-index: 80;
  left: 0;
}
.full-screen-modal .modal-dialog {
  z-index: 1000;
  width: auto;
  display: flex;
}

.full-screen-modal .modal-content {
  min-width: 460px;
  max-width: 900px;
  background-color: #e5f4ff;
  display: flex;
  align-items: flex-start;
  margin: auto;
  flex: 0 0 auto;
  padding: 17px 0px;
  border-radius: 3px;
  border: 0 solid;
  box-shadow: -3px 4px 3px 0 rgba(50, 50, 50, 0.9);
  flex-direction: column;
}

.full-screen-modal .modal-content .main-header {
  color: #000;
  font-size: 15pt;
  position: relative;
  top: 1px;
}
.full-screen-modal .modal-content .modal-form-content {
  margin-top: 20px;
  width: calc(100% - 30px);
}
.full-screen-modal .modal-content.please-wait {
  padding: 0;
}
.full-screen-modal
  .modal-content
  .modal-form-content.editing-building-addr-info {
  margin-top: 0px;
}
.full-screen-modal .modal-content .modal-form-content .form {
  background-color: #e5f4ff;
}
.full-screen-modal .modal-content .modal-header {
  padding: 0px 15px 0px;
  border-bottom: 0px none;
  margin: auto;
  display: flex;
  align-items: flex-start;
  font-weight: normal;
}
.full-screen-modal .modal-content .modal-header p {
  float: left;
  line-height: 1.15em;
  margin-bottom: 0;
}
.full-screen-modal .modal-content .text {
  padding-left: 11px;
  margin: auto;
  flex: 1 1 0%;
  width: 300px;
}
.full-screen-modal .modal-content .text span {
  margin-bottom: 10px;
  max-width: 400px;
  float: left;
}
.section-header-sub-text {
  font-weight: bold;
  line-height: 30px;
}
.title-middle {
  font-weight: bold;
}
.account-namedinsured-form {
  background: white;
  padding: 20px 0px;
  border-radius: 5px;
  border: 1px solid #999;
}
.section-header-row {
  &.endorsement-header {
    display: block;
    .section-header {
      width: 250px;
      margin-top: 20px;
    }
  }
}

.add-address-popup {
  .section-header-icon {
    height: 45px !important;
  }

  .section-split-subsections-half
    .subsection.subsection-map
    .map-pins-row
    .map-pin-group
    + .map-pin-group {
    margin-left: 7px;
  }

  .ae-location {
    .section-header-row {
      .section-header-group {
        h2 {
          float: left;
        }
        .address-not-verified {
          margin-bottom: 0px;
          margin-top: 10px;
        }
      }
    }
  }
}

.advanced-notice-modal {
  &.notification-field {
    border: 1px solid #ccc;
    border-color: #005e7a;
    border-radius: 3px;
    background-color: white;
    padding: 5px;
    margin-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
  }
}

a {
  color: #005e7a;
}

.nc-form-hover {
  margin-left: -10px;
}

.modal.modal-type-state-selection .button-option-container {
  padding-right: 10px;
}

.button-edit-left {
  float: left;
  text-align: left;
}
