.button-icon-circle {
  @include button-icon-sm;
  @include image-url("icon-radio-unselected.svg");
}

.button-icon-circle-white {
  @include button-icon-sm;
  @include image-url("icon-radio-unselected-white.svg");
}

.button-icon-circle-selected {
  @include button-icon-sm;
  @include image-url("icon-radio-selected.svg");
}

.button-icon-circle-unselected {
  @include button-icon-sm;
  @include image-url("icon-radio-selected-black.svg");
}

.button-icon-square-white {
  @include button-icon-sm;
  @include image-url("icon-radio-unselected-white.svg");
}

.button-icon-square-black {
  @include button-icon-sm;
  @include image-url("icon-checkbox-unselected.svg");
}

.button-icon-square-white-checked {
  @include button-icon-sm;
  @include image-url("icon-checkbox-selected.svg");
}

.btn {
  $button-caution-text: $black;
  $button-error-text: $black;
  height: $field-min-height;
  padding: 0 9px;
  @include border-radius("all", button);
  box-shadow: none;

  &[disabled] {
    .button-icon {
      &.button-icon-circle-selected {
        @include button-icon-sm;
        @include image-url("icon-radio-selected.svg");
      }
    }
  }

  &.disabled,
  &[disabled] {
    &:active,
    &:hover {
      background-color: $cerulean;
    }
  }

  &.unchangeable {
    cursor: not-allowed;

    .button-icon {
      opacity: 0.5;
    }
  }

  @include btn-modes();
}

.btn-primary {
  color: $btn-primary-text;
  background-color: $btn-primary;

  .icon-edit {
    @include button-icon-med;
  }

  .icon-delete {
    @include button-icon-med;
  }

  .icon-copy-white {
    @include button-icon-med;
  }

  &[disabled],
  &.disabled {
    background-color: $btn-primary;

    &:hover,
    &:focus,
    &:active {
      background-color: $btn-primary;
    }

    &:hover,
    &:active {
      background-color: $btn-primary;
    }
  }

  &.btn-launch-url {
    background-color: $btn-default;

    &.disabled,
    &[disabled] {
      background-color: $btn-default;
    }
  }

  &.btn-default,
  &.btn-launch-url {
    @include btn-default();
  }
}

.btn-med {
  text-align: center;
  margin-right: #{map-get($btn-med, margin-right)}px;
}

.btn-default {
  min-width: #{$btn-default-width}px;
  background-color: $btn-default;

  @include btn-default();
}

.btn-error {
  @include btn-error();
}

.btn-sm {
  padding: 0 3px;
}

.negative,
.affirmative {
  &.btn-selected {
    background-color: $btn-selected;
    color: $btn-selected-text;
  }
}

.button-contents {
  display: flex;
  align-items: center;
  height: 100%;

  .add-icon {
    float: left;
    max-width: none;
    min-width: 19px;
    @include square(19px);
    @include icon();
    @include image-url("icon-add.svg");
    margin-right: 3px;
  }
}

.btn-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.button-icon {
  background-repeat: no-repeat;
  @include square(10px);
  margin: auto;
  display: inline-block;
  position: relative;
  background-size: 10px auto;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.button-row {
  .button-row-group {
    &:first-child {
      float: left;
    }

    &:last-child {
      float: right;
    }

    .btn {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.button-row-left {
    .button-row-group {
      width: 100%;
      justify-content: flex-start;
      display: flex;
    }
  }

  &.button-row-vertical {
    display: flex;
    flex-direction: column;
  }

  @include button-row-blue-overline();
}

.button-group {
  .btn {
    margin-bottom: 5px;

    &,
    &[disabled] {
      .button-icon {
        &.icon-checkbox {
          @include image-url("icon-checkbox-unselected.svg");
        }
      }

      &.entity-product-businessowners {
        .icon-entity {
          @include image-url("icon-bop-black.svg");
        }
      }

      &.entity-product-workers-comp {
        .icon-entity {
          @include image-url("icon-wc-black.svg");
        }
      }

      &.entity-product-commercial-auto {
        .icon-entity {
          @include image-url("icon-auto-black.svg");
        }
      }

      &.entity-product-property {
        .icon-entity {
          @include image-url("icon-property-black.svg");
        }
      }

      &.entity-product-inland-marine {
        .icon-entity {
          @include image-url("icon-inland-marine-black.svg");
        }
      }

      &.entity-product-general-liability {
        .icon-entity {
          @include image-url("icon-general-liability-black.svg");
        }
      }

      &.entity-product-farm {
        .icon-entity {
          @include image-url("icon-farm-black.svg");
        }
      }

      &.entity-product-crime {
        .icon-entity {
          @include image-url("icon-crime-black.svg");
        }
      }

      &.entity-product-umbrella {
        .icon-entity {
          @include image-url("icon-umbrella-black.svg");
        }
      }

      &.entity-product-human-services {
        .icon-entity {
          @include image-url("icon-human-service-black.svg");
        }
      }

      &.entity-product-senior-living {
        .icon-entity {
          @include image-url("icon-senior-living-black.svg");
        }
      }

      &.entity-product-cop {
        .icon-entity {
          @include image-url("icon-cop-black.svg");
        }
      }

      &.entity-product-professional-liability {
        .icon-entity {
          @include image-url("icon-pl-black.svg");
        }
      }

      &.entity-product-nc-facility-auto {
        .icon-entity {
          @include image-url("icon-nc-facility-auto-black.svg");
        }
      }
    }

    &.interacted {
      background-color: $btn-selected;
      color: $btn-selected-text;

      .button-icon {
        &.icon-checkbox {
          @include image-url("icon-checkbox-checked.svg");
        }
      }

      &.entity-product-businessowners {
        .icon-entity {
          @include image-url("icon-bop-white.svg");
        }
      }

      &.entity-product-workers-comp {
        .icon-entity {
          @include image-url("icon-wc-white.svg");
        }
      }

      &.entity-product-commercial-auto {
        .icon-entity {
          @include image-url("icon-auto-white.svg");
        }
      }

      &.entity-product-property {
        .icon-entity {
          @include image-url("icon-property-white.svg");
        }
      }

      &.entity-product-inland-marine {
        .icon-entity {
          @include image-url("icon-inland-marine-white.svg");
        }
      }

      &.entity-product-general-liability {
        .icon-entity {
          @include image-url("icon-general-liability-white.svg");
        }
      }

      &.entity-product-farm {
        .icon-entity {
          @include image-url("icon-farm-white.svg");
        }
      }

      &.entity-product-crime {
        .icon-entity {
          @include image-url("icon-crime-white.svg");
        }
      }

      &.entity-product-umbrella {
        .icon-entity {
          @include image-url("icon-umbrella-white.svg");
        }
      }

      &.entity-product-human-services {
        .icon-entity {
          @include image-url("icon-human-service-white.svg");
        }
      }

      &.entity-product-senior-living {
        .icon-entity {
          @include image-url("icon-senior-living-white.svg");
        }
      }

      &.entity-product-cop {
        .icon-entity {
          @include image-url("icon-cop-white.svg");
        }
      }

      &.entity-product-professional-liability {
        .icon-entity {
          @include image-url("icon-pl-white.svg");
        }
      }

      &.entity-product-nc-facility-auto {
        .icon-entity {
          @include image-url("icon-nc-facility-auto-white.svg");
        }
      }
    }
  }

  &.button-group-row {
    display: flex;
    button:not(:first-of-type) {
      margin-left: 5px;
    }
  }

  &.button-group-confirmation {
    @include border("bottom", blue);
    margin-bottom: 10px;
    padding-bottom: 5px;
  }

  &.button-group-lg {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    button {
      @include font-secondary-fallback();
      height: 36px;
      width: calc(33% - 3px);
      margin-bottom: 7px;
      margin-right: 5px;

      &[disabled] {
        background-color: $medium-gray;
        color: $ship-gray;
      }
    }

    .btn-text {
      font-weight: 900;
      font-size: font-size("btn-text");
      text-align: left;
      line-height: 0.95;

      white-space: normal;
      min-width: 0;

      flex: 1;
    }

    .button-icon-group {
      display: flex;
      align-items: center;

      .button-icon {
        @include square(15px);
        margin-right: 5px;
        @include icon();
        flex-shrink: 0;

        + .button-icon {
          @include square(25px);
        }

        &:only-child {
          @include square(25px);
        }
      }
    }

    &:after {
      content: "";
      width: calc(33% - 3px);
    }

    button:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}

.btn[cqui-error-tool-tip-severe-error="true"],
.#{$lib-prefix}-button-severe-error {
  @include severe-button-error();

  &.btn-default {
    @include severe-button-error();

    &:hover,
    &:focus,
    &:active {
      @include severe-button-error();
    }

    &:active {
      &:focus {
        @include severe-button-error();
      }
    }
  }

  &:hover,
  &:focus,
  &:active {
    @include severe-button-error();
  }

  &:active {
    &.btn {
      @include severe-button-error();

      &.btn-primary {
        @include severe-button-error();
      }
    }
  }
}
