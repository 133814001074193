.wc-add-location {
  .building-address-section {
    width: 750px;
    margin: 0 auto;
    padding-left: 69px;
    padding-bottom: 3px;
    font-size: 10pt;
  }
  .address-details-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    padding-right: 0;
    height: 100%;
  }
  .building-address-suggestion {
    height: 50%;
    padding-right: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .building-address-suggestion .sub-header-row .description {
    font-size: 12pt;
  }
  .additional-information-required .building-address-suggestion.one-entry {
    height: 25%;
  }
  .building-address-suggestion.one-entry .suggested-address-container {
    width: 100%;
    float: left;
  }

  .enter-address.enable-edit {
    height: auto;
    margin-top: 0;
  }
  .pin-with-address {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
  }
  .pin-with-address-text {
    display: flex;
    align-items: center;
  }
  .pin-icon {
    height: 40px;
    width: 28px;
    margin: 0 3px;
  }
  .pin-icon-legend {
    width: 14px;
    height: 20px;
    margin-right: 5px;
    margin-left: 10px;
  }
  .pin-icon-legend:first-child {
    margin-left: 0;
  }
  .map-legend {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
  }
  .suggested-address-container {
    height: 100%;
    overflow-y: auto;
    padding-left: 0;
    padding-right: 0;
  }
  .suggested-address-entry,
  .entered-address-entry {
    float: left;
    width: calc(100% - 5px);
    padding: 10px 0 10px;
    border-bottom: 1px solid #0086ae;
  }
  .suggested-address-entry.active {
    background: #e3e3e3;
  }
  .suggested-address-entry .address-description {
    color: #005e7a;
    padding-right: 5px;
  }
  .entered-address-entry .pin-with-address {
    flex-direction: column;
  }
  .suggested-address-entry:last-child,
  .entered-address-entry:last-child {
    border-bottom: none;
  }
  .entered-address-entry .button-group {
    margin-right: 0;
  }
  .address-description {
    text-transform: uppercase;
    margin-bottom: 0;
    margin-left: 10px;
  }
  .display-inline-flex {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: flex;
  }
}
