.apply-pricing {
  .cqui-collapsible-container {
    .heading-content {
      outline: none;
    }
  }
  .row-box-border {
    @include make-row();
    .field-border {
      @include make-sm-column(10);
      border-top: 1px solid #0086ae;
      padding-bottom: 5px;
      margin-top: 5px;
    }
  }

  .cqui-collapsible-container-group
    .cqui-collapsible-container
    .container-text {
    align-items: center;
  }

  .help-text {
    width: 63%;
    float: left;
  }
  .btn-edit-pricing {
    float: right;
    margin-right: 10px;
  }
}
@media (min-width: $screen-sm-min) {
  .apply-pricing .row-box-border .field-border {
    margin-left: 4.5%;
    margin-right: -5px;
    width: 84.2%;
  }
  .field-credit {
    .form-control {
      padding: 2px 0px 3px 20%;
      min-width: 70px;
    }
  }
}
@media (min-width: $screen-md-min) {
  .apply-pricing .row-box-border .field-border {
    margin-left: 7%;
    width: 84.5%;
  }
  .field-credit {
    .form-control {
      padding: 2px 0px 3px 20%;
      min-width: 70px;
    }
  }
}
@media (min-width: $screen-lg-min) {
  .apply-pricing .row-box-border .field-border {
    margin-left: 8.2%;
    width: 84.8%;
  }
  .field-credit {
    .form-control {
      padding: 2px 0px 3px 30%;
    }
  }
}
