@import "prerequisites";

#{$lib-prefix}-dropdown {
  display: inline-block;
  color: black;
  .dropdown-menu-button-container {
    .btn {
      &.#{$lib-prefix}-button-caret {
        .btn-content {
          &:after {
            content: "\25BC";
            position: relative;
            font-size: font-size("button-caret");
            margin-left: 5px;
            transform: scale(1, 0.7);
          }
        }
      }

      &:not(.dropdown-menu-button-has-text) {
        margin-right: 5px;

        &.#{$lib-prefix}-button-caret {
          .btn-content {
            &:after {
              margin: 0 3px;
            }
          }
        }
      }
    }
  }
}

.#{$lib-prefix}-dropdown-menu {
  $dropdown-menu-border-radius: 3px;
  $dropdown-menu-inner-border-radius: $dropdown-menu-border-radius - 1px;

  box-shadow: 1px 1px 3px 0px rgba(50, 50, 50, 0.75);
  text-align: left;
  list-style: none;
  min-width: 200px;
  max-width: 100%;
  @include border("all", gray);
  @include border-radius("all", $dropdown-menu-border-radius);

  &.dropdown-menu-entry-container {
    display: block;
    padding: 0;
  }

  > ng-transclude {
    $dropdown-entry-container-section: $light-blue;
    $dropdown-entry-container-section-hover: $light-medium;
    $dropdown-entry-container-section-text-hover: $white;
    $dropdown-entry-container-section-content-icon-width: 20px;
    $dropdown-entry-container-section-content-related-indent: $dropdown-entry-container-section-content-icon-width +
      20px;

    display: flex;
    flex-direction: column;
    padding: 0;
    border: none;
    border-radius: $dropdown-menu-border-radius;
    background-color: $dropdown-entry-container-section;

    #{$lib-prefix}-dropdown-entry-container {
      .dropdown-menu {
        min-width: 250px;
        max-width: 375px;
        position: fixed;
        display: block;
        padding: 0;
        border-radius: 0;
        @include border("all", gray);
        box-shadow: 2px 2px 3px 0px $gray;
      }
    }

    #{$lib-prefix}-dropdown-entry-container-section {
      display: flex;
      width: 100%;

      &:hover {
        [role="menusection"] {
          background-color: $dropdown-entry-container-section;
        }

        #{$lib-prefix}-dropdown-entry-container-section-content {
          &:not(:hover) {
            background-color: $dropdown-entry-container-section;
          }
        }
      }

      + #{$lib-prefix}-dropdown-entry-container-section {
        @include border("top", gray);
      }

      [role="menusection"] {
        background-color: $dropdown-entry-container-section;
        display: flex;
        padding: 0;
        width: 100%;
        @include border-radius("all", $dropdown-menu-border-radius);

        ng-transclude {
          display: flex;
          width: 100%;
          flex-direction: column;
        }

        #{$lib-prefix}-dropdown-entry-container-section-content {
          display: flex;
          align-items: center;
          padding: 5px 10px;
          line-height: 1.1;

          > #{$lib-prefix}-icon {
            @include square(
              $dropdown-entry-container-section-content-icon-width
            );
            min-width: $dropdown-entry-container-section-content-icon-width;
            @include icon-margin("right");
          }

          &:first-child {
            @include border-radius(
              "top-left-right",
              #{$dropdown-menu-inner-border-radius}
            );
          }

          &:last-child {
            @include border-radius(
              "bottom-left-right",
              #{$dropdown-menu-inner-border-radius}
            );
          }

          &:hover:not(.info-content) {
            cursor: pointer;
            background-color: $dropdown-entry-container-section-hover;
            color: $dropdown-entry-container-section-text-hover;
          }

          &:disabled,
          &[disabled],
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }

          + #{$lib-prefix}-dropdown-entry-container-section-content {
            @include border("top", gray);

            &.#{$lib-prefix}-dropdown-entry-section-content-related {
              width: 100%;
              padding-left: $dropdown-entry-container-section-content-related-indent;
            }
          }
        }

        .#{$lib-prefix}-dropdown-entry-container-section-content-container {
          > .section {
            padding: 15px;
          }

          .section-group {
            display: flex;

            .section-header-row {
              &.section-header-row-large {
                margin-top: 0;
                padding-top: 0;
                padding-left: 0;
              }
            }

            .section-subgroup {
              flex: 1 0 auto;

              &.section-subgroup-buttons {
                display: flex;
                align-items: center;
                flex: 0 1 auto;
              }
            }
          }
        }

        #{$lib-prefix}-dropdown-entry-container-section-text {
          flex: 1 1 auto;
          margin-right: 5px;
        }

        #{$lib-prefix}-dropdown-entry-container-section-info {
          min-height: 30px;
          height: calc(100% + 10px);
          margin-top: -5px;
          margin-bottom: -5px;
          margin-right: -10px;
          padding-left: 10px;
          padding-right: 10px;
          border-left: 1px solid #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $dropdown-entry-container-section;
        }
      }

      &:not(:only-child) {
        &:first-child {
          .#{$lib-prefix}-dropdown-entry-container-section-content-container {
            #{$lib-prefix}-dropdown-entry-container-section-content {
              &:first-child {
                &:hover {
                  @include border-radius(
                    "top-left-right",
                    #{$dropdown-menu-inner-border-radius}
                  );
                }
              }

              &:last-child {
                &:hover {
                  border-radius: 0;
                }
              }
            }
          }
        }

        &:last-child {
          .#{$lib-prefix}-dropdown-entry-container-section-content-container {
            #{$lib-prefix}-dropdown-entry-container-section-content {
              &:first-child {
                &:hover {
                  border-radius: 0;
                }
              }

              &:last-child {
                &:hover {
                  @include border-radius(
                    "bottom-left-right",
                    #{$dropdown-menu-inner-border-radius}
                  );
                }
              }
            }
          }
        }
      }
    }
  }

  &.dropdown-menu-entry-custom-content {
    box-shadow: none;
    border: none;
    width: 100%;

    #{$lib-prefix}-dropdown-entry-container-section {
      [role="menusection"] {
        background-color: transparent;
        border-radius: 0;
      }
    }
  }
}
