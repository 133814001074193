@import "prerequisites";

.cqui-tabs-set {
  $tab-container: $powder-blue;
  $tab-container-hover: $lightest-gray;
  $tab-container-hover-border: $medium-blue;
  $tab-container-hover-text: $black;
  $tab-container-active: $white;
  $tab-container-inactive: $light-medium-blue;
  $tab-container-text: $black;
  $tab-container-active-border-bottom: $white;
  $tab-state-disabled: $disabled-gray;
  $tab-state-disabled-text: $disabled-text-gray;
  $tabs-container-fixed-nav-header: $white;

  margin: 10px 0;

  .cqui-tabs-set {
    &.cqui-tabs-inline-type {
      .cqui-tabs-content-container {
        &.contents-present {
          margin: 10px 0;

          cqui-tabs-contents {
            padding: 0;
          }
        }
      }
    }
  }

  &.cqui-tabs-split-type {
    .no-nav {
      + .contents-present {
        cqui-tabs-contents {
          padding: 0;

          .section-header-row {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  &.cqui-tabs-inline-type {
    .cqui-tabs-nav-header-buttons {
      display: flex;

      .cqui-tabs-nav-tabs {
        flex: 1 0 auto;
        display: flex;

        cqui-tabs-nav {
          display: flex;
        }
      }
    }
  }

  &.cqui-tabs-fixed-header {
    .tab-contents-wrapper {
      display: inline-block;
    }

    $tabs-container-fix-header-left: 30px;
    $tabs-container-fix-header-shorten-width: 70px;
    $tab-split-container-header-shorten-width: $tabs-container-fix-header-shorten-width -
      $tabs-container-fix-header-left;

    > .cqui-tabs-nav-container {
      position: absolute;
      max-height: $tabs-container-fixed-nav-height;
      left: $tabs-container-fix-header-left;
      max-width: calc(100% - #{$tabs-container-fix-header-shorten-width});
      background-color: $tabs-container-fixed-nav-header;
      margin-top: -10px;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 30px;
        top: -15px;
        background-color: $tabs-container-fixed-nav-header;
        z-index: -1;
      }
    }

    &.cqui-tabs-split-type {
      position: absolute;
      min-height: $tabs-container-fixed-nav-height;
      width: 100%;

      > .cqui-tabs-nav-container {
        left: 15px;
        max-width: 100%;
      }

      + .cqui-tabs-split-type {
        &:not(.cqui-tabs-fixed-header) {
          margin-top: $tabs-container-fixed-nav-height;
        }
      }
    }

    > .cqui-tabs-content-container {
      > cqui-tabs-contents {
        padding-top: $tabs-container-fixed-nav-height;
      }
    }
  }

  @include tabs-wrap();

  .cqui-tabs-nav-container,
  cqui-tabs-nav {
    @include square(100%);
    @include font-base();
  }

  .cqui-tabs-nav-container {
    > .cqui-tabs-nav-tabs {
      > cqui-tabs-nav {
        display: flex;
        @include border("bottom", blue);
      }

      + .nav-buttons {
        @include border("bottom", blue);
      }
    }
  }

  .cqui-tab-text-group {
    display: flex;
    align-items: center;
    position: relative;
    min-height: $tab-text-group-height;

    #{$lib-prefix}-icon {
      > span {
        position: relative;

        > svg {
          position: absolute;
          top: 0;
          left: 0;
          @include square(100%);
        }
      }
    }
  }

  .cqui-tab-text-state {
    display: flex;
    white-space: nowrap;
    align-items: center;
    padding: 5px 7px;
  }

  .cqui-tab-icon {
    @include size(40px, 20px);
    @include icon-margin("right");

    &.tab-state {
      width: 20px;
    }
  }

  .cqui-tab-text {
    line-height: 1.1;
    white-space: normal;
  }

  .cqui-tabs-nav-container
    > .cqui-tabs-nav-tabs
    > cqui-tabs-nav
    .cqui-tab-text-group,
  .cqui-tab-container {
    background-color: $tab-container;
    font-size: font-size("tabs-container");
    margin-right: 5px;
    @include border-radius("top-left-right");
    @include border("top", blue);
    @include border("left", blue);
    @include border("right", blue);
    //opacity: 0.6;
    position: relative;
    color: $tab-container-text;

    &.active {
      background-color: $tab-container-active;
      opacity: 1;
      border-width: 2px;

      &:after {
        content: "";
        background-color: $tab-container-active-border-bottom;
        @include tab-border-after();
      }

      &:hover {
        background-color: $tab-container-hover;

        &:after {
          background-color: $tab-container-hover;
        }
      }
    }

    &.invalid {
      background-color: $alert-background-pink;
      color: $black;
      border-color: #{map-get($border-color, error)};

      &:after {
        background-color: $alert-background-pink;
      }

      &:hover {
        &:after {
          background-color: $alert-background-pink-hover;
        }
      }
    }

    &:hover {
      cursor: pointer;
      background-color: $tab-container-inactive;
      color: $tab-container-hover-text;
      border-color: $tab-container-hover-border;

      &:after {
        @include tab-border-after();
      }

      &.disabled {
        cursor: not-allowed;
      }

      &.invalid {
        background-color: $alert-background-pink-hover;
        border-color: #{map-get($border-color, error)};
      }
    }

    &.disabled {
      background-color: $tab-state-disabled;
      color: $tab-state-disabled-text;
    }

    .cqui-tab {
      padding: 5px 7px;
      display: flex;
    }
  }

  cqui-tabs-contents,
  .cqui-tabs-contents {
    padding: 15px 0;
    display: block;

    .section-header-row {
      margin-bottom: 10px;
    }
  }

  cqui-tabs-contents {
    > .tab-contents-wrapper {
      width: 100%;
      display: inline-block;

      .section-fields-underlined {
        &:not(:last-child) {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }

      .section-subsection {
        + .section-subsection {
          margin-top: 15px;
        }
      }

      .section-split-subsections-half {
        padding: 0;

        > .subsection {
          padding-left: 0;
        }
      }
    }

    cqui-tabs-contents {
      padding: 0;
    }
  }

  @media (min-width: $screen-md) {
    .tab-word-break {
      display: none;
    }
  }
}
