#{$lib-prefix}-field-label {
  .field-description {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    > #{$lib-prefix}-icon {
      @include square(20px);
      @include icon-margin("right");
    }

    @include ng-transclude-full-width();
    @include container-label-error();
  }

  .expansion-indicator {
    margin-right: 10px;

    &.open {
      @include collapse-arrow-expanded();
    }

    &.collapse {
      display: inline;
      @include collapse-arrow-collapsed();
    }
  }

  &.text-bold {
    .field-description {
      @include text-bold();
    }
  }
}
