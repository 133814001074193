@import "prerequisites";

cqui-nav-bar {
  display: flex;
  margin: 10px 0;

  .icon {
    @include icon();
    min-width: 25px;
    height: 30px;
    padding: 0;
    margin-right: 5px;
  }

  ul,
  li {
    list-style: none;
  }

  > ul {
    padding-left: 0;
    display: flex;

    > li {
      @include border("top", "nav-bar-border");
      @include border("bottom", "nav-bar-border");
      overflow: hidden;
      display: flex;

      &:first-child {
        @include border("left", "nav-bar-border");
        @include border-radius("top-left" "bottom-left", navbar);
      }

      &:last-child {
        @include border("right", "nav-bar-border");
        @include border-radius("top-right" "bottom-right", navbar);
      }

      a {
        color: $nav-bar-available-text;
        font-weight: bold;

        &:focus,
        &:hover,
        &:active,
        &:active:hover {
          color: $nav-bar-available-text;
        }
      }

      > a {
        height: 100%;

        > .lister-content {
          display: flex;
          height: 100%;
          align-items: center;
          padding: 10px;
          line-height: 1.2;
        }
      }
    }
  }

  &.nav-bar-common {
    max-height: 50px;
    max-width: 100%;

    > .navbar-nav {
      max-width: 100%;

      > li {
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }

  .navbar-tab {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
  }

  @media (min-width: $screen-sm) {
    &.nav-bar-common {
      > ul.nav {
        > li {
          > a {
            background-color: $nav-bar;
            padding: 0;

            &:hover {
              background-color: $nav-bar-hover;
            }
          }
        }
      }
    }
  }
}
