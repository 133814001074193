@import "@clearquote/ui/lib/assets/scss/prerequisites";

.typeahead.dropdown-menu,
ul[uib-typeahead-popup] {
  z-index: map-get($z-index, typeahead);
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  > li {
    > a {
      white-space: normal;
    }
  }
}

.#{$lib-prefix}-typeahead {
  $typeahead-accent: $cerulean;
  $typeahead-border: $cerulean;
  $typeahead-match-active: $curious;
  $typeahead-match-active-text: $white;
  $typeahead-match-color: $black;

  $typeahead-hint-bar-height: 34px;
  $typeahead-hint-bar: $cerulean;
  $typeahead-hint-bar-text: $white;

  padding: 0;
  border-radius: 0;
  @include border("all", $typeahead-border);
  box-shadow: -4px 5px 3px -2px rgba(95, 95, 95, 0.9);

  .icon-set {
    @include icon-group-set();
  }

  .#{$lib-prefix}-typeahead-list-entries {
    list-style: none;
    padding: 0;

    .#{$lib-prefix}-typeahead-list-code {
      margin: 0 25px 0 10px;
      min-width: 40px;
    }

    .uib-typeahead-match {
      @include zebra-striping-even();
      position: relative;

      &:before {
        content: "";
        position: absolute;
        @include size(5px, 100%);
        background-color: $typeahead-accent;
        cursor: pointer;
        pointer-events: none;
      }

      &.active {
        background-color: $typeahead-match-active;
        a {
          color: $typeahead-match-active-text;
        }
      }

      &:last-child {
        border-radius: 0;
      }

      a {
        color: $typeahead-match-color;
        width: 100%;
        display: flex;
        padding: 7px;
        text-decoration: none;
        padding-left: 15px;

        .no-status-icon {
          position: relative;
          padding-left: 20px;
          padding-right: 0;
          margin-right: 10px;
        }
      }
    }

    &.no-status-in-results {
      .uib-typeahead-match {
        a {
          .no-status-icon {
            padding-left: 0;
            margin-right: 25px;
          }
        }
      }
    }
  }

  &.#{$lib-prefix}-typeahead-hint {
    .#{$lib-prefix}-typeahead-hint-bar {
      min-height: $typeahead-hint-bar-height;
      background-color: $typeahead-hint-bar;
      cursor: default;
      color: $typeahead-hint-bar-text;
      padding: 7px;
      display: flex;
      align-items: center;
      font-style: italic;

      > * {
        flex: 1;
      }
    }

    .#{$lib-prefix}-typeahead-hint-bar-title {
      margin: 0 15px 0 10px;
      flex: 0 1 auto;
    }

    .uib-typeahead-match {
      a {
        > * {
          flex: 1;
          padding-right: 10px;

          &.#{$lib-prefix}-icon {
            height: 20px;
            flex: 0 1 auto;
            padding-right: 0;

            + .#{$lib-prefix}-typeahead-list-code {
              margin-right: 40px;
            }
          }
        }

        .#{$lib-prefix}-typeahead-list-code {
          flex: 0;
        }

        .#{$lib-prefix}-typeahead-list-entry {
          &:nth-child(1):nth-last-child(3) {
            min-width: 85px;
            margin-right: 15px;
          }
        }
      }
    }
  }

  &.#{$lib-prefix}-typeahead-no-button-in-last-column {
    .uib-typeahead-match {
      a {
        .#{$lib-prefix}-typeahead-list-entry {
          &:nth-child(1):nth-last-child(3) {
            min-width: 50px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
