.cqui-main-nav-bar {
  border: none;
  @include border-radius("all", 0);
  color: $main-nav-bar-text;
  margin-bottom: 0;
  flex-shrink: 0;
  background-color: $white;

  ul {
    padding: 0;
  }

  li {
    list-style: none;
  }

  .navbar-container {
    position: relative;
    width: 100%;
    display: flex;
    background-color: $main-nav-bar-container;
  }

  .navbar-nav {
    width: 100%;
    margin: $nav-bar-vertical-gap;
    height: $nav-bar-height;
    display: flex;
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: calc(100% + 1px);
      height: 4px;
      background-color: $ship-gray;
      left: 0;
    }

    &:before {
      top: -3px;
    }

    &:after {
      bottom: -3px;
      z-index: 2;
    }

    > li {
      height: $nav-bar-height;
      flex: 1;

      a {
        line-height: 0;
        color: $medium-gray;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-direction: column;
      }

      .nav-bar-icon-group {
        display: flex;
        min-height: 15px;
        min-width: 25px;
        margin-bottom: 5px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
      }

      .nav-bar-icon {
        @include icon();
        @include size(25px, 20px);
        min-height: 20px;
        min-width: 25px;
        background-position: left center;
      }

      .nav-bar-icon-status {
        @include icon();
        @include square(13px);
        position: absolute;
        bottom: 1px;
        align-self: flex-start;
        left: -7px;
      }

      .nav-bar-content-marker-container {
        width: calc(100% - 15px);
        height: 10px;
        top: 2px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .nav-bar-content-marker {
        margin-right: 5px;
        @include border("top", "nav-bar-indicator-arrow", 5px);
        @include border("left", clear, 5px);
        @include border("right", clear, 5px);
        @include border("bottom", clear, 0);
        bottom: 1px;
        display: block;
        margin-left: 5px;
      }

      .nav-bar-text {
        font-size: font-size("main-nav-bar-text");
        line-height: 1em;
        justify-content: center;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 15px);
        z-index: 2;
      }

      .nav-bar-icon {
        &.icon-person,
        &.icon-account {
          @include image-url("icon-person-white.svg");
        }

        &.icon-bop {
          @include image-url("icon-bop-white.svg");
        }

        &.icon-auto {
          @include image-url("icon-auto-white.svg");
        }

        &.icon-wc {
          @include image-url("icon-wc-white.svg");
        }

        &.icon-gl,
        &.icon-general-liability {
          @include image-url("icon-general-liability-white.svg");
        }

        &.icon-farm {
          @include image-url("icon-farm-white.svg");
        }

        &.icon-prop,
        &.icon-property {
          @include image-url("icon-property-white.svg");
        }

        &.icon-im {
          @include image-url("icon-inland-marine-white.svg");
        }

        &.icon-umb {
          @include image-url("icon-umbrella-white.svg");
        }

        &.icon-review {
          @include image-url("icon-review-quote-white.svg");
        }
      }

      & {
        @include nav-bar-middle-line-gap-offset-cover();
      }

      &.available {
        a {
          color: $main-nav-bar-available-text;

          + #{$lib-prefix}-menu-entry-container {
            position: absolute;
            top: 100%;

            &.potential-menu-indicator {
              position: fixed;
            }
          }
        }

        &.menu-active {
          &:first-child {
            background-color: $nav-bar-hover;
          }

          &:before,
          &:after {
            background-color: $nav-bar-hover;
          }

          .navbar-nav-tab-content {
            a {
              @include nav-bar-tab-square-corners-cover {
                background-color: $nav-bar-hover;
              }
            }
          }
        }

        &:hover {
          &:before,
          &:after {
            background-color: $nav-bar-hover;
          }
        }
      }

      &.visited {
        &:first-child {
          .navbar-nav-tab-content {
            a:before {
              background-color: $main-nav-bar-visited;
            }
          }
        }
      }

      .nav-bar-content-marker {
        @include border("color", "nav-bar-indicator-arrow-active");
        @include border("left", clear, 5px);
        @include border("right", clear, 5px);
        z-index: 2;
      }

      @include nav-bar-direction-arrow-point("non-active");

      &.active {
        flex: 3;
        position: relative;
        display: flex;
        align-items: center;
        background-color: $main-nav-bar-active;

        @include nav-bar-direction-arrow-point("active");

        @include nav-bar-middle-line-gap-offset-cover();

        a {
          color: $main-nav-bar-active-text;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
        }

        .nav-bar-icon-group {
          margin-bottom: 0;
        }

        .nav-bar-content-marker-container {
          width: auto;
          justify-content: flex-start;
          top: 0;
        }

        .nav-bar-text {
          width: auto;
          margin-left: 10px;
          text-transform: uppercase;
          justify-content: flex-start;
          font-size: font-size("main-nav-bar-text-active");
          text-align: left;
        }

        &:not(:first-child) {
          a {
            padding-left: 25px;
            background-color: $nav-bar-page-bar-marker-active-backdrop;
          }
        }

        &:first-child {
          background-color: $nav-bar-active;

          &:hover {
            background-color: $nav-bar-hover;
          }

          .navbar-nav-tab-content {
            a {
              @include nav-bar-tab-square-corners-cover {
                left: 0;
              }
            }
          }
        }

        &:last-child {
          .navbar-nav-tab-content {
            a {
              background-color: transparent;

              @include nav-bar-tab-square-corners-cover {
                right: 0;
              }
            }
          }
        }
      }

      &:not(.available):not(.active) {
        .nav-bar-content-marker {
          @include border("color", "nav-bar-menu-item-disabled");
          @include border("left", clear, 5px);
          @include border("right", clear, 5px);
        }
      }

      // items prior to the current active item
      &:has(~ .active) {
        background-color: $nav-bar-page-bar-marker-before-active;
      }

      &.menu-item-disabled {
        pointer-events: none;

        .icon-toggle {
          background-color: $main-nav-bar-menu-item-disabled;
        }

        .icon-toggle::after,
        .icon-toggle::before {
          border-color: $main-nav-bar-menu-item-disabled;
        }
      }

      &.last-visible-not-first-tab,
      &:last-child {
        overflow: hidden;

        // Prevent the overflow of the arrow pointer on the
        // far right tab entry
        &:before,
        &:after {
          right: 10px;
          width: calc(100% - #{$main-nav-bar-pseudo-width-offset});
          border-right: none;
        }

        .navbar-nav-tab-content {
          a {
            @include border-radius("top-right" "bottom-right");

            &:before {
              z-index: 1;
            }

            &:hover {
              @include nav-bar-tab-square-corners-cover {
                right: 0;
                background-color: $nav-bar-hover;
                z-index: 1;
              }
            }
          }
        }

        &.visited {
          &:not(.menu-active):not(:hover) {
            a {
              @include nav-bar-tab-square-corners-cover {
                right: 0;
                background-color: transparent;
              }

              &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                @include size(50%, 100%);
              }
            }
          }
        }

        &.active {
          &:hover {
            a {
              background-color: $nav-bar-page-bar-marker-active-backdrop;
            }
          }

          &:not(.menu-active):not(:hover) {
            a {
              &:before {
                background-color: $nav-bar-page-bar-marker-active;
              }

              background-color: $nav-bar-page-bar-marker-active-backdrop;
            }
          }
        }
      }

      > a {
        &:first-child {
          @include border-radius("top-left" "bottom-left");
        }

        &:last-child {
          @include border-radius("top-right" "bottom-right");
        }
      }

      &:not(:first-child):not(:last-child) {
        .navbar-nav-tab-content {
          a {
            &:before,
            &:after {
              content: "";
              position: absolute;
              @include size($main-nav-bar-pseudo-width-offset, 50%);
              background-color: transparent;
              z-index: 4;
            }

            &:before {
              top: 0;
              right: -6px;
              @include nav-bar-direction-arrow-skew-x-bottom("negative");
              width: calc(100% - 5px);
            }

            &:after {
              top: 50%;
              right: -6px;
              @include nav-bar-direction-arrow-skew-x-bottom();
            }
          }
        }
      }

      &:first-child:not(:last-child) {
        .navbar-nav-tab-content {
          a {
            position: relative;

            &:before,
            &:after {
              content: "";
              position: absolute;
              @include size($main-nav-bar-pseudo-width-offset, 50%);
              background-color: transparent;
              z-index: 2;
            }

            &:after {
              right: -15px;
              height: 100%;
              width: 31px;
              @include nav-bar-direction-arrow-skew-x-bottom();
              top: 1px;
            }
          }
        }

        + li {
          .navbar-nav-tab-content {
            a {
              position: relative;

              &:before {
                width: 100%;
              }
            }
          }
        }
      }

      &:first-child:not(.active) {
        &:hover {
          .navbar-nav-tab-content {
            a {
              @include nav-bar-tab-square-corners-cover {
                left: 0;
                background-color: $nav-bar-hover;
              }
            }
          }
        }

        &.visited:not(.menu-active):not(:hover) {
          .navbar-nav-tab-content {
            a {
              background-color: $main-nav-bar-visited;

              @include nav-bar-tab-square-corners-cover {
                left: 0;
                background-color: $main-nav-bar-visited;
              }
            }
          }
        }
      }

      &.disabled {
        opacity: $nav-bar-disabled-opacity;
        z-index: 2;

        position: relative;
        cursor: not-allowed;

        &:hover {
          &:before,
          &:after {
            background-color: transparent;
          }
        }

        .navbar-nav-tab {
          &:before {
            content: "";
            position: absolute;
            width: 50%;
            height: 100%;
            z-index: 0;
          }
        }

        .navbar-nav-tab-content {
          position: relative;

          &:before,
          &:after {
            content: "";
            position: absolute;
            width: 41px;
            height: 13px;
            z-index: 10;
            transform-origin: 0 100%;
            cursor: not-allowed;
          }

          &:before {
            right: -42px;
            bottom: 1px;
            @include nav-bar-direction-arrow-skew-x-bottom-disabled("before");
          }

          &:after {
            right: -28px;
            top: -4px;
            @include nav-bar-direction-arrow-skew-x-bottom-disabled("after");
          }

          a {
            cursor: not-allowed;
            pointer-events: none;
          }
        }
      }
    }
  }

  .nav-tab {
    &.nav-tab-description {
      flex: 1 0 50%;
      color: $nav-bar-page-bar-description-text;

      &:before,
      &:after {
        display: none;
      }

      .nav-bar-text {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        padding-left: 20px;
        line-height: 1.2;
      }
    }

    &.navbar-nav-tab-no-menu {
      pointer-events: none;
    }
  }

  .navbar-nav-tab {
    padding: 0;
    @include square(100%);
    display: flex;
    list-style: none;
    flex-direction: column;

    .navbar-nav-tab-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      a {
        text-decoration: none;
        height: 100%;
        padding: 5px 15px;
        position: relative;
      }
    }
  }

  .navbar-brand {
    align-self: center;
    flex: 1 0 auto;
    padding: 0;
    padding-right: 5px;
    min-height: $brand-height;
  }

  .navbar-brand-link {
    height: 100%;
    padding: 4px 30px 4px 15px;
    display: inline-block;

    img,
    .nationwide-brand {
      display: block;
      height: 100%;
    }
  }

  .navbar-info {
    margin: 2px 10px;
    padding: 5px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
    align-self: center;
    line-height: 1.2;

    p {
      margin-bottom: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-wrap: wrap;
      max-width: 350px;
      margin: 0;
      color: $nav-bar;
      font-size: font-size("main-nav-bar-info");
    }

    .main-form-address-text {
      &:first-child {
        font-weight: 600;
        @include font-light();
        font-size: font-size("main-nav-bar-main-form-address-text");
        margin-bottom: 2px;
      }
    }

    + #{$lib-prefix}-radio-buttons-set {
      margin: 2px 0;
      padding-left: 10px;
      @include border("left", $radio-btn-set-border);
      display: flex;
      align-items: center;

      .radio-buttons-set-description {
        color: $main-nav-bar-radio-button-set-text;
      }

      .radio-button-set-buttons {
        .btn {
          display: flex;
          height: 20px;
          text-align: center;
          line-height: 0;
          align-items: center;
        }
      }

      + cqui-info-indicator {
        margin: 7px 5px;
      }
    }
  }

  .navbar-brand-large {
    display: inline;
  }

  .nationwide-brand {
    @include nationwide-white-logo();
    @include icon();
    width: 125px;
  }

  .navbar-pagebar {
    min-height: $nav-bar-page-bar-height;
    max-height: $nav-bar-page-bar-height;
    width: 100%;
    background-color: $nav-bar-page-bar;
    display: flex;
    position: relative;

    .navbar-pagebar-page-container-group {
      min-height: $nav-bar-page-bar-height;
      display: flex;
      height: 100%;
      flex: 1 0 auto;
      justify-content: center;

      &:first-child {
        flex: 0 1 auto;
        flex-direction: column;
        padding: 5px 5px 5px 15px;
      }

      &.navbar-pagebar-page-container-header-group {
        line-height: 1.2;
        flex: 1 0 30%;
        max-width: 30%;
      }

      &.navbar-pagebar-page-container-nav-group {
        // Flex end should be removed when there are only about three
        // items. Use as a rough estimate as when there will be enough width space
        // to prevent text overflows
        justify-content: flex-end;
      }

      max-width: 70%;
      flex: 1 0 70%;

      .navbar-pagebar-header-group {
        display: flex;
        color: $nav-bar-page-bar-header-group;

        $nav-bar-page-bar-header-icon-width: 25px;
        $nav-bar-page-bar-header-icon-margin-right: 10px;
        $nav-bar-page-bar-header-icon-width-use: $nav-bar-page-bar-header-icon-width +
          $nav-bar-page-bar-header-icon-margin-right;

        .navbar-pagebar-header {
          font-size: font-size("main-nav-bar-pagebar-header");
          margin-right: 5px;
          display: flex;
          align-items: center;
          white-space: nowrap;
          word-break: break-word;
        }

        .navbar-pagebar-subheader {
          font-size: font-size("main-nav-bar-page-bar-subheader");
          @include text-overflow();
          margin-left: $nav-bar-page-bar-header-icon-width-use;
        }

        .navbar-pagebar-header-icon {
          @include icon();
          @include square($nav-bar-page-bar-header-icon-width);
          min-width: $nav-bar-page-bar-header-icon-width;
          @include icon-margin(
            "right",
            $nav-bar-page-bar-header-icon-margin-right
          );
        }
      }
    }

    .navbar-pagebar-page-container {
      min-height: $nav-bar-page-bar-height;
      height: auto;
      width: 100%;
      display: flex;
      align-items: stretch;
      align-content: stretch;

      .pagebar-menu-entry-content {
        font-size: font-size("main-nav-bar-page-bar-menu-entry");
        display: flex;
        align-items: center;

        a {
          color: $nav-bar-page-bar-text-active;
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: $nav-bar-margin-left-offset;
          padding-right: $nav-bar-page-bar-spacing;
          position: relative;
          z-index: 2;

          @include nav-bar-direction-arrow-point-update;

          &:before,
          &:after {
            pointer-events: all;
            width: calc(100% + #{$nav-bar-margin-left-offset});
            z-index: -1;
          }

          &:hover {
            // Ensure anchors without hrefs are viewed a "clickable"
            cursor: pointer;
          }
        }
      }

      .pagebar-menu-entry {
        position: relative;

        &.active {
          @include nav-bar-tab-horizontal-seam-cover($nav-bar-page-bar-active);

          .pagebar-menu-entry-content {
            a {
              color: $nav-bar-page-bar-text-active;
              text-transform: uppercase;

              &:before,
              &:after {
                background-color: $nav-bar-page-bar-active;
              }
            }
          }

          &:first-child {
            a {
              background-color: $nav-bar-page-bar-active;
            }
          }
        }

        &.inactive {
          @include nav-bar-tab-horizontal-seam-cover(
            $nav-bar-page-bar-inactive
          );

          .pagebar-menu-entry-content {
            a {
              background-color: $nav-bar-page-bar-inactive;

              &:before,
              &:after {
                background-color: $nav-bar-page-bar-inactive;
              }
            }
          }
        }

        &:hover {
          .pagebar-menu-entry-content {
            position: relative;

            a {
              background-color: $nav-bar-page-bar-hover;
              color: $nav-bar-page-bar-hover-text;

              &:before,
              &:after {
                background-color: $nav-bar-page-bar-hover;
              }
            }

            &:hover {
              @include nav-bar-tab-horizontal-seam-cover(
                $nav-bar-page-bar-hover
              );
            }
          }
        }
      }

      > .navbar-pagebar-page {
        :not(:only-child).pagebar-menu-entry {
          &:last-child {
            .pagebar-menu-entry-page-marker-container {
              width: $nav-bar-page-bar-marker-width;
            }

            .pagebar-menu-entry-page-marker-line {
              display: none;
            }
          }
        }
      }

      &:last-child {
        .pagebar-menu-entry-page-marker-container {
          width: $nav-bar-page-bar-marker-width;

          > .pagebar-menu-entry-page-marker-line {
            display: none;
          }
        }
      }

      &.disabled {
        cursor: default;

        .pagebar-menu-entry-content {
          .pagebar-menu-entry-text {
            &:hover {
              cursor: default;
            }
          }
        }
      }
    }

    .navbar-pagebar-page-groupings {
      display: flex;
      height: 100%;
    }

    .navbar-pagebar-page {
      color: $nav-bar-page-bar-text;
      display: flex;
      padding: 2px 0;
      line-height: 1.2;
      white-space: nowrap;
      align-items: center;
      height: auto;
      min-height: 100%;

      .pagebar-menu-entry {
        display: flex;
        align-items: stretch;
        height: auto;
        min-height: $nav-bar-page-bar-tab-min-height;

        &.active {
          @include font-base();
          font-weight: 700;
        }

        &:first-child {
          a {
            padding-left: 20px;
            margin-left: 0;

            &:before,
            &:after {
              width: 100%;
            }
          }
        }

        &:last-child {
          padding-right: 0;

          a {
            &:before,
            &:after {
              border-right-color: transparent;
            }
          }
        }

        &.disabled {
          opacity: 0.5;

          &:hover {
            background-color: transparent;

            .pagebar-menu-entry-text {
              cursor: not-allowed;
              background-color: $nav-bar-page-bar-inactive;
              color: $nav-bar-page-bar-text;

              &:before,
              &:after {
                background-color: $nav-bar-page-bar-inactive;
              }
            }
          }
        }

        .pagebar-menu-entry-content {
          height: auto;
          min-height: $nav-bar-page-bar-tab-min-height;
          flex: 1 0 auto;
        }
      }

      .pagebar-menu-entry-text {
        line-height: 1.1;
        text-decoration: none;
        position: relative;

        &:before {
          content: "";
          top: 0;
          @include square(100%);
        }
      }
    }

    &:before,
    &:after {
      content: " ";
      left: 0;
      height: $nav-bar-page-bar-top-spacing;
      width: 100%;
      position: absolute;
      background-color: $nav-bar-page-bar;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    &.first-page-bar-tab-active {
      background-color: $nav-bar-page-bar-active;
    }

    &.last-page-bar-tab-active {
      .navbar-pagebar-page-container {
        background-color: $nav-bar-page-bar-active;
      }
    }

    &.single-page-only {
      background-color: $nav-bar-page-bar-active;

      .pagebar-menu-entry {
        &:before {
          display: none;
        }

        .pagebar-menu-entry-content {
          &:before {
            display: none;
          }
        }

        &.active {
          .pagebar-menu-entry-text {
            &:hover {
              background-color: transparent;
              cursor: default;
              color: $nav-bar-page-bar-text-active;

              &:before,
              &:after {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
}

.is-sub-page {
  .navbar-pagebar {
    background-color: $sub-page-nav-bar-page-bar;
    justify-content: space-between;
    @include border("top", "nav-bar-page-bar-top", 1px);

    &:before,
    &:after {
      display: none;
    }

    &.no-pagebar-menu-entries {
      background-color: $sub-page-nav-bar-page-bar-no-page-bar;
    }

    &.pagebar-tab-index-not-first-active {
      background-color: $sub-page-nav-bar-page-bar-active-not-first-active;
    }

    .navbar-pagebar-page-container {
      &.disabled {
        .pagebar-menu-entry {
          position: relative;

          &:before,
          &:after {
            content: "";
            position: absolute;
            width: 34px;
            height: 13px;
            z-index: 10;
            transform-origin: 0 100%;
          }

          &:before {
            right: -36px;
            bottom: 0;
            @include nav-bar-direction-arrow-skew-x-bottom-disabled("before");
          }

          &:after {
            right: -22px;
            top: -4px;
            @include nav-bar-direction-arrow-skew-x-bottom-disabled("after");
          }
        }
      }
    }

    .navbar-pagebar-page {
      padding: 2px 0;

      flex-direction: column;

      .pagebar-menu-entry {
        height: 100%;
        min-height: $nav-bar-page-bar-tab-min-height;
        flex: 1 0 auto;

        padding-right: $nav-bar-page-bar-spacing;

        .pagebar-menu-entry-content {
          &:before {
            display: none;
          }

          a {
            color: $sub-page-nav-bar-page-bar-text;
            margin-left: $sub-page-nav-bar-link-left-offset;
            padding-right: 5px;

            &:before,
            &:after {
              display: block;
              border-right-color: $sub-page-nav-bar-page-arrow-outline;
              width: calc(100% + #{$sub-page-nav-bar-link-left-offset});
            }

            &:before {
              top: 0;
            }
          }
        }

        &.active {
          .pagebar-menu-entry-content {
            a {
              color: $sub-page-nav-bar-page-bar-text;

              &:before,
              &:after {
                background-color: $sub-page-nav-bar-page-bar-active;
              }
            }
          }

          &:first-child {
            a {
              background-color: transparent;
            }
          }
        }

        &.inactive {
          .pagebar-menu-entry-content {
            a {
              background-color: transparent;

              &:before,
              &:after {
                background-color: $sub-page-nav-bar-page-bar-unvisited;
              }
            }
          }
        }

        &.active,
        &.inactive {
          .pagebar-menu-entry-content {
            a {
              &:hover {
                &:before,
                &:after {
                  background-color: $sub-page-nav-bar-page-bar-hover;
                }
              }

              &:after {
                height: calc(50% + 0.5px);
              }
            }
          }
        }

        &:first-child {
          .pagebar-menu-entry-content {
            a {
              padding-left: 0;
            }
          }
        }

        &:last-child {
          .pagebar-menu-entry-content {
            a {
              &:hover {
                background-color: $sub-page-nav-bar-page-bar-hover;
              }
            }
          }

          &.inactive {
            .pagebar-menu-entry-content {
              a {
                background-color: $sub-page-nav-bar-page-bar-inactive-index-greater-than-active;

                &:hover {
                  background-color: $sub-page-nav-bar-page-bar-hover;
                }
              }
            }

            &:not(.inactive-tab-greater-index-than-active) {
              .pagebar-menu-entry-content {
                a {
                  &:before,
                  &:after {
                    background-color: $sub-page-nav-bar-page-bar-inactive;
                  }

                  &:hover {
                    &:before,
                    &:after {
                      background-color: $sub-page-nav-bar-page-bar-hover;
                    }
                  }
                }
              }

              &.inactive-tab-less-than-index-of-active {
                a {
                  &:before,
                  &:after {
                    background-color: $sub-page-nav-bar-page-bar-inactive-index-less-than-of-active;
                  }
                }
              }
            }
          }

          &.active {
            .pagebar-menu-entry-content {
              a {
                background-color: $sub-page-nav-bar-page-bar-active;

                &:hover {
                  background-color: $sub-page-nav-bar-page-bar-hover;
                }
              }
            }
          }
        }
      }

      .pagebar-menu-entry-text {
        color: $sub-page-nav-bar-page-bar-text;

        &:before {
          top: -5px;
          height: 100%;
        }

        &:visited,
        &:hover {
          color: $sub-page-nav-bar-page-bar-text;
        }
      }
    }

    .navbar-pagebar-page-container-nav-group {
      .navbar-pagebar-page-container {
        width: auto;

        .pagebar-menu-entry {
          &:before {
            display: none;
          }
        }

        &.disabled {
          &:hover {
            cursor: not-allowed;
          }

          a {
            opacity: $nav-bar-disabled-opacity;
            pointer-events: none;

            &:after,
            &:before {
              pointer-events: none;
            }
          }
        }

        &:last-child {
          a {
            padding-right: 15px;

            &:before,
            &:after {
              border-right-color: transparent;
              width: 100%;
              left: -#{$main-nav-bar-pseudo-width-offset};
            }
          }

          .pagebar-menu-entry {
            &.active {
              .pagebar-menu-entry-content {
                a {
                  &:after {
                    height: 50%;
                  }
                }
              }
            }
          }
        }
      }
    }

    .navbar-pagebar-page-container-group {
      .navbar-pagebar-header-group {
        color: $sub-page-nav-bar-page-bar-header-group;
      }
    }
  }
}

@media (max-width: $screen-md-max) {
  .is-sub-page {
    .navbar-pagebar {
      .navbar-pagebar-page-container-group {
        flex: 0 1 65%;
        max-width: 65%;

        &.navbar-pagebar-page-container-header-group {
          flex: 0 1 35%;
          max-width: 35%;
        }
      }
    }
  }
}

@media (max-width: $screen-md-max) {
  .cqui-main-nav-bar {
    .navbar-nav {
      > li {
        &:not(.active) {
          .navbar-nav-tab-content {
            .nav-bar-icon-group,
            .nav-bar-text,
            .nav-bar-content-marker-container {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: #{$screen-sm-max + 50px}) {
  .cqui-main-nav-bar {
    .navbar-nav {
      > li {
        &:first-child:not(.active) {
          // Add this special treatment for the first nav bar tab
          // to avoid the menu from going beyond the form area
          .#{$lib-prefix}-menu-container {
            right: 0;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-sm-max) {
  .cqui-main-nav-bar {
    .tab-word-break {
      display: none;
    }
  }

  .is-sub-page {
    .navbar-pagebar {
      .navbar-pagebar-page-container-group {
        .pagebar-menu-entry-content {
          .pagebar-menu-entry-text {
            font-size: font-size("main-nav-bar-page-bar-menu-entry-text");
          }
        }
      }
    }
  }
}
