.auto-location-class {
  .field-input-year-make-model {
    display: flex;

    .form-control {
      margin-right: 5px;
    }

    .form-control-year {
      width: 15%;
      min-width: 65px;
    }

    .form-control-make {
      width: 35%;
    }

    .form-control-model {
      width: 50%;
    }
  }
}
