.scheduled-coverage-section {
  .button-group {
    margin-right: 0px;
  }
  .section-header-row {
    float: none;
    padding-bottom: 5px;
  }
  .form-control {
    float: none;
  }
  .cqui-table {
    border: 1px solid $input-border;

    input {
      max-width: 300px;
    }
  }
}

.ae-scheduled-coverage-table {
  .cqui-table {
    thead {
      th {
        vertical-align: top;
        white-space: nowrap;
      }
    }
    tbody {
      tr {
        td.td-size {
          width: 20%;
          word-break: break-word;
          -ms-word-break: break-word;
        }
        td.td-size-cp {
          width: 20%;
        }
      }

      tr.fill {
        height: auto !important;
        background-color: $white;

        td {
          padding: 0px;
        }
      }
    }
    .btn-checkbox {
      min-width: inherit;
    }
  }

  th .wrapped-heading {
    display: flex;
    flex-direction: row;
    justify-content: center;

    label {
      left: 0px;
    }

    .indicator {
      width: 10px;
      margin-left: 2px;
    }
  }
}

ae-scheduled-coverage.wrapped-header {
  .ae-scheduled-coverage-table {
    .cqui-table {
      thead {
        th {
          vertical-align: middle;
          white-space: normal;
        }
      }
    }
  }
}
