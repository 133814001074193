@import "prerequisites";

.cqui-cover {
  position: absolute;
  @include square(100%);

  //  opacity of $light-blue;
  background-color: rgba(229, 244, 255, 0.75);
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @include border("all", $medium-light-blue);

  .icon {
    @include icon-margin("right");

    &.animated-loader {
      @include size(50px, 20px);

      .cqui-cover-text {
        margin-right: 20px;
      }
    }
  }

  .cqui-cover-text {
    font-size: #{$base-font-size - 1pt};
    display: flex;
    align-items: center;
  }
}
