.ae-bop-building-address {
  .field-row .field-input {
    padding-left: 0;
  }

  .pin-icon {
    height: 40px;
    width: 28px;
    margin: 0 3px;
    flex-shrink: 0;
    background-size: 100% 100%;
  }
  .pin-icon-legend {
    width: 14px;
    height: 20px;
    margin-right: 5px;
    margin-left: 10px;
  }
  .pin-icon-legend:first-child {
    margin-left: 0;
  }

  .pin-with-address {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
  }
  .pin-with-address-text {
    display: flex;
    align-items: center;
  }

  .map-legend {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
  }

  .suggested-address-container {
    height: 100%;
    overflow-y: auto;
    padding-left: 0;
    padding-right: 0;
  }
  .suggested-address-entry,
  .entered-address-entry {
    float: left;
    width: calc(100% - 5px);
    padding: 10px 0 10px;
    border-bottom: 1px solid #0086ae;
  }
  .entered-address-entry {
    button {
      max-width: 165px;
    }
  }
  .suggested-address-entry.active {
    background: #e3e3e3;
  }
  .suggested-address-entry .address-description {
    color: #005e7a;
    padding-right: 5px;
  }
  .entered-address-entry .pin-with-address {
    flex-direction: column;
  }
  .suggested-address-entry:last-child,
  .entered-address-entry:last-child {
    border-bottom: none;
  }
  .entered-address-entry .button-group {
    margin-right: 0;
  }
  .address-description {
    text-transform: uppercase;
    margin-bottom: 0;
    margin-left: 10px;
  }

  .display-inline-flex {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    //display: inline-flex;
  }

  .address-container {
    height: auto;
  }

  .stack-buttons {
    flex-direction: column;
    align-items: flex-start;

    .btn {
      text-align: left;
      overflow: hidden;
      margin-bottom: 5px;
    }
  }

  .even-contact {
    background: #d2ebf3;
  }

  .odd-contact {
    background: #ffffff;
  }

  .zip-field {
    width: 100%;
  }
}
