.bop-address {
  .address-container {
    height: 265px;
    overflow-y: auto;
    margin-bottom: 10px;
  }
  .listing-entry {
    margin-left: 10px;
  }

  .section-split-subsections-half .subsection.subsection-listing {
    .listing-group {
      height: 100%;
    }

    .listing-entry {
      padding-left: 0;
      padding-bottom: 0;
      padding-top: 0;
      align-items: center;
      .listing-entry-button {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .listing-entry-wrapper {
    border-bottom: none;
    padding: 10px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .listing-entry-button .edit-button {
    min-width: 81px;
  }

  .existing-address-field {
    .new-address-details {
      margin-left: 10px;
    }
  }

  .contact-container {
    background-color: transparent;
  }

  .contact-container .panel-body {
    min-height: 50px;
    overflow-y: hidden;
  }
  .contact-container .no-address-selected .panel-body {
    min-height: 100px;
    overflow: hidden;
    text-align: center;
    color: $gray;
    font-style: italic;
    line-height: 90px;
  }
  .contact-info-field {
    .select-contact-name {
      @include make-sm-column(3);
      padding-left: 0;
      padding-right: 0;
    }

    .select-contact-address {
      @include make-sm-column(3);
      padding-left: 0;
      padding-right: 0;
    }

    .select-contact-type {
      @include make-sm-column(3);
      display: flex;
      padding-right: 0;
    }

    .select-contact-add {
      @include make-sm-column(3);
      display: flex;
      padding-right: 0;
    }

    .button-group {
      display: flex;
      justify-content: flex-end;
      flex: 1 0 auto;
      align-items: center;
    }
  }

  .subsection .button-group {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .btn-primary {
      &:first-child {
        margin-right: 5px;
      }
    }
  }

  .address-field,
  .zip-field-row {
    padding-right: 0;

    .field-input {
      padding-left: 0;
    }
  }

  .zip-field-row {
    .field-input-multiple {
      cqui-cover,
      .cqui-cover {
        width: 100%;
      }
    }
  }

  .label-header .section-header {
    margin-left: 0px;
  }

  .contact-container .button-group.add-new-contact {
    margin-right: 0px;
  }

  .section-subsection {
    min-height: 365px;
  }

  .btn-content {
    justify-content: flex-start;
  }
}
