@import "@clearquote/ui/lib/assets/scss/variables";
@import "@clearquote/assets/scss/common/variables";
@import "@clearquote/assets/scss/common/functions";

cq-map {
  .map {
    position: relative;
    background-color: $light-gray;
    height: 100%;
  }

  .cq-map-background {
    background: ae-image-url("us-map.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
  }

  .esri-basemap-toggle {
    height: 55px;
    width: 55px;
  }

  .esri-basemap-toggle__container,
  .esri-basemap-toggle__image {
    height: 50px;
    width: 50px;
  }

  /*
		This is likely violating Esri attribution agreements, but I don't have time to track down any licensing agreement
		we may or may not have for our usage. Our current requirements dictate that this doesn't show up, so if someone
		comes hollering about attribution requirement violations, this is the offending style to remove.
	*/
  .esri-attribution {
    display: none;
  }
}
