@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "@clearquote/ui/lib/assets/scss/variables";

cq-internet-explorer-suppression {
  .wrapper {
    position: absolute;
    width: 100%;
    z-index: 3;
    background-color: $yellow;
    padding: 10px 5px;
  }

  .notice {
    display: flex;
    align-items: center;
    margin: auto;

    @media (min-width: $screen-md-min) {
      width: calc(#{$container-md} - 30px);
    }
    @media (min-width: $screen-lg-min) {
      width: calc(#{$container-lg} - 30px);
    }
  }

  .supported {
    margin: auto;
  }

  .close {
    font-size: 30px;
  }
}
