@import "prerequisites";

.#{$lib-prefix}-date-picker {
  .form-control {
    &[cqui-error-tool-tip-active="true"] {
      @include input-error-outline();
    }
  }

  .btn {
    padding: 0 5px;
  }

  .btn-default,
  .btn-default:hover {
    min-width: auto;
    min-width: initial;
    background-color: transparent;
    border: none;
  }

  .button-icon {
    @include square(18px);
  }

  .input-group-btn {
    .btn {
      background-color: transparent;

      &:focus {
        background-color: transparent;
        outline: 1px dotted $black;
      }
    }
  }
}

.dropdown-menu.uib-datepicker-popup {
  $cqui-date-button: #f0f0f0;
  $cqui-date-button-hover: #d8d8d8;
  $cqui-date-button-info: $medium-blue;
  $cqui-date-button-info-text: $white;
  $cqui-date-button-info-hover: $light-medium-blue;
  $cqui-date-button-info-hover-text: $ship-gray;

  padding: 10px;
  min-width: 280px;
  margin: 0;

  table {
    width: 100%;

    .button-calendar-entry {
      width: 100%;
    }

    .btn {
      background-color: $cqui-date-button;

      &:hover {
        background-color: $cqui-date-button-hover;
      }
    }

    .btn-info {
      background-color: $cqui-date-button-info;

      .text-info {
        color: $cqui-date-button-info-text;
      }

      .text-muted {
        color: $cqui-date-button-info-text;
      }

      &.active,
      &:active,
      &:focus {
        &:hover {
          .text-info {
            color: $cqui-date-button-info-hover-text;
          }
        }
      }

      &:hover {
        background-color: $cqui-date-button-info-hover;
        color: $cqui-date-button-info-hover-text;

        .text-info {
          color: $cqui-date-button-info-hover-text;
        }

        &.active,
        &:active,
        &:focus {
          .text-info {
            color: $cqui-date-button-info-hover-text;
          }
        }
      }
    }

    .btn-default {
      min-width: 100%;
    }
  }

  td,
  th {
    padding: 1px;
  }

  .glyphicon-chevron-left {
    @include image-url("icon-chevron-left.svg");
  }

  .glyphicon-chevron-right {
    @include image-url("icon-chevron-right.svg");
  }

  .glyphicon-chevron-left,
  .glyphicon-chevron-right {
    @include icon();
    @include size(100%, 12px);
    &:before {
      display: none;
    }
  }
}
