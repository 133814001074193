@import "prerequisites";

// To handle relatively high z-index values such as
// modals, tooltips, and dropdown menus which tend to
// overlap each other.

.dropdown-menu[datepicker-popup-wrap] {
  z-index: map-get($z-index, datepicker);
}

// Date pickers gets the third highest z-index
// to handle the case where a datepicker is used in a modal
.uib-datepicker-popup {
  z-index: #{$max-z-index - 2};
}

.#{$lib-prefix}-dropdown-menu {
  z-index: #{$max-z-index - 2};
}

.#{$lib-prefix}-typeahead {
  z-index: #{$max-z-index - 2};
}

// In majority of circumstances, the tooltip should have the higher
// z-index relative to any other 'overlay' elements
.tooltip-container {
  z-index: #{$max-z-index - 1};
}

// Menu container should have the highest z-index to allow for
// proper view of menu entry when attempting to navigate away from current page
.#{$lib-prefix}-menu-container {
  z-index: $max-z-index;
}

.navbar-nav-tab {
  &:hover {
    .#{$lib-prefix}-menu-container {
      z-index: $max-z-index;
    }
  }
}

cqui-menu {
  &.menu {
    z-index: map-get($z-index, searchMenu);
  }
}

// Overlay element associated with a modal will get a boost in z-index
// to prevent the case where elements such as a tooltip
// or datepicker might go behind the active modal.
body {
  &.modal-open {
    .cqui-tabs-set {
      &.cqui-tabs-fixed-header {
        > .cqui-tabs-nav-container {
          // Allow the tooltip to go over the tabs properly
          // when using the fixed nested tab row
          z-index: $modal-z-index - 1;
        }
      }
    }
  }
}
