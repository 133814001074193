.star-unselected {
  height: 50px;
  width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  outline: none;

  @include image-url("icon-star-unselected.svg");
}

.star-selected {
  height: 50px;
  width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  outline: none;

  @include image-url("icon-star-selected.svg");
}
