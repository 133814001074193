.ae-bop-info {
  /*
		Due to how the add building modal functions, its content must exist inside the same container as the 
		building and locations section. This means any additional styling have the potential of introducing
		layout issues within it.

		In light of this, we need to make sure we contain specific styling to areas of this view which will
		not affect the add builing modal.
	*/
  .policy-information {
    .field-input {
      .form-control:disabled {
        background-color: $medium-gray;
        opacity: 0.5;
      }
    }

    .expansible-header,
    .heading-content {
      outline: 0;
    }

    // from assets/css/business-info.css
    .form-description .note {
      font-size: 10pt;
      float: right;
      margin-top: -5px;
    }

    .field-label {
      // from assets/sass/common/_frame.scss
      .form-description,
      .form-description-boolean {
        line-height: 1.3em;
        text-align: right;
        left: 0;
        margin-right: -7px;
        float: right;

        // from assets/css/account-setup.css
        .required {
          top: 0px;
          position: relative;
          right: 0px;
        }
      }
    }

    .form-description-boolean {
      margin-right: 0;
    }

    .gpa-input-ctrl,
    .gpa-ctrl-group {
      .col-xs-12 {
        width: auto;
        float: none;
        position: inherit;
        min-height: inherit;
        padding-left: inherit;
        padding-right: inherit;
      }
    }
  }

  .exp-date {
    width: 100%;
    outline: 0;
  }

  .duplicate-building-message-inline-row {
    background-color: $light-blue !important;
    .message {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .icon {
        width: 15px;
        height: 15px;
        margin: 5px 5px 5px 0;
        display: inline-block;
      }
    }
    td {
      border-top: 1px solid $light-blue;
    }

    .icon-information-circle {
      display: inline-block;
      height: 15px;
      width: 15px;
      margin-left: 3px;
      margin-right: 5px;
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
  }

  .table-container .table .table-body tr:first-child {
    .table-col {
      border-right: transparent;

      &:last-child {
        border-bottom-right-radius: 0px;
      }
      &:first-child {
        border-bottom-left-radius: 0px;
      }
    }
  }

  .tables-container .warning-container {
    // Remove additional margin coming from the mockup site
    margin-bottom: 0px;
  }
}
