@import "prerequisites";

.animated {
  animation-duration: 0.5s;
}

.animate-end {
  display: none;
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: hidden;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes fade-out {
  from {
    transform: translate3d(0, 0, 0);
  }

  90% {
    opacity: 0.5;
  }

  95% {
    opacity: 0.25;
  }

  99% {
    opacity: 0.01;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

.fade-out {
  animation-name: fade-out;
}

.pending,
.processing {
  @include opacity(0.1);
}

@keyframes pulse {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: $golden-sand;
  }
  100% {
    background-color: transparent;
  }
}

.#{$lib-prefix}-illuminated {
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: 3;
}

.#{$lib-prefix}-illuminated-outline {
  @include border("all", $orange, 2px);
  @include border-radius("all", 3px);
}
