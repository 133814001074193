.cq-progress-overlay-backdrop {
  background: rgba(255 255 255 / 32%);
  transition: opacity 200ms cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 99999;
}

.cq-progress-overlay-pane {
  z-index: 99999;
}

.cq-progress-overlay-backdrop.cdk-overlay-backdrop-showing {
  transition-duration: 3000ms;
}
