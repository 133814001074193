@import "@clearquote/ui/layout/common/common";

.review-quote {
  $premium-main-content: $white;

  $progress-meter: $light-bright-blue;

  $expansible-divider: $white;

  $summary-section-large-height: 57px;

  $coverage-button-width: 26px;
  $coverage-button-width-margin: 5px;

  .cqui-alert-list {
    margin-bottom: 5px;
    @include border("top");
    padding-top: 5px;

    & + .section {
      @include border("top");
    }
  }

  .main-form-input-section {
    height: calc(100% - 40px);

    > .section {
      border-top: none;

      &:first-child {
        padding-top: 5px;
        margin-bottom: 0;
      }

      &.section-notification {
        padding-bottom: 0;

        > .cqui-notification {
          .notification-text {
            font-size: font-size(
              "quote-section-notification",
              $pages-font-vars-to-size-ratios
            );
          }
        }

        &:last-child {
          padding: 0 0 0 0;
        }
      }
    }
  }

  .cqui-expansible-container {
    &.container-is-unchecked {
      .expansible-container {
        background-color: $muted-powder-blue;
      }
    }
  }

  .summary-section {
    margin-bottom: 0;
    padding-top: 0px;
    display: flex;
    flex: 1 0 auto;
    padding-bottom: 0px;

    .quote-buttons-section {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .btn {
        margin-left: 5px;
        flex-grow: 1;
      }

      .premium-container {
        @include pill-container();
        margin-left: 5px;
        margin-bottom: 5px;

        .premium-main-label {
          @include pill-label();
          padding-left: 10px;
          white-space: nowrap;
        }

        .progress-meter {
          position: absolute;
          background-color: $progress-meter;
          top: 0;
          left: 0;
          padding: 0;
          @include size(0, 100%);
          @include border-radius("top-right" "bottom-right");
        }

        .premium-main-content {
          @include pill-value();
          @include premium-content;
        }

        @include dancing-dots;

        .dancing-dots {
          top: 0;
          position: absolute;
          width: 60%;
          right: 0;
        }
      }

      .button-container {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        &:last-child {
          padding-bottom: 0;
        }
      }

      .larger-button-container {
        .cqui-icon-button {
          height: $summary-section-large-height;
          font-size: font-size(
            "quote-large-container-buttons",
            $pages-font-vars-to-size-ratios
          );

          .btn-content {
            justify-content: center;
          }
        }
      }

      .left-align-buttons,
      .right-align-buttons {
        flex: 1 0 50%;
      }

      .left-align-buttons {
        display: flex;
        width: 65%;

        .premium-container {
          @include size(65%, $summary-section-large-height);

          + .button-container {
            .btn {
              margin-bottom: 5px;
              width: 100%;
            }
          }
        }
      }

      .right-align-buttons {
        display: flex;
        justify-content: flex-end;

        > * {
          width: 50%;
          max-width: 310px;
          min-width: 310px;

          &.larger-button-container {
            .btn {
              width: 100%;

              .btn-icon {
                @include square(35px);
              }

              font-size: font-size(
                "quote-container-right-align-buttons",
                $pages-font-vars-to-size-ratios
              );
            }
          }
        }
      }
    }
  }

  .accordion-section {
    .accordion-content {
      .quote-accordion {
        .expanded-accordian {
          max-height: 570px;
        }
      }
    }
  }

  .icon-thumbs-up {
    &.btn-icon {
      @include square(30px);
      margin-right: 5px;
    }
  }

  .quote-review-form-buttons {
    display: flex;
    align-items: flex-end;

    .proceed-button {
      margin-left: auto;

      .cqui-icon-button {
        height: 40px;
        font-size: font-size(
          "quote-container-proceed-button",
          $pages-font-vars-to-size-ratios
        );
        min-width: 295px;

        .btn-content {
          padding: 0 40px;
        }
      }
    }
  }

  .underwriting-issues {
    padding-top: 5px;
    @include border("top", blue);
    @include border("bottom", blue);
    padding-bottom: 5px;

    .uw-sub-group {
      @include expansible-content-grouping;

      + .uw-sub-group {
        @include expansible-content-grouping-neighbor;
      }

      color: $black;
      background-color: $powder-blue;
    }

    .uw-sub-header {
      @include expansible-content-grouping-header;
      margin-bottom: 5px;
    }

    @mixin icon-small {
      @include expansible-content-grouping-header-icon;
    }

    .icon {
      @include icon-small;
    }

    .icon-declined,
    .icon-approved,
    .icon-error {
      @include icon();
      @include square(15px);
      vertical-align: middle;
      float: left;
    }

    .container-alert {
      @include border-radius();

      &.underwriting-issues-approved {
        background-color: $informational-background-blue;

        .uw-sub-group {
          background-color: $informational-background-blue;
        }
      }

      .expansible-header {
        .expansible-container-header-icon {
          flex: 0 0 #{$expansible-container-large-header-icon-height};
          height: $expansible-container-large-header-icon-height;
        }
      }

      .additional-uw-req-sub-container {
        @include expansible-content-grouping;

        + .uw-sub-group {
          @include expansible-content-grouping-neighbor;
        }

        color: $black;
        background-color: $powder-blue;
      }

      .uw-sub-group {
        color: $black;
        background-color: $light-pink;
      }
    }

    .declined-list,
    .approved-list,
    .error-list,
    .document-list {
      @include expansible-content-grouping-list;

      strong {
        margin-right: 5px;
      }

      .icon {
        @include square(15px);
        margin-right: 5px;
        min-width: 15px;
      }
    }

    .error-list,
    .approved-list {
      display: flex;
      flex-direction: column;

      .list-item {
        padding-left: 5px;
      }
    }

    .approved-list-entry,
    .error-list-entry,
    .document-list-entry {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      line-height: 1;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      .item {
        margin-top: 10px;
        &:first-child {
          margin-top: inherit;
        }
      }
    }

    .header-title-text {
      font-size: font-size("expansible-container-large-header-title");
      line-height: 1;
      width: 100%;
      max-width: 100%;
      display: flex;
    }

    .header-subtitle-text {
      font-weight: 700;
      line-height: 1.2;
      display: flex;
      flex: 1 0 100%;
      width: 100%;
      max-width: 100%;
    }

    .expansible-header {
      padding: 15px;
      flex-direction: row;
      justify-content: flex-start;

      .expansible-container-middle-content-wrapper {
        flex: 1 0 1px;
        width: auto;
        height: 100%;
        min-height: 100%;

        .expansible-container-middle-content {
          @include square(100%);
          max-width: 100%;
          min-height: 100%;
          flex: 1 0 100%;
        }

        .header-text {
          flex: 0 1 100%;
          flex-direction: row;
        }

        & + .header-end {
          flex: 0 1 auto;
        }
      }

      .button-group {
        &.button-group-right {
          display: flex;
          flex: 1 0 auto;
          justify-content: flex-end;
        }
      }
    }

    .expansible-content {
      margin-top: 0;
    }
  }

  .underwriting-issues,
  .submission-details {
    .header-text {
      display: flex;
      margin-left: 10px;
      margin-right: 10px;
      flex-wrap: wrap;
      @include font-base();
      align-content: flex-start;
      align-items: flex-start;
      flex: 0 1 100%;
      max-width: calc(100% - 65px);
    }
  }

  .cqui-expansible-list {
    .underwriting-issues {
      border-bottom: none;
      padding: 0;

      .cqui-expansible-container {
        &.container-alert {
          padding: 0;

          .expansible-container {
            background-color: $alert-background-pink;
          }
        }
      }
    }

    .underwriting-documents {
      .cqui-expansible-container {
        &.container-alert {
          background-color: $informational-background-blue;

          .expansible-container {
            background-color: $informational-background-blue;
          }
        }

        .expansible-header {
          background-color: $informational-background-blue;
        }
      }
    }
  }

  .submission-details {
    &.submission-details-issues,
    &.submission-details-incomplete {
      .expansible-header,
      .expansible-container,
      .expansible-content {
        background-color: $alert-background-pink;
      }
    }

    .expansible-container {
      margin-top: 5px;
      background-color: $powder-blue;
    }

    .expansible-header {
      flex-direction: row;
      align-items: center;

      .expansible-container-middle-content-wrapper {
        flex: 0 1 100%;
        max-width: calc(100% - 160px);
        position: relative;

        &:before {
          content: " ";
          height: 100%;
          @include border("all", $white);
          position: absolute;
          margin-left: -12px;
          top: 0;
          background-color: $white;
        }
      }

      .header-subtitle-text {
        @include font-weight-base();
        font-family: sans-serif;
        flex: 0 1 100%;
        flex-wrap: wrap;
        max-width: 100%;
      }

      .header-subtitle-note {
        font-size: font-size(
          "quote-submission-details-header-subtitle-note",
          $pages-font-vars-to-size-ratios
        );
      }

      .header-text {
        padding: 15px 0;
      }

      .include-in-quote {
        position: relative;

        &.disabled {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            @include square(100%);
            top: 0;
            left: 0;
            z-index: 2;
            pointer-events: none;
          }
        }

        .btn,
        .cqui-icon-button {
          padding: 0 5px;
          &[disabled] {
            background-color: $btn-selected;
          }
        }

        #{$lib-prefix}-icon {
          @include square(15px);
          max-width: 15px;
          max-height: 15px;
        }
      }

      .check-spacer {
        background: $expansible-divider;
        margin-left: 8px;
        margin-right: 10px;
      }

      .premium {
        @include border("all", blue, 2px);
        @include border-radius("all", 5px);
        background-color: $premium-main-content;
        min-width: 110px;
        width: fit-content;
        height: 40px;
        text-align: center;
        margin-left: auto;
        font-size: 24px;
        line-height: 1;
        margin-top: 5px;
        margin-bottom: 5px;
        display: flex;
        justify-content: center;
        position: relative;

        &:hover {
          cursor: default;
        }

        .premium-val {
          line-height: 1.5;
          font-size: font-size(
            "quote-lob-premium-header-val",
            $pages-font-vars-to-size-ratios
          );
          padding: 0 10px;
          font-weight: normal;
          display: flex;
          align-items: center;
        }

        @include premium-content;
      }

      .premium-container {
        display: block;
        right: 15px;
        font-weight: normal;
        top: 0px;
      }

      .premium-applied {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: -4px;

        .icon-pricing-applied {
          @include icon();
          @include square(15px);
          margin-right: 2px;
        }

        span {
          &:not(.icon) {
            font-size: font-size(
              "quote-apply-pricing",
              $pages-font-vars-to-size-ratios
            );
            white-space: nowrap;
          }
        }
      }

      .minimum-premium {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -4px;

        .icon-information-circle {
          @include icon();
          @include square(15px);
          margin-right: 2px;
        }

        span {
          &:not(.icon) {
            font-size: font-size(
              "quote-apply-pricing",
              $pages-font-vars-to-size-ratios
            );
            white-space: nowrap;
          }
        }
      }

      .header-title-text {
        margin-left: 2px;
        font-size: font-size(
          "quote-expansible-header-title",
          $pages-font-vars-to-size-ratios
        );
        word-break: break-all;
      }

      .header-subtitle-text {
        font-weight: normal;
        font-family: sans-serif;
        margin-left: 2px;
        align-items: center;

        > * {
          &:first-child {
            margin-right: 5px;
            word-break: break-all;
            word-wrap: break-word;
          }
        }
      }

      .expansible-container-button {
        .icon-checkbox-checked {
          @include square(12px);
        }
      }
    }

    .business-details-help-text {
      display: block;
      padding-top: 8px;
      padding-bottom: 5px;
    }

    .cqui-expansible-container {
      .expansible-header {
        .expansible-container-header-icon {
          @include icon();
          @include size(30px, 40px);
          min-width: 30px;
          flex: 0 0 30px;
          position: relative;
        }
      }
    }

    .expand-icon-quote-status {
      @include icon();
      @include square(20px);
      position: absolute;
      margin-left: 15px;
      margin-top: 22px;
    }

    .coverage-list {
      font-size: font-size(
        "quote-submission-coverage-list",
        $pages-font-vars-to-size-ratios
      );
      margin-left: 30px;
      margin-right: 10px;
      display: block;

      + .tables-container-wrapper {
        @include border-radius(
          "bottom-left" "bottom-right",
          expansible-container
        );
      }

      + .tables-container {
        @include make-row();

        .table-wrapper {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        .tables-container-wrapper {
          margin: 0;
        }
      }

      .header {
        @include border("bottom", blue);
        font-size: font-size(
          "quote-submission-coverage-list-header",
          $pages-font-vars-to-size-ratios
        );
        font-weight: 600;
        margin-top: 5px;
      }

      .coverage-container {
        width: 100%;
        display: flex;

        .coverage-section {
          width: 33%;
          margin: 10px 0;
          @include border("right", blue);

          &:first-child {
            margin-left: #{$coverage-button-width-margin +
              $coverage-button-width};

            .icon-check-blue {
              margin-left: 10px;
            }
          }

          .coverage-section-entry {
            display: flex;
            align-items: flex-start;
            margin-bottom: 5px;
          }

          .coverage-description {
            line-height: 1.2;
            width: calc(100% - 40px);
            margin-left: 10px;
            padding-right: 10px;
          }

          .icon-check-blue {
            @include icon();
            @include square(15px);
            margin-left: 15px;
          }

          .icon-im-black-blue {
            @include icon();
            @include square(15px);
            margin-left: 15px;
          }

          &.coverage-edit {
            border-right: none;
            margin: 10px $coverage-button-width-margin 0 0;
            width: auto;

            .btn {
              width: $coverage-button-width;
            }

            + .coverage-section {
              margin-left: 0;

              .icon-check-blue {
                margin-left: 10px;
              }
            }
          }

          &:last-child {
            border-right: none;
          }
        }

        .im-coverage-section {
          width: 33%;
          margin: 10px 0;
          @include border("right", blue);

          &:first-child {
            margin-left: 0px;

            .icon-check-blue {
              margin-left: 10px;
            }
          }

          .coverage-section-entry {
            display: flex;
            align-items: flex-start;
            margin-bottom: 5px;
          }

          .coverage-description {
            line-height: 1.2;
            width: calc(100% - 40px);
            margin-left: 10px;
            padding-right: 10px;
          }

          .icon-im-black-blue {
            @include icon();
            @include square(15px);
            margin-left: 15px;
          }

          &.coverage-edit {
            border-right: none;
            margin: 10px $coverage-button-width-margin 0 0;
            width: auto;

            .btn {
              width: $coverage-button-width;
            }

            + .coverage-section {
              margin-left: 0;

              .icon-check-blue {
                margin-left: 10px;
              }
            }
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }

  .business-details-help-text {
    display: block;
    padding-top: 8px;
  }

  @media (min-width: $screen-sm-min) {
    .summary-section {
      flex-direction: column;

      .quote-buttons-section {
        display: flex;

        .left-align-buttons {
          flex: 1 0 65%;

          .premium-container {
            margin-left: 0;
          }

          .premium-main-label {
            white-space: unset;
          }
        }

        .right-align-buttons {
          flex: 1 0 35%;

          > * {
            max-width: 75%;
            min-width: auto;
          }

          .button-container {
            width: 90%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

body {
  &.feature-normalize-width-next-button {
    .review-quote {
      &.main-form-content {
        @include main-form-buttons {
          min-width: 295px;
        }
      }
    }
  }
}
