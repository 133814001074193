#{$lib-prefix}-field-row-expansible-header {
  display: flex;
  position: relative;

  @include expansible-container-header-end();

  .header-end {
    cursor: pointer;

    cqui-expansible-header-end {
      padding: 7px 3px;
    }
  }

  > ng-transclude {
    display: flex;

    &.expand-visible,
    &.expand-collapse {
      position: relative;

      @include expansible-container-header-divider();
    }
  }

  &.unexpanded {
    .field-row {
      cursor: default;
    }
  }

  &.expand-visible,
  &.expand-collapse {
    background-color: $expansible-container-embed;
    @include border-radius("all", expansible-container);

    .field-row,
    .field-description {
      cursor: pointer;
    }

    margin-top: 5px;
  }

  &.expand-collapse {
    > ng-transclude {
      display: flex;

      &.expand-visible,
      &.expand-collapse {
        @include expansible-container-header-divider(transparent);
      }
    }

    margin-bottom: 5px;
  }

  &.expand-visible {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .header-end {
      @include border-radius("bottom-right", 0);
    }

    .field-row {
      &:first-child {
        border-bottom: none;
      }
    }

    + .field-expansible-type-embed {
      > .field-row {
        &:first-child {
          padding-top: 0;
        }
      }
    }
  }
}
