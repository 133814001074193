@import "@clearquote/ui/lib/assets/scss/prerequisites";

//Colors aliases
$screen-background: $light-gray;

$table-container: $white;
$table-sub-container: $table-container;
$table: $white;
$table-row: $cerulean;
$table-header-outline: $cerulean;
$table-body-outline: $medium-gray;

$section-highlighted-light: $light-blue;
$section-highlighted-lightest: $white;
$section-highlighted-lightest-border: $border-divider-medium-gray;

$section-highlighted-blue: $powder-blue;

$subsection-listing: $white;

$paragraph: $black;

$multi-tag: $cerulean;
$multi-tag-text: $white;
$multi-tag-button: $lightest-gray;

$listing-selected: $light-medium-blue;
$listing-header: $medium-dark-blue;
$listing-text: $cerulean;
$listing-border: $border-divider-blue;

$zip-address-text: $nationwide-blue;

$row-margin-left: 15px;

$modal: $light-blue;
$modal-section: $white;
$modal-icon-margin-right: 10px;

$map-container: $white;
$map-container-min-height: 320px;
$map-background: $light-gray;
$map-pin-text: $cerulean;

$accordion-header-opened-underline: $cerulean;

$expansible-tag-group: $light-gray;
$expansible-tag: $white;
$expansible-tag-text-notice: $cerulean;
$expansible-tag-text-notice-color: $white;

$expansible-container: $light-gray;
$expansible-container-border-underline: $medium-gray;

$section-bar-listing: $cerulean;

// Margin
$btn-med: (
  margin-right: 5,
);

// Width

$modal-icon-width: 60;

/*
	In the 'section-fields-underlined' container, the width of the inputs will need to be
	the same width as the combined width of the two yes/no radio buttons
*/
$section-underline: (
  input: #{2 * $btn-default-width + map-get($btn-med, margin-right)},
);

// Page specific font size ratio mapping
$pages-font-vars-to-size-ratios: (
  quote-section-notification: 0.9302325581 * $base-font-size,
  quote-submission-details-header-subtitle-note: 0.9302325581 * $base-font-size,
  quote-submission-coverage-list: 0.9302325581 * $base-font-size,
  quote-apply-pricing: 0.9302325581 * $base-font-size,
  quote-submission-coverage-list-header: 0.976744186 * $base-font-size,
  irpm-state-header: 1.209302326 * $base-font-size,
  quote-large-container-buttons: 1.209302326 * $base-font-size,
  quote-container-right-align-buttons: 1.395348837 * $base-font-size,
  quote-container-proceed-button: 1.302325581 * $base-font-size,
  quote-expansible-header-title: 1.302325581 * $base-font-size,
  quote-lob-premium-header-val: 1.534883721 * $base-font-size,
);
