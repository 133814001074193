@use "units";

$even-row-background-color: #e5f4ff;
$odd-row-background-color: #fff;

.cq-table {
  --mat-table-header-container-height: #{units.px(30)};

  &.mat-mdc-table {
    border-collapse: separate;
    border: units.px(1) solid #ccc;
    border-radius: units.px(4);
    overflow: hidden;
  }

  .mat-mdc-header-row {
    background-color: #005e7a;
  }

  .mat-mdc-header-cell {
    color: white;
    font-weight: normal;
    padding: units.px(5) units.px(10);
    text-align: center;
  }

  .mat-mdc-cell {
    border-bottom-style: none;
    padding: units.px(5) units.px(10);
    text-align: center;
  }

  .mat-mdc-row,
  .mat-mdc-row:hover {
    background-color: var(--cq-table-row-background-color);
  }

  .mat-mdc-row:nth-child(even) {
    --cq-table-row-background-color: #e5f4ff;
  }
}
