@import "@clearquote/ui/layout/common/common";

.wc-coverages {
  .state-header {
    @include border("bottom", blue);
    padding-bottom: 0px;
  }

  .section-header {
    float: none;
  }

  .section.section-fields {
    padding-bottom: 0px;
  }

  .section-group.state-group {
    border-top: none;

    &:first-child {
      .section {
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    & > .section {
      padding-top: 0px;

      > .section-subsection:only-child {
        margin-top: 15px;
      }
    }

    .section-modifiers,
    .section-deductibles {
      padding-left: 0;
      padding-right: 0;
    }

    .section-modifiers {
      .section-fields-underlined {
        .field-row {
          &.field-row-level-top {
            &:last-child {
              @include border("bottom", gray);
            }
          }
        }
      }
    }

    .section-deductibles {
      .section-header {
        margin-left: 15px;
      }
    }
  }

  .section-modifiers {
    border-bottom: none;
  }

  .modifiers-and-deductibles {
    .section {
      border-bottom: none;
    }

    .section-header {
      margin-left: 0;
      margin-right: 0;
    }

    > .section-header {
      margin-left: 15px;
    }

    .section-modifiers,
    .section-deductibles {
      .section-header {
        margin-top: 0;
      }

      > .section {
        padding-top: 0;
        padding-bottom: 0;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 0;

        &.section-highlighted {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }

    .section-modifiers {
      > .section-header {
        margin-left: 15px;
      }

      .section > .field-row:first-child:nth-last-child(1) {
        margin-top: 5px;
      }

      > .section {
        + .section:not(.section-highlighted) {
          .section-header {
            margin-top: 10px;
          }
        }
      }
    }

    .state-group {
      &.section-group {
        .section-deductibles {
          .section-header {
            margin-left: 25px;
          }
        }
      }
    }
  }
}
