.auto-coverages {
  .section-fields-underlined-top {
    .field-row {
      border-bottom: 0px;
      border-top: 1px solid #dddddd;
    }
  }

  .no-border {
    border: none !important;
  }

  .total-driver {
    margin-right: 8px;
  }
  .has-limited-width {
    width: 180px !important;
  }
  .underwriting-question-section {
    .cqui-expansible-container.expansible-common .expansible-container {
      background-color: #e5f4ff;
      .header-title-text-wrapper {
        font-size: 10.75pt;
        font-weight: normal;
      }
    }
  }
}

.section-bottom {
  border-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.max-width {
  width: 100%;
}

.btn-no-whitespace {
  white-space: normal;
}

.auto-width {
  width: auto !important;
}

.required-remove-absolute {
  position: relative !important;
}

.col-address-bind-second {
  .address-single-text {
    width: auto !important;
  }
}

.advanced-notice-modal {
  &.notification-field-auto {
    border: 1px solid #ccc;
    border-color: #005e7a;
    border-radius: 3px;
    background-color: white;
    padding: 5px;
    margin-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    /* line-height: 1.14em; */
    width: auto;
  }
}
