.ae-bop-building-coverages {
  .recommended-coverages-section {
    min-height: 0px;
  }

  .section.bop-increased-limit-section {
    padding: 0 !important;
  }

  .cqui-collapsible-container {
    a:hover,
    a:focus {
      text-decoration: none;
    }
    .heading-content {
      outline: none;
    }
  }

  .contact-container .panel-body {
    flex-direction: column;
    padding: 0px;
  }

  .contact-info-field {
    padding: 10px;

    .select-contact-type {
      @include make-sm-column(3);
      display: flex;
      padding-right: 0;
    }

    .select-contact-add {
      @include make-sm-column(3);
      display: flex;
      padding-right: 0;
    }
  }

  .dry-cleaner-tooltip {
    .tooltip-container {
      max-width: 100%;
    }

    .dry-cleaner-table .dct-tier {
      font-weight: bold;
      width: 100px;
    }
    .dry-cleaner-table .dct-item {
      width: 140px;
      font-weight: bold;
    }
    .dry-cleaner-table .dct-header {
      font-weight: bold;
      text-align: center;
    }
    .dry-cleaner-table .dct-footer {
      padding: 5px;
    }
    .dry-cleaner-table {
      width: 530px;
    }
  }
}

.bop-coverages {
  @include coverages-expansible-containers-borderfix;

  .contact-container {
    .no-address-selected .panel-body {
      min-height: 100px;
      overflow: hidden;
      text-align: center;
      color: $gray;
      font-style: italic;
      line-height: 90px;
    }
  }

  .field-row .field-input {
    @include make-sm-column(5);
    @include make-md-column(4);
    @include make-lg-column(3);

    select {
      width: 100%;
    }
  }

  .field-container {
    .expansible-container {
      .expansible-content {
        padding-right: 15px;
        padding-left: 15px;
        padding-bottom: 10px;
      }
    }
  }

  .field-row {
    &.field-row-last-divider {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 75%;
        @include border("bottom", blue, 1px);
        background-color: $cerulean;
        margin-left: 25%;
        left: 0;
      }
    }
  }

  .section-fields-underlined {
    .field-row-nested-group {
      padding-top: 7px;
    }
  }
}
