.main-page-container,
.main-form-content,
.main-form-input-section,
.main-form-buttons,
.main-form-container,
.section,
.state-selection-container .form-group,
.section-subgroup,
.section-header-group,
.state-selection-container,
.tables-container,
.table-container,
.section-header-row,
.table-col-cell,
.text-group,
.text-line,
.date-from,
.button-row,
.map-pins-row,
.label-header,
.coverage-list,
.expand-content,
.existing-address-field,
.address-container {
  @include clearfix;
}

.section-subsection,
.section-description,
.multi-tag-selection-group,
.multi-tag {
  @include float();
}

ng-form {
  &.section {
    display: flex;
    flex-direction: column;
  }
}
