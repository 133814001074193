$layout-width: 73rem;
$layout-medium-breakpoint: 75rem;
$layout-medium-width: 60rem;
$layout-small-breakpoint: 62rem;
$layout-small-width: 45rem;

@mixin layout-small() {
  @media (width < $layout-small-breakpoint) {
    @content;
  }
}

@mixin layout-medium() {
  @media (width < $layout-medium-breakpoint) {
    @content;
  }
}

@mixin layout-minimum-medium() {
  @media (width >= $layout-small-breakpoint) {
    @content;
  }
}
