.section {
  &.wc-primary-location {
    .header-field {
      .section-header,
      .section-header-note {
        display: inline-block;
      }
    }
    .primary-location {
      padding-left: 25px;
    }
  }
}
