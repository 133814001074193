@import "prerequisites";

.tiles-container {
  background-color: $light-gray;
  padding: 15px 15px 10px 15px;
  @include border-radius();
  margin-bottom: 5px;

  .section-header-group {
    display: flex;

    .section-header-icon {
      @include icon();
      margin-right: 5px;
      margin-bottom: 5px;
      @include square(30px);

      &.icon-auto-car {
        @include size(35px, 20px);
      }
    }

    .section-header {
      margin-top: 0;
    }
  }
}

.tiles-group {
  $tile-width: 30%;
  $tile-label-min-width: 70px;
  $tile-input-buffer-for-label: 10px;
  $tile-text-label-input: $label-spacing-right;
  $tile-text-label-header-text: $label-spacing-right;

  &.tile-group-thirds {
    .tile {
      width: $tile-width;
    }

    .tile-text {
      flex: 1 0 100%;
    }

    .tile-text-group {
      width: 100%;
    }
  }

  .btn {
    &[icon-class="icon-delete-gray"] {
      background-color: $disabled-content-gray;
      @include border("all", disabled-gray);

      &:focus,
      &:active,
      &:hover {
        cursor: not-allowed;
        background-color: $disabled-content-gray;
      }
    }
  }

  .tiles-row {
    @include tiles-row();

    .tile-field-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-wrap: wrap;

      &.tile-field-input-group {
        padding-bottom: 10px;

        .tile-text-label {
          flex: 3;
          min-width: $tile-label-min-width;
        }
      }
    }

    .tile-field-row {
      display: flex;
      flex: 1;
      word-break: keep-all;

      &.tile-type-input {
        display: flex;
        .tile-text-label {
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-start;
          padding-right: $label-description-padding-right;
          margin-right: $tile-text-label-input;
          line-height: 1.1;

          #{$lib-prefix}-required-indicator {
            top: -5px;
          }
        }

        .tile-button-group {
          top: 10px;
          position: absolute;
          margin: 0;
          right: 10px;
        }

        .tile {
          padding: 10px;
          position: relative;
        }

        .btn {
          background-color: transparent;
          border: none;
          padding: 0;
        }
      }

      &.tile-type-input {
        margin-bottom: 5px;
      }

      &:last-child {
        margin-bottom: 7px;
      }

      .tile-text-input {
        flex: 4;
      }

      .tile-text-description {
        flex: 6;
      }
    }

    // Add margin for section header rows contained between tile rows
    & + .section-header-row {
      margin-top: 7px;
    }

    &.tiles-row-last {
      .tile {
        margin-bottom: 0;
      }
    }
  }

  .tile {
    @include tile();
  }

  .tile-text {
    width: auto;
    word-break: break-all;
  }

  .tile-text-line {
    word-break: break-all;
    width: 100%;
    float: left;
    clear: both;
    line-height: 1.2;
    font-size: font-size("tile-text-line");

    &.tile-text-line-icon {
      display: flex;
      align-items: center;
      margin-top: 5px;
      font-size: font-size("tile-text-line-icon");
      flex-wrap: wrap;

      .icon {
        @include square(15px);
        @include icon-margin("right");
        min-width: 15px;
      }

      .tile-text-line {
        font-weight: 900;
        width: auto;
        margin-right: 3px;
        display: flex;
        word-break: keep-all;
      }
    }
  }

  .tile-text-group {
    display: flex;
  }

  .tile-text-label {
    @include field-label("parent");
    @include required();
    flex: 1 0 20%;
    padding-right: $label-description-padding-right;
    margin-right: $tile-text-label-header-text;
  }

  .tile-text {
    @include tile-text();
  }

  .tile-text-description {
    &.tile-text-bold {
      @include text-bold();
      flex: 1 0
        calc(100% - #{$tile-label-min-width + $tile-input-buffer-for-label});
      min-width: calc(
        100% - #{$tile-label-min-width + $tile-input-buffer-for-label}
      );
      padding-right: 10px;
    }
  }

  .tile-field-input {
    flex: 0 1 80%;
    margin-right: 5px;

    .form-control {
      width: 100%;
    }
  }

  .tile-button-group {
    @include tile-button-group();
  }
}

@media (max-width: $screen-sm-max) {
  .tiles-group {
    &.tile-group-thirds {
      .tile {
        align-items: flex-start;
        width: 45%;
      }
    }
  }
}
