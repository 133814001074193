@use "units";

.cq-checkbox-button {
  .mat-button-toggle .mat-button-toggle-button {
    border-radius: units.px(4);
  }

  .mat-button-toggle.tight-button .mat-button-toggle-label-content {
    padding: units.px(0) units.px(4);
  }
}
