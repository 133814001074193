.table {
  @include table-large();
  @include table-striped();

  .cqui-notification {
    margin: 0;

    .notification-icon {
      @include square(15px);
      min-width: 15px;
    }
  }

  @include table-col-to-left();

  @include table-dynamic-row();

  &.table-first-column-precedence {
    > thead {
      > tr {
        > th {
          &:first-child {
            width: 35%;
          }
        }
      }
    }
  }
}

.tables-container {
  .table-col-entry-details {
    padding-bottom: 5px;
    border-bottom: 1px solid $border-divider-medium-gray;

    &:last-child {
      border-bottom: none;
    }
  }

  .warning-container {
    background-color: $table-sub-container;
    margin: 0 0 15px;
    height: 100px;
    @include border-radius("all", 4px);
    display: flex;
    align-items: center;
    justify-content: center;

    .warning-container-alert {
      @include square(18px);
      @include image-url("icon-alert-information.svg");
    }

    .warning-container-text {
      padding-left: 10px;
      padding-top: 10px;
    }
  }

  .table {
    border: 1px solid $border-divider-medium-gray;
    margin-bottom: 10px;
  }

  .table-body {
    border: 1px solid $border-divider-medium-gray;
    border-top: none;
  }

  &.table-details-type {
    .table-body {
      tr {
        @include border("bottom");
        &:last-child {
          border-bottom: none;
        }
      }

      .table-col {
        vertical-align: top;

        .cqui-notification {
          .notification-icon {
            margin-top: 3px;
          }
        }
      }
    }
  }

  &.table-master-details-type {
    $table-col-sm-min-width: map-get($table-col-min-width, sm);
    $table-col-md-min-width: map-get($table-col-min-width, md);
    $table-col-md-lg-min-width: map-get($table-col-min-width, mdlg);
    $table-col-lg-min-width: map-get($table-col-min-width, lg);

    $table-col-padding-left-or-right-side: 10px;

    $table-col-left-most-sm-width-with-offset: $table-col-sm-min-width +
      $table-col-padding-left-or-right-side;

    .table-container {
      .table {
        .table-col {
          width: auto;

          &.table-col-sm {
            width: $table-col-left-most-sm-width-with-offset;
          }

          &.table-col-md {
            width: $table-col-md-min-width;
          }

          &.table-col-md-lg {
            width: $table-col-md-lg-min-width;
          }

          &.table-col-lg {
            max-width: $table-col-lg-min-width;
          }

          &.table-col-master {
            width: 30%;
            padding-right: 17px;

            @include table-container-master-col();
          }

          &.table-col-details {
            padding: 3px 10px 3px 10px;

            + .table-col-details {
              padding-left: 0;
            }

            .table-col-entry-details-row {
              flex-wrap: wrap;
            }
          }
        }
      }
    }

    .table-head {
      .table-col-master {
        max-width: 25%;
      }

      .table-col-details {
        padding: 3px 0 3px 10px;

        &:last-child {
          text-align: right;
        }
      }

      > tr {
        > td {
          &.table-col-details:nth-child(2):nth-last-child(2),
          &.table-col-details:nth-child(3):nth-last-child(1) {
            width: 35%;
          }
        }
      }
    }

    .table-body {
      .table-col-master {
        width: 25%;

        .text-group {
          width: 100%;
          max-width: 100%;
        }
      }

      .table-col-details {
        width: 50%;

        .col-sm {
          width: $table-col-sm-min-width;
          min-width: $table-col-sm-min-width;
          display: flex;
          align-items: flex-start;
        }

        .col-md {
          width: $table-col-md-min-width;
        }

        .col-md-lg {
          width: $table-col-md-lg-min-width;

          @media (max-width: $screen-sm-max) {
            & {
              max-width: $table-col-md-lg-min-width;
              flex: 1 0 #{$table-col-md-lg-min-width};
            }
          }
        }

        .col-lg {
          min-width: $table-col-lg-min-width;
          max-width: 45%;
        }

        .table-col-entry-details {
          padding: 0 0 5px 0;
        }
      }

      .info-row {
        .table-col-details {
          vertical-align: middle;

          .icon {
            @include square(15px);
            min-width: 15px;
            @include icon-margin();
          }

          .text-line {
            align-items: center;
            display: flex;
          }

          .table-col-cell {
            display: flex;
            justify-content: center;
            flex: 1 0 100%;
          }

          .table-col-entry-details {
            width: 100%;
          }

          .table-col-entry-details-row {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 0 100%;
          }
        }
      }
    }
  }

  &.tables-container-dual-columns {
    @include table-container-dual-columns();
  }

  @include table-wrapper;

  .tables-container-wrapper-separated {
    padding-bottom: 0px;
    margin-bottom: 0px;

    .table-container {
      .label-header {
        + .tables-container {
          .table-wrapper {
            border-radius: 0;
            padding-top: 0;
          }
        }
      }
    }

    .table-wrapper {
      .table-container {
        .table {
          + .table {
            margin-top: 15px;
          }
        }

        &:last-child {
          padding-bottom: 15px;
        }
      }
    }
  }

  .table-container {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .cqui-table {
      margin-left: 15px;
      margin-right: 15px;
      width: calc(100% - 30px);

      tbody {
        > tr {
          > td {
            padding: 10px;
          }
        }
      }
    }
  }

  .empty-column {
    line-height: 70px;
    text-align: center;
    color: $gray;
    font-style: italic;
    border-top: none;
  }
}

.table-container {
  @include alert-row("info", "global");
  @include alert-row("alert", "global");

  .label-header {
    .section-header {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  > .row {
    margin: 0;

    .section-header {
      margin-left: 0;
      margin-right: 10px;
    }
  }

  @include button-group();
  @include table-wrapper;

  .table {
    border-collapse: unset;
    @include border-radius("all", table);
    margin-bottom: 20px;
    background-color: $table;
    width: 100%;
    table-layout: fixed;

    @include table-fields;

    &.table-outline {
      .table-head {
        border: 1px solid $table-header-outline;
        border-bottom: none;
      }

      .table-body {
        border: 1px solid $table-body-outline;
      }
    }

    & > tbody > tr > td {
      padding: 0;
    }

    .table-col {
      width: 15%;
      word-wrap: break-word;
      padding: 10px;
      border-top: none;

      .table-col-cell {
        display: block;

        .button-row {
          > *,
          .btn {
            margin-bottom: 5px;
          }

          #{$lib-prefix}-dropdown {
            margin-right: 5px;
          }
        }

        .address-details {
          + .button-row {
            margin-top: 10px;
          }
        }
      }

      .table-col-icon {
        @include icon();
        @include square(50px);
        margin-right: 5px;
      }

      p {
        margin: 0;
      }

      .text-group {
        margin-right: 10px;
        flex: 1 0 auto;
        max-width: calc(100% - 155px);
      }

      .text-group + .button-row {
        margin-top: 5px;
        .btn {
          margin-bottom: 5px;
        }
      }

      .text-line {
        display: block;
      }

      .text-line-group-indent {
        margin-left: 20px;
      }

      .text-line-number {
        text-align: right;
      }

      .table-col-entry-details-row {
        display: flex;
        padding: 5px 0;

        @include border("bottom");

        .text-group {
          // Using a fixed width for the text description here, but parent container
          // has a display: flex property which still allows the element to take up
          // enough space on the screen. This value here is an assurance for when
          // the screen shrinks down to its smallest.
          width: 33%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          &.flex-row {
            flex-direction: row;

            &.full-width {
              @include full-width();

              &.children-pair {
                @include split-even-children(2);
                flex-wrap: nowrap;

                *:nth-child(1):nth-last-child(2) {
                  text-align: right;
                  padding-right: 5px;
                  display: flex;
                  justify-content: flex-end;
                }

                *:nth-child(2):nth-last-child(1) {
                  text-align: left;
                }
              }
            }
          }
        }

        .text-flex-align {
          flex: 1 0 33%;

          @media (max-width: $screen-sm-max) {
            & {
              max-width: 33%;
            }
          }
        }

        .text-padding-right {
          padding-right: 10px;
        }

        > * {
          &:first-child {
            position: relative;

            &:not(.cqui-notification) {
              .#{$lib-prefix}-icon {
                &[cqui-error-tool-tip] {
                  @include icon-margin("right");
                  position: absolute;
                  margin-top: 3px;
                  @include square(15px);
                  left: -18px;
                  top: 0;
                }
              }
            }
          }
        }

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }

        > .cqui-notification {
          width: 100%;

          .notification-icon {
            position: relative;
            left: auto;
          }
        }

        .table-col-entry-details-row-wrapper {
          display: flex;
          flex-wrap: wrap;
          max-width: 100%;
          overflow-y: hidden;
          overflow-x: auto;

          &.table-col-details-width-match {
            flex: 1 0 50%;
          }

          .#{$lib-prefix}-dropdown-menu {
            > ng-transclude {
              background-color: transparent;

              #{$lib-prefix}-dropdown-entry-container-section {
                > [role="menusection"] {
                  &:hover {
                    background-color: transparent;
                  }

                  // Allow horizontal scrollbar to appear
                  // for tables which has a lot of columns
                  .table {
                    min-width: 550px;
                    margin: 10px 10px 10px 0;
                    width: calc(100% - 15px);
                    table-layout: auto;
                  }
                }
              }
            }
          }
        }
      }
    }

    @include table-col-to-left();

    .table-header-row {
      tr th {
        background-color: $cerulean;
      }

      tr:first-child td {
        padding: 3px 0;
      }

      .table-col {
        padding: 0 10px;

        &:first-child {
          border-left: none;
          @include border-radius("top-left", table-inside);
        }

        &:last-child {
          @include border-radius("top-right", table-inside);
          vertical-align: middle;
        }
      }
    }

    .table-head {
      color: $table;

      td {
        padding: 3px 10px;
      }

      tr {
        &:first-child td {
          padding-top: 3px;
          padding-bottom: 3px;
        }

        td {
          background-color: $table-row;
          position: relative;
          @include table-header-col-before-hide-gap();
        }
      }

      p {
        color: $white;
        margin: 0;
      }
    }

    .table-body {
      tr td {
        vertical-align: middle;
      }

      tr {
        &:last-child {
          .table-col {
            &:first-child {
              @include border-radius("bottom-left", table);
            }

            &:last-child {
              @include border-radius("bottom-right", table);
            }
          }
        }
      }

      .table-col {
        @include border-radius("all", 0);
      }
    }
  }

  .table-container-notification {
    padding: 0 15px 10px;
  }
}

.tables-container-blanket-waiver {
  .table-col {
    text-align: center;

    .table-col-cell {
      @include datepicker-calendar;
    }

    &.table-col-blanket-waiver-id {
      width: 6.38%;
    }

    &.table-col-blanket-waiver-description {
      width: 22.06%;
    }

    &.table-col-blanket-waiver-state {
      width: 10.75%;
    }

    &.table-col-blanket-waiver-wc-law {
      width: 12.654%;
    }

    &.table-col-blanket-waiver-effective-date {
      width: 19.597%;
    }

    &.table-col-blanket-waiver-date-divider {
      width: 2.35%;
    }

    &.table-col-blanket-waiver-expiration-date {
      width: 19.597%;
    }

    &.table-col-blanket-waiver-delete {
      width: 5.487%;
    }
  }

  .table-body {
    .table-col-blanket-waiver-delete {
      padding-left: 0;
    }
  }
}

.cqui-table {
  border-collapse: separate;
  @include table-error-row;
  @include table-fields;

  &.table-header-left {
    & > thead {
      & > tr {
        & > th {
          text-align: left;
        }
      }
    }
  }

  &.table-body-text-center {
    @include table-body-td {
      @include text-center-selector(current);
    }
  }

  &.table-body-text-left {
    @include table-body-td {
      @include text-left-selector(current);
    }
  }

  &.table-input-tall {
    td {
      .form-control {
        min-height: 35px;
      }
    }
  }

  &.table-input-type {
    tbody {
      .field-row {
        border-bottom: none;

        .field-input {
          &:only-child {
            width: 100%;
            padding-right: 0;
          }
        }
      }
    }

    .col-lg {
      min-width: map-get($table-col-min-width, lg);
    }

    .col-sm {
      width: map-get($table-col-min-width, sm);
    }
  }

  .table-header-row {
    .table-col {
      line-height: 1.15;
    }

    th:first-child {
      text-align: left;
    }
  }

  .table-content-flex {
    @include flex;
  }

  .table-content-flex-content-right {
    justify-content: flex-end;
  }

  .table-content-flex-col {
    @include flex-col;
  }

  .table-cell-bold,
  .table-cell-content-bold {
    @include text-bold();
  }

  .table-col-with-icon {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .table-cell-with-icon {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .table-col-icon-and-text {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .table-col-icon {
    @include icon();
    @include square(20px);
    margin-right: 5px;

    &.icon-building {
      width: 20px;
      height: 15px;
    }
  }

  @include button-group();

  &.available-lookups {
    .vin-column,
    .makemodel-column {
      width: 33%;
      max-width: 33%;
      word-break: break-word;

      span:not(.icon) {
        display: flex;
        flex: 1 0;
      }
    }
  }

  &.available-lookups-details {
    .vin-column,
    .makemodel-column {
      width: 20%;
      max-width: 20%;
    }

    .type-column {
      width: 20%;
    }

    .original-cost-column {
      width: 15%;
    }

    .location-column {
      width: 25%;
    }
  }

  @include table-auto-add-driver();

  @media (max-width: $screen-sm-max) {
    &.available-lookups-details {
      .vin-column,
      .makemodel-column {
        width: 25%;
        max-width: 25%;
      }

      .type-column {
        width: 15%;
      }

      .original-cost-column {
        width: 15%;
      }

      .location-column {
        width: 20%;
      }
    }
  }
}

@include tables-container-wrapper();
