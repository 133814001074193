.main-footer {
  display: flex;
  padding: 10px 15px;
  justify-content: space-between;
  line-height: 1.15;
  position: relative;
  background-color: $ship-gray;

  font-size: font-size("main-footer");

  .link-group {
    display: flex;
    flex: 1 0 auto;
    text-align: left;
    align-items: center;

    .app-brand-logo {
      margin-right: 15px;
      color: $white;
      font-size: font-size("footer-app-brand-logo");
      margin-bottom: 0;
      display: flex;

      .app-brand-mark {
        font-size: 0.9rem;
        margin-top: -0.125rem;
        display: block;
      }
    }

    .link-entry-link {
      color: $white;

      &:after {
        content: "|";
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    .link-entry {
      font-size: 0.83rem;

      &:last-child {
        .link-entry-link {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .copyright-notice {
    margin: 0;
    color: $white;
    flex: 1 0 auto;
    font-size: 0.82rem;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
