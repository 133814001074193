@import "prerequisites";

.no-opacity {
  opacity: 0;
}

.full-opacity {
  opacity: 1;
}

.no-visibility {
  visibility: hidden;
}

.full-visibility {
  visibility: visible;
}

.hidden-overflow {
  overflow: hidden;
}

.light-blue {
  background-color: $light-blue;
}

.powder-blue {
  background-color: $powder-blue;
}
