.ae-zipcode {
  .zip-city-state-field {
    display: inline-flex;
    position: relative;

    .zip-code-field {
      width: 20%;
    }
    .input-city-state-disabled-field {
      width: 100%;
      margin-left: 2px;
      &.form-control-placeholder-disabled-bold {
        @include placeholder($input-disabled-placeholder-bold);

        &.placeholder-hint {
          @include placeholder($color: $input-disabled-placeholder-less-bold);
        }
      }
    }
    .input-city-field {
      width: 65%;
      margin-left: 2px;
    }

    .input-state-field {
      width: 15%;
      margin-left: 2px;
    }

    .multiple-addresses-return {
      width: 100%;
      margin-left: 10px;
    }

    .zipcode-verify-message {
      display: flex;
      align-items: center;
      justify-content: center;
      border-style: solid;
      border-color: #999;
      border-width: thin;
      position: absolute;
      left: 0;
      width: calc(100% - 15px);
      height: 26px;
      background-color: rgba(178, 218, 231, 0.7);
    }

    &.zip-lookup-unsuccessful {
      .input-city-field,
      .input-state-field {
        display: none;
      }
    }
  }

  // Forcefully override some styles coming from the mockup site
  .zip-field {
    padding-left: 5px !important;
    padding-right: 3px !important;
  }

  .gear-group-loader-zipcode-popup {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .display-inline-flex.ng-scope {
    display: inline-flex;
    .address-description-zipcode-service.ng-binding {
      color: #005e7a;
      font-family: Corbel, "Lucida Grande", "Lucida Sans Unicode",
        "Lucida Sans", "DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans",
        Verdana, "Verdana Ref", sans-serif;
      margin-left: 5px;
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }

  .form-description.control-label.ng-scope {
    float: right;
    left: 0;
    padding-bottom: 5px;
  }

  .gear-group {
    position: relative;
    top: 0;
    width: 400px;
    height: 400px;
  }

  .gear {
    position: absolute;
  }

  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-moz-keyframes rotate {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .small-gear.gear {
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -webkit-animation-direction: reverse;
    -moz-animation: rotate 2s linear infinite;
    -moz-animation-direction: reverse;
    -ms-animation-name: rotate;
    -ms-animation-duration: 2s;
    -ms-animation-direction: reverse;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    width: 46%;
    right: 5px;
    top: -2px;
    position: absolute;
  }

  .large-gear.gear {
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation: rotate 3s linear infinite;
    -ms-animation-name: rotate;
    -ms-animation-duration: 3s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    width: 73%;
    bottom: 0;
    position: absolute;
  }
}

.section .section-split-subsections-half .subsection {
  .ae-zipcode .zipcode-verify-message {
    width: 100%;
  }
}
