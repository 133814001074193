// from assets/css/main.css.
.info-message-outer-container {
  float: left;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: flex-center;
  -ms-flex-align: center;
  align-items: center;
  height: 26px;
  margin-left: 4px;
}

.info-message-outer-container .tooltip-icon,
.info-message-container .tooltip-icon {
  width: 15px;
  height: 14px;
}

.info-message-wrapper {
  display: block;
  position: absolute;
  z-index: 10;
  top: 0px;
  width: 275px;
}
.field-min-tooltip {
  left: 75px;
}

.info-message-container {
  background-color: #e5f4ff;
  float: left;
  padding: 4px 7px;
  border: 1px solid #ddd;
  box-shadow: 4px 4px 8px #888888;
  display: block;
  position: absolute;
  z-index: 10;
  top: 0px;
  width: 275px;
}

.info-message-container .tooltip-text {
  min-width: 80px;
  width: calc(100% - 27px);
  float: left;
  white-space: pre-line;
}

.info-message-container .tooltip-icon {
  margin-right: 5px;
  margin-top: 4px;
  float: left;
}

// input datepicker styles
.account-date-picker {
  float: left;
}
.account-date-picker input {
  float: left;
  width: 110px;
}

// input error message styles
.error-text-field,
.form-control-validated.ng-invalid.ng-touched {
  border: 1px solid #cc0000;
  background: #ffcccc !important;
}

.collapsible-container-group.section
  .section-group.section-group-highlighted-light
  .section
  .section-split-subsections-half
  .subsection {
  .zipcode-verify-message {
    width: 100%;
  }
}

.hidden {
  visibility: hidden;
  display: none;
}

ui-view {
  float: none;
}

.field-row .form-control:disabled {
  background-color: #d6d6d6;
  border: 1px solid #999;
  font-family: inherit;
  color: #000;
}

.center-aligned {
  display: flex;
  align-items: center;
}

.address-single-text {
  display: block;
  width: 175px;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.navbar-container {
  .disabled {
    pointer-events: none;
  }
}

.typeahead.dropdown-menu,
ul[uib-typeahead-popup] {
  z-index: 2000;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  > li > a {
    white-space: normal;
  }
}

::-ms-clear {
  display: none;
}
