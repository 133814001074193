.ae-auto-vehicle-details {
  .field-input-year-make-model > * + * {
    margin-left: 5px;
    margin-right: 0px !important;
  }

  .field-input > .inline-description {
    line-height: 1.5;
  }
}
