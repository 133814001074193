@import "prerequisites";

.cqui-notification {
  $notification-padding-top: 7px;
  $notification-margin-top: 5px;
  $notification-padding-sides: 5px;

  @mixin notification-container {
    @include border-radius();
    border: none;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: #{$notification-padding-top} #{$notification-padding-sides};
    font-size: $base-font-size;
    color: $black;
    display: flex;
    align-items: center;
    max-width: 100%;
    flex-wrap: wrap;
  }

  @include notification-container;

  a {
    color: $cerulean;

    &:hover {
      color: $light-medium;
    }
  }

  .notification-content {
    display: flex;
    align-items: center;
    flex: 1 0 100%;

    &.text-align-right {
      justify-content: flex-end;
    }

    &.text-align-center {
      justify-content: center;
    }
  }

  .notification-icon {
    @include square($inline-icon-height);
    min-width: $inline-icon-height;
    @include icon();
    margin-right: 5px;
    align-self: baseline;
  }

  .notification-text {
    display: flex;
    align-items: center;
    justify-content: center;

    &.notification-text-bold {
      font-weight: bold;
    }

    &.notification-color-gray {
      color: $dove-gray;
    }
  }

  .notification-text-content {
    &.notification-text-content-italicized {
      font-style: italic;
    }
  }

  .notification-text-content-hint {
    font-weight: normal;
    font-size: font-size("notification-text-content-hint");
  }

  .notification-text-container {
    width: 100%;
  }

  &.alert-danger {
    background-color: $alert-background-pink;
  }

  &.alert-caution {
    background-color: $caution-background-yellow;
  }

  &.alert-info-warning {
    background-color: $exclamation-background;
  }

  &.alert-exclamation-warning,
  &.alert-info-warning,
  &.alert-alert,
  &.notification-show-border {
    @include border("all", $black);
  }

  &.notification-border-red {
    border-color: red;
  }

  &.notification-background-white {
    background-color: white;
  }

  &.notification-background-none {
    background-color: transparent;
  }

  &.notification-medium {
    padding: 5px;

    .notification-icon {
      @include square($expansible-container-medium-header-icon-height);
    }

    .notification-text-container {
      display: flex;
      flex-direction: column;
    }

    .notification-text-content {
      line-height: 1;
      width: 100%;
      font-size: font-size("expansible-container-medium-header-title");
    }

    .notification-text-content-hint {
      font-weight: 700;
      font-size: font-size("expansible-container-medium-header-subtitle");
      font-weight: normal;
      display: flex;
    }
  }

  &.notification-large {
    .notification-content {
      min-height: calc(
        75px - #{2 * ($notification-padding-top + $notification-margin-top)}
      );
    }

    .notification-icon {
      @include square($expansible-container-large-header-icon-height);
    }

    .notification-text-content {
      font-size: font-size("expansible-container-large-header-title");
      line-height: 1;
      font-weight: normal;
      display: flex;
    }

    .notification-text-content-hint {
      font-weight: 700;
      font-size: font-size("expansible-container-header-subtitle");
      display: flex;
    }

    .notification-text-container {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &.notification-compact,
  &.notification-ultra-compact {
    .notification-icon {
      @include square(15px);
      min-width: 15px;
      margin-top: 3px;
    }
  }

  &.notification-ultra-compact {
    padding: 0 #{$notification-padding-sides};
  }
}
