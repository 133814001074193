.ae-account-setup {
  // from assets/css/business-info.css
  .form-description .note {
    font-size: 10pt;
    float: right;
    margin-top: -5px;
  }

  .field-label {
    // from assets/sass/common/_frame.scss
    .form-description,
    .form-description-boolean {
      line-height: 1.3em;
      text-align: right;
      left: 0;
      margin-right: -7px;
      float: right;

      // from assets/css/account-setup.css
      .required {
        top: 0px;
        position: relative;
        right: 0px;
      }
    }
  }
  .section-split-subsections-half {
    .subsection {
      &:last-child {
        @include border("left");

        padding-right: 0;
        margin-left: 10px;
        width: calc(50% - 10px);

        .field-row:last-child {
          margin-bottom: 5px;
        }
      }
    }
  }

  .subsection:first-child:nth-last-child(1),
  .subsection:first-child:nth-last-child(2),
  .subsection:first-child:nth-last-child(2) ~ .subsection {
    .section-header {
      padding-left: 15px;
    }
  }

  .first-name.field-input {
    @include make-sm-column(2);
    padding-left: 0;
    padding-right: 5px;
  }

  .last-name.field-input {
    @include make-sm-column(2);
    padding-left: 0;
    padding-right: 5px;
  }

  .suffix.field-input {
    @include make-sm-column(2);
    padding-left: 0;
  }

  .mid-name.field-input {
    @include make-sm-column(1);
    padding-left: 0;
    padding-right: 5px;
  }

  .field-input-indicator {
    .cqui-informational-indicator {
      position: absolute;
      top: 5px;
    }
    .tooltip-container {
      max-width: none;
    }
  }
  .form-description-boolean {
    margin-right: 0;
  }

  .gpa-input-ctrl,
  .gpa-ctrl-group {
    .col-xs-12 {
      width: auto;
      float: none;
      position: inherit;
      min-height: inherit;
      padding-left: inherit;
      padding-right: inherit;
    }
  }

  .copyright-notice {
    width: auto;
  }

  .account-namedinsured-form {
    background: white;
    padding: 20px 0px;
    border-radius: 5px;
    border: 1px solid #999;
  }

  .ky-exemption-notification {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 10px;

    .icon-exclamation-warning {
      height: 16px;
      width: 16px;
      background-size: 100% 100%;
      margin-right: 5px;
    }
  }
  .is-diff-mail-addr,
  .is-diff-billing-addr {
    .section-header {
      margin-left: 0;
    }
    .field-input {
      padding-right: 0;
    }
  }

  .ae-named-insured-dynamic-entity {
    .field-description {
      line-height: 1em;
      .note {
        font-size: 10pt;
      }
    }

    .entity-buttons {
      position: absolute;
      top: 0;
      right: -45px;
      width: 55px;
    }
  }
  .coverage-period-information-text {
    .inline-description-icon {
      min-width: 20px;
      min-height: 20px;
    }
  }

  .button-edit-left {
    float: left;
  }
  .account-address-table-col {
    text-align: left;
  }
}
