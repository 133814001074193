@import "prerequisites";

.pill-container {
  @include pill-container();

  .pill-label {
    @include pill-label();
  }

  .pill-value {
    @include pill-value();
  }
}
