@use "units";
@use "layout";

@import "@clearquote/ui/lib/assets/scss/_prerequisites";

.main-app {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
}

// IE Fix for the 'main-form-container' height value differs from
// the other major browsers, which causes content overflow when zoomed in
// at a high level

.main-page-container {
  .main-form-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    ui-view {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .main-form-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    overflow-y: hidden;

    uib-accordion {
      .section-subsection {
        padding: 0;

        @include subsection-confirmation();
      }

      #{$lib-prefix}-field-row-expansible-content {
        > ng-transclude {
          > .section-subsection {
            padding: 15px;

            + .section-subsection {
              padding-top: 0;
            }
          }
        }
      }

      @include section-divider-settings();
    }
  }

  .main-form-container-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  & {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    overflow: hidden;
    width: layout.$layout-width;
    box-shadow: units.px(-4) 0 units.px(3) units.px(-2) $shadow;

    .main-form-container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .main-form-input-section {
      display: flex;
      flex-direction: column;

      > .section:first-child {
        &.section-header-row {
          .section-header {
            margin-top: 0;
          }
        }
      }

      > .section-notification {
        display: flex;
        flex-direction: column;
        padding: 15px 15px 20px;
        margin-bottom: 10px;

        .section-header {
          padding-left: 0;
        }

        .section-header-icon {
          margin-right: 7px;
        }

        .cqui-notification {
          .notification-icon {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@include layout.layout-medium {
  .main-page-container {
    width: layout.$layout-medium-width;
  }
}

@include layout.layout-small {
  .main-page-container {
    width: layout.$layout-small-width;
  }
}

.content-container,
.nav-container {
  padding: 0;
  width: 100%;
}

.nav-container {
  position: relative;
}

.content-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.cqui-main-nav-bar {
  + .content-container {
    height: 100%;
    position: relative;
  }
}

.main-form-container {
  background-color: $white;
  position: relative;
  height: auto;
  z-index: 2;
}

.main-form-top-description {
  margin: 20px 30px;
  line-height: 1.3em;
  padding-right: 44px;
  display: inline-block;
}

.main-form-input-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100% - 26px);
  @include section;
}

.main-form-buttons {
  .main-form-buttons-group {
    &:first-child {
      float: left;
    }

    &:last-child {
      float: right;
    }
  }
}

.is-sub-page {
  background-image: bg-linear-diagonal($boulder, $dove-gray);

  .main-page-container {
    .main-form-input-section {
      > :not(cqui-collapsible-container-group) {
        .section-subsection {
          &:first-child {
            padding-bottom: 0;
          }

          + .section-subsection {
            margin-top: 0;
            padding-bottom: 0;
          }
        }
      }

      > .section-header-row {
        display: flex;
        flex: 0 1 auto;
        height: auto;
        overflow: hidden;

        + .section {
          flex: 0 1 auto;
        }
      }
    }
  }

  .icon-address {
    &.active {
      @include image-url("icon-address-available.svg");
    }
  }

  .icon-class-building-details {
    &.active {
      @include image-url("icon-class-building-details-available.svg");
    }
  }

  .icon-qualification-questions {
    &.active {
      @include image-url("icon-qualification-questions-available.svg");
    }
  }
}
