@include text-center-selector();
@include text-left-selector();

@include informational-icon();

.visibility-hidden {
  visibility: hidden;
  visibility: hidden;
}

.word-break {
  @media (min-width: $screen-lg) {
    display: none;
  }
}

.required {
  color: $hot-pink;
  top: 0;
  font-size: 2.05em;
  @include font-weight-base();
  @include font-light();
}

.sort-indicator {
  @include square(12px);
  min-width: 12px;
  @include icon();
  @include image-url("icon-sort-white.svg");
}

.text-center {
  @include text-center;
}

.text-bold {
  @include text-bold;
}

.text-italic {
  font-style: italic;
}

.text-overflow-hidden {
  text-overflow: ellipsis;
  overflow: hidden;
}

@include full-width();

.field-icon {
  @include icon();
  @include square(15px);
  min-width: 15px;
  min-height: 15px;
}

.flex-row {
  flex-direction: row;
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.field-small {
  width: 150px;
}

.align-items-center {
  align-items: center;
}

.justify-content-end {
  justify-content: flex-end;

  @include button-group();
}

.width-auto {
  width: auto;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.associated-grouping {
  @include border("top", blue);
}

.bullet-listing-grouping {
  .section-header {
    margin-bottom: 5px;
  }

  > .section-header {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bullet-listing-group {
  p {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .sub-bullet-list {
    font-size: 11pt;
    padding-left: 10pt;

    .sub-bullet-list-header {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .sub-bullet-list-content {
      margin-bottom: 5pt;
      line-height: 11pt;
    }
  }
}

.legend-group {
  display: flex;
  align-items: flex-start;
  flex: 2;
  flex-wrap: wrap;
  float: left;
}

.legend-container {
  display: flex;
  flex-wrap: wrap;

  .legend-entry {
    margin-right: 10px;
  }

  .legend-text {
    margin-bottom: 0;
    display: flex;
  }

  .legend-key {
    font-weight: 700;
    font-size: font-size("field-legend-key");
    position: relative;
    margin-right: 7px;

    &:before {
      content: ":";
      position: absolute;
      right: -5px;
      @include font-weight-base();
    }
  }

  .legend-value {
    font-size: font-size("field-legend-value");
  }
}

.input-group-addon {
  border-radius: 0;
  border-color: $input-border;
  font-size: $base-font-size;
  min-height: $field-min-height;
  width: $field-min-height;
  max-height: $field-min-height;
  @include size(auto, 100%);
  min-width: $input-group-addon-min-width;
  padding: 2px 5px;
  line-height: 1.4;
  text-align: center;
}

.field-row {
  @include make-row();
  display: flex;
  margin: 0 0 5px;

  &.field-divider-related {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      @include border("bottom", blue, 1px);
      background-color: $cerulean;
      left: 0;
    }
  }

  &.field-row-last {
    .field-input {
      padding-bottom: 5px;
    }
  }

  &.field-row-bottom-border {
    .field-input {
      @include input-border();

      padding-bottom: 5px;
    }

    margin-bottom: 0;
  }

  @include field-row-vertical();

  & ~ .section-subsection {
    margin: 15px 0;

    + .section-subsection {
      margin-top: 0;
    }

    .field-row:last-child {
      border-bottom: none;
    }
  }

  @include field-row-notification();

  input::placeholder {
    color: $input-placeholder-text;
  }

  @include required();

  @include field-label-class();

  .field-label {
    @include make-sm-column(5);

    ng-transclude {
      display: flex;
      flex-direction: row;

      .icon-information-circle {
        margin-left: 3px;
      }
    }
  }

  .form-control {
    display: inline;
    padding: 2px 3px 3px 5px;
    color: $black;
    background-color: $input;
    @include border("all", input);
    @include border-radius("all", 0);
    font-size: $base-font-size;

    &.input-sm {
      height: $field-min-height;
    }

    & ~ .tooltip-container {
      & + .field-icon {
        margin-left: 5px;
      }
    }

    &[cqui-error-tool-tip-active="true"] {
      @include input-error-outline();
    }

    &[tooltip-error-type="true"] {
      @include input-error-outline();
    }

    &[tooltip-caution-type="true"] {
      @include input-caution-outline();
    }

    &[disabled] {
      background-color: $input-disabled;

      &.form-control-placeholder-disabled-bold {
        @include placeholder($input-disabled-placeholder-bold);

        &.placeholder-hint {
          @include placeholder($color: $input-disabled-placeholder-less-bold);
        }
      }
    }

    + .btn {
      margin-left: 5px;
    }

    &[prefilled="true"] {
      border: 1.5px solid #265d77;
    }
  }

  &:not(.field-row-level) {
    .expansible-type-embed {
      &:before {
        @include square(0);
      }
    }
  }

  .input-size {
    width: 100%;
    height: $field-min-height;
  }

  .coverage-period {
    width: calc(100% - 21px);
  }

  .coverage-period-narrow {
    width: calc(50% - 21px);
  }

  .date-from,
  .date-to {
    width: calc((100% - 25px) / 2);
    display: flex;
    padding: 0;
    align-items: center;
    float: left;
  }

  .date-to {
    float: right;
  }

  .date-form-divider {
    float: left;
    height: auto;
    /*
			IE has rounding problems with calc and width,
			suppose to be 25px but using one 1px less to avoid
			unintended wrapping
		*/
    width: 24px;
  }

  .date-form-divider-marker {
    height: 2px;
    background-color: $input-date-divider;
    width: calc(100% - 15px);
    display: inline-block;
    margin-left: 7.5px;
  }

  @include datepicker-calendar;

  &.zip-field-row {
    .field-input-multiple {
      @include field-input-multiple-zip-city-state();

      &:last-child {
        margin-right: 0;
      }

      .cqui-cover {
        width: calc(100% - 15px);
      }
    }
  }

  &.field-input-multiple {
    &.field-input-section-township-range {
      .field-input {
        margin-right: 5px;
        height: auto;
        flex: 0 1 calc((100% - 10px) / 3);

        &:last-child,
        &:nth-of-type(3),
        &:nth-of-type(4) {
          margin-right: 0;
        }

        &:nth-of-type(4) {
          margin-top: 5px;
        }
      }

      &[#{$lib-prefix}-component] {
        > ng-transclude {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }

  + .button-row {
    @include button-row-blue-overline {
    }

    &.button-row-no-underline {
      margin-top: 0;

      .button-row-group {
        padding-top: 0;
        border: none;
      }
    }
  }

  + .cqui-expansible-container {
    margin-top: 10px;
  }

  min-height: $field-min-height;

  .field-container {
    width: 100%;
    height: 100%;

    .tiles-container {
      .tiles-group {
        .tile {
          max-width: 100%;
        }
      }
    }

    .field-row {
      .field-input {
        @include input-group();
      }
    }

    .field-full-width {
      width: 100%;
    }

    .question-type-input {
      max-width: 100%;

      .section-header-row {
        .section-header {
          font-size: $base-font-size;

          #{$lib-prefix}-required-indicator {
            position: relative;
            left: -1px;
          }
        }
      }

      @include input-group();

      &.input {
        margin-right: 5px;
      }
    }

    .notification-field {
      margin-top: 0;
      margin-bottom: 5px;
    }

    .expansible-list-table {
      display: flex;
      flex-direction: column;
      flex-flow: column-reverse;
      background-color: $expansible-container;
      @include border-radius();

      .expansible-list-table-body {
        display: flex;
        padding: 5px 15px 15px;
      }
    }

    .expansible-common {
      .expansible-container {
        background-color: $expansible-container;
        height: 100%;

        .expansible-header {
          font-weight: 700;
          padding: 0;
          position: relative;

          &:after {
            height: 0;
            background-color: transparent;
          }

          .expansible-container-middle-content {
            margin: 0;
            flex: 1 0 auto;
          }

          @include expansible-container-header-end();

          .header-title-text {
            font-size: font-size("expansible-header-title-text-common");
            line-height: 1.2;
          }

          .header-text {
            text-align: right;
            overflow: hidden;
          }
        }

        .expansible-content {
          padding: 0;
          height: 100%;

          .field-row-nested {
            height: 100%;
          }

          .notification-field {
            margin-left: 10px;
            margin-right: 10px;
          }
        }

        .section-fields-underlined {
          & > :first-child {
            &.field-row-nested-group {
              > .field-row-first-list-child {
                border-top: none;
                padding-top: 10px;
              }
            }
          }

          .field-row {
            &.field-row-first-list-child {
              border-bottom: none;

              .field-input {
                &.field-notification {
                  margin-right: 0;
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }

    .expansible-type-embed {
      position: relative;

      &.expansible-type-top-level-bold {
        .section-fields-underlined {
          .field-row-nested-group {
            .field-row-first-list-child {
              @include border("top", gray);
            }
          }

          .field-row {
            &.field-row-first-list-child {
              border-bottom: none;
            }
          }
        }

        .field-row {
          &.field-row-first-list-child {
            .field-description {
              font-weight: bold;
            }
          }
        }
      }

      &.collapsed {
        .expansible-container {
          .expansible-header {
            flex: 0 1 auto;

            &:after {
              height: 0;
              background-color: transparent;
            }
          }
        }
      }

      &.expanded {
        .expansible-container {
          .expansible-content {
            > ng-transclude {
              @include expansible-container-field-row-nested();
            }
          }

          .expansible-header {
            @include expansible-container-embed-divider(
              "after",
              (
                width: calc(100% - 20px),
                left: 10px,
                bottom: 0.3px,
                top: auto,
              )
            );

            flex: 0 1 auto;

            @include expansible-container-header-divider();

            .header-end {
              @include border-radius("bottom-right", 0);
            }
          }
        }
      }

      .expansible-container {
        background-color: $expansible-container-embed;
        position: relative;
        height: auto;

        &.no-header {
          background-color: transparent;
        }

        @include expansible-container-embed-divider("after");

        .expansible-header {
          font-weight: normal;

          .header-end {
            cqui-expansible-header-end {
              padding: 0 3px;
            }
          }

          .header-title-text {
            font-size: $base-font-size;
            line-height: 1.2;
            margin: 5px 0;

            .header-title-text-wrapper {
              display: flex;
              flex: 0 1 auto;
              justify-content: flex-end;
              position: relative;

              .header-title-text-content {
                padding-right: 5px;
              }

              #{$lib-prefix}-required-indicator {
                max-height: 15px;
                top: -5px;

                > span {
                  position: relative;
                  top: -5px;
                }
              }
            }
          }

          .expand-text {
            font-size: font-size("field-expand-text");
            font-style: italic;
            margin-left: 5px;

            &.no-italic {
              font-style: normal;
            }

            &.custom-content {
              margin-left: 0;
              display: block;
            }
          }
        }
      }

      &[expansible-container="expansible-header-right-large"] {
        .header-text {
          @include label-width(
            $field-row-expansible-group-label-width-small-screen-width
          );
        }

        @include field-static();
      }

      @media (min-width: $screen-md-max) {
        &[expansible-container="expansible-header-right-large"] {
          .header-text {
            @include label-width($field-row-expansible-group-label-width);
          }
        }
      }
    }

    &.field-container-input {
      @include make-xs-column-offset(4);
      @include make-sm-column(7);
      padding: 0;

      .expansible-content {
        padding-left: 10px;
        padding-right: 10px;
      }

      .tiles-group {
        .tile {
          min-width: 40%;
        }
      }
    }

    @include table-header-asterisk-in-header();
  }

  .field-row-level {
    width: 100%;
    display: flex;
    @include field-question-type();
  }

  .field-label {
    @include make-sm-column(percent-to-bootstrap-grid-unit($label-width));
    min-height: $field-min-height;

    + .field-static {
      padding-left: 0;
      @include make-sm-column(
        percent-to-bootstrap-grid-unit($input-static-width)
      );
      padding-left: 0;
    }
  }

  .field-label + * {
    padding-left: 0;
    margin-left: 0;
  }

  .field-static {
    display: flex;
    align-items: center;
    min-height: $field-min-height;

    &.field-static-bold {
      @include text-bold();
    }
  }

  .field-input {
    @include make-sm-column(7);
    padding-left: 0;
    padding-right: 15px;
    margin-left: 0;
    display: flex;

    [disabled] {
      cursor: not-allowed;
      .question-type-input {
        &.toggle-radio-yes-no {
          display: flex;
        }
      }
    }

    @include input-group();

    &.field-input-btn-group {
      .cqui-icon-button {
        margin-right: 5px;
      }
    }

    &.field-input-bold {
      font-weight: bold;
    }

    .question-type-input {
      + .input-right-template-container {
        > [template="info-icon"] {
          height: 100%;

          cqui-info-indicator {
            display: flex;
            align-items: center;
            height: 100%;
          }
        }
      }

      + .input-bottom-template-container {
        width: 100%;
      }

      &.button {
        margin-right: 5px;
      }

      > ng-form {
        display: flex;
      }
    }

    .question-type-value-edit {
      display: flex;

      .field-static {
        display: block;
        margin-right: 5px;
      }
    }

    &.field-input-sm {
      @include field-input-sm;
    }

    &.field-input-multiple {
      display: flex;

      @include make-sm-column(7);

      margin-left: 0;
      padding-left: 0;

      @include fields-input-name();

      input,
      select {
        &:not(:last-child),
        &:not(:first-child) {
          margin-right: 5px;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }

    &.field-input-bottom-border {
      @include input-border();
    }

    .form-control {
      &.multi-tag-selection-group {
        height: auto;
        min-height: 38px;
        max-height: 135px;
        overflow: auto;
        padding: 2.5px;
        display: flex;
        cursor: pointer;

        &.subsequent-activate {
          cursor: default;
        }

        button {
          &[icon-class="icon-add-blue"] {
            background-color: $multi-tag-button;
            border-color: $multi-tag-button;
          }
        }

        .multi-tag-selection-subgroup {
          &:first-child {
            align-self: flex-start;
            flex: 1 0 45%;
          }

          &:last-child {
            align-self: center;
          }
        }

        .multi-tag {
          min-height: $field-min-height;
          min-width: 115px;
          background-color: $multi-tag;
          padding: 0 7px;
          margin: 2.5px;
          @include border-radius();
          width: auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: $multi-tag-text;

          .multi-tag-text {
            + .btn {
              margin-left: 5px;
            }
          }

          &:hover {
            cursor: pointer;
          }

          &.unchangeable {
            cursor: not-allowed;
          }

          button {
            border: none;
            padding-right: 0;
            background-color: transparent;
            outline: none;
            box-shadow: none;

            &:active,
            &:active:hover {
              outline: none;
              background-color: transparent;
            }
          }

          .btn-icon {
            &.icon-delete-white {
              width: 15px;
              height: 15px;
            }
          }
        }
      }

      ~ {
        @include informational-icon("margin-left");
      }
    }

    &.field-input-info {
      @include field-input-info;
    }

    @include field-input-group-auto-width();

    ~ .btn {
      &.btn-default {
        min-width: 0;
      }
    }

    .input-button-group {
      margin-left: 5px;
      display: flex;

      &.input-button-group-pair {
        min-width: #{2 * ($btn-icon-width + (2 * $btn-icon-padding) + 5px)};
      }

      .btn {
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    // For IE not allowing the notification container to span
    // the full width of its outside container
    @include field-input-notification();
    .cqui-notification {
      width: 100%;
    }
  }

  .fein-field {
    width: 25%;
    float: left;
  }

  .fein-input {
    padding-right: 2%;
  }

  .state-field {
    width: 25%;
    float: right;
  }

  @include field-full-input();

  .phone-field,
  .email-field {
    width: 25%;
  }

  .search-field {
    width: 30%;
  }

  &.coverage-period-field,
  &.search-field {
    @include field-input-notification();
  }

  @include field-text-area();
  @include field-row-right-large();
  @include search-field-row(false);
}

.field-input-group {
  display: flex;
  flex-direction: column;
  width: 100%;

  .field-input {
    margin-bottom: 5px;
  }

  .btn {
    min-height: $field-min-height;
    width: 100%;
    height: auto;
    display: flex;

    .button-icon {
      min-width: 10px;
      margin-right: 5px;
      margin-left: 0;
      margin-top: 9px;
    }

    .button-text {
      margin: 5px 0;
      white-space: normal;
      text-align: left;
      line-height: 1.2;
      display: flex;
      align-items: center;
    }
  }
}

@include field-labels-wide();

.date-from,
.date-to {
  display: flex;
  align-items: center;

  #{$lib-prefix}-date-picker {
    width: 100%;
  }
}

.date-to {
  #{$lib-prefix}-date-picker {
    margin-left: 5px;
  }
}

.field-input {
  &.field-input-type-number,
  &.field-input-type-currency {
    margin-right: 5px;

    input {
      text-align: left;
    }
  }

  .field-input-premises-id {
    min-width: 200px;
    margin-bottom: 10px;
  }
}

.field-input-with-information-box-below {
  width: 70%;
  display: flex;
}

.field-description {
  @include field-description-props();

  .field-description-note {
    display: inline-block;
    width: 100%;
    font-size: #{$base-font-size - 2pt};
  }

  .field-description-prefix {
    font-weight: bold;
    margin-right: 10px;
  }

  &.field-description-bold {
    font-weight: bold;
  }
}

@include zip-code-field;

.search-bar-container {
  flex: 1 0 auto;
  align-items: center;
  display: flex;
  justify-content: flex-end;

  .field-row {
    flex: 0 1 250px;
    margin-bottom: 0;

    .field-input {
      width: 100%;
    }

    .form-control {
      padding-right: 45px;

      + button {
        position: absolute;
        top: 0;
        right: 15px;
        border-radius: 0;
        padding: 0 9px;
      }
    }
  }
}

.search-field {
  button {
    margin-left: 7px;
  }

  .field-label {
    @include make-sm-column(6);
    min-height: $field-min-height;
  }

  .field-input {
    display: flex;
    @include make-sm-column(4);
    padding-left: 0;
  }
}

.inline-field {
  margin-bottom: 5px;
}

.notification-field {
  @include border("all");
  border-color: $inline-description-border;
  @include border-radius();
  background-color: $inline-description;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  line-height: 1.14em;

  .notification-icon {
    @include icon();
    @include square(20px);
    min-width: 20px;
    margin-right: 5px;
  }

  .notification-text {
    line-height: 1.3;
    width: 100%;
    color: $inline-description-text;
    padding-right: 10px;
    display: flex;
    margin: 0 auto;
  }
}

.header-field {
  @include make-row();
  display: flex;

  .section-header {
    padding-left: 15px;

    .required {
      font-size: font-size("section-header-required");
    }
  }

  &.header-field-center {
    @include make-xs-column-offset(2);
    @include make-lg-column-offset(4);
    margin-right: 10%;
  }

  &.header-field-bold {
    .section-header {
      margin-top: 0;
      margin-bottom: 5px;
      font-weight: bold;
    }
  }

  .section-header-icon {
    @include header-field-icon;

    + .section-header {
      margin-left: 0;
      margin-top: 0;
    }
  }

  + .section-bar-listing {
    margin-top: 7px;
  }

  ~ .section-description {
    margin: auto;
    padding-left: 15px;
  }
}

.header-description {
  padding-bottom: 10px;
}

textarea {
  &.no-resize {
    resize: none;
    overflow: hidden;
  }

  min-height: 25px;
}

@include class-is-required();
@include field-miscellaneous();
