@use "units";

.cq-checkbox-button-grid {
  .mat-button-toggle-button {
    text-align: start; // left align the start of the button content
  }

  .mat-button-toggle .mat-button-toggle-button {
    border-radius: units.px(4);
  }

  .mat-button-toggle-label-content {
    width: 100%; // ensure the label fills the full width of the button content
  }
}
