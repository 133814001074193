.cq-expansion-panel,
.cq-lob-expansion-panel {
  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-expansion-panel .mat-content.mat-content-hide-toggle {
    margin: 0;
  }
}
