.button-container,
.justify-left {
  justify-content: flex-start;
}

.margin-left {
  margin-left: 5px !important;
}

.advance-notice-cancellation,
.garage-keepers {
  .no-border-bottom {
    border-bottom: none !important;
  }
  .cqui-table > thead > tr > th {
    text-align: left;
  }
}

.add-new-contact {
  float: right;
}
.ineligible-vehicle-message-inline-row {
  background-color: $light-pink !important;
  .message {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .icon {
      width: 15px;
      height: 15px;
      margin: 5px 5px 5px 0;
      display: inline-block;
    }
  }
  td {
    border-top: 1px solid $light-pink;
  }
}
.duplicate-vehicle-message-inline-row {
  background-color: $light-blue !important;
  .message {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .icon {
      width: 15px;
      height: 15px;
      margin: 5px 5px 5px 0;
      display: inline-block;
    }
  }
  td {
    border-top: 1px solid $light-blue;
  }

  .icon-information-circle {
    display: inline-block;
    height: 15px;
    width: 15px;
    margin-left: 3px;
    margin-right: 5px;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
}

.mvr-report {
  border-top: 1px solid #005e7a;
  border-bottom: 1px solid #005e7a;
}

.mvr-report-list {
  border-bottom: 1px solid #005e7a;
  &:last-child {
    border-bottom: none;
  }
}

.mvr-report-grouping {
  margin-top: 10px;
}

.mvr-report-header {
  margin-left: -20px;
}

.auto-add-driver {
  .driver-name-column {
    text-align: left !important;
  }
}

.icon-margin-left {
  margin-left: 30% !important;
}

.auto-text-align-center {
  text-align: center;
}

.page-auto-info {
  ng-form.section.cqui-collapsible-container-group {
    overflow-y: auto;
  }
}
