.cqui-table {
  & > tbody > tr > td {
    &.button-group {
      white-space: nowrap;
    }
  }

  tr.empty-table td {
    color: $dove-gray;
    text-align: center;
    height: 100px;
  }
  &.available-lookups {
    > tbody > tr > td {
      text-align: center;
    }
  }
  .icon-sort {
    min-height: 12px;
    min-width: 12px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

// Added missing background color for even table rows
.tables-container.table-master-details-type
  tbody
  > tr:nth-child(2n):not(.info-row) {
  background-color: $light-blue;
}

// Prevent column header wrapping for
.table-responsive {
  .cqui-table > thead > tr > th {
    white-space: nowrap;
  }
}
