@import "@clearquote/ui/layout/common/common";

.apply-pricing {
  $field-category-width: 35%;
  $field-credit-width: 20%;
  $field-justication-width: 100% - $field-category-width - $field-credit-width;

  .irpm-form-container {
    float: left;
    width: 100%;
    padding: $row-margin-left;

    .irpm-form-container-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    .row-box {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 5px;
      padding-top: 0;

      > .field-category {
        justify-content: flex-end;
        padding-right: 20px;
        width: $field-category-width;
        text-align: right;
        min-height: $field-min-height;
        display: inline-flex;
        align-items: center;
        position: relative;

        span {
          float: left;
          width: 90%;
          line-height: 1.3em;
        }

        .required {
          float: left;
          position: absolute;
          top: -3px;
          right: 5px;
          @include size(5%, 15px);

          span {
            height: 15px;
            position: relative;
            top: -5px;
          }
        }
      }

      .field-credit {
        float: left;
        width: $field-credit-width;
        text-align: center;
        min-height: $field-min-height;
        display: inline-flex;
        align-items: center;

        span {
          width: 100%;
          line-height: 1.2;
        }

        select {
          padding-left: 40%;

          @media (max-width: $screen-sm-max) {
            & {
              padding-left: 30%;
            }
          }

          @supports (text-align-last: center) {
            & {
              padding: 2px 3px 3px 5px;
              text-align-last: center;
            }
          }
        }
      }

      .field-justification {
        float: left;
        width: $field-justication-width;
        margin-left: 10px;
        text-align: left;
        min-height: $field-min-height;
        display: inline-flex;
        align-items: center;
      }

      &.fields-header {
        color: $general-border-blue;
        padding-top: 10px;
        font-weight: 600;
      }

      &.fields-footer {
        margin-top: 10px;
        padding-top: 15px;
        padding-bottom: 10px;
        @include border("top", "blue-general");

        .field-credit {
          margin-right: 10px;

          b {
            word-break: break-word;
          }
        }

        .inline-description {
          width: calc(#{$field-justication-width} - 5px);
          align-items: flex-start;
        }
      }

      &.footer-border {
        @include border("bottom", 1px, $light-gray);
        margin-bottom: 15px;
      }

      &.no-border {
        border-bottom: none;
      }
    }

    .form-control[disabled],
    .form-control[readonly],
    fieldset[disabled] .form-control {
      opacity: 0.5;
      background-color: $disabled-gray;
      @include border("all", 1px, gray);
    }

    .irpm-state-header {
      .state-name {
        font-weight: bold;
        font-size: font-size(
          "irpm-state-header",
          $pages-font-vars-to-size-ratios
        );
        @include font-base();
      }
    }

    .irpm-state-header,
    .irpm-form-header {
      display: flex;
      padding-bottom: 0;

      .inline-description-icon {
        margin-top: 2px;
      }

      .form-button {
        margin-left: auto;
        padding-left: 5px;
      }

      .help-text {
        width: 100%;

        .inline-description {
          margin-top: 5px;
          padding: 5px 10px;
          align-items: center;
        }
      }
    }
  }
}
