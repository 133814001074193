.cqui-icon-button {
  // fix color contrast for accessibility
  &.btn-launch-url {
    background-color: #b2dae7;
  }
  &.icon-small {
    padding: 0px 2px;
  }

  &.checkbox-button {
    min-width: 0px;

    .btn-icon {
      width: 15px;
      height: 15px;
    }

    &.unchecked {
      background-color: #b2dae7;
      border-color: #ccc;
      color: black;
    }
  }
}
