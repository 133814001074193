@import "@clearquote/ui/lib/assets/scss/prerequisites";

#{$lib-prefix}-carousel {
  $carousel-container-min-height: 165px;
  $carousel-description-text: $blue-lagoon;
  $carousel-indicators-marker: $gray;
  $carousel-indicators-marker-inactive: $ash-gray;
  $carousel-indicators-marker-active: $astronaut-blue;

  .#{$lib-prefix}-carousel-container {
    .carousel {
      display: flex;
      flex-direction: column;
      min-height: $carousel-container-min-height;
    }

    .carousel-inner {
      flex: 1 0 auto;
      min-height: $carousel-container-min-height;

      .item {
        height: 100%;

        .#{$lib-prefix}-slide-content {
          display: flex;
          height: 100%;
        }
      }

      .#{$lib-prefix}-carousel-side-icon-container {
        width: 105px;
        padding: 15px 20px;
        display: flex;
        flex: 1 0 auto;
        align-items: flex-start;
        justify-content: center;

        .#{$lib-prefix}-carousel-side-icon {
          @include square(70px);
          max-width: 70px;
          max-height: 70px;
          align-self: flex-start;
        }
      }

      .#{$lib-prefix}-carousel-description-container {
        display: flex;
        flex: 0 1 auto;
        flex-direction: column;
        width: 100%;

        .#{$lib-prefix}-carousel-header {
          @include modal-header();
        }

        .#{$lib-prefix}-carousel-description-entries-container {
          padding: 0 0 0 15px;
        }

        .#{$lib-prefix}-carousel-description-entries {
          color: $carousel-description-text;
          font-size: font-size("carousel-description-text-bullet");
          position: relative;
          list-style: none;

          &:before {
            position: absolute;
            top: 3px;
            left: -15px;
            height: 10px;
            content: "•";
            font-size: font-size("carousel-description-text-bullet");
            padding-right: 5px;
          }
        }

        .#{$lib-prefix}-carousel-description-icon {
          @include square(50px);
          max-width: 50px;
        }

        .#{$lib-prefix}-carousel-description-text {
          font-size: font-size("carousel-listing-description-text");
          color: $carousel-description-text;
          line-height: 2;
        }

        &.no-side-icon {
          display: flex;
          flex-wrap: wrap;
          max-width: 100%;
          padding: 0;

          .#{$lib-prefix}-carousel-header {
            font-size: font-size("carousel-modal-body-header");
            text-align: center;
            padding: 0 10px;
          }

          .#{$lib-prefix}-carousel-description-entries-container {
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            margin-top: 15px;
          }

          .#{$lib-prefix}-carousel-description-entries {
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            padding: 10px;

            &:before {
              display: none;
            }
          }

          .#{$lib-prefix}-carousel-description-text {
            line-height: 1;
            margin-top: 10px;
            text-align: center;
            font-size: font-size("carousel-description-icon-with-text");
            padding: 0 5px;
            width: 100%;
          }
        }
      }
    }

    .carousel-indicators {
      position: relative;
      flex: 0 1 auto;
      height: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0;
      top: 35px;

      .carousel-indicators-marker {
        @include border("all", $carousel-indicators-marker);
        @include square(15px);
        border-radius: 0;
        margin-right: 5px;
        background-color: $carousel-indicators-marker-inactive;
        cursor: default;

        &.stepped,
        &.active {
          background-color: $carousel-indicators-marker-active;
        }
      }

      &.no-user-interactions {
        pointer-events: none;
      }
    }
  }
}
