$button-icon-size: var(--mdc-icon-button-icon-size);
$button-layer-size: var(--mdc-icon-button-state-layer-size);

.cq-date-picker {
  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-icon-size: var(--cq-input-height);
    --mdc-icon-button-state-layer-size: calc(
      var(--mdc-icon-button-icon-size) * 1.25
    );

    padding: calc(($button-layer-size - $button-icon-size) / 2);
  }

  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    height: $button-icon-size;
    width: $button-icon-size;
  }

  mat-datepicker-toggle mat-icon {
    font-size: $button-icon-size;
    height: $button-icon-size;
    width: $button-icon-size;
  }
}
