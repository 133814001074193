.ae-auto-vehicle-coverages {
  .expansible-list-table {
    .cqui-table {
      border-collapse: separate;
    }
  }

  .interest-table {
    .contact-number {
      text-align: center;
    }

    .contact-icon {
      text-align: right;
    }

    .contact-name {
      text-align: left;
    }

    .contact-address {
      text-align: left;
    }
  }

  .has-medium-width {
    width: 120px !important;
  }

  .cqui-collapsible-container-group .section {
    border-bottom: none;
  }
}
