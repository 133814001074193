@import "prerequisites";

.tooltip-container {
  $tooltip-max-width: 400px;
  $tooltip-severe-error: $misty-rose;
  $tooltip-severe-error-border: $hot-pink;
  $tooltip-severe-caution: $yellow;
  $tooltip-severe-caution-border: $corn;

  position: fixed;
  left: 0px;
  min-width: 200px;
  width: auto;
  max-width: $tooltip-max-width;
  background-color: $light-blue;
  box-shadow: 2px 2px 3px 0px $gray;
  display: none;
  animation-fill-mode: forwards;
  pointer-events: none;
  @include border("all", "gray-general");
  @include border-radius("all", tooltip);
  outline: 1px solid transparent;

  &.active {
    display: block;
  }

  &.no-immediate-tooltip,
  &.animate-end {
    display: none;
  }

  .tooltip-content {
    display: flex;
    padding: 4px 7px;
    align-items: flex-start;
    line-height: 1.25em;

    .tooltip-icon {
      @include square(15px);
      min-width: 15px;
      @include icon-margin("right");
    }

    .tooltip-text {
      color: $black;
      text-align: left;
      line-height: 1.1em;
      font-size: font-size("tooltip-text");
      font-weight: normal;

      .section {
        background-color: transparent;
      }

      .required {
        position: relative;
        right: 0;
        float: left;
        margin-right: 5px;
      }

      .required {
        position: relative;
        right: 0;
        float: left;
        margin-right: 5px;
      }

      p {
        + .tooltip-list {
          padding-left: 25px;
        }
      }
    }

    .tooltip-list {
      padding-left: 20px;
      line-height: 1.1;
    }

    .tooltip-list-entry {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.tooltip-container-large {
    max-width: 600px;
  }

  &.tooltip-container-extra-large {
    max-width: 700px;
  }

  &.tooltip-severe-error {
    background-color: $tooltip-severe-error;
    border-color: $tooltip-severe-error-border;
  }

  &.tooltip-caution-type {
    background-color: $tooltip-severe-caution;
    border-color: $tooltip-severe-caution-border;
  }

  .tooltip-listing-group {
    color: $black;

    .tooltip-listing-header-group {
      display: flex;
      padding-bottom: 4px;
      margin-top: 5px;
      @include border("bottom", "black");

      .#{$lib-prefix}-icon {
        @include icon-margin("right", 5px);
      }

      .tooltip-listing-header {
        margin: 0;
        padding-right: 5px;
        font-weight: bold;
      }
    }

    .tooltip-listing {
      padding: 5px;
    }

    .tooltip-listing-entry {
      margin: 5px 10px 0;
      display: flex;
      align-items: center;

      .tooltip-listing-icon-container {
        min-width: 35px;
        display: flex;
        justify-content: center;
      }

      #{$lib-prefix}-icon {
        height: 20px;
        width: 35px;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  @media (max-width: $tooltip-max-width) {
    & {
      max-width: 100%;
    }
  }
}

.cqui-informational-indicator {
  @include square(15px);
  display: flex;
  font-size: 0;
  white-space: pre-wrap;
}
