.loss-information {
  .form-control {
    width: 45%;
  }

  .cqui-collapsible-container {
    .heading-content {
      outline: none;
    }
  }
}
