.cqui-collapsible-container-group
  .cqui-collapsible-container.disabled
  .accordion-heading
  .container-heading
  .container-title {
  //fix color contrast for accessibility
  color: #595959;
}

.cqui-collapsible-container-group
  .cqui-collapsible-container
  .accordion-heading
  .accordion-toggle:empty {
  min-height: 0;
}
