.auto-coverages {
  .state-header {
    @include border("bottom", blue);
    padding-bottom: 0px;
  }

  .section-header {
    float: none;
  }

  .section.section-fields {
    padding-bottom: 0px;
  }

  .section-group.state-group {
    border-top: none;

    & > .section {
      padding-top: 0px;
    }
  }

  .section-modifiers {
    border-bottom: none;
  }

  .modifiers-and-deductibles {
    .section {
      border-bottom: none;
    }

    .section-header {
      margin-left: 0;
      margin-right: 0;
    }

    .section-modifiers,
    .section-deductibles {
      .section-header {
        margin-top: 0;
      }

      > .section {
        padding-top: 0;
        padding-bottom: 0;

        &.section-highlighted {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
}
