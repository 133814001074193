.wc-classification {
  .class-column {
    width: 190px;
    text-align: center;
  }

  .icon-forbidden {
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 3px;
  }
}

.wc-classification {
  .class-column-jobsitecounty {
    width: 150px;
  }
}

.wc-classification {
  select.form-control {
    color: #000;

    option:not(:checked) {
      color: $black;
    }

    &.selected-option {
      color: $black;
    }
  }
}
